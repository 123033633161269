var remote = window.require('electron').remote;
var fs = remote.require('fs');
const { inAppPurchase } = remote

//const PRODUCT_IDS = ['mac1credit', 'mac5credit', 'mac10credit', 'mac20credit', 'mac40credit', 'mac80credit', 'mac100credit', 'mac150credit']

if (inAppPurchase.canMakePayments() === true) {
    inAppPurchase.on('transactions-updated', (event, transactions) => {
        console.log('transactions-updated-------->')
        if (!Array.isArray(transactions)) {
            return
        }

        // Check each transaction.
        transactions.forEach(function (transaction) {
            var payment = transaction.payment
            window.dispatchEvent(new CustomEvent("onStoreUpdate", { detail: transaction.transactionState }));

            switch (transaction.transactionState) {
                case 'purchasing':
                    console.log(`Purchasing ${payment.productIdentifier}...`)
                    break
                case 'purchased':

                    console.log(`${payment.productIdentifier} purchased.`)

                    // Get the receipt url.
                    let receiptURL = inAppPurchase.getReceiptURL()

                    console.log(`Receipt URL: ${receiptURL}`)

                    //window._iapUrl = 'http://192.168.0.42:5000/simpleschedule-9ae21/us-central1/iapVerify'
                    var iapUrl = `https://${window._iapUrl}/iapVerify?p=${window._projectKey}` //

                    console.log('iapUrl', iapUrl)
                    // get receipt data from file
                    var receiptRaw = fs.readFileSync(receiptURL)

                    // base64 encode the receipt data
                    var receiptEncoded = receiptRaw.toString('base64')
                    window.dispatchEvent(new CustomEvent("onStoreUpdate", { detail: 'waiting_for_validation' }));
                    fetch(iapUrl,
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                id: payment.productIdentifier,
                                transaction: {
                                    extratype: 'mac',
                                    type: 'apple',
                                    appStoreReceipt: receiptEncoded
                                }
                            })
                        }).then(res => {

                            console.log('res.status', res.status)
                            if (res.status === 200)
                                window.dispatchEvent(new CustomEvent("onStoreUpdate", { detail: 'success' }));
                            else
                                window.dispatchEvent(new CustomEvent("onStoreUpdate", { detail: 'faild' }));

                        })

                    // Finish the transaction.
                    inAppPurchase.finishTransactionByDate(transaction.transactionDate)

                    break
                case 'failed':

                    console.log(`Failed to purchase ${payment.productIdentifier}.`)

                    // Finish the transaction.
                    inAppPurchase.finishTransactionByDate(transaction.transactionDate)

                    break
                case 'restored':

                    console.log(`The purchase of ${payment.productIdentifier} has been restored.`)

                    break
                case 'deferred':

                    console.log(`The purchase of ${payment.productIdentifier} has been deferred.`)

                    break
                default:
                    break
            }
        })
    })
    window.isStoreInit = true

} else {
    console.log('#####The user is not allowed to make in-app purchase.#######')
}
export default inAppPurchase