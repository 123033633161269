import React, { Component } from "react";
import { Segment, Header, Button } from "../../common/component/Button";
import { FireHelper } from '../../common/util/FireHelper'
import { connect } from 'react-redux'
import { db } from "../../config/firebase";
import { isMobile } from 'react-device-detect';

//import PropTypes from 'prop-types'

class Dev extends Component {
  state = {
    role: '',
    pKey: '',
    msg: '',
    title: '',
  }

  componentDidMount = () => {
    //TODO check for auth before 
    if (this.props.authUser) {
      FireHelper.reloadToken().then((token) => {

        this.setState({ role: token.claims.role || '', pKey: token.claims.pKey || '' })
      })
    }
  }

  testCreateUser = (key) => async () => {
    var projectKey = 'KOuSxdTCEyK139TK4cNP'

    switch (key) {
      case 4:
        var uid = 'testUi150'
        var newUser = { role: 250 }
        this.setState({ title: 'setUser', msg: '' })
        await db.doc(`users/${uid}`).set(newUser, { merge: true }).catch(err => {
          this.setState({ msg: JSON.stringify(err) })
        })
        break;
      case 3:
        // var uid = 'testUi'
        // var newUser = { uid, name: 'test', role: 50 }
        // this.setState({ title: 'setUser', msg: '' })
        // await db.doc(`users/${uid}`).set(newUser).catch(err => {
        //   this.setState({ msg: JSON.stringify(err) })
        // })
        break;
      case 2:
        this.setState({ title: 'user get list', msg: '' })
        await db.collection(`users`).get().then(function (doc) {
          console.log('doc', doc)
          this.setState({ msg: JSON.stringify(doc) })
          console.log(doc)
        }).catch(err => {
          this.setState({ msg: JSON.stringify(err) })
        })

        break;
      case 1:
        this.setState({ title: '/users/userlist', msg: '' })

        db.doc(`projects/${projectKey}/users/userlist`).get().then(function (doc) {
          this.setState({ msg: JSON.stringify(doc) })
          console.log(doc)
        }).catch(err => {
          this.setState({ msg: JSON.stringify(err) })
        })

        break;

      default:
        break;
    }
  }
  testQ = async () => {
    if (this.props.authUser) {
      FireHelper.reloadToken().then((token) => {

        this.setState({ role: token.claims.role || '', pKey: token.claims.pKey || '' })
      })
    }
    // var query = await db.collection(`users`)

    // query = query.where('projectKey', '==', 'cjoh61emj000002s69x2m8sid')
    // query = query.where('extraData.isActive', '==', true)
    // query = query.where('alerts.m.All', '==', 0)

    // var data = await query.get().catch(err => {
    //   console.log(err)
    //   this.setState({ msg: JSON.stringify(err) })
    // })
    // 'pS4IsDI6GCZg3jhupRedkihKbgP2',
    // 'aG94xaMYivVLPvOvfRIsgZh7Skv2',
    // 'X0P5qYoumJZM5sdmSIZLPiQNVZU2',
    // '4FTRbBNvxPh9xvVMpykWEmFXbBG3',
    // 'Egltis59epXM0B1ALDvkA8DsoBI2',
    // 'yyRyTMItaINvxQkQzAbYqBHv7HX2',
    // 'yyRyTMItaINvxQkQzAbYqBHv7HX2',
    // 'QCapKJkdloaH6hsPG2cSyoKYrRf2',
    // 'gy0hnhPfAecIjAIoSCZTi3pC9WL2',
    // 'L8DWPyCkXVUFhvofyqCp024lSDD2',
    // 'xNeyomneFFRX9ehC45OlTfIsoPO2',
    // 'xcZBWTqQUmUvgsF81x2Q0t9yCFN2',


    // var document = { p: ['gC1T6k6t9IgmAgBtONl59H0M1de2'] }
    // console.log('document.p', document.p)
    // document.p.forEach(async (uKey) => {
    //   var data1 = await db.collection(`users`).where('key', '==', uKey).get().catch(err => {console.log(err)})
    //   if (data1 && data1.docs[0]) {
    //     data1.docs.set({ alerts: { m: { [docKey]: 0 } } }, { merge: true });
    //   }
    // });
    // query1 = query1.where('extraData.isActive', '==', true)

    // console.log('data1', data1)
    // if (data1 && data1.size) {
    //   console.log('data1.size', data1.size)
    //   data1.docs.map((doc) => {
    //     console.log('doc')
    //     // return doc.ref.set({ alerts: { m: { [docKey]: 0 } } }, { merge: true });
    //   })
    // } else {
    //   console.log('no users to update 2')
    // }


  }

  repairAccount = async () => {
    const { currentUser } = this.props

    console.log('do it', currentUser)
    console.log(await FireHelper.addCmd({ uk: currentUser.key, cmd: 'repairRole' }, currentUser))
    console.log('done')
  }

  render() {
    // if (!window.location.hostname === "localhost")
    //   return (<span>.</span>)

    //console.log(this.props.authUser.uid)

    //FireHelper.reloadToken();

    return (
      <Segment attached style={{ border: 'none' }}>
        <Header as="h2" >Dev</Header>
        {this.props.authUser &&
          <div>uid : {this.props.authUser.uid}</div>
        }
        <div>isMobile: {isMobile && 'true'}</div>
        <div>token.claims.role : {this.state.role}</div>
        <div>currentUser.role : {this.props.currentUser.role}</div>
        <div>pKey : {this.state.pKey}</div>
        <h3>{this.state.title}</h3>
        <div>{this.state.msg}</div><br />
        <Button compact onClick={this.repairAccount}>Repair account</Button>
        <Button compact onClick={() => { window.location.reload() }}>Refresh</Button>

        {/* <Button onClick={this.testQ}>1</Button> */}
        {/* <Button onClick={this.testCreateUser(1)}>1</Button>

        <Button onClick={this.testCreateUser(2)}>2</Button>
        <Button onClick={this.testCreateUser(3)}>3</Button>
        <Button onClick={this.testCreateUser(4)}>4</Button> */}
        {/* <Button onClick={this.testCreateUser(3)}>test create 200</Button><br/>
        <Button onClick={this.testCreateUser(4)}>test create 200</Button><br/>
        <Button onClick={this.testCreateUser(5)}>test create 200</Button><br/> */}

      </Segment>

    );
  }
}
const mapStateToProps = (state) => {
  //state.firebase.auth().currentUser.reload()
  // auth.currentUser.getIdToken(true).then(x => console.log('>>getIdToken', x))

  // auth.currentUser.getIdTokenResult().then((idTokenResult) => {
  //   // Confirm the user is an Admin.
  //   console.log('8')
  //   console.log('>>idTokenResult.claims', idTokenResult.claims)
  //   if(idTokenResult.claims.role)
  //   {
  //     alert('redirect')
  //   }

  // })
  //   .catch((error) => {
  //     console.log(error);
  //   });

  return { authUser: state.firebase.auth, currentUser: state.firebase.profile, firebase: state.firebase };
}
const mapActions = {}
export default connect(mapStateToProps, mapActions)(Dev);

