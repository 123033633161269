import React, { Component } from "react";
import PropTypes from 'prop-types'
import { connect } from "react-redux";
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import Message from 'semantic-ui-react/dist/commonjs/collections/Message'
import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

import { openModal } from "../../redux/action/modalAction";
import ShiftUserInput from '../modal/ShiftUserInput'
import FirestoreListnerHelper from '../../common/util/FirestoreListnerHelper'
import noUserImage from '../../../img/noimg.png'
//import Swipe from 'react-swipe-component'
import { renderDateAddDays, setRenderNow } from '../../redux/action/viewstatAction'
import { isMobile } from 'react-device-detect';
import { getUserImage, toTop, getLocalDate } from '../../common/util/helpers'
import SwipeX from '../../common/component/SwipeX'



import moment from 'moment'
const modalKey = 'shiftuserinput'
class ScheduleUser extends Component {
  state = { schedulesList: [], isEmpty: true, slideAni: '' }
  static contextTypes = { store: PropTypes.object.isRequired }




  componentDidMount = () => {
    toTop()
    if (this.props.currentUser.isLoaded === true) {
      FirestoreListnerHelper.setWeekViewListner(this.context, this.props.renderDate)
    }
  }
  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    if (prevProps.renderDateKey !== this.props.renderDateKey) {
      FirestoreListnerHelper.setWeekViewListner(this.context, this.props.renderDate)
    }
    console.log('componentDidUpdate')

  }


  onOpenModal = (data) => () => {
    this.props.onOpenModal(modalKey, data)
  }
  renderCells = (shifts, todayStr, scheduleKey) => {
    var shift = shifts[todayStr]


    if (shift) {
      return (
        <td className="two wide scheduelCell" >
          <div className='flexDiv'>
            {shift.map(x => {
              return (
                <div onClick={() => { console.log('show info box!') }} key={x.k} className={'x' + (x.sik === true ? '' : x.c) + ' cellMulti ui label dragMe'} >
                  <div style={{ color: x.sik ? 'silver' : '' }} >{x.l}</div>
                  {/* {x.co && <span >{x.l}</span>} */}
                  {x.sik && <span> <Icon name='bed' size='small' /></span>}

                </div>
              )
            })}
          </div>
        </td>
      )
    }
    return (
      <td className="two wide scheduelCell" ></td>
    )
  }

  renderSchedual = (schedule, date) => {

    const { projectKey, project } = this.props

    if (!project)
      return <div style={{ textAlign: 'center', marginTop: '50px' }}><Loader inline active>{window._getText('loading')}</Loader></div>

    if (!schedule)
      return <div style={{ textAlign: 'center', marginTop: '50px' }}><Loader inline active>{window._getText('loading')}</Loader></div>


    //  console.log('schedule', schedule)
    return (
      <React.Fragment key={schedule.sKey}>
        <div style={{ padding: '4px', marginTop: '5px', fontWeight: 'bolder', textAlign: "center", fontSize: '26px' }}>{schedule.sName}</div>
        <table key={schedule.sKey} style={{ border: 'solid silver 0px ', paddingLeft: '10px', margin: '0px', marginBottom: '20px' }} className="ui very basic table tableBody unstackable tblScheduel">
          <thead>

            <tr>
              <td className="two wide tdDateHeader"></td>


              <td className="two wide tdDateHeader"   >{getLocalDate(date, "dd\u00A0DD")}</td>
              <td className="two wide tdDateHeader" >{getLocalDate(date.clone().add(1, 'days'), "dd\u00A0DD")}</td>
              <td className="two wide tdDateHeader" >{getLocalDate(date.clone().add(2, 'days'), "dd\u00A0DD")}</td>
              <td className="two wide tdDateHeader" >{getLocalDate(date.clone().add(3, 'days'), "dd\u00A0DD")}</td>
              <td className="two wide tdDateHeader" >{getLocalDate(date.clone().add(4, 'days'), "dd\u00A0DD")}</td>
              <td className="two wide tdDateHeader" >{getLocalDate(date.clone().add(5, 'days'), "dd\u00A0DD")}</td>
              <td className="two wide tdDateHeader" >{getLocalDate(date.clone().add(6, 'days'), "dd\u00A0DD")}</td>


            </tr>


          </thead>
          <tbody >
            {schedule.rows.map(row => {

              //if (row.k === this.props.inSysUserKey || this.props.isUnknown === true)
              if (row.k.startsWith('div_')) {
                return (
                  <tr key={row.k}>
                    <td rowSpan='8' style={{ padding: '0px', paddingTop: '10px', }} >
                      <span style={{ fontSize: '13px', fontWeight: 'bold' }}>{row.u}</span>
                    </td>
                  </tr>
                )
              }
              else {
                var user = project.cache[row.k] || { i: '', n: '#unknown', a: false, sk: '' }
                if (user.a === false)
                  return null

                return (
                  <tr key={row.k}>
                    <td className='cellUsername'>
                      <Image style={{ width: '35px', height: '35px' }} size='mini' src={user.i ?
                        getUserImage(projectKey, row.k, user.i)
                        : noUserImage} circular
                        onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }}
                      />
                      {!this.props.isLandscape && <div style={{
                        fontSize: '13px',
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        verticalAlign: 'top', margin: '0px', padding: '2px', lineHeight: '12px'
                      }}>{user.n}</div>}
                      {this.props.isLandscape && <div style={{
                        fontSize: '13px',
                        lineHeight: '14px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}>{user.n}</div>}
                    </td>

                    {this.props.firstDayOfWeekNumber === 0 &&
                      <React.Fragment>
                        {this.renderCells(row.s, 7, schedule.sKey)}
                        {this.renderCells(row.s, 1, schedule.sKey)}
                        {this.renderCells(row.s, 2, schedule.sKey)}
                        {this.renderCells(row.s, 3, schedule.sKey)}
                        {this.renderCells(row.s, 4, schedule.sKey)}
                        {this.renderCells(row.s, 5, schedule.sKey)}
                        {this.renderCells(row.s, 6, schedule.sKey)}
                      </React.Fragment>
                    }
                    {this.props.firstDayOfWeekNumber === 1 &&
                      <React.Fragment>
                        {this.renderCells(row.s, 1, schedule.sKey)}
                        {this.renderCells(row.s, 2, schedule.sKey)}
                        {this.renderCells(row.s, 3, schedule.sKey)}
                        {this.renderCells(row.s, 4, schedule.sKey)}
                        {this.renderCells(row.s, 5, schedule.sKey)}
                        {this.renderCells(row.s, 6, schedule.sKey)}
                        {this.renderCells(row.s, 7, schedule.sKey)}
                      </React.Fragment>
                    }
                  </tr>
                )
              }


            })}
          </tbody>
        </table>
      </React.Fragment>
    )
  }

  toggleFullScreen = () => {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl);
    }
    else {
      cancelFullScreen.call(doc);
    }
  }

  setRenderDate = (d) => () => {
    this.props.renderDateAddDays(d)
  }

  gotoToday = () => {
    this.props.setRenderNow()
  }


  onSwipedLeft = () => {
    this.setState({ slideAni: 'slideAniLeft' })
    this.props.renderDateAddDays(7)

  }
  onSwipedRight = () => {
    this.setState({ slideAni: 'slideAniRight' })
    this.props.renderDateAddDays(-7)
  }
  animationEnd = (e) => {
    if (e.target.className === 'slideAniLeft') {
      this.setState({ slideAni: '' })
    }
    else if (e.target.className === 'slideAniRight') {
      this.setState({ slideAni: '' })
    }
  }

  render() {
    const rDate = this.props.renderDate.clone()
    const scheduleWeekData = this.props.fireData['weekview_' + this.props.renderDateKey] || {}

    const renderData =
      Object.keys(scheduleWeekData).map(key => {
        return this.renderSchedual(scheduleWeekData[key], rDate)
      })



    return (
      <React.Fragment>
        <SwipeX onSwipedLeft={this.onSwipedLeft} onSwipedRight={this.onSwipedRight}></SwipeX>

        {/* <Swipe
          className='swipeDiv'
          onSwipedLeft={this.onSwipedLeft}
          onSwipedRight={this.onSwipedRight}
        > */}
        <div style={{ overflow: 'hidden' }}>




          {!isMobile &&
            <div className='fixed' style={{ position: 'fixed', background: '#fff', width: '100%', zIndex: '10', height: '60px' }}>
              <Icon name='angle left' style={{ float: 'left', cursor: 'pointer', paddingTop: '15px' }} size='big' onClick={this.setRenderDate(-7)}></Icon>
              <div style={{ float: 'left', textAlign: 'center', paddingTop: '15px' }}>
                <div style={{ fontSize: '40px', fontWeight: '900' }} >{window._getText('week')} {rDate.local().format("w")}</div>
                <div onClick={this.gotoToday} style={{ cursor: 'pointer', color: 'silver', paddingRight: '10px' }}>{getLocalDate(moment(), "LL")}</div>
              </div>
              <Icon name='angle right' style={{ float: 'left', cursor: 'pointer', paddingTop: '15px' }} size='big' onClick={this.setRenderDate(7)}></Icon>
              <div style={{ clear: 'both' }}></div>
            </div>
          }

          {isMobile &&
            <div className='fixed' style={{ position: 'fixed', background: '#fff', width: '100%' }}>
              <div style={{ paddingLeft: '10px', paddingTop: '10px', float: 'left', fontSize: '20px', fontWeight: '900' }} >{window._getText('week')} {rDate.local().format("w")}</div>
              <div onClick={this.gotoToday} style={{ fontSize: '12px', color: 'silver', float: 'right', paddingRight: '10px' }}>{getLocalDate(moment(), "LL")}</div>
              <div style={{ clear: 'both' }}></div>
            </div>
          }
          <div className={this.state.slideAni} onAnimationEnd={this.animationEnd} style={{ paddingTop: isMobile ? '25px' : '60px' }}>
            <Loader active={!this.props.isDataLoaded} style={{ textAlign: 'center', marginTop: '50px' }}>{window._getText('loading')}</Loader>

            {this.props.isUnknown &&
              <Message>
                <Message.Header>{window._getText('usernotmapped_title')}</Message.Header>
                <p>{window._getText('usernotmapped_txt1')} <br />
                  {window._getText('usernotmapped_txt2')}</p>
              </Message>
            }

            {(renderData.length === 0 && this.props.isDataLoaded) &&
              <div style={{ textAlign: 'center', marginTop: '50px' }}>
                <Icon name='heart outline' size='huge'></Icon><br />
                {window._getText('userhome.youarefree')}
              </div>
            }

            {renderData.length > 0 && renderData}
          </div>
          {/* <br />
          <h3 style={{ padding: '0px', margin: '0px', display: 'inline' }}><Icon name="archive" />Available shifts</h3>
          <br />
          <h3 style={{ padding: '0px', margin: '0px', display: 'inline' }}><Icon name="exchange" />Swap requests</h3>
          <br /> */}

          <div style={{ position: 'absolute', top: '0px' }}>
            <ShiftUserInput></ShiftUserInput>
          </div>
        </div>
        {/* </Swipe> */}
      </React.Fragment>
    )
  }
}


const mapStateToProps = (state, ownProps, ownState) => {


  const inSysUserKey = state.firebase.profile.inSysUserKey
  const firstDayOfWeekNumber = Number(state.firebase.profile.firstDay) //sunday 0 , monday 1


  var isUnknown = true
  if (inSysUserKey)
    isUnknown = false;



  return ({
    isDataLoaded: state.firestore.status.requested[`projects/${state.firebase.profile.projectKey}/weekview/${state.viewstate.renderDateKey}`],
    renderDate: state.viewstate.renderDate,
    renderDateKey: state.viewstate.renderDateKey,
    projectKey: state.firebase.profile.projectKey,
    project: state.firestore.data.project,
    fireRequested: state.firestore.status.requested,
    fireData: state.firestore.data,

    isLandscape: state.viewstate.isLandscape,
    currentUser: state.firebase.profile,
    firstDayOfWeekNumber,
    isUnknown,
    inSysUserKey,

  });
}
const mapActionsFromProps = {
  onOpenModal: openModal,
  renderDateAddDays,
  setRenderNow
}
export default connect(
  mapStateToProps,
  mapActionsFromProps
)(ScheduleUser);

