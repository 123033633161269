import React, { PureComponent } from "react";
import { Message, Button, Icon } from "../../../common/component/Button";
import { connect } from 'react-redux'
import moment from 'moment';
import PropTypes from 'prop-types'


class Warning extends PureComponent {
  static contextTypes = { router: PropTypes.object }
  state = { isClosed: false }

  onClose = () => {
    this.setState({ isClosed: true })
  }
  render() {
    var { project, isLandscape } = this.props
    if (!project || !project.disableDate)
      return ''

    var dayDiff = 0
    const diablseAccountAfterDays = 7
    if (project && project.disableDate) {
      var disableDate = moment(project.disableDate).utc().add(diablseAccountAfterDays, 'days')
      var now = moment().utc()
      dayDiff = disableDate.diff(now, 'days')
      console.log('-----------')
      console.log('disableDate', disableDate.format('YYYY-MM-DD HH:mm'))
      console.log('dayDiff', dayDiff)
      console.log('project.enabled', project.enabled)
      console.log('project.disabled', project.disabled)
      console.log('-----------')
    }




    if (!project.enabled && !project.disabled) {
      if (this.state.isClosed === true)
        return (
          // onClick={() => { this.context.router.history.push('/schedule/settings') }}
          <span style={{ borderRadius: '5px', padding: '3px', backgroundColor: '#fff', opacity: '0.9', zIndex: '100', position: 'fixed', right: '15px', bottom: isLandscape ? '3px' : '75px', cursor: 'pointer' }}><Icon name='exclamation' color='red' />{dayDiff} days left<Icon name='exclamation' color='red' /></span>
        )


      return (
        <div className='fixedWarning' style={{ opacity: '0.9', width: isLandscape ? '' : '98%', zIndex: '100', position: 'fixed', marginLeft: '2px', bottom: isLandscape ? '3px' : '75px', cursor: 'pointer' }}>


          <Message color='red'>
            <Button compact className='closeButton' size='huge' onClick={this.onClose} floated='right' icon='close' />
            <Message.Header>{window._getText('billing.msgHead')} </Message.Header>
            <p onClick={() => { this.context.router.history.push('/schedule/settings') }}>
              {window._getText('billing.msgHead1')} {dayDiff} {window._getText('billing.msgBodyDays')}{' '}
              {window._getText('billing.msgHead2')}
            </p>
          </Message>


        </div>
      )
    }


    return ''

  }
}



export default connect((state) => ({
  project: state.firestore.data.project,
  isLandscape: state.viewstate.isLandscape
}))(Warning)
