import { Component } from 'react'

export default class SwipeX extends Component {


    swipeHandler = (obj) => {
        const swipe = obj.detail
        if (swipe)
            if (swipe.dir === 'left') {
                if (this.props.onSwipedLeft) { this.props.onSwipedLeft() }
            }
            else {
                if (this.props.onSwipedRight) { this.props.onSwipedRight() }
            }

    }

    componentWillUnmount = () => {
        //console.log('removeEventListener->onSwipeX')
        window.removeEventListener('onSwipeX', this.swipeHandler, false);
    }

    componentDidMount = () => {
        //console.log('componentDidMount->onSwipeX')
        window.addEventListener('onSwipeX', this.swipeHandler, false);

    }

    render() {
        return ('')
    }
}
