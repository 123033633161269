import { MODAL_CLOSE, MODAL_OPEN, MODAL_EVENT_CLOSE, MODAL_EVENT_OPEN, MODAL_COPY_SCHEDULE_OPEN, MODAL_COPY_SCHEDULE_CLOSE, MODAL_EDIT_SCHEDULE_OPEN, MODAL_EDIT_SCHEDULE_CLOSE } from "../action/modalAction";
import { createReducer } from "../../common/util/reducerUtil";

const initState = {
  modals: {
    shiftadminrequest: { open: false },
    appSettings: { open: false },
    userBulkImport: { open: false },
    selectUsers: { open: false },
    userSendinvite: { open: false },
    user: { open: false },
    shiftuserinput: { open: false },
    appMenu: { open: false },
    selectschedules: { open: false }
  },
  event: null,
  eventKey: null,
  eventModalOpen: false,
  copyShedualModalOpen: false,
  editShedualModalOpen: false,
  editShedualModalKey: null,
  editShedualModalName: null
};

export const openModal = (state, payload) => {

  var modalKey = payload.modalKey
  var modals = { ...state.modals }
  var modal = { ...modals[modalKey], ...payload }
  modals[modalKey] = modal



  return { ...state, modals: { ...modals } };
};
export const closeModal = (state, payload) => {
  var modalKey = payload.modalKey
  var modals = { ...state.modals }
  var modal = { ...modals[modalKey], ...payload }
  modals[modalKey] = modal

  return { ...state, modals: { ...modals } };
};
export const openModalEvent = (state, payload) => {
  return { ...state, ...payload };
};
export const closeModalEvent = (state, payload) => {
  return { ...state, ...payload };
};

export const openCopySchedule = (state, payload) => {
  return { ...state, ...payload };
};
export const closeCopySchedule = (state, payload) => {
  return { ...state, ...payload };
};

export const openEditSchedule = (state, payload) => {
  return { ...state, ...payload };
};
export const closeEditSchedule = (state, payload) => {
  return { ...state, ...payload };
};


export default createReducer(initState, {
  [MODAL_CLOSE]: closeModal,
  [MODAL_OPEN]: openModal,
  [MODAL_EVENT_CLOSE]: closeModalEvent,
  [MODAL_EVENT_OPEN]: openModalEvent,
  [MODAL_COPY_SCHEDULE_OPEN]: openCopySchedule,
  [MODAL_COPY_SCHEDULE_CLOSE]: closeCopySchedule,
  [MODAL_EDIT_SCHEDULE_OPEN]: openEditSchedule,
  [MODAL_EDIT_SCHEDULE_CLOSE]: closeEditSchedule
});
