import React, { Component } from 'react'
import { Button, Icon, Popup } from "../../common/component/Button";
import { Dropdown, Menu } from "semantic-ui-react";
import { ScheduleWeekpicker } from './ScheduleWeekpicker'
import moize from "moize";
import { isMobile } from 'react-device-detect';
import { getLocalDateStr } from '../../common/util/helpers'


import moment from 'moment'
import { shallowEqual } from 'shouldcomponentupdate-children';

var isElectronApp = (process.env.REACT_APP_ELECTRON === '1')
let ipcRenderer
isElectronApp = !!window.require
if (isElectronApp) {
    ipcRenderer = window.require('electron').ipcRenderer
}

let isMacOs = (process.env.REACT_APP_MACOS === '1')
console.log('isMacOs', isMacOs)
export class ScheduleMenu extends Component {
    state = { openDDCopy: false, openDDPlus: false, openDDPrint: false, openDDRedo: false, openDDView: false }// ?? inuse ? 
    shouldComponentUpdate(nextProps, nextState) {
        const r = shallowEqual(this.props, nextProps, this.state, nextState);
        // console.log('>>shouldComponentUpdate>>SchedualMenu>>', r)
        return r;
    }

    componentDidMount = () => {


    }
    componentWillUpdate = () => {
        //if (this.state.selectedScheduel === null && this.props.scheduleDropDownList[0])// ?? inuse ? 
        //  this.setState({ selectedScheduel: this.props.scheduleDropDownList[0].text })// ?? inuse ? 

    }

    onClickSchema = (e, { value, text }) => {
        // this.setState({ selectedScheduel: text }) // ?? inuse ? 
        this.props.onChangeSchema(e, { value, text })
    }
    onOpenEditScheduleModal = () => {
        this.props.openEditScheduleModal(null, null)
        this.setState({ openDDCopy: false, openDDPlus: false })
    }
    onOpenEditScheduleModalEdit = () => {
        this.props.openEditScheduleModal(this.props.scheduleDropdownKey, this.props.scheduleDropdownName)
        this.setState({ openDDCopy: false, openDDPlus: false })
    }
    openSelectSchedualModal = () => {
        console.log('openSelectSchedualModal')
        this.props.openSelectSchedualModal()
        this.setState({ openDDCopy: false, openDDPlus: false, openDDPrint: false, openDDRedo: false, openDDView: false })
    }
    onMenuClick = (key) => () => {
        this.props[key]()
        this.setState({ openDDCopy: false, openDDPlus: false, openDDPrint: false, openDDRedo: false, openDDView: false })
    }


    setOpen = (name) => () => {
        this.setState({ [name]: true })
    }
    setClose = (name) => () => {
        this.setState({ [name]: false })
    }
    getDropdownData = moize.react((scheduleDropDownList) => {
        //console.log('menu no cahce')
        return (
            <Dropdown.Menu scrolling>
                {scheduleDropDownList.map(option => {
                    if (!option) {
                        return null
                    }

                    return (<Dropdown.Item icon='calendar alternate outline' onClick={this.onClickSchema} key={option.value} {...option} />)
                })}

            </Dropdown.Menu>)

    })

    short = (name, n) => {
        if (!name)
            return ''
        return (name.length > n) ? name.substr(0, n - 1) + '...' : name
    }

    printPdf = () => {
        if (isElectronApp) {
            ipcRenderer.send('printToPDF', '')
        }
    }

    render() {

        var { isLandscape, scheduleDropDownList, currentScheduelData, onSaveSchedule, curScheduleView, showWeekPicker, onClickChangeDate2, onClickChangeDate, onClickShowWeekPicker } = this.props
        return (
            <React.Fragment>
                <div className='sMenu_s1 dragWindow noPrint' style={{ border: 'solid 0px red', paddingTop: '5px', background: 'var(--color-bg1)', paddingRight: isMacOs === true ? '6px' : '70px', }} >
                    <div  >



                        {isMobile &&
                            <div onClick={this.openSelectSchedualModal} size='large'>
                                <Icon size='large' name='calendar alternate outline'></Icon>{this.short(this.props.scheduleDropdownName, 8)}
                            </div>
                        }
                        {!isMobile && isMacOs !== true &&
                            <div className='noPrint'   >

                                <Icon name="calendar alternate outline" size='large' />


                                <Dropdown
                                    style={{ minWidth: '150px' }}
                                    text={isLandscape ? this.short(this.props.scheduleDropdownName, 30) : this.short(this.props.scheduleDropdownName, 14)}
                                    floating labeled className='icon' pointing='top left'   >
                                    <Dropdown.Menu  >

                                        <Dropdown.Header >
                                            <div className='sMenu_s2'>
                                                <b className='sMenu_s3'>{this.props.scheduleDropdownName} </b>
                                                <Popup trigger={<Button compact icon='pencil alternate' size='small' className='sMenu_s4' onClick={this.onOpenEditScheduleModalEdit} />} content={window._getText('schedual.editS')} />
                                            </div>
                                        </Dropdown.Header>



                                        {this.getDropdownData(scheduleDropDownList || [])}

                                    </Dropdown.Menu>
                                </Dropdown>



                            </div>}


                        {isLandscape && isMacOs !== true &&
                            <React.Fragment>


                                <Dropdown className='noPrint' item icon='add' open={this.state.openDDPlus} onMouseLeave={this.setClose('openDDPlus')} onMouseMove={this.setOpen('openDDPlus')}     >
                                    <Dropdown.Menu   >
                                        <Dropdown.Item icon='add user' onClick={this.onMenuClick('onClickToolsAddUser')} text={window._getText('schedual.m.addNewUser')}   ></Dropdown.Item>
                                        <Dropdown.Item icon='star' onClick={this.onMenuClick('onClickToolsAddOpenShifts')} text={window._getText('schedual.m.addOpenShifts')}   ></Dropdown.Item>
                                        <Dropdown.Item icon='window minimize outline' onClick={this.onMenuClick('onClickToolsAddDivider')} text={window._getText('schedual.m.addDiv')}  ></Dropdown.Item>
                                        <Dropdown.Item icon='add to calendar' onClick={this.onOpenEditScheduleModal} text={window._getText('schedual.m.addNewSchedule')}   ></Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>

                                <Dropdown className='noPrint' item icon='copy outline' open={this.state.openDDCopy} onMouseLeave={this.setClose('openDDCopy')} onMouseMove={this.setOpen('openDDCopy')} >
                                    <Dropdown.Menu    >
                                        <Dropdown.Item icon='copy outline' onClick={this.onMenuClick('onClickToolsCopy')} text={window._getText('schedual.m.copyS')}></Dropdown.Item>
                                        <Dropdown.Item icon='paste' onClick={this.onMenuClick('onClickToolsPaste')} text={window._getText('schedual.m.pasteS')}></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                {isElectronApp && <div onClick={this.printPdf} icon='print' className='topMenuItem' />}



                                <Dropdown className='noPrint' item icon='redo' open={this.state.openDDRedo} onMouseLeave={this.setClose('openDDRedo')} onMouseMove={this.setOpen('openDDRedo')}     >
                                    <Dropdown.Menu    >
                                        <Dropdown.Item icon='redo' onClick={this.onMenuClick('onClickToolsReload')} text={window._getText('schedual.m.undo')} />
                                        <Dropdown.Item icon='trash' onClick={this.onMenuClick('onClickToolsClear')} text={window._getText('schedual.m.clear')} />
                                    </Dropdown.Menu>
                                </Dropdown>

                            </React.Fragment>}




                        {!isLandscape &&
                            <React.Fragment>


                                {/* <Dropdown className='noPrint' item style={{ fontSize: isMobile ? '18px' : undefined, padding: isMobile ? '8px' : undefined }} content={window._getText('schedual.m.tools')} icon='add' open={this.state.openDDPlus} onMouseLeave={this.setClose('openDDPlus')} onMouseMove={this.setOpen('openDDPlus')}     >
                                    <Dropdown.Menu   >
                                        <Dropdown.Item icon='add user' onClick={this.onMenuClick('onClickToolsAddUser')} text={window._getText('schedual.m.addNewUser')}   ></Dropdown.Item>
                                        <Dropdown.Item icon='star' onClick={this.onMenuClick('onClickToolsAddOpenShifts')} text={window._getText('schedual.m.addOpenShifts')}   ></Dropdown.Item>
                                        <Dropdown.Item icon='window minimize outline' onClick={this.onMenuClick('onClickToolsAddDivider')} text={window._getText('schedual.m.addDiv')}  ></Dropdown.Item>
                                        <Dropdown.Item icon='add to calendar' onClick={this.onOpenEditScheduleModal} text={window._getText('schedual.m.addNewSchedule')}   ></Dropdown.Item>

                                        <Dropdown.Item icon='copy outline' onClick={this.onMenuClick('onClickToolsCopy')} text={window._getText('schedual.m.copyS')}></Dropdown.Item>
                                        <Dropdown.Item icon='paste' onClick={this.onMenuClick('onClickToolsPaste')} text={window._getText('schedual.m.pasteS')}></Dropdown.Item>

                                        <Dropdown.Item icon='redo' onClick={this.onMenuClick('onClickToolsReload')} text={window._getText('schedual.m.undo')} />
                                        <Dropdown.Item icon='trash' onClick={this.onMenuClick('onClickToolsClear')} text={window._getText('schedual.m.clear')} />


                                        {isElectronApp && <Dropdown.Item onClick={this.printPdf} icon='print' text={window._getText('schedual.m.print')} />}



                                    </Dropdown.Menu>

                                </Dropdown> */}
                            </React.Fragment>}






                        {isMacOs === true &&
                            <div style={{ display: 'flex', width: '100%' }}>
                                <div style={{ display: 'flex', padding: '8px' }}  >

                                    <b onClick={onClickChangeDate(-7)} style={{ fontSize: '20px', paddingTop: '10px' }}  >
                                        <Icon size='large' name='left angle'></Icon>
                                    </b>

                                    <b className='item' style={{ padding: '0px' }}  >
                                        <div className='topMenuWeekDateMainDiv' onClick={onClickShowWeekPicker} >
                                            <span className='text'  ><b className='topMenuWeekDateMainB' ><span className='topMenuWeekDateMainWeek'>{window._getText('week')} </span>{moment.utc(currentScheduelData.renderDayDate).local().format("w")}</b>
                                                <div className='topMenuWeekDateDiv'>{getLocalDateStr(moment.utc(currentScheduelData.renderDayDate))}  - {getLocalDateStr(moment.utc(currentScheduelData.renderDayDate).add(6, 'days'))} {moment.utc(currentScheduelData.renderDayDate).format('YYYY')}<Icon name='dropdown' /></div>
                                            </span>
                                        </div>
                                    </b>
                                    <b onClick={onClickChangeDate(7)} style={{ fontSize: '20px', paddingTop: '10px' }}>
                                        <Icon size='large' name='right angle'></Icon>
                                    </b>



                                </div>

                                <div style={{ flexGrow: '1' }}></div>


                                <Button
                                    loading={currentScheduelData.isLoading}
                                    onClick={onSaveSchedule}
                                    disabled={curScheduleView && curScheduleView.dirty === false}
                                    positive
                                    compact
                                    style={{ height: '30px' }}
                                    content='Publish'
                                    icon='cloud upload'
                                    color='green'
                                ></Button>

                            </div>
                        }

                        {isMacOs === false &&
                            <React.Fragment>


                                <div style={{ display: 'flex', width: '100%' }}>

                                    <div>
                                        <div style={{ display: 'flex', padding: '8px' }}  >

                                            <b onClick={onClickChangeDate(-7)} style={{ fontSize: '20px', paddingTop: '10px' }}  >
                                                <Icon size='large' name='left angle'></Icon>
                                            </b>

                                            <b className='item' style={{ padding: '0px' }}  >
                                                <div className='topMenuWeekDateMainDiv' onClick={onClickShowWeekPicker} >
                                                    <span className='text'  ><b className='topMenuWeekDateMainB' ><span className='topMenuWeekDateMainWeek'>{window._getText('week')} </span>{moment.utc(currentScheduelData.renderDayDate).local().format("w")}</b>
                                                        <div className='topMenuWeekDateDiv'>{getLocalDateStr(moment.utc(currentScheduelData.renderDayDate))}  - {getLocalDateStr(moment.utc(currentScheduelData.renderDayDate).add(6, 'days'))} {moment.utc(currentScheduelData.renderDayDate).format('YYYY')}<Icon name='dropdown' /></div>
                                                    </span>
                                                </div>
                                            </b>
                                            <b onClick={onClickChangeDate(7)} style={{ fontSize: '20px', paddingTop: '10px' }}>
                                                <Icon size='large' name='right angle'></Icon>
                                            </b>



                                        </div>
                                    </div>
                                    <div style={{ flexGrow: '1' }}></div>


                                    <Button
                                        loading={currentScheduelData.isLoading}
                                        onClick={onSaveSchedule}
                                        disabled={curScheduleView && curScheduleView.dirty === false}
                                        positive
                                        compact
                                        style={{ height: '30px' }}
                                        content='Publish'
                                        icon='cloud upload'
                                        color='green'
                                    ></Button>

                                </div>






                            </React.Fragment>
                        }






                    </div>

                    {showWeekPicker &&
                        <ScheduleWeekpicker firstDayOfWeekNumber={Number(this.props.firstDayOfWeekNumber)} renderDayDate={currentScheduelData.renderDayDate} onClickDate={onClickChangeDate2} ></ScheduleWeekpicker>
                    }
                </div>
            </React.Fragment >

        )

    }
}
