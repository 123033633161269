import React, { Component } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import firebase from '../../config/firebase';
import { FireHelper } from '../../common/util/FireHelper'
//import Image from 'semantic-ui-react/dist/commonjs/elements/Image'

import { version } from '../../../../package.json'
//mport Login from "../login/Login";
// import SignOut from '../login/SignOut'
// import PrivacyPolicy from '../login/PrivacyPolicy'
// import Setup from '../login/Setup'
// import CreateNewAccount from '../login/CreateNewAccount'

import { lLogin, lDev, lSignOut, lPrivacyPolicy, lSetup, lCreateNewAccount, lTermsConditions } from './Loader'



import imgLogo from '../../../img/logo.png'
import bglogin from '../../../img/bglogin.jpg'
//import Dev from '../dev/Dev'



import PropTypes from 'prop-types'
// auth.onAuthStateChanged(async user => {

//   console.log('!!!!!! auth.onAuthStateChanged!!!!!!!!!')
//   if (user) {
//     console.log('Logged in')

//     try {
//       const idTokenResult = await user.getIdTokenResult(true)
//       await firebase.reloadAuth();
//       console.log('idTokenResult.claims', idTokenResult.claims)
//       console.log('idTokenResult.claims.role', idTokenResult.claims.role)
//       console.log('idTokenResult.claims.pKey', idTokenResult.claims.pKey)
//       //if (idTokenResult.claims.role && idTokenResult.claims.pKey)
//       // alert('ok redirect', idTokenResult.claims.pKey)

//     } catch (error) {
//       console.log(error)
//     }

//   }
//   else
//     console.log('NOT Logged in')
//   console.log('!!!!!!')
//   console.log('')
// });
var isElectronApp = (process.env.REACT_APP_ELECTRON === '1')
let ipcRenderer
isElectronApp = !!window.require //TEMP
if (isElectronApp) {
  ipcRenderer = window.require('electron').ipcRenderer
}
class LoginRouter extends Component {
  static contextTypes = { router: PropTypes.object }

  state = { haveUser: false }

  updateClaim = () => {
    if (this.props.authUser)
      firebase.reloadAuth().then(() => FireHelper.reloadToken(true).then(u => {
        console.log('-------------------------------')
        console.log('u.role', u.claims.role)
        console.log('u.pKey', u.claims.pKey)
        if (u.claims.role >= 50 && u.claims.pKey) {
          console.log('---================>componentDidUpdate REDIRECT2')
          // if (this.state.haveUser === false)
          //   this.setState({ haveUser: true })
        }

        console.log('-------------------------------')
      }));
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    // console.log('******componentDidUpdate***********')
    // if (prevProps.currentUser)
    //   console.log('prevProps', prevProps.currentUser.publicKey)

    // if (this.props.currentUser)
    //   console.log('props', this.props.currentUser.publicKey)
    // if (prevProps.currentUser && this.props.currentUser && prevProps.currentUser.publicKey !== this.props.currentUser.publicKey) {
    //   console.log('Try to update user!')

    // }
    // console.log('*****************')
  }

  componentDidMount = () => {

    if (ipcRenderer)
      ipcRenderer.send('setMenu', '/login')

  }
  //   console.log('¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤login router componentDidMount¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤¤')
  //   //auth.signOut()
  //   // if (this.props.authUser.uid && this.props.curUser.projectKey) {
  //   //   this.animationsIsRunning = true
  //   //   this.setState({ haveUser: true })
  //   // }

  //   // Bind the variable to the instance of the class.
  //   this.authFirebaseListener = firebase.auth().onAuthStateChanged(async (user) => {
  //     console.log('!!!!!! auth.onAuthStateChanged!!!!!!!!!')
  //     if (user) {
  //       console.log('Logged in')

  //       try {
  //         // await firebase.reloadAuth();
  //         const idTokenResult = await user.getIdTokenResult(false)

  //         //console.log('idTokenResult.claims', idTokenResult.claims)
  //         console.log('idTokenResult.claims.role', idTokenResult.claims.role)
  //         console.log('idTokenResult.claims.pKey', idTokenResult.claims.pKey)
  //         //if (idTokenResult.claims.role && idTokenResult.claims.pKey)
  //         // alert('ok redirect', idTokenResult.claims.pKey)
  //         if (idTokenResult.claims.role >= 50 && idTokenResult.claims.pKey) {
  //           console.log('---================>componentDidMount REDIRECT1')
  //           if (this.state.haveUser === false)
  //             this.setState({ haveUser: true })
  //         }
  //         else {

  //           await firebase.reloadAuth().then(async () => {
  //             // alert('todo wee need to know when cloudfunctions is done!, use httpfunctions och vänta på response ?')
  //             const idTokenResult2 = await user.getIdTokenResult(true)
  //             console.log('22idTokenResult2.claims.role', idTokenResult2.claims.role)
  //             console.log('22idTokenResult2.claims.pKey', idTokenResult2.claims.pKey)
  //             if (idTokenResult2.claims.role >= 50 && idTokenResult2.claims.pKey) {
  //               console.log('---================>componentDidMount REDIRECT2')
  //               if (this.state.haveUser === false)
  //                 this.setState({ haveUser: true })
  //             }
  //           })
  //         }

  //       } catch (error) {
  //         console.log(error)
  //       }

  //     }
  //     else
  //       console.log('NOT Logged in')
  //     console.log('!!!!!!')
  //     console.log('')
  //   });


  // }
  // componentWillUnmount() {
  //   this.authFirebaseListener && this.authFirebaseListener() // Unlisten it by calling it as a function
  // } 
  addAnimations = () => {
    if (this.props.haveUser)
      return 'loginHidebox'
  }
  animationEnd = (e) => {
    if (e.target.className === 'loginHidebox divLoginBox')
      this.context.router.history.push('/schedule/schedule/default') //TODO do we need to send the to /newuser ? to reload auth
    //auth.signOut()
    // 
  }
  render() {
    //console.log('->LoginRouter')
    var { currentUser } = this.props
    if (!currentUser)
      currentUser = { isEmpty: true }


    // console.log('currentUser', currentUser)
    // console.log('authUser', authUser)
    // console.log('this.props.authUser.isEmpty ', this.props.authUser.isEmpty)

    return (
      <React.Fragment>
        <div className='bgCover dragWindow' style={{ height: '100%', backgroundImage: `url(${bglogin})`, xbackgroundColor: 'rgb(69, 69, 68)' }}>
          <span style={{ color: 'silver', position: 'absolute', bottom: '2px', right: '8px', fontSize: '10px' }}>{version}</span>
          <div onAnimationEnd={this.animationEnd} className={this.addAnimations() + ' divLoginBox'} >
            <div style={{ display: 'flex', flexDirection: 'column', xbackgroundColor: 'rgb(69, 69, 68)' }}  >
              <div >
                <div style={{ alignContent: 'center', display: 'inline-block', position: 'relative' }}>
                  <div className='logoAni'>
                    <img size='mini' alt='TimeTo.Work' src={imgLogo} style={{}}></img>

                  </div>
                </div>
              </div>
              <div  >



                {!this.props.authUser.isEmpty &&
                  <Route
                    path="*"
                    render={() => (
                      <Switch>
                        <Route path="/setup" component={lSetup} />
                        <Route path="/login" component={lLogin} />
                        <Route path="/signout" component={lSignOut} />
                        <Route path="/privacypolicy" component={lPrivacyPolicy} />
                        <Route path="/termsconditions" component={lTermsConditions} />
                        <Route path="/dev" component={lDev} />
                        <Redirect to="/setup" />
                      </Switch>
                    )}
                  />
                }

                {(!this.props.authUser || this.props.authUser.isEmpty) &&
                  <Route
                    path="*"
                    render={() => (
                      <Switch>
                        <Route path="/setup" component={lSetup} />
                        <Route path="/login" component={lLogin} />
                        <Route path="/newuser" component={lCreateNewAccount} />
                        <Route path="/newschedule" component={lCreateNewAccount} />
                        <Route path="/privacypolicy" component={lPrivacyPolicy} />
                        <Route path="/termsconditions" component={lTermsConditions} />
                        <Route path="/dev" component={lDev} />
                        <Redirect to="/login" />
                      </Switch>
                    )}
                  />
                }

              </div>

            </div>
          </div>
        </div>


      </React.Fragment>



    )
  }
}


const mapStateToProps = (state, ownProps) => {
  return { authUser: state.firebase.auth, curUser: state.firebase.profile }
};
const mapActionsFromProps = {};
export default withRouter(connect(
  mapStateToProps,
  mapActionsFromProps
)(LoginRouter))



