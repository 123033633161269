import React, { Component } from "react";
import { Menu, Button, Icon, Checkbox, Input } from "../../../common/component/Button";
import { connect } from "react-redux";
import memoizeOne from 'memoize-one';
import array from "lodash/array";

import { openModal, closeModal } from "../../../redux/action/modalAction";
//import { registerUser } from "../../../redux/action/userAction";
import UserCard from "./UserCard";
import collection from "lodash/collection";
import UserSendInvitesModal from "../../modal/UserSendInvitesModal";
import UserBulkImport from "../../modal/UserBulkImport";
import { FireHelper } from '../../../common/util/FireHelper'
import { MobileView, BrowserView } from 'react-device-detect';
import Locked from "../../schedule/settings/Locked";

class Users extends Component {
  state = { searchText: "", cbShowOnlyActiveUsers: true };


  componentDidMount = () => {
    window.addEventListener('newuser', this.openEmptyModal);
  }

  componentWillUnmount = () => {
    window.removeEventListener('newuser', this.openEmptyModal);

  }
  toggleShowOnlyActiveUsers = () => this.setState({ cbShowOnlyActiveUsers: !this.state.cbShowOnlyActiveUsers })
  onOpenModal = key => () => {
    console.log('open', key)
    this.props.onOpenModal('user', key);
  };
  onOpenModalSendInvite = () => {
    this.props.onOpenModal('userSendinvite');
  };
  onOpenModalBulkImport = () => {
    this.props.onOpenModal('userBulkImport');
  };
  // // onAddNewUser = name => () => {
  // //   {this.props.onOpenModal}

  // // };

  tbSearchChange = e => {
    this.setState({ searchText: e.target.value });
  };
  openEmptyModal = () => {
    this.props.onOpenModal('user');
  }
  sendMail = (mail) => {
    if (this.props.currentUser)
      FireHelper.setInviteMail(this.props.project.key, mail, this.props.project.publicKey, this.props.project.name)
  }

  styles = {
    meny: { border: 'none', borderBottom: 'solid 1px #f1f1f1', position: 'fixed', height: '50px', zIndex: '10' },
    input1: { width: '200px' },
    width160: { width: '125px' },
    s1: { paddingLeft: '5px', paddingRight: '5px' },
    s2: { border: 'none', },
  }

  getUsersCards = memoizeOne((users, search, active) => {
    //console.log('===>inside moize')
    let userCards = []
    if (users) {
      // var showOnlyActiveUsers = this.state.cbShowOnlyActiveUsers
      var searchList1 = collection.filter({ ...users }, (obj, key, x) => {
        if (!obj || !obj.key) return;
        //obj.key = key;// REMOVED , == error
        return (obj.name || '').toLowerCase().indexOf(search) > -1; //&& showOnlyActiveUsers === obj.isActive
      });
      var searchList2 = collection.filter({ ...users }, (obj, key, x) => {
        if (!obj || !obj.key) return;
        //obj.key = key;// REMOVED , == error
        return (obj.email || '').toLowerCase().indexOf(search) > -1; //&& showOnlyActiveUsers === obj.isActive
      });

      var searchList = array.uniq([...searchList1, ...searchList2]) || []

      searchList = searchList.sort((a, b) => {

        if (!a.name || !b.name)
          return 0

        const aName = (a.name || '').toLowerCase()
        const bName = (b.name || '').toLowerCase()

        if (aName < bName) return -1
        if (aName > bName) return 1
        return 0
      })

      userCards = Object.keys(searchList).map(key => {
        if (searchList[key] == null) return null;
        return (
          <UserCard
            projectKey={this.props.projectKey}
            sendMail={this.sendMail}
            user={{ ...searchList[key] }}
            openModal={this.props.onOpenModal}
            key={key}
          />
        );
      });
    }
    return userCards
  })


  selectUser = (user) => () => {
    this.props.onOpenModal('user', user.key);
  }
  lastChar = ''
  listUser = (user, selectedUsers) => {
    // console.log('>>listUser')
    if (!user)
      return (null)

    const u = selectedUsers[user.key] || {}

    var firstChar = (user.name || '').substr(0, 1).toLowerCase()
    var divHeader = null

    if (firstChar !== this.lastChar) {
      this.lastChar = firstChar
      divHeader = (
        <div key={firstChar} style={{ textTransform: ' capitalize ', fontWeight: 'bold', borderBottom: 'dotted 1px var(--color-border)', paddingTop: '8px', paddingLeft: '14px' }}>
          {firstChar}
        </div>
      )
    }


    return (
      <React.Fragment key={user.key}>
        {divHeader}
        <div className='noSelect selectUserBtn' onClick={this.selectUser(user)} key={user.key} style={{
          cursor: 'pointer',

          backgroundColor: u.selected === true ? 'rgba(33, 186, 69, 0.3)' : 'rgba(0, 0, 0, 0.01)',
          margin: '2px', borderRadius: '0px', padding: '5px', paddingLeft: '24px'
        }}>
          {/* <Image floated="left" style={{ width: '25px' }} size="mini" src={user.image ?
            user.image
            : noUserImage} circular
            onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }}
          /> */}
          <Icon name={user.isActive === true ? 'user' : 'user outline'}></Icon>
          {user.name}

        </div>
      </React.Fragment>
    )
  }

  render() {
    const { users, project } = this.props;

    if (project && project.disabled === true)
      return <Locked />

    const search = (this.state.searchText || '').toLowerCase()

    const userCards = this.getUsersCards(users, search, this.state.cbShowOnlyActiveUsers);

    var arrUserlist = userCards.map(u => (u.props || {}).user)





    return (
      <React.Fragment>

        {1 === 0 &&
          <div className='fixed' style={{ position: 'fixed', width: '100%' }}>
            <BrowserView>

              <Menu attached className='dragWindow' >
                <Menu.Item >
                  <Icon name="users" size='large' />
                  <Input
                    icon="search"
                    placeholder={window._getText('search')}
                    onChange={this.tbSearchChange}
                    style={this.styles.input1}
                  />
                </Menu.Item>
                <Menu.Item>
                  <Checkbox toggle label={window._getText('user.active')} onChange={this.toggleShowOnlyActiveUsers} checked={this.state.cbShowOnlyActiveUsers}></Checkbox>
                </Menu.Item>

                <Menu.Item >
                  <Button compact icon='mail' color='blue' onClick={this.onOpenModalSendInvite} style={{ marginRight: '10px' }} />

                  <Button compact icon='list' color='blue' onClick={this.onOpenModalBulkImport} />
                </Menu.Item>
                <Menu.Item position='right'>
                  <Button compact icon='add user' onClick={this.openEmptyModal} />
                </Menu.Item>





                {/* <Menu.Item >
                <Button compact icon='mail' color='blue' content={window._getText('invites')} onClick={this.onOpenModalSendInvite} />
              </Menu.Item>
              <Menu.Item >
                <Button compact icon='list' color='blue' content={window._getText('import')} onClick={this.onOpenModalBulkImport} />
              </Menu.Item>
              <Menu.Item position='right'>
                <Button compact icon='add user' content={window._getText('users.newuser')} color="green" onClick={this.openEmptyModal} />
              </Menu.Item> */}
              </Menu>
            </BrowserView>
            <MobileView>


              <Menu attached style={{ width: '100%' }} >
                <Menu.Item  >
                  <Icon name="users" size='large' />
                  <Input

                    icon="search"
                    placeholder={window._getText('search')}
                    onChange={this.tbSearchChange}
                    style={this.styles.width160}
                  />
                </Menu.Item>
                <Menu.Item style={this.styles.s1} >
                  <Checkbox toggle onChange={this.toggleShowOnlyActiveUsers} checked={this.state.cbShowOnlyActiveUsers}></Checkbox>
                </Menu.Item>
                <Menu.Item style={this.styles.s1} >
                  <Button compact icon='mail' color='blue' onClick={this.onOpenModalSendInvite} />
                </Menu.Item>
                <Menu.Item style={this.styles.s1} >
                  <Button compact icon='list' color='blue' onClick={this.onOpenModalBulkImport} />
                </Menu.Item>
                <Menu.Item style={this.styles.s1}  >
                  <Button compact icon='add user' color="green" onClick={this.openEmptyModal} />
                </Menu.Item>
              </Menu>
            </MobileView>
          </div>}

        <div style={{ flexDirection: 'row', display: 'flex', height: '100%', width: '100%' }}>
          <div style={{ maxWidth: '250px', minWidth: '250px', background: 'rgba(204,204,204,0.1)', borderRight: 'solid 1px var(--color-border)' }}>
            <div style={{ paddingTop: '12px', paddingBottom: '12px', paddingLeft: '14px', paddingRight: '5px', borderBottom: 'solid 1px var(--color-border)', color: '#000', display: 'flex', fontSize: '12px' }}>

              <input
                className="form-control"
                icon="search"
                value={this.state.searchText}
                placeholder={window._getText('search')}
                onChange={this.tbSearchChange}
                style={{ height: '10px', marginRight: '8px' }}
              />


              {/* {(this.props.currentUser.role > 100) &&
                <button style={{ marginRight: '8px', marginLeft: '8px' }} className="btn btn-default" onClick={() => this.setState({ searchText: '' })}>
                  <span className="ico ico-cancel"></span>
                </button>
              } */}

            </div>

            <div style={{ overflowY: 'auto', height: 'calc(100% - 90px)' }}>
              {arrUserlist.map(u => this.listUser(u, users))}
            </div>

            <div style={{ flexGrow: '1' }}></div>
            <div style={{ border: 'solid 0px red', height: '40px', padding: '6px', borderTop: 'solid 1px var(--color-border)', background: 'rgba(204,204,204,0.1)', }}>
              {(this.props.currentUser.role > 100) &&


                <div style={{ display: 'flex' }}>
                  <Button tooltip_t={window._getText("schedual.m.addNewUser")} compact icon='add user' style={{ marginRight: '8px', marginLeft: '8px' }} onClick={this.openEmptyModal} />

                  <div style={{ flexGrow: '1' }}></div>



                  <div className="btn-group" style={{ marginRight: '8px' }}>

                    <Button tooltip_t={window._getText("user.bulkimport")} compact icon='download' onClick={this.onOpenModalBulkImport} />
                    <Button tooltip_t={window._getText("user.inviteUnMaped")} compact icon='mail' onClick={this.onOpenModalSendInvite} />


                  </div>
                </div>
              }

            </div>
          </div>

          <div style={{ flexGrow: '1' }}  >

            <div style={{ borderBottom: 'solid 1px var(--color-border)', height: '50px', paddingTop: '12px', paddingBottom: '12px', paddingLeft: '14px', paddingRight: '5px', color: '#000', display: 'flex', fontSize: '12px' }}>



              {/* 
              <Checkbox toggle label={window._getText('user.active')} onChange={this.toggleShowOnlyActiveUsers} checked={this.state.cbShowOnlyActiveUsers}></Checkbox> */}
              <div style={{ fontSize: '20px', fontWeight: 'bold', }}>

                Users
              </div>
              {/* <Button tooltip_r={window._getText("schedual.m.addNewUser")} compact icon='add user' onClick={this.openEmptyModal} />
 */}


            </div>
            <div style={{ flexWrap: 'wrap', display: 'flex', overflowY: 'auto', overflowX: 'hidden', height: 'calc(100% - 50px)', padding: '6px', }}>
              {userCards}
            </div>
          </div>

        </div>


        <UserSendInvitesModal></UserSendInvitesModal>
        <UserBulkImport></UserBulkImport>
      </React.Fragment >
    );
  }
}
const mapActions = {
  onOpenModal: openModal,
  onCloseModal: closeModal

};
const mapStateToProps = state => {
  var userList = {};

  if (state.firestore.data.users) {
    userList = state.firestore.data.users.userlist;
  }

  return {
    projectKey: state.firebase.profile.projectKey,
    project: state.firestore.data.project,
    currentUser: state.firebase.profile,
    user: state.user,
    users: userList
  };
};
export default connect(
  mapStateToProps,
  mapActions
)(Users);
