import React, { Component } from 'react'
import shortid from 'shortid'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
// import moment from 'moment'
import { renderTimeLable } from '../../../common/util/helpers'


export class ScheduleTemplates extends Component {
    state = { templates: [] }
    setTemplates = () => {
        var templates = Object.keys(this.props.templates).map((templetKey, index) => {
            var item = this.props.templates[templetKey]
            if (!item)
                return null;

            var lbl = this.getLabel(item)
            return (

                <Label
                    onDragStart={this.onDragStart}
                    key={item.key}
                    onClick={this.onCellClick}
                    draggable
                    onDragEnd={this.props.onDragOverHideDropZone}
                    id={item.key}
                    className='small cellMulti dragMe'
                    color={'x' + item.color}
                    style={{ width: '95%' }}

                >


                    <div>{lbl}</div>
                    {/* {moment.utc(event.date).format('DD/MM')} */}

                    {item.comment && <span style={{ fontSize: '11px' }}>{item.comment}</span>}

                </Label>
            )
        })
        console.log('setState')
        this.setState({ templates })
    }
    componentDidMount() {
        if (this.props.templates)
            this.setTemplates()


    }
    componentDidUpdate(prevProps) {
        if (this.props.templates !== prevProps.templates)
            this.setTemplates()

    }
    onDragStart = e => {

        e.dataTransfer.setData("text", JSON.stringify({ t: "template", id: e.target.id, clone: true, template: true }));
        this.props.onDragOverShowDropZone();
    };
    getLabel = (shift) => {

        return renderTimeLable(shift.label, shift.startDate, shift.stopDate)

        // var lbl = shift.label || '';

        // if (lbl.indexOf('@') > -1) {
        //     if (shift.startDate) {
        //         const startDate = moment.utc(shift.startDate).format('HH:mm')
        //         lbl = lbl.replace('@start', startDate)
        //     }
        //     if (shift.stopDate) {
        //         const stopDate = moment.utc(shift.stopDate).format('HH:mm')
        //         lbl = lbl.replace('@end', stopDate)
        //     }
        // }

        // return lbl
    }
    onCellClick = () => {
        // alert('edit')
        // this.props.onOpenEventModal(this.props.event);
    }
    addNewTemplate = () => {
        var templateEvent = {
            color: 'red',
            //comment: '',
            date: +new Date(),
            dirty: true,
            template: true,
            hours: 0,
            //isTemplate: false,
            label: "@start - @end",
            // sid: this.getCurScheduleView().key,
            key: shortid.generate(),
        };

        this.props.onOpenEventModal(templateEvent)
    }
    render() {

        return (
            <div style={{ borderLeft: 'solid 1px var(--color-border)', width: '200px', display: 'block', marginLeft: '10px' }}>
                <div style={{ width: '198px', position: 'relative', paddingLeft: '6px' }}>
                    <Menu attached='top' style={{ border: 'none' }} >
                        <Menu.Item style={{ border: 'none' }} ><b>Templates</b>
                        </Menu.Item>
                        <Menu.Item icon='add' content='New' position='right' onClick={this.addNewTemplate}>
                        </Menu.Item>
                    </Menu>

                    <div style={{ padding: '5px' }}>
                        {this.state.templates}
                    </div>
                </div>
            </div>
        )
    }
}

export default ScheduleTemplates
