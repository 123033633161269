import React, { PureComponent } from 'react'
import moment from 'moment'
import { getLocalDateStr } from '../../common/util/helpers'

export class ScheduleWeekpicker extends PureComponent {

    constructor(props) {
        super(props);
        this.startRef = React.createRef();


    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        //this.startRef.current.scrollIntoView({ "block": "center" })
    }
    componentDidMount() {

        if (this.startRef && this.startRef.current) {
            // setTimeout(() => {
            try {
                this.startRef.current.scrollIntoView({ "block": "center" })
            } catch (error) {
                //   console.log('scroll to it error', error)
            }

            // console.log('scroll to it')
            //}, 5);

        }
    }


    renderDateButtons = (startDate, firstDayOfWeekNumber) => {

        var today = moment.utc(new Date()).isoWeekday(firstDayOfWeekNumber).format('YYYYMMDD');

        var btns = []
        var rDate = startDate.clone()
        var monthCounter = rDate.format('MM')

        for (let index = 0; index < 8; index++) {

            var strRenderDayDate = moment.utc(this.props.renderDayDate).format('YYYYMMDD')
            var strRDate = rDate.format('YYYYMMDD')
            var isSameDate = (strRenderDayDate === strRDate)
            var lblStr = getLocalDateStr(moment.utc(rDate)) + '-' + getLocalDateStr(moment.utc(rDate).add(6, 'days'))
            if (isSameDate) {
                btns.push(
                    <div key={rDate} onClick={this.props.onClickDate(rDate)} size='mini' style={{ margin: '0px', paddingLeft: '12px', paddingRight: '12px', cursor: 'pointer!important', borderRadius: '8px', background: 'rgba(34, 36, 38, 0.05)' }}  >
                        <span key={rDate} ref={this.startRef} style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                            {(today === strRDate) &&
                                <b style={{ color: 'rgba(153,0,0,1)', width: '100%', display: 'block' }}>{window._getText('wShort')} {moment.utc(rDate).local().format("ww")} <br /></b>
                            }
                            {(today !== strRDate) &&
                                <b style={{ color: 'gray', width: '100%', display: 'block' }} >{window._getText('wShort')} {moment.utc(rDate).local().format("ww")} <br /></b>
                            }
                            <span style={{ color: 'silver', fontSize: '11px' }}>{lblStr}</span>
                        </span>
                    </div>
                )
            }
            else {
                btns.push(
                    <div key={rDate} onClick={this.props.onClickDate(rDate)} size='mini' style={{ margin: '0px', paddingLeft: '12px', paddingRight: '12px', cursor: 'pointer!important' }} >
                        <span style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                            {(today === strRDate) &&
                                <b style={{ color: 'rgba(153,0,0,1)', width: '100%', display: 'block' }}>{window._getText('wShort')} {moment.utc(rDate).local().format("ww")} <br /></b>
                            }
                            {(today !== strRDate) &&
                                <b style={{ color: 'gray', width: '100%', display: 'block' }} >{window._getText('wShort')} {moment.utc(rDate).local().format("ww")} <br /></b>
                            }
                            <span style={{ color: 'silver', fontSize: '11px' }}>{lblStr}</span>
                        </span>
                    </div>
                )
            }


            rDate = moment.utc(rDate).add(7, 'days')
            if (monthCounter !== rDate.format('MM'))
                break

            monthCounter = rDate.format('MM')
        }

        return (

            btns

        )
    }
    renderDates = (startDate) => {
        var btns = []
        const firstDayOfWeekNumber = this.props.firstDayOfWeekNumber //sunday 0 , monday 1
        var renderDate = moment.utc().add(-26, 'weeks').startOf('month').clone().isoWeekday(firstDayOfWeekNumber)
        var monthCounter = renderDate.format('MM')

        for (let index = 0; index < 53; index++) {
            renderDate = moment.utc(renderDate).clone().add(7, 'days')
            if (monthCounter !== renderDate.format('MM')) {
                btns.push(this.renderDateButtons(renderDate, firstDayOfWeekNumber))
            }
            monthCounter = renderDate.format('MM')
        }


        return btns;
    }






    //on='click'
    render() {

        var { renderDayDate } = this.props
        return (
            <div style={{ xposition: 'fixed', width: '100%', height: '72px', background: 'rgba(0,0,0,0.04)' }} className='xfixed'>
                <div style={{ overflowX: 'scroll', padding: '6px', margin: '0px' }}>
                    <div style={{ display: 'flex', borderBottom: 'none!important', padding: '0px', margin: '0px' }}>
                        {this.renderDates(renderDayDate)}
                    </div>
                </div>
            </div>
        )
    }
}
