import React, { Component } from "react";
import { Icon, Image, Loader, Button } from "../../common/component/Button";
import { connect } from 'react-redux'
//import FirestoreListnerHelper from '../../common/util/FirestoreListnerHelper'
import { FireHelper } from '../../common/util/FireHelper'
import PropTypes from 'prop-types'
import noUserImage from '../../../img/noimg.png'
import { getUserImage, toTop, renderTimeLable } from '../../common/util/helpers'
import moment from 'moment'
import ShiftContainer from './ShiftContainer'

//import Arr from 'lodash/array'

var isElectronApp = (process.env.REACT_APP_ELECTRON === '1')
let ipcRenderer
isElectronApp = !!window.require //TEMP
// if (isElectronApp) {
//   ipcRenderer = window.require('electron').ipcRenderer
// }


class Requests extends Component {
  static contextTypes = { store: PropTypes.object.isRequired }
  state = { requestClick: {}, view: 0 }

  componentDidMount = () => {
    //console.log('componentDidMount> set listner')
    //  FirestoreListnerHelper.setRequestAllListner(this.context)
    toTop()

  }
  componentDidUpdate = (prevProps, prevState, snapshot) => {

    if (prevProps.requestData && this.props.requestData) {
      const a = this.props.requestData
      for (let index = 0; index < prevProps.requestData.length; index++) {
        const item = prevProps.requestData[index];
        if (!a.find(x => x.id === item.id)) {
          // console.log('$kill ', item.id)
          var requestClick = { ...this.state.requestClick }
          delete requestClick[item.id]
          this.setState({ requestClick })
        }
      }
    }
  }


  //TODO MOVE THIS TO HELPER AND MAKE SURE WE REUSE IT !
  temp = (date, t1, t2, brake) => {

    const dateStr = moment.utc(date).format('YYYY-MM-DD')
    var startDate = moment.utc(`${dateStr} ${t1}`)
    var stopDate = moment.utc(`${dateStr} ${t2}`)

    if (startDate > stopDate)
      stopDate.add(1, 'days')


    var duration = moment.duration(stopDate.diff(startDate));
    var minutes = duration.asMinutes();

    //small hack , here we set the start and stop date to save 
    var r = {}
    r.startDate = startDate.utc().unix() * 1000
    r.stopDate = stopDate.utc().unix() * 1000
    r.timeStart = t1
    r.timeEnd = t2
    r.minutes = minutes - brake
    r.break = brake

    return r
  }


  onApproveChange = (doc, shiftOwnerKey, shiftChanged) => (e) => {
    const { shiftKey, docKey, schedualKey, id, n } = doc
    const { settings } = this.props
    var requestClick = { ...this.state.requestClick }
    requestClick[id] = true
    var msg = 'Admin Approved the ' + doc.type + ' request for shift ' + doc.data.sh.d + '>' + doc.data.sh.l + ' @ ' + (this.props.project.cache[doc.schedualKey].n || '')
    if (n === 'deviation') {
      let newData = this.temp(shiftChanged.d, shiftChanged.t1, shiftChanged.t2, shiftChanged.b || 0)
      this.setState({ requestClick }, () => {
        FireHelper.approveChangeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, this.props.currentUser, msg)
      })
    }
    else if (n === 'adminrequest') {

      console.log('hy ??? ')
      if (doc.data.sh.newU[0]) {
        console.log('Aproved by user')
        let newData = { uid: this.props.currentUser.inSysUserKey }
        this.setState({ requestClick }, () => {
          FireHelper.approveChangeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, this.props.currentUser, msg)
        })
      }




    }
    else if (n === 'change') {



      if (doc.data.ct === 1) // its a cancelations
      {
        this.setState({ requestClick }, () => {
          FireHelper.removeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, n, this.props.currentUser, msg)
        })

      } else if (doc.data.ct === 2) // its a time change
      {
        let newData = this.temp(shiftChanged.d, shiftChanged.t1, shiftChanged.t2, shiftChanged.b || 0)
        this.setState({ requestClick }, () => {
          FireHelper.approveChangeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, this.props.currentUser, msg)
        })
      } else if (doc.data.ct === 3) // its a user shift change
      {
        //var newUserKey = doc.data.sh.newU[0] || '' //get first user from the array 

        if (!doc.adm) {
          //this is NOT an Admin , so we sett current user here !
          //let newData = { uid: this.props.currentUser.inSysUserKey }

          //update the ChangeShift to ready fro admin aporval 
          if (settings.sMov === true) {
            if (doc.data.sh.newU[0]) {
              console.log('Aproved by user')
              let newData = { uid: this.props.currentUser.inSysUserKey }
              this.setState({ requestClick }, () => {
                FireHelper.approveChangeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, this.props.currentUser, msg)
              })
            }
          }
          else {
            this.setState({ requestClick }, () => { FireHelper.setToAdminRequest(docKey, schedualKey, shiftKey, n, this.props.currentUser) })
          }

        }
        else {
          //admin 
          if (doc.data.sh.newU[0]) {
            let newData = { uid: doc.data.sh.newU[0] }
            this.setState({ requestClick }, () => {
              FireHelper.approveChangeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, this.props.currentUser, msg)
            })
          }
        }



      } else if (doc.data.ct === 4)// its a user shift swap
      {
        if (!doc.adm) {
          if (settings.sSwp === true) {

            console.log('Aproved by user')
            this.setState({ requestClick }, () => {
              var uid = doc.uk
              var uid2 = this.props.currentUser.inSysUserKey
              var docKey2 = doc.data.sh2.dkey
              var shiftKey2 = doc.data.sh2.k
              var shiftOwnerKey2 = (this.props.project.cache[uid2] || {}).sk || ''
              var schedualKey2 = doc.data.sh2.schKey
              //shiftKey, shiftKey2, docKey, docKey2, scheduleKey, shiftOwnerKey, shiftOwnerKey2, uid, uid2, rType, profile, msg
              FireHelper.approveSwapShift(shiftKey, shiftKey2, docKey, docKey2, schedualKey, schedualKey2, shiftOwnerKey, shiftOwnerKey2, uid, uid2, n, this.props.currentUser, msg)
              return
            })

          }
          else {
            this.setState({ requestClick }, () => {
              FireHelper.setToAdminRequest(docKey, schedualKey, shiftKey, n, this.props.currentUser)
            })
          }
        }
        else {
          //admin 
          if (doc.data.sh.newU[0]) {

            this.setState({ requestClick }, () => {
              var uid = doc.uk
              var uid2 = doc.data.sh.newU[0]
              var docKey2 = doc.data.sh2.dkey
              var shiftKey2 = doc.data.sh2.k
              var shiftOwnerKey2 = (this.props.project.cache[uid2] || {}).sk || ''
              var schedualKey2 = doc.data.sh2.schKey
              //shiftKey, shiftKey2, docKey, docKey2, scheduleKey, shiftOwnerKey, shiftOwnerKey2, uid, uid2, rType, profile, msg
              FireHelper.approveSwapShift(shiftKey, shiftKey2, docKey, docKey2, schedualKey, schedualKey2, shiftOwnerKey, shiftOwnerKey2, uid, uid2, n, this.props.currentUser, msg)
              return
            })
          }
        }
      }


    } else if (n === 'comment') {


      this.setState({ requestClick }, () => {
        var msg2 = 'Admin have read your comment for shift ' + doc.data.sh.d + '>' + doc.data.sh.l + ' @ ' + (this.props.project.cache[doc.schedualKey].n || '')
        let newData = { userCom: doc.data.com }
        FireHelper.approveChangeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, this.props.currentUser, msg2)
      })


    } else if (n === 'sick') {

      let newData = { sick: true, timeOf: doc.data.tof || 0 }
      this.setState({ requestClick }, () => {
        FireHelper.approveChangeShift(shiftKey, docKey, schedualKey, id, shiftOwnerKey, newData, n, this.props.currentUser, msg)
      })

    } else {
      console.log('#missing type=> ', n)
    }

    this.setState({ s_schedualName: null, s_user: null, s_userChanged: null, s_shiftChanged: null, s_shiftOrginal: null, s_doc: null })
  }


  onDenyRequest = (doc, shiftOwnerKey, shiftChanged) => () => {
    const { shiftKey, docKey, schedualKey, id, n } = doc
    var requestClick = { ...this.state.requestClick }
    requestClick[id] = true
    console.log('>>onDenyRequest', this.state.view)
    if (this.state.view === 1) {
      console.log('>>is admin cancle!')
      var msg1 = 'Admin cancel the ' + doc.type + ' request for shift ' + doc.data.sh.d + '>' + doc.data.sh.l + ' @ ' + (this.props.project.cache[doc.schedualKey].n || '')
      this.setState({ requestClick }, () => {
        FireHelper.removeRequest(docKey, schedualKey, shiftKey, shiftOwnerKey, n, this.props.currentUser, msg1)
      })
    }
    else {
      if (!doc.adm) {
        console.log('doc.data.ct', doc.data.ct)
        if (doc.data.ct === 3) // its a user shift change , remove this user from the parsipants 
        {

          this.setState({ requestClick }, () => {
            const requestOwnerKey = (this.props.project.cache[doc.uk] || {}).sk || ''
            FireHelper.removeParticipantRequest(docKey, schedualKey, shiftKey, n, requestOwnerKey, this.props.currentUser)
          })
        } else if (doc.data.ct === 4) {
          var msg = 'You cancel the ' + doc.type + ' request for shift ' + doc.data.sh.d + '>' + doc.data.sh.l + ' @ ' + (this.props.project.cache[doc.schedualKey].n || '')
          this.setState({ requestClick }, () => {
            FireHelper.removeRequest(docKey, schedualKey, shiftKey, shiftOwnerKey, n, this.props.currentUser, msg)//todo fix the message DUBBLE CHECK THIS!
          })
        }
      }
      else {
        console.log('is admin!')
        var msg2 = 'Admin cancel the ' + doc.type + ' request for shift ' + doc.data.sh.d + '>' + doc.data.sh.l + ' @ ' + (this.props.project.cache[doc.schedualKey].n || '')
        this.setState({ requestClick }, () => {
          FireHelper.removeRequest(docKey, schedualKey, shiftKey, shiftOwnerKey, n, this.props.currentUser, msg2)
        })
      }
    }
    //FireHelper.removeRequestDirect(reqDocKey, shiftOwnerKey, rType, this.props.currentUser)

    this.setState({ s_schedualName: null, s_user: null, s_userChanged: null, s_shiftChanged: null, s_shiftOrginal: null, s_doc: null })

  }


  getTitle = (doc) => {
    switch (doc.n) {
      case 'adminrequest':
        return window._getText('request.adminRequest')
      case 'deviation':
        return window._getText('request.titleTime')
      case 'sick':
        return window._getText('request.titleSick')
      case 'change':
        switch (doc.data.ct) {
          case 1:
            return window._getText('request.titleCancle')
          case 2:
            return window._getText('request.titleChange')
          case 3:
            return window._getText('request.titleMove')
          case 4:
            return window._getText('request.titleSwap')
          default:
            return ''
        }

      case 'comment':
        return window._getText('request.titleComment')
      default:
        return ''
    }

  }
  getIcon = (doc) => {
    return <Icon name='arrow right' style={{ fontSize: '29px' }}></Icon>


    // //console.log('doc', doc.data.ct || '')
    // switch (doc.n) {
    //   case 'deviation':
    //     return <Icon name='clock' size='big'></Icon>
    //   case 'sick':
    //     return <Icon name='bed' size='big'></Icon>
    //   case 'change':
    //     switch (doc.data.ct) {
    //       case 1:
    //         return <Icon name='calendar times outline' size='big'></Icon>
    //       case 2:
    //         return <Icon name='clock outline' size='big'></Icon>
    //       case 3:
    //         return <Icon name='arrow right' size='big'></Icon>
    //       case 4:
    //         return <Icon name='exchange' size='big'></Icon>
    //       default:
    //         return <Icon name='arrow right' size='big'></Icon>
    //     }

    //   case 'comment':
    //     return <Icon name='arrow right' size='big'></Icon>
    //   default:
    //     return <Icon name='arrow right' size='big'></Icon>
    // }

  }
  setView = (view) => () => {
    this.setState({ view, s_schedualName: null, s_user: null, s_userChanged: null, s_shiftChanged: null, s_shiftOrginal: null, s_doc: null })
  }
  render() {

    const { requestData, requestMyData, project, projectKey, currentUser } = this.props

    var rData = this.state.view === 1 ? requestMyData : requestData
    if (currentUser && currentUser.role < 100)
      return (<div></div>)

    if (!rData || !project)
      return (<div style={{ textAlign: 'center', marginTop: '50px' }}><Loader inline active>{window._getText('loading')}</Loader></div>)

    console.log('rData', rData)
    var sortedKeys = Object.keys(rData).map(key => {
      var item = rData[key]
      if (item && item.cdate)
        return { k: key, d: item.cdate }
    })
    sortedKeys = sortedKeys.sort((a, b) => {
      if (!a.d || !b.d)
        return 0
      if (a.d.seconds > b.d.seconds) return -1
      if (a.d.seconds < b.d.seconds) return 1
      return 0
    })

    const requestsKeys = sortedKeys.map(item => item.k);// Object.keys(rData)
    var { s_schedualName, s_user, s_userChanged, s_shiftChanged, s_shiftOrginal, s_doc } = this.state



    return (

      <React.Fragment>
        {/* {isElectronApp === false &&
          <div style={{ display: 'block' }}>
            <select style={{ width: '100%' }}>
              <option>Reqeust from Annn</option>
              <option>Reqeust from Joe</option>
              <option>Reqeust from Kim</option>
              <option>Reqeust from Anna</option>
              <option>Reqeust from Fin</option>
              <option>Reqeust from Lisa</option>

            </select>
          </div>
        } */}

        <div style={{ border: 'solid 0px red', display: 'flex', flexDirection: 'row', height: '100%' }}>




          <div style={{ maxWidth: '250px', minWidth: '250px', background: 'rgba(204,204,204,0.1)', borderRight: 'solid 1px var(--color-border)', display: isElectronApp === false ? 'none' : '' }}>
            <div style={{ paddingTop: '12px', paddingBottom: '12px', paddingLeft: '14px', paddingRight: '5px', borderBottom: 'solid 1px var(--color-border)', color: '#000', display: 'flex', fontSize: '12px', minHeight: '50px', height: '50px', overflow: 'hidden', }}>


              <div className="btn-group" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <button style={{}} className={this.state.view === 0 ? 'active btn btn-default' : 'btn btn-default'} onClick={this.setView(0)} >
                  <div tooltip={'Incoming requests'}  ><span style={{ width: '95px' }} className="ico ico-download" > In ({(requestData || 0).length}) </span></div>
                </button>
                <button style={{}} className={this.state.view === 1 ? 'active btn btn-default' : 'btn btn-default'} onClick={this.setView(1)} >
                  <div tooltip={'Outgoing requests'}> <span style={{ width: '95px' }} className="ico ico-upload"> Out ({(requestMyData || 0).length})</span></div>
                </button>
              </div>


            </div>






            {
              requestsKeys.map((docKey) => {
                const doc = rData[docKey]

                if (!doc)
                  return ('')

                const schedualName = project.cache[doc.schedualKey] ? project.cache[doc.schedualKey].n : '-'
                var user = project.cache[doc.uk] ? { ...project.cache[doc.uk] } : { n: 'unknown', i: '', sk: null }
                user.img = user.i ? getUserImage(projectKey, doc.uk, user.i) : noUserImage
                var userChanged = { ...user };
                var shiftOrginal = { ...doc.data.sh } || {}
                var shiftChanged = { ...doc.data.sh } || {}
                var newUserKey = ''
                console.log('doc', doc.n)
                switch (doc.n) {
                  case 'sick':
                    //console.log('sick')
                    shiftChanged.sik = true
                    break;
                  case 'deviation':
                    // console.log('deviation')
                    // console.log('>>shiftChanged>>', doc)
                    shiftChanged.l = renderTimeLable('@start - @end', doc.data.sh.d + ' ' + doc.data.t1, doc.data.sh.d + ' ' + doc.data.t2, this.props.AmPm)
                    shiftChanged.t1 = doc.data.t1
                    shiftChanged.t2 = doc.data.t2
                    break;

                  case 'change':
                    // console.log('change')
                    if (doc.data.ct === 1) {
                      //  console.log('change 1')
                      shiftChanged.l = <span style={{ textDecoration: 'line-through' }}>{shiftChanged.l}</span>
                    }
                    else if (doc.data.ct === 3 && shiftChanged.newU) {
                      // console.log('change 3')
                      newUserKey = shiftChanged.newU[0] || ''
                      if (!doc.data.adm) {
                        //this is not an ADmin , so we sett current user here !
                        newUserKey = this.props.currentUser.inSysUserKey
                      }
                      userChanged = { ...project.cache[newUserKey] }
                      // console.log('>#shiftChanged', shiftChanged)
                      // console.log('>#shiftChanged.newU', newUserKey)
                      // console.log('>#userChanged', userChanged)
                      // console.log('>#cache', project.cache)
                      if (userChanged)
                        userChanged.img = userChanged.i ? getUserImage(projectKey, newUserKey, userChanged.i) : noUserImage
                      else {
                        userChanged = { n: 'unknown', i: '', sk: null, img: noUserImage }
                      }

                    }
                    else if (doc.data.ct === 4 && shiftChanged.newU) {
                      //console.log('change 4')
                      newUserKey = shiftChanged.newU[0] || ''
                      userChanged = { ...project.cache[newUserKey] }
                      shiftChanged = { ...doc.data.sh2 } || {}
                      shiftOrginal = { ...doc.data.sh } || {}
                      //userChanged = user
                      if (!doc.data.adm) {
                        //this is not an ADmin , so we sett current user here !
                        newUserKey = this.props.currentUser.inSysUserKey
                      }

                      if (userChanged)
                        userChanged.img = userChanged.i ? getUserImage(projectKey, newUserKey, userChanged.i) : noUserImage
                      else {
                        userChanged = { n: 'unknown', i: '', sk: null, img: noUserImage }
                      }
                    }
                    else {
                      //console.log('change else')
                      shiftChanged.l = renderTimeLable('@start - @end', doc.data.sh.d + ' ' + doc.data.t1, doc.data.sh.d + ' ' + doc.data.t2, this.props.AmPm)
                    }

                    shiftChanged.t1 = doc.data.t1
                    shiftChanged.t2 = doc.data.t2
                    break;

                  case 'adminrequest':
                    //console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>adminrequest', doc)

                    user = { n: window._getText('schedual.adminrequest'), i: '', sk: null }
                    user.img = user.i ? getUserImage(projectKey, 'adminrequest', user.i) : noUserImage

                    newUserKey = shiftChanged.newU[0] || ''
                    // if (!doc.data.adm) {
                    //   //this is not an ADmin , so we sett current user here !
                    //   newUserKey = this.props.currentUser.inSysUserKey
                    // }
                    userChanged = { ...project.cache[newUserKey] }
                    if (shiftChanged.newU && this.state.view === 1) {
                      userChanged.participants = []
                      shiftChanged.newU.forEach(userKey => {

                        var u = project.cache[userKey]
                        // console.log('>>', u)
                        if (u) {
                          userChanged.participants.push({ ...u, img: u.i ? getUserImage(projectKey, newUserKey, u.i) : noUserImage })
                        }
                      });

                      //  console.log('>>userChanged.participants', userChanged.participants)
                    }


                    // console.log('>#shiftChanged', shiftChanged)
                    // console.log('>#shiftChanged.newU', newUserKey)
                    // console.log('>#userChanged', userChanged)
                    // console.log('>#cache', project.cache)
                    if (userChanged)
                      userChanged.img = userChanged.i ? getUserImage(projectKey, newUserKey, userChanged.i) : noUserImage
                    else {
                      userChanged = { n: 'unknown', i: '', sk: null, img: noUserImage }
                    }



                    shiftChanged.t1 = doc.data.t1
                    shiftChanged.t2 = doc.data.t2
                    break;




                  default:
                    break;
                }

                const isClicked = this.state.requestClick[doc.id] || false
                //console.log('doc', doc)

                if (!s_schedualName && !isClicked) {
                  s_user = user
                  s_userChanged = userChanged
                  s_shiftChanged = shiftChanged
                  s_shiftOrginal = shiftOrginal
                  s_doc = doc
                  s_schedualName = schedualName
                }




                return (

                  <div key={doc.id} className={isClicked ? 'requestClickAni' : ''} style={{
                    borderLeft: doc.id === (s_doc || {}).id ? 'solid 3px rgb(251, 189, 8)' : 'solid 3px transparent',
                    background: doc.id === (s_doc || {}).id ? 'rgba(204, 204, 204, 0.3)' : '',
                    borderRadius: '0px', borderBottom: 'dotted 1px var(--color-border)', margin: '0px', fontSize: '12px',
                    display: 'flex'
                  }}
                    onClick={() => {
                      this.setState({ s_schedualName, s_user: user, s_userChanged: userChanged, s_shiftChanged: shiftChanged, s_shiftOrginal: shiftOrginal, s_doc: doc })

                    }} >


                    <div style={{ padding: '7px', paddingTop: '10px', flexGrow: '1', position: 'relative' }}>
                      <div style={{ padding: '0px', fontSize: '12px', marginRight: '4px', color: 'silver', position: 'absolute', top: '0px', right: '0px' }}>
                        {doc.cdate && moment(doc.cdate.seconds * 1000).fromNow()}
                      </div>
                      <Image circular size='mini' floated='left' src={user.img} alt='' style={{ margin: '0px', marginRight: '8px' }} onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }} />
                      <b style={{ display: 'block' }}>{user.n}</b>
                      <span style={{ fontSize: '12px', }}>
                        {(doc.n === 'deviation') && <Icon name='tag' size='small' />}
                        {(doc.n === 'comment') && <Icon name='comment' size='small' />}
                        {(doc.n === 'change') && <Icon name='comments' size='small' />}
                        {(doc.n === 'sick') && <Icon name='bed' size='small' />}
                        {this.getTitle(doc)}
                      </span>


                    </div>



                  </div>
                )
              })
            }



          </div >



          <div style={{ flexGrow: '1', border: 'solid 0px', overflowY: 'auto', overflowX: 'hidden', height: '100%', }}>
            {
              requestsKeys.length === 0 &&
              <div style={{ textAlign: 'center', marginTop: '150px' }}>
                <Icon name='folder open outline' style={{ fontSize: '50px' }}></Icon><br />
                {window._getText('message.in.empty')}
              </div>
            }
            {s_schedualName &&
              <React.Fragment>
                <div style={{ paddingTop: '12px', paddingBottom: '12px', paddingLeft: '14px', paddingRight: '12px', borderBottom: 'solid 1px var(--color-border)', minHeight: '50px', height: '50px', overflow: 'hidden', display: 'flex', flexDirection: 'row' }}>
                  <div style={{ flexGrow: '1', fontSize: '20px', fontWeight: 'bold', }}>
                    {this.getTitle(s_doc)}
                  </div>
                  <div>

                    {/* x<Button compact color='red' icon='remove' onClick={this.onDenyRequest(doc, user.sk, shiftChanged)} style={{ marginRight: '10px' }}  ></Button>
                  x <Button compact color='green' icon='check' onClick={this.onApproveChange(doc, user.sk, shiftChanged)}   ></Button> */}

                    {this.state.view === 0 &&
                      <Button tooltip_r={'Decline'} style={{ marginRight: '10px' }} className="btn btn-default" onClick={this.onDenyRequest(s_doc, s_user.sk, s_shiftChanged)} icon='x'></Button>
                    }
                    {this.state.view === 1 &&
                      <Button tooltip_r={'Cancel'} style={{ marginRight: '10px' }} className="btn btn-default" onClick={this.onDenyRequest(s_doc, s_user.sk, s_shiftChanged)} icon='x'></Button>
                    }

                    {/* <button tooltip={'Decline'} style={{ marginRight: '10px' }} className="btn btn-default" onClick={this.onDenyRequest(s_doc, s_user.sk, s_shiftChanged)} >
                    <div><span className="ico ico-cancel"></span></div>
                  </button> */}
                    {this.state.view === 0 &&
                      <Button tooltip_r={'Approve'} style={{}} className="btn btn-default" onClick={this.onApproveChange(s_doc, s_user.sk, s_shiftChanged)} icon='check'></Button>
                    }
                    {/* <button tooltip={'Approve'} style={{}} className="btn btn-default" onClick={this.onApproveChange(s_doc, s_user.sk, s_shiftChanged)} >
                    <div ><span className="ico ico-check"></span></div>
                  </button> */}

                  </div>

                </div>

                <div style={{ padding: '8px' }} >
                  <ShiftContainer view={this.state.view} schedualName={s_schedualName} user={s_user} shiftOrginal={s_shiftOrginal} ></ShiftContainer>
                  <div style={{ border: 'solid 0px red', float: 'left', padding: '15px', marginTop: '50px' }}>
                    {this.getIcon(s_doc)}
                  </div>
                  <ShiftContainer view={this.state.view} schedualName={s_schedualName} user={s_userChanged} shiftOrginal={s_shiftChanged} ></ShiftContainer>

                  <div style={{ border: 'solid 0px red', clear: 'both' }}>
                    {s_doc.data.com && <div style={{ paddingLeft: '22px', fontSize: '12px' }}><Icon name='comment outline' /> {s_doc.data.com}</div>}
                  </div>
                </div>
              </React.Fragment>
            }
          </div>

        </div >
      </React.Fragment>
    )
  }
}



const mapActions = {};
const mapStateToProps = (state, ownProps, ownState) => {

  return {
    projectKey: state.firebase.profile.projectKey,
    project: state.firestore.data.project,
    requestData: state.firestore.ordered['requestAllListner'],
    requestMyData: state.firestore.ordered['requestMyListner'],
    currentUser: state.firebase.profile,
    AmPm: state.firestore.data.project.s ? state.firestore.data.project.s.AmPm || false : false,
    settings: state.firestore.data.project.s || {}

  };
};
export default connect(
  mapStateToProps,
  mapActions
)(Requests);


