import React, { Component } from 'react'
import { openCopyScheduleModal, closeCopyScheduleModal } from "../../redux/action/modalAction";
import { connect } from 'react-redux'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import Input from 'semantic-ui-react/dist/commonjs/elements/Input'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

import { Field, reduxForm } from "redux-form";
import ModalFrame from './ModalFrame'

class CopyScheduleModal extends Component {
    onSubmit = values => {
        this.props.onCloseModal();
    };
    DropdownFormField = props => (
        <Form.Field>
            <Dropdown
                selection
                options={this.colors}
                {...props.input}
                value={props.input.value}
                onChange={(param, data) => props.input.onChange(data.value)}
                placeholder={props.label}
            />
        </Form.Field>
    );


    render() {

        let { handleSubmit, isOpen } = this.props; // formState
        // if (!formState || !formState.values) return null;


        return (
            <ModalFrame
                open={isOpen}
                // onClose={this.handleClose}
                dimmer='inverted'
                size="tiny"
                className='modalAniSlideUp'
            >
                <ModalFrame.Header>
                    Copy schedule
            </ModalFrame.Header>
                <ModalFrame.Content>
                    <Form >
                        <ModalFrame.Description>


                            Select schdeula<br />
                            <Field
                                name="color"
                                component={this.DropdownFormField}
                                label="Select color"

                            />


                            {window._getText('week')}<br />
                            <Field
                                name="comment"
                                component={Input}
                                type="text"
                                placeholder={window._getText('shiftuserinput.comment')}
                            /><br />

                            Copy<br />
                            <Field
                                name="hours"
                                component={Input}
                                type="text"
                                placeholder="hours"
                            />
                        </ModalFrame.Description>
                    </Form>
                </ModalFrame.Content>
                <ModalFrame.Actions>
                    <Form.Button
                        color="grey"
                        floated="left"
                        onClick={this.props.onCloseModal}
                    >
                        <Icon name="remove" /> Close
              </Form.Button>
                    <Form.Button
                        type="submit"
                        color="blue"
                        onClick={handleSubmit(this.onSubmit)}
                    >
                        <Icon name="save" /> Update
              </Form.Button>
                </ModalFrame.Actions>
            </ModalFrame>
        )
    }
}

const mapStateToProps = (state) => ({
    isOpen: state.modal.copyShedualModalOpen
})

const mapDispatchToProps = {
    onOpenModal: openCopyScheduleModal,
    onCloseModal: closeCopyScheduleModal

}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({ form: "copyScheduleForm" })(CopyScheduleModal))
