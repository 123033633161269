import React from 'react'
import { Image, Icon } from "../../common/component/Button";
import { short } from '../../common/util/helpers'
import noUserImage from '../../../img/noimg.png'

export default function ShiftContainer(props) {
    const { schedualName, user, shiftOrginal } = props
    const sick = shiftOrginal.sik === true ? true : false
    //   console.log('>>participants', user)

    return (
        <div style={{ margin: '2px', border: 'solid 1px #f1f1f1', float: 'left', padding: '15px', borderRadius: '9px', minWidth: '150px' }}>
            <Icon name='clock outline' /><span style={{ fontSize: '14px' }}>{shiftOrginal.d}<br /></span>
            <Icon name='calendar alternate outline' /><span style={{ fontSize: '14px' }}>{short(schedualName, 22)}<br /></span>
            <div style={{ float: 'left', border: 'solid 0px red', paddingTop: '4px', paddingBottom: '0px', minWidth: '200px' }}>
                <Image className='noMargin' size='mini' style={{ width: '16px' }} floated='left' src={user.img} circular onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }} />
                &nbsp;
                {user.participants === undefined && short(user.n, 22)}
                {user.participants !== undefined && user.participants.length + ' Users pending answer'}

                <br />
                <div className={"cellMulti ui label large dragMe x" + (sick ? 'var(--color-font)' : shiftOrginal.c)} style={{ minHeight: '34px', width: '100%' }} >
                    <div style={{ color: (sick === true ? 'silver' : 'var(--color-font)') }}>{shiftOrginal.l}</div>
                    <div style={{ color: (sick === true ? 'silver' : 'var(--color-font)') }}>{shiftOrginal.co}</div>
                </div>

            </div>
            <div style={{ clear: 'both' }}></div>
        </div>

    )
}
