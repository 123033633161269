import React, { Component } from "react";
import { Segment, Button, Icon, Loader } from "../../../common/component/Button";
import { ScheduleRow } from "./ScheduleRow";
import { connect } from "react-redux";
import moment from 'moment'
import { openModal, closeModal, openEventModal, openCopyScheduleModal } from "../../../redux/action/modalAction";
// import ScheduleEmpty from './ScheduleEmpty'
import ScheduleTemplates from './ScheduleTemplates'
import collection from "lodash/collection";
import { addUser } from "../../../redux/action/userAction";
import moize from "moize";
import memoizeOne from 'memoize-one';
import { shallowEqual } from 'shouldcomponentupdate-children';
import { isMobile } from "react-device-detect";

var isElectronApp = (process.env.REACT_APP_ELECTRON === '1')
let ipcRenderer
let electronMenu
let electronRemote
isElectronApp = !!window.require //TEMP
if (isElectronApp) {
    ipcRenderer = window.require('electron').ipcRenderer
    electronRemote = window.require('electron').remote;
    electronMenu = electronRemote.Menu;
}


const getUserEvents = moize((eventList, user) => {
    //console.log('>>>get fresh userevetn list',user.key)
    return collection.filter(eventList, obj => {
        return obj.uid === user.key;
    });
})


const getHeader = memoizeOne((renderDayDate, todayDate, onDragOver) => {
    const today = moment.utc(todayDate).format('dd\u00A0DD')
    // console.log('>>getHeader memoizeOne', renderDayDate)
    // console.log('>>today', today)
    // console.log('---------')

    const css = { color: '#fff', padding: '3px', background: 'rgba(153,0,0,1)', borderRadius: '30px', }

    let dd1 = moment.utc(renderDayDate)
    let dd2 = moment.utc(renderDayDate).add(1, 'days')
    let dd3 = moment.utc(renderDayDate).add(2, 'days')
    let dd4 = moment.utc(renderDayDate).add(3, 'days')
    let dd5 = moment.utc(renderDayDate).add(4, 'days')
    let dd6 = moment.utc(renderDayDate).add(5, 'days')
    let dd7 = moment.utc(renderDayDate).add(6, 'days')

    let d1 = dd1.format("dd\u00A0DD")
    let d2 = dd2.format("dd\u00A0DD")
    let d3 = dd3.format("dd\u00A0DD")
    let d4 = dd4.format("dd\u00A0DD")
    let d5 = dd5.format("dd\u00A0DD")
    let d6 = dd6.format("dd\u00A0DD")
    let d7 = dd7.format("dd\u00A0DD")



    if (d1 === today)
        d1 = <React.Fragment>{dd1.format("dd\u00A0")}<span style={css}>{dd1.format("DD")}</span></React.Fragment>
    else if (d2 === today)
        d2 = <React.Fragment>{dd2.format("dd\u00A0")}<span style={css}>{dd2.format("DD")}</span></React.Fragment>
    else if (d3 === today)
        d3 = <React.Fragment>{dd3.format("dd\u00A0")}<span style={css}>{dd3.format("DD")}</span></React.Fragment>
    else if (d4 === today)
        d4 = <React.Fragment>{dd4.format("dd\u00A0")}<span style={css}>{dd4.format("DD")}</span></React.Fragment>
    else if (d5 === today)
        d5 = <React.Fragment>{dd5.format("dd\u00A0")}<span style={css}>{dd5.format("DD")}</span></React.Fragment>
    else if (d6 === today)
        d6 = <React.Fragment>{dd6.format("dd\u00A0")}<span style={css}>{dd6.format("DD")}</span></React.Fragment>
    else if (d7 === today)
        d7 = <React.Fragment>{dd7.format("dd\u00A0")}<span style={css}>{dd7.format("DD")}</span></React.Fragment>




    return (
        <tr onDragOver={onDragOver} style={{ height: '40px' }}>
            <th style={{ border: 'none', borderBottom: 'solid 1px rgba(34, 36, 38, 0.1)' }} >

            </th>


            <th className='scheduelHeaderCell noSelect'>{d1}</th>
            <th className='scheduelHeaderCell noSelect'>{d2}</th>
            <th className='scheduelHeaderCell noSelect'>{d3}</th>
            <th className='scheduelHeaderCell noSelect'>{d4}</th>
            <th className='scheduelHeaderCell noSelect'>{d5}</th>
            <th className='scheduelHeaderCell noSelect'>{d6}</th>
            <th className='scheduelHeaderCell noSelect'>{d7}</th>


        </tr>)
})

class Schedule1 extends Component {
    constructor(props) {
        super(props);
        this.AddButtonRef = React.createRef();
    }
    state = { showDropZone: false, templates: {} };

    shouldComponentUpdate(nextProps, nextState) {
        const r = shallowEqual(this.props, nextProps, this.state, nextState);
        // console.log('>>shouldComponentUpdate>>Schedule1>>', r)
        // if (r === true) {
        //     Object.keys(this.props).forEach(key => {
        //         if (this.props[key] !== nextProps[key]) {
        //             console.log('key', key)
        //             console.log('props', this.props[key] === nextProps[key])
        //         }
        //     })
        // }

        return r;
    }

    componentUpdateData() {
        //    console.log('componentUpdateData')
        var { schedules, currentScheduelData } = this.props

        if (schedules && currentScheduelData) {

            if (schedules && currentScheduelData && schedules[currentScheduelData.selectedKey]) {
                if (schedules[currentScheduelData.selectedKey].templates !== this.state.templates) {
                    this.setState({ templates: schedules[currentScheduelData.selectedKey].templates })
                }
            }
        }

    }
    componentDidMount() {
        //   console.log('componentDidMount')

        //window.addEventListener("onscroll", this.hideAddShiftButton)
        this.componentUpdateData()
    }

    componentWillUnmount() {
        // window.removeEventListener("onscroll", this.hideAddShiftButton)

    }
    componentDidUpdate = (prevProps, prevState, snapshot) => {
        // console.log('-----------')
        //   console.log('>>>componentDidUpdate')
        //   console.log('prevProps',prevProps)
        //   console.log('this.props',this.props)
        //   console.log('this.props',this.props===prevProps)

        var { schedules, currentScheduelData } = this.props

        if (schedules !== prevProps.schedules || currentScheduelData !== prevProps.currentScheduelData) {
            // console.log('do force update')
            this.componentUpdateData()
        }

    }

    //TODO clean this dragand drop events ! maybe we can reuse some also
    preventDefault = event => () => {
        event.preventDefault();
    };


    onSchedualContextMenu = (e) => {
        e.preventDefault();


        if (!isElectronApp)
            return


        var item = e.target
        var exit = 20
        var eventId
        if (item.tagName.toLowerCase() !== "td") {
            do {
                if (item) {
                    if (item.id)
                        eventId = item.id

                    item = item.parentNode;
                }
                else
                    exit = -1
                // console.log(exit, item)

                exit--;
            } while ((item.tagName.toLowerCase() !== "body" && item.tagName.toLowerCase() !== "td") || exit < 0);
        }

        window._clickedTarget = item;
        console.log('item.tagName.toLowerCase()', item.tagName.toLowerCase())
        console.log('eventId', eventId)
        console.log('item.tagName', item)
        console.log('****************************')

        if (item.tagName.toLowerCase() === "td") {
            var userkey = item.getAttribute('userkey') || null
            var dataedate = item.getAttribute('dataedate')
            var contextMenu

            if (eventId) {
                contextMenu = electronMenu.buildFromTemplate([
                    {
                        label: 'New Shift',

                        click: () => window.dispatchEvent(new CustomEvent("newshift", { detail: { userkey, dataedate } })),
                    },
                    {
                        label: 'Paste Shift',

                        click: () => window.dispatchEvent(new CustomEvent("pasteshift", { detail: { userkey, dataedate } })),
                    },
                    {
                        label: 'Copy shift',
                        click: () => window.dispatchEvent(new CustomEvent("copyshift", { detail: { eventId } })),
                    },

                    {
                        label: 'Delete shift',

                        click: () => window.dispatchEvent(new CustomEvent("onRemoveEvent", { detail: { eventId } })),
                    },
                    { type: 'separator' },
                    {
                        label: 'Create shift request',

                        click: () => window.dispatchEvent(new CustomEvent("onCreateShiftRequest", { detail: { eventId } })),
                    },
                ])
            }
            else {
                contextMenu = electronMenu.buildFromTemplate([
                    {
                        label: 'New Shift',
                        accelerator: 'CmdOrCtrl+Z',
                        selector: 'undo:',
                        click: () => window.dispatchEvent(new CustomEvent("newshift", { detail: { userkey, dataedate } })),
                    },
                    {
                        label: 'Paste Shift',
                        accelerator: 'CmdOrCtrl+V',
                        selector: 'paste:',
                        click: () => window.dispatchEvent(new CustomEvent("pasteshift", { detail: { userkey, dataedate } })),
                    }
                ])
            }

            contextMenu.popup(electronRemote.getCurrentWindow());

        }


    }

    onDragStartRow = e => {
        console.log('onDragStartRow', e.target.tagName.toLowerCase())
        if (e.target.tagName.toLowerCase() === "div" || e.target.tagName.toLowerCase() === "img" || e.target.tagName.toLowerCase() === "td" || e.target.tagName.toLowerCase() === "h5" || e.target.tagName.toLowerCase() === "h4") {
            var TR = e.target;
            var exit = 10;
            do {
                TR = TR.parentNode;
                exit--;
            } while (TR.tagName.toLowerCase() !== "tr" || exit < 0);

            e.dataTransfer.setData("text", JSON.stringify({ id: TR.id, t: "row" }));
            this.onDragOverShowDropZone();
        }
    };
    onDragOver = e => {
        e.stopPropagation();
        e.preventDefault();
    };
    onDragOverRow = e => {
        e.stopPropagation();
        e.preventDefault();
    };
    onDropRow = e => {

        e.preventDefault();

        var data;
        try {
            data = JSON.parse(e.dataTransfer.getData("text"));
        } catch (error) {
            console.log("JSON.parse error");
            return;
        }
        if (data.t !== "row") {
            console.log("exit row drop");
            return;
        }

        let dragItem = document.getElementById(data.id);
        console.log(">>>dragItem", dragItem);

        var dropObj = e.target;
        var exit = 10;
        do {
            dropObj = dropObj.parentNode;
            //console.log('dropObj',dropObj.order)
            exit--;
        } while (dropObj.tagName.toLowerCase() !== "tr" || exit < 0);

        try {
            var dragValue = dragItem.attributes["order"].value || 0;
            var dropValue = dropObj.attributes["order"].value || 0;

            this.props.mergedUsers.splice(dragValue - 1, 1); // REMOVE ORGINAL ITEM
            this.props.mergedUsers.splice(dropValue - 1, 0, data.id); // dubbel check this ?
            this.props.setSortedUserList(this.props.mergedUsers, data.id);
            //what to do herer ? save a sorted list ?
        } catch (error) {
            console.log("error -- error swapping", error);
        }

        this.onDragOverHideDropZone();
    };

    onDragOverHideDropZone = () => {
        //console.log(">>onDragOverHideDropZone");
        this.setState({ showDropZone: false });
    };
    onDragOverShowDropZone = () => {
        if (this.AddButtonRef && this.AddButtonRef.current) {
            this.AddButtonRef.current.style.top = '-500px'
        }
        this.setState({ showDropZone: true });

    };

    onDropRemove = e => {
        var data;
        try {
            data = JSON.parse(e.dataTransfer.getData("text"));
        } catch (error) {
            console.log("JSON.parse error");
            return;
        }


        if (data.t === "cell") this.props.onRemoveEvent(data.id);
        else if (data.t === "row") this.props.onRemoveRow(data.id);
        else if (data.t === "template") this.props.onRemoveTemplate(data.id);

        this.onDragOverHideDropZone();
    };


    onOpenModal = (key) => {
        this.props.openModal('user', key)
    }
    onOpenModalSelectUsers = () => {
        this.props.openModal('selectUsers')
    }
    addButtonItem = null
    showAddShiftButton = (pos, eventDate, userKey) => {
        if (this.AddButtonRef && this.AddButtonRef.current) {

            if (pos) {
                if (this.hideAddShiftButtonTimer)
                    clearTimeout(this.hideAddShiftButtonTimer)

                this.addButtonItem = { eventDate, userKey }
                this.AddButtonRef.current.style.left = (pos.right - 30) + 'px'
                this.AddButtonRef.current.style.top = pos.top + 'px'
                this.AddButtonRef.current.style.opacity = 1
                //hide the + if mouse in the top , this is not the best way to do it !
                if (this.AddButtonRef.current.getBoundingClientRect().top < 39) {
                    this.AddButtonRef.current.style.opacity = 0
                    this.AddButtonRef.current.style.top = '-500px' //TODO WE can do this better
                }
            }
            else {
                this.AddButtonRef.current.style.opacity = 0
                this.AddButtonRef.current.style.top = '-500px'
            }
        }

    }
    hideAddShiftButtonTimer = null
    hideAddShiftButton = () => {

        if (this.AddButtonRef && this.AddButtonRef.current) {
            if (this.hideAddShiftButtonTimer)
                clearTimeout(this.hideAddShiftButtonTimer)

            this.hideAddShiftButtonTimer = setTimeout(() => {
                if (this.AddButtonRef.current) {
                    this.AddButtonRef.current.style.top = '-500px'
                    this.AddButtonRef.current.style.opacity = 0
                }
            }, 100);

        }
    }
    clearhideAddShiftButtonTimer = () => {
        clearTimeout(this.hideAddShiftButtonTimer)
    }
    onClickAddButton = () => {
        const { userKey, eventDate } = this.addButtonItem
        this.props.onAddEvent(eventDate, userKey)()
        this.hideAddShiftButton()
    }
    onClickAddButton2 = () => {
        this.props.onAddEvent(null, null)()
        this.hideAddShiftButton()
    }

    onOpenEventModal = (event, shift) => {
        if (isElectronApp) {

            var target = event.target
            // if(target.tagName!=='div')
            // {

            // }
            console.log('>>onOpenEventModal', target)
            var exit = 5

            if (!target.id) {
                do {
                    console.log('target', target)
                    if (target)
                        target = target.parentNode;
                    else
                        exit = -1



                    exit--;
                } while (target.id === '' || exit < 0);
            }


            console.log('target============', target)

            var { x, y, width, height, top, left, right } = target.getBoundingClientRect()
            x = left
            y = top

            if (ipcRenderer)
                ipcRenderer.send('renderWindowTargetPos', { x, y, width, height, top, left, right })
        }
        this.props.openEventModal(shift)
    }
    renderRows = () => {
        const { openDivierModal, users, onAddEvent, currentScheduleData, mergedUsers, onAddTemplateShift, onMoveEvent, renderDayDate, renderDayCount } = this.props;

        //TODO 
        // do the dividerList exists in the currentScheduleData.dividerList list ?

        const eventList = currentScheduleData.scheduleEventsList;
        const metaDataList = currentScheduleData.metaData

        if (!mergedUsers) return; //TEMP FUCKER

        //ORDER herer!!!
        var order = 0;

        var userRows = mergedUsers.map(key => {
            order++;
            if (users) {
                var user = users[key];
                if (!user) {
                    user = { name: "Unknown user", newUser: true, key, isActive: true }; //TODO user factory
                }
            }

            if (user) {
                var userEvents = getUserEvents(eventList, user)
                return (
                    <ScheduleRow
                        AmPm={this.props.AmPm}
                        showAddShiftButton={this.showAddShiftButton}
                        hideAddShiftButton={this.hideAddShiftButton}
                        projectKey={this.props.projectKey}
                        dividerList={currentScheduleData.dividerList}
                        openDivierModal={openDivierModal}
                        renderDayDate={renderDayDate}
                        renderDayCount={renderDayCount}
                        onOpenEventModal={this.onOpenEventModal}
                        order={order}
                        onDragOverShowDropZone={this.onDragOverShowDropZone}
                        onDragOverHideDropZone={this.onDragOverHideDropZone}
                        onMoveEvent={onMoveEvent}
                        onAddTemplateShift={onAddTemplateShift}
                        key={key}
                        userKey={user.key}
                        dragStartRow={this.onDragStartRow}
                        dropRow={this.onDropRow}
                        dragOverRow={this.onDragOverRow}
                        imgUrl={user.image}
                        openUser={this.onOpenModal}
                        name={user.name}
                        subText={user.name}
                        onAddEvent={onAddEvent}
                        userEvents={userEvents}
                        metaDataList={metaDataList}


                    />
                );
            } else return null;
        });

        return userRows;
    };

    styleTbl = () => ({ minHeight: '100%', marginTop: this.props.showWeekPicker ? '88px' : '0px', marginBottom: '70px' })
    style = {
        tblHead: { xposition: 'fixed', background: '#fff' },
        tblRowDelete: { height: '40px' },
        tblRowDelete1: { position: 'relative', },
        tblRowDelete2: { height: '40px', overflow: 'hidden', position: 'absolute', top: '0px', width: '100%' },
        tblRowDelete3: { paddingTop: '5px', paddingBottom: '1px', height: '31px', marginTop: '7px' },
        tblBody: { xmarginTop: '40px', display: 'block', overflow: 'scroll', height: 'calc(100vh -  80px)' },
        addShiftBtn: { position: 'absolute', top: '-500px', opacity: '0' },
        addShiftBtn2: { position: 'fixed', bottom: '80px', right: '35px', opacity: '0.7', zIndex: '1000' },

    }

    render() {
        // console.log('>>render-->>Schedual1')
        if (false) {
            console.log('************')
            console.log('Schedle 1 ', this.props);
            console.log('Schedle 1 ', this.props.currentScheduleData);
            console.log('isEmpty ', this.props.currentScheduleData.isEmpty);
            console.log('loading ', this.props.currentScheduleData.loading);
            console.log('************')
        }



        return (

            <React.Fragment >
                <div className='schedulePrint'   >
                    {/* <div className="divFooter">www.TimeTo.Work</div> */}

                    {(collection.size(this.props.mergedUsers) === 0) &&
                        <React.Fragment>

                            {/* {this.props.isLoadingEvents === false && (this.props.currentScheduelData && this.props.currentScheduelData.selectedKey) && <ScheduleEmpty onOpenCopySheduleModal={this.props.openCopyScheduleModal}></ScheduleEmpty>} */}
                            <div className='cc' style={{ animation: 'fadeinLoader 3s', position: 'fixed' }}>
                                <Loader inline active={this.props.isLoadingEvents} style={{ textAlign: 'center', marginTop: '50px' }} ></Loader>
                            </div>

                        </React.Fragment>
                    }

                    <table className="ui very basic table tableBodyScroll unstackable tblScheduel" style={this.styleTbl()} onContextMenu={this.onSchedualContextMenu}  >
                        <thead style={this.style.tblHead} className='fixed tblSchedualHead'  >
                            {this.state.showDropZone === true && (
                                <tr onDrop={this.onDropRemove} onDragOver={this.onDragOverShoweDropZone} style={this.style.tblRowDelete}>
                                    <th colSpan={8} style={this.style.tblRowDelete1}>
                                        <div className='printHidden' style={this.style.tblRowDelete2}>
                                            <Segment style={this.style.tblRowDelete3} inverted color="red" tertiary onDragOver={this.onDragOver}>
                                                <Icon name="trash" />  {window._getText('schedual.dropdelete')} <Icon name="trash" />
                                            </Segment>
                                        </div>
                                    </th>
                                </tr>
                            )}
                            {this.state.showDropZone === false && this.props.renderDayDate && (
                                getHeader(this.props.renderDayDate.format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'), this.onDragOver)
                            )}
                        </thead>



                        <tbody style={this.style.tblBody} onScroll={this.hideAddShiftButton} onMouseLeave={this.hideAddShiftButton} >
                            {this.renderRows(this.props.renderDayDate)}


                            <tr className='noPrint'>
                                <td className="two wide sRowCell"  >
                                    <div style={{ minHeight: 'calc(100vh - 95px)', height: 'calc(100% - 95px)' }}></div>
                                </td>
                                <td className="two wide scheduelCell" dataedate={this.props.renderDayDate}></td>
                                <td className="two wide scheduelCell" dataedate={moment(this.props.renderDayDate).add(1, 'days')} ></td>
                                <td className="two wide scheduelCell" dataedate={moment(this.props.renderDayDate).add(2, 'days')} ></td>
                                <td className="two wide scheduelCell" dataedate={moment(this.props.renderDayDate).add(3, 'days')} ></td>
                                <td className="two wide scheduelCell" dataedate={moment(this.props.renderDayDate).add(4, 'days')} ></td>
                                <td className="two wide scheduelCell" dataedate={moment(this.props.renderDayDate).add(5, 'days')} ></td>
                                <td className="two wide scheduelCell" dataedate={moment(this.props.renderDayDate).add(6, 'days')} ></td>

                            </tr>

                        </tbody>



                    </table>

                    {!isMobile && isElectronApp === false &&
                        <span className='addShiftBtn' ref={this.AddButtonRef} style={this.style.addShiftBtn} onMouseMove={this.clearhideAddShiftButtonTimer} >
                            <Button compact onClick={this.onClickAddButton} circular icon='add' size='tiny' />
                        </span>
                    }
                    {isMobile && isElectronApp === false &&
                        <span style={this.style.addShiftBtn2} >
                            <Button compact onClick={this.onClickAddButton2} circular icon='add' color='blue' size='huge' style={{ boxShadow: ' 1px 1px 1px rgba(68,68,68,0.6)' }} />
                        </span>
                    }
                </div>
                {this.props.viewstate.template &&
                    <ScheduleTemplates
                        onDragOverShowDropZone={this.onDragOverShowDropZone}
                        onDragOverHideDropZone={this.onDragOverHideDropZone}
                        templates={this.state.templates}
                        onOpenEventModal={this.props.onOpenEventModal}></ScheduleTemplates>
                }
            </React.Fragment>
        );
    }
}

const mapActions = {

    openModal: openModal,
    onCloseModal: closeModal,
    openEventModal: openEventModal,
    openCopyScheduleModal,
    addUser
};
const mapStateToProps = state => {
    var userList = {};


    //USERS
    if (state.firestore.data.users)
        userList = state.firestore.data.users.userlist;

    return {
        projectKey: state.firebase.profile.projectKey,
        isLandscape: state.viewstate.isLandscape,
        viewstate: state.viewstate,
        users: userList,
        AmPm: state.firestore.data.project.s ? state.firestore.data.project.s.AmPm || false : false
    };
};
export default connect(
    mapStateToProps,
    mapActions
)(Schedule1);

//jens