import React, { Component } from 'react'

import PropTypes from 'prop-types'


class Locked extends Component {
  static contextTypes = { router: PropTypes.object }

  render() {
    return (
      <div style={{ padding: '20px' }} >
        <h2>{window._getText('locked.title')}</h2>
        <p>
          {window._getText('locked.txt1')}<br />
          {window._getText('locked.txt2')}<br />
          <b style={{ cursor: 'pointer' }} onClick={() => { this.context.router.history.push('/schedule/settings') }}>{window._getText('locked.link')}</b>
        </p>
      </div>
    )
  }
}


export default (Locked)