import React, { Component } from 'react';




const colors = [
  "red",
  "orange",
  "yellow",
  "olive",
  "green",
  "teal",
  "blue",
  "violet",
  "purple",
  "pink",
  "brown",
  "grey",
  "black"
];

const colorsCodes = [
  'rgba(219, 40, 40, 0.4)',
  'rgba(242, 113, 28, 0.4)',
  'rgba(251, 189, 8, 0.4)',
  'rgba(181, 204, 24, 0.4)',
  'rgba(33, 186, 69, 0.4)',
  'rgba(2, 195, 154, 0.4)',
  'rgba(33, 133, 208, 0.4)',
  'rgba(100, 53, 201, 0.4)',
  'rgba(163, 51, 200, 0.4)',
  'rgba(224, 57, 151, 0.4)',
  'rgba(165, 103, 63, 0.4)',
  'rgba(118, 118, 118, 0.4)',
  'rgba(27, 28, 29, 0.4)'
]
export default class ColorSlider extends Component {
  state = { colorIndex: -1 }


  componentDidMount = () => {
    this.setValue()
  }
  componentDidUpdate = (prevProps, prevState, snapshot) => {

    if (prevProps.input.value !== this.props.input.value)
      this.setValue()


  }
  setValue = () => {
    const { input } = this.props
    if (input.value) {
      const colorIndex = colors.indexOf(input.value)
      this.setState({ colorIndex: Number(colorIndex) })
    }

  }
  changeValue = () => {
    this.setState({ show: false })
    this.props.input.onChange(colors[this.state.colorIndex])
  }
  //handleChange = (event) => this.setState({ colorIndex: Number(event.target.value) }, () => { this.changeValue() });

  setColor = (event) => {
    console.log(event.target.value);
    const colorIndex = colors.indexOf(event.target.value)
    this.setState({ colorIndex: Number(colorIndex) }, () => { this.changeValue() });
    this.props.toggle()
  }

  render() {
    const { input, isMobile, lable } = this.props

    if (this.state.colorIndex === -1)
      return (null)


    return (

      <div style={{ border: 'solid 0px red', position: 'relative', }}>
        <input onChange={(param, data) => input.onChange(data.value)} {...input} type='hidden' />

        {!isMobile && <span style={{ width: '100%' }} >
          {lable && <b>{lable}:</b>}

        </span>}
        <div onClick={this.props.toggle} style={{ width: '24px', height: '24px', borderRadius: '25px', border: 'solid 2px #f1f1f1', backgroundColor: colorsCodes[this.state.colorIndex] }}></div>
        {this.props.showColor &&
          <div onMouseDown={(e) => { e.stopPropagation() }} onChange={this.setColor} style={{
            display: 'flex', justifyContent: 'space-evenly',
            left: '5px', marginTop: '20px', position: 'absolute', top: '10px', width: '320px', xheight: '150px', background: '#FAFAFA', boxShadow: '1px 3px 3px 0px rgba(0, 0, 0, 0.2), 1px 3px 15px 2px rgba(0, 0, 0, 0.2)', border: 'solid 1px var(--color-border)', padding: '6px', zIndex: '4', borderRadius: '4px'
          }}>
            <div style={{ width: '10px', height: '10px', background: '#FAFAFA', position: 'absolute', top: '-6px', transform: 'rotate(45deg)', left: '3px', borderTop: 'solid 1px rgba(187,187,187,0.6)', borderLeft: 'solid 1px rgba(187,187,187,0.6)' }}></div>

            {colors.map((item, index) => {
              return (
                <div style={{ padding: '2px' }} key={item} className='noSelect'>
                  <input style={{ display: 'none' }} type="radio" id={item} value={item} name="color" defaultChecked={this.state.colorIndex === index} />
                  <label htmlFor={item} style={{ border: this.state.colorIndex === index ? 'solid 2px #5492F' : 'solid 2px #f1f1f1', backgroundColor: colorsCodes[index], borderRadius: '20px', content: '', width: !isMobile ? '20px' : '25px', height: !isMobile ? '20px' : '25px', display: 'block' }}>{'\u00A0'}</label>
                </div>

              )
            })}



          </div>
        }

        {/* <div style={{ border: 'solid 0px green', margin: '0px', padding: '0px' }}>
          <input
            type="range"
            min="0" max="12"
            //defaultValue="0"
            value={this.state.colorIndex || 0}
            onChange={this.handleChange}
            step="1"
            style={{ width: '100%', left: '0px', top: '0px', margin: '0px' }}
          />
        </div> */}
      </div>

    )

  }
}