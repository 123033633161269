import { GET_SCHEDULE, SET_SCHEDULE, SET_SCHEDULE_ISLOADING, SET_SCHEDULE_RENDERDATE, COPY_SCHEDULE_SHIFT, RESET_SCHEDULE } from "../action/currentScheduelDataAction";
import { createReducer } from "../../common/util/reducerUtil";

const initState = { selectedKey: null, selectedDateKey: null, refresh: false, isLoading: false, renderDayDate: null, copyShiftObject: null }
const initScheduleObject = {
  key: null,
  dateKey: '-',
  selectedSchedule: null,
  isNew: false,
  dirty: false,
  scheduleEventsList: {}, //events belongs to schedule
  scheduleUserList: [], //users belongs to schedule
  selectedUsers: [], // new users add from the full userslist
  sortedUserList: [], //users keys sorted
  dividerList: {} // list of dividers 
};

//FIX THIS NAME WRONG! it returns the state and not a schedule
export const getSchedule = (state, payload) => {
  //console.log('***getSchedule')
  //var key = payload? payload:state.selectedKey  
  //var schdeule = state[key] 

  return { ...state };
};

export const resetSchedule = (state, payload) => {
  //console.log('***ok resetSchedule')
  //var key = payload? payload:state.selectedKey  
  //var schdeule = state[key] 
  //console.log('RESET', { ...initState })
  return { ...initState };
};


export const setIsLoadingSchedule = (state, payload) => {
  //console.log('***setIsLoadingSchedule')
  return { ...state, isLoading: payload }
}
export const setRenderDayDateSchedule = (state, payload) => {
  //console.log('***setRenderDayDateSchedule')
  return { ...state, renderDayDate: payload }
}


export const setSchedule = (state, payload) => {
  //console.log('***setSchedule')
  var newState = { ...state }
  var key = payload.key ? payload.key : state.selectedKey // important to set selected key , before doinign any other updates 
  var datekey = payload.dateKey ? payload.dateKey : state.selectedDateKey
  newState.selectedKey = key;
  newState.selectedDateKey = datekey;
  newState[key + '_' + datekey] = { ...initScheduleObject, ...newState[key], ...payload };
  return newState;
};
export const copyScheduleShift = (state, payload) => {
  //console.log('***copyScheduleShift', payload)
  const newState = { ...state, copyShiftObject: payload }
  return newState;
}

export default createReducer(initState, {
  [GET_SCHEDULE]: getSchedule,
  [SET_SCHEDULE]: setSchedule,
  [SET_SCHEDULE_ISLOADING]: setIsLoadingSchedule,
  [SET_SCHEDULE_RENDERDATE]: setRenderDayDateSchedule,
  [COPY_SCHEDULE_SHIFT]: copyScheduleShift,
  [RESET_SCHEDULE]: resetSchedule
});
