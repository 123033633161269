
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/messaging';

// eslint-disable-next-line
const devConfig = {
    apiKey: "AIzaSyCKSc7auF6aHqcVGSbDbeGjdbv9sXp-XTc",
    authDomain: "simpleschedule-9ae21.firebaseapp.com",
    databaseURL: "https://simpleschedule-9ae21.firebaseio.com",
    projectId: "simpleschedule-9ae21",
    storageBucket: "simpleschedule-9ae21.appspot.com",
    messagingSenderId: "328636915454",
    timestampsInSnapshots: true,
    experimentalTabSynchronization: true,
    imgBase: 'us-central1-simpleschedule-9ae21.cloudfunctions.net',
}

const prodConfig = {
    apiKey: "AIzaSyClVenkfR062MtZQJUR-zvK_j42s1boFrk",
    authDomain: "timetowork-prod.firebaseapp.com",
    databaseURL: "https://timetowork-prod.firebaseio.com",
    projectId: "timetowork-prod",
    storageBucket: "timetowork-prod.appspot.com",
    messagingSenderId: "580487839902",
    timestampsInSnapshots: true,
    experimentalTabSynchronization: true,
    imgBase: 'us-central1-timetowork-prod.cloudfunctions.net',
};
const stagingConfig = {
    apiKey: "AIzaSyBAiZs9L562ohBJU3kBlMdnEPFcfD2MHEY",
    authDomain: "timetowork-staging.firebaseapp.com",
    databaseURL: "https://timetowork-staging.firebaseio.com",
    projectId: "timetowork-staging",
    storageBucket: "timetowork-staging.appspot.com",
    messagingSenderId: "1039703131188",
    timestampsInSnapshots: true,
    experimentalTabSynchronization: true,
    imgBase: 'us-central1-timetowork-staging.cloudfunctions.net',
};

const config = process.env.REACT_APP_ENV === 'production'
    ? prodConfig
    : process.env.REACT_APP_ENV === 'staging' ? stagingConfig : devConfig;

if (process.env.REACT_APP_ENV !== 'production') {
    console.log('**CONFIG**', process.env.REACT_APP_ENV)
    console.log('**CONFIG**', config.authDomain)
}
window._iapUrl = config.imgBase
var db;
if (!firebase.apps.length) {
    if (process.env.REACT_APP_ENV !== 'production') {
        console.log('INIT FIREBASE', firebase.SDK_VERSION)
        console.log('Config', process.env.REACT_APP_ENV)

    }
    firebase.initializeApp(config);

    db = firebase.firestore()
    db.settings({ timestampsInSnapshots: true })
    db.enablePersistence({ experimentalTabSynchronization: true }).then(() => {
        //console.log("Woohoo! Multi-Tab Persistence!");
    }).catch(function (err) {
        console.log('enablePersistence', err)
        // alert( err)
        if (err.code === 'failed-precondition') {

            // Multiple tabs open, persistence can only be enabled
            // in one tab at a a time.
            // ...
        } else if (err.code === 'unimplemented') {
            // The current browser does not support all of the
            // features required to enable persistence
            // ...
        }
    });

}

export default firebase;
const auth = firebase.auth();
const getMessaging = () => {
    return firebase.messaging()
}
// const messaging = undefined
// try {
//     messaging = firebase.messaging()
// } catch (error) {
//     console.log('error', error)
// }
export const provider = new firebase.auth.GoogleAuthProvider();
export { auth };
export { db };
export { config };
export { getMessaging };


