function onLoad() {
    document.addEventListener("deviceready", onDeviceReady, false);
}
window.addEventListener("load", onLoad);


function onDeviceReady() {
    console.log('>> CORDOVA  onDeviceReady')
    document.addEventListener("backbutton", onBackKeyDown, false);
    window.isCordovaApp = true;
    // try {
    //     //  initializeStore();
    // } catch (error) {
    //     console.log('error initStore', error)
    // }

    // try {
    //     console.log('window.cordova', window.cordova)
    //     console.log('window.cordova.plugins', window.cordova.plugins)
    //     window.cordova.plugins.Keyboard.automaticScrollToTopOnHiding = true;
    // } catch (error) {
    //     console.log('keyboard errro', error)
    // }


    const scrollToTopFn = () => {
        if (window.Keyboard && !window.Keyboard.isVisible) {
            window.scrollTo(0, 0);
            window.document.body.scrollTop = 0;
            window.log('keyboardDidHide !!!!')
        }
    };

    window.addEventListener('keyboardDidHide', () => {
        window.setTimeout(scrollToTopFn, 100);
        window.log('keyboardDidHide')
    });




    initFirebasePlugin()
}

function onBackKeyDown() {
    window.history.back();
}



function initFirebasePlugin() {

    if (window.FirebasePlugin) {


        window.FirebasePlugin.grantPermission();

        // window.FirebasePlugin.hasPermission(function (data) {
        //     console.log(data.isEnabled);
        //     if (data.isEnabled === false)
        //         window.FirebasePlugin.grantPermission();

        // });


        window.FirebasePlugin.onMessage(function (notification) {
            console.log('msg in cordova!')
            window.dispatchEvent(new CustomEvent("onMessagePush", { detail: { ...notification } }));
        }, function (error) {
            console.error(error);
        });

        window.FirebasePlugin.onNotificationOpen(function (notification) {
            window.dispatchEvent(new CustomEvent("onNotificationOpen", { detail: { ...notification } }));
        }, function (error) {
            console.error(error);
        });

        window.FirebasePlugin.onTokenRefresh(function (token) {
            window.dispatchEvent(new CustomEvent("onTokenRefresh", { detail: token }));
        }, function (error) {
            console.error(error);
        });

        window.FirebasePlugin.getToken(function (token) {
            window.dispatchEvent(new CustomEvent("getToken", { detail: token }));
        }, function (error) {
            console.error(error);
        });
    }
}


window.initializeStore = (projectId) => {
    console.log('initializeStore')
    if (!window.isStoreInit) {
        window.isStoreInit = true
        console.log('----------->InitStore v0.01<-------------------')
        if (!window.store) {
            console.log('Store not available');
            return;
        }

        var store = window.store
        // var device = window.device

        // var platform = device.platform.toLowerCase();

        // Enable maximum logging level
        store.verbosity = store.DEBUG;

        // Enable remote receipt validation
        //store.validator = "http://192.168.0.46:5000/simpleschedule-9ae21/us-central1/iapVerify?p=" + projectId;

        store.validator = `https://${window._iapUrl}/iapVerify?p=${projectId}`
        //store.validator = `http://192.168.0.42:5000/simpleschedule-9ae21/us-central1/iapVerify?p=${projectId}`


        //alert('store.validator', store.validator)
        // Inform the store of your products
        console.log('registerProducts');
        store.register({ id: '1credit', alias: '1 credit', type: store.CONSUMABLE });
        store.register({ id: '5credit', alias: '5 credits', type: store.CONSUMABLE });
        store.register({ id: '10credit', alias: '10 credits', type: store.CONSUMABLE });
        store.register({ id: '20credit', alias: '20 credits', type: store.CONSUMABLE });
        store.register({ id: '30credit', alias: '30 credits', type: store.CONSUMABLE });
        store.register({ id: '40credit', alias: '40 credits', type: store.CONSUMABLE });
        store.register({ id: '50credit', alias: '50 credits', type: store.CONSUMABLE });
        store.register({ id: '60credit', alias: '60 credits', type: store.CONSUMABLE });
        store.register({ id: '70credit', alias: '70 credits', type: store.CONSUMABLE });
        store.register({ id: '80credit', alias: '80 credits', type: store.CONSUMABLE });
        store.register({ id: '90credit', alias: '90 credits', type: store.CONSUMABLE });
        store.register({ id: '100credit', alias: '100 credits', type: store.CONSUMABLE });
        store.register({ id: '150credit', alias: '150 credits', type: store.CONSUMABLE });
        store.register({ id: '200credit', alias: '200 credits', type: store.CONSUMABLE });
        store.register({ id: '250credit', alias: '250 credits', type: store.CONSUMABLE });


        store.when("product").approved(function (p) {
            console.log(">>>>product approved");
            p.verify();
        });
        store.when("product").verified(function (p) {
            console.log(">>>>product verified");
            p.finish();
        });
        store.when("product").unverified(function (p) {
            console.log(">>>>product unverified");
        });
        // store.when("10credit").updated(function (p) {
        //     console.log('>>when("10credit").updated')
        //     console.log(p)


        //     // if (p.owned) {
        //     //     document.getElementById('subscriber-info').innerHTML = 'You are a lucky subscriber!';
        //     // }
        //     // else {
        //     //     document.getElementById('subscriber-info').innerHTML = 'You are not subscribed';
        //     // }
        // });

        // store.when("1credit").owned(function (p) {
        //     alert('>>owned 1credit', p)
        // });


        // store.when("10credit").owned(function (p) {
        //     alert('>>owned', p)
        // });

        // Log all errors
        store.error(function (error) {
            console.log('#ERROR ' + error.code + ': ' + error.message);
        });

        store.ready(function () {
            // var el = document.getElementById("loading-indicator");
            // if (el)
            //     el.style.display = 'none';

            console.log('Stor is ready to GO!')
        });




        console.log('refresh')
        store.refresh()
    }
}