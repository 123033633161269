import React, { Component } from "react";
import { Button } from '../../../common/component/Button'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'

//import { isIOS,isAndroid } from 'react-device-detect';
import PropTypes from 'prop-types'

var isElectronApp = (process.env.REACT_APP_ELECTRON === '1')
var MacIAP



class Wallet extends Component {
  static contextTypes = { store: PropTypes.object.isRequired, }
  constructor(props) {
    super(props)
    isElectronApp = (process.env.REACT_APP_ELECTRON === '1')
  };

  state = { creditValue: '1credit' }

  onStoreUpdate = (payload) => {


    //console.log('>>onStoreUpdate', payload.detail)

    switch (payload.detail) {

      case 'waiting_for_validation':
        //  console.log(`waiting_for_validation`)
        toastr.success(window._getText('wallet.waiting_for_validation'), window._getText('wallet.pleasewait'));
        break
      case 'success':
        // console.log(`success`)
        toastr.success(window._getText('wallet.success'), window._getText('wallet.success1'));
        break
      case 'faild':
        //console.log(`faild`)
        toastr.success(window._getText('wallet.faild'), window._getText('wallet.faild1'));
        break
      case 'purchasing':
        //console.log(`Purchasing`)
        // toastr.success('Purchasing...', 'please wait');
        break
      case 'purchased':

        //console.log(` purchased.`)
        //toastr.success('Purchased...', 'please wait');


        break
      case 'failed':

        // console.log(`Failed to purchase .`)
        toastr.success(window._getText('wallet.failed'), window._getText('wallet.failed1'));


        break
      case 'restored':

        //console.log(`The purchase of has been restored.`)
        toastr.success(window._getText('wallet.restored'), '');
        break
      case 'deferred':

        //console.log(`The purchase of   has been deferred.`)
        toastr.success(window._getText('wallet.deferred'), '');

        break
      default:
        break
    }

  }
  componentWillUnmount = () => {
    window.removeEventListener("onStoreUpdate", this.onStoreUpdate)
  }
  componentDidMount = () => {

    window.addEventListener("onStoreUpdate", this.onStoreUpdate)


    if (isElectronApp && this.state.creditValue === '1credit') {
      this.setState({ creditValue: 'mac1credit' })
    }

    if (window.isCordovaApp === true) {
      if (window.isStoreInit !== true) {
        console.log('<<INIT STORE>>')
        window.initializeStore(this.props.projectKey)
      }
    } else {

      if (isElectronApp === true) {
        if (window.isStoreInit !== true) {
          //init Electron IAP
          //    console.log('<<INIT STORE electron>>')
          MacIAP = require('../../../common/component/MacIAP')

        }
      }
    }

  }
  onAddMoreCredits = () => {
    window.store.order(this.state.creditValue);
  }
  onAddMoreCreditsMac = () => {
    try {
      console.log('before buy')
      MacIAP.default.purchaseProduct(this.state.creditValue, 1, isProductValid => {
        if (!isProductValid) {
          console.log('The product is not valid.')
          return
        }
        console.log('The payment has been added to the payment queue.')
      })
      // .then(isProductValid => {
      //   if (!isProductValid) {
      //     console.log('The product is not valid.')
      //     return
      //   }

      //   console.log('The payment has been added to the payment queue.')
      // })
    } catch (error) {
      console.log('#ERR wallet.onAddMoreCredits ', error)
    }

  }
  onRefresh = () => {
    window.log('onRefresh')
    //console.log('onRefresh!88')
    window.store.refresh()
    //console.log('window.store.restore', window.store.restore)
    try {

      if (window.store && window.store.restore) {
        //  console.log('store restore');
        window.store.restore();
      } else {
        //  console.log('store restore DONT EXITS ');
      }
    } catch (error) {
      console.log('#err', error)
    }

  }
  handleChange = (event) => {
    //console.log(this.state.creditValue)
    this.setState({ creditValue: event.target.value })
  }
  render() {

    // const { project } = this.props
    //console.log(project)
    return (
      <div style={{ minWidth: '390px', maxWidth: '600px' }}>

        {isElectronApp &&
          <div >
            <h2>{window._getText('wallet.title1')}</h2>
            {/* <span>Current balance: {project.credits || 0}</span> */}
            <div style={{ border: 'solid 0px red' }}>

              <select value={this.state.creditValue} onChange={this.handleChange} style={{ width: '100%' }}>
                <option value='mac1credit'>1 user credit </option>
                <option value='mac5credit'>5 user credits </option>
                <option value='mac10credit'>10 user credits </option>
                <option value='mac20credit'>20 user credits </option>
                {/* <option value='30credit'>30 user credits </option> */}
                <option value='mac40credit'>40 user credits </option>
                {/* <option value='50credit'>50 user credits </option>
             <option value='60credit'>60 user credits </option>
             <option value='70credit'>70 user credits </option> */}
                <option value='mac80credit'>80 user credits </option>
                {/* <option value='90credit'>90 user credits </option> */}
                <option value='mac100credit'>100 user credits </option>
                <option value='mac150credit'>150 user credits </option>
                {/* <option value='200credit'>200 user credits </option>
             <option value='250credit'>250 user credits </option> */}
              </select>
            </div>
            <div>(1 user for 30 days = 1 credit)</div>
            <div style={{ border: 'solid 0px red', paddingBottom: '10px', paddingTop: '10px' }}>
              <Button color='green' compact style={{ width: '100%' }} onClick={this.onAddMoreCreditsMac} content='Add more mac credits'></Button>
            </div>
            {/* <div style={{ border: 'solid 0px red' }}>
              <Button compact style={{ width: '100%' }} onClick={this.onRefresh} >Restore previous purchase</Button>
            </div> */}




          </div>
        }
        {window.isCordovaApp &&
          <div >
            <h2>{window._getText('wallet.title1')}</h2>
            {/* <span>Current balance: {project.credits || 0}</span> */}
            <div style={{ border: 'solid 0px red' }}>

              <select value={this.state.creditValue} onChange={this.handleChange} style={{ width: '100%' }}>
                <option value='1credit'>1 user credit </option>
                <option value='5credit'>5 user credits </option>
                <option value='10credit'>10 user credits </option>
                <option value='20credit'>20 user credits </option>
                {/* <option value='30credit'>30 user credits </option> */}
                <option value='40credit'>40 user credits </option>
                {/* <option value='50credit'>50 user credits </option>
                <option value='60credit'>60 user credits </option>
                <option value='70credit'>70 user credits </option> */}
                <option value='80credit'>80 user credits </option>
                {/* <option value='90credit'>90 user credits </option> */}
                <option value='100credit'>100 user credits </option>
                <option value='150credit'>150 user credits </option>
                {/* <option value='200credit'>200 user credits </option>
                <option value='250credit'>250 user credits </option> */}
              </select>
            </div>
            <div>(1 user for 30 days = 1 credit)</div>
            <div style={{ border: 'solid 0px red', paddingBottom: '10px', paddingTop: '10px' }}>
              <Button color='green' compact style={{ width: '100%' }} onClick={this.onAddMoreCredits} content='Add more credits'></Button>
            </div>
            <div style={{ border: 'solid 0px red' }}>
              <Button compact style={{ width: '100%' }} onClick={this.onRefresh} >Restore previous purchase</Button>
            </div>




          </div>
        }

        {/* {(!window.isCordovaApp && !isElectronApp) &&
          <div style={{ paddingBottom: '15px' }}>
            <h2>{window._getText('wallet.title1')}</h2>
            {window._getText('wallet.txtWeb1')}
            <br />
            {window._getText('wallet.txtWeb2')}
          </div>
        } */}

      </div>

    );
  }
}



export default connect((state) => ({
  billing: state.firestore.data.billing,
  project: state.firestore.data.project,
  projectKey: state.firebase.profile.projectKey,
}))(Wallet)

