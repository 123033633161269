import React, { PureComponent } from 'react'
import Modal from 'semantic-ui-react/dist/commonjs/modules/Modal'
import { Button } from '../../common/component/Button'
import { isMobile } from 'react-device-detect';
import { ElectronWindowPortal } from './ElectronWindowPortal';

var isElectronApp = (process.env.REACT_APP_ELECTRON === '1')
let ipcRenderer

isElectronApp = !!window.require



export const Header = (data) => {
    //console.log('Header>', Array.isArray(data.children))

    if (Array.isArray(data.children)) {
        return data.children.map(child => {
            return child;
        })
    }


    if (data.children) {
        if (typeof data.children === 'string' || data.children instanceof String)
            return data.children

        return (
            { ...data.children }
        )
    }


    return null
}
Header.displayName = "Header"

export const Content = (data) => {
    //console.log('Content')
    const { children } = data
    if (Array.isArray(children)) {
        return (
            // <Modal.Content {...rest}>

            children.map(child => {
                return child;
            })

            // </Modal.Content>
        )
    }

    if (data.children) {
        if (typeof data.children === 'string' || data.children instanceof String)
            return data.children

        return (
            { ...data.children }
        )
    }

    return null
}
Content.displayName = "Content"

export const Actions = (data) => {
    //console.log('Actions')
    if (Array.isArray(data.children)) {
        return data.children.map(child => {
            return child;
        })
    }

    if (data.children) {
        if (typeof data.children === 'string' || data.children instanceof String)
            return data.children

        return (
            { ...data.children }
        )
    }


    return null
}
Actions.displayName = "Actions"





export default class ModalFrame extends PureComponent {
    static Header = Header
    static Content = Content
    static Actions = Actions
    state = { reRender: 0, tipPos: { x: "left", y: "top", showArrow: false }, css: true }

    tipPos = (event, arg) => {
        //console.log('--->REPLY', arg)
        this.setState({ tipPos: arg })
    }
    onCloseBlur = () => {
        console.log('------------------>>>>>>>>blut close')
        this.setState({ css: false }, () => { setTimeout(() => { this.onClose() }, 50) })

    }
    componentDidMount() {
        if (isElectronApp && this.props.name === 'eventModal') {
            if (!ipcRenderer)
                ipcRenderer = window.require('electron').ipcRenderer

            ipcRenderer.on('renderWindow-reply', this.tipPos)
            ipcRenderer.on('onCloseBlur', this.onCloseBlur)

        }
    }

    componentWillUnmount() {
        if (isElectronApp && this.props.name === 'eventModal') {
            if (!ipcRenderer)
                ipcRenderer = window.require('electron').ipcRenderer

            ipcRenderer.removeListener('renderWindow-reply', this.tipPos)
            ipcRenderer.removeListener('onCloseBlur', this.onCloseBlur)
        }
        //  clearAllBodyScrollLocks();
    }
    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if (prevProps) {
            if (prevProps.open === true && this.props.open === false) { this.setState({ css: false }) }
            else if (prevProps.open === false && this.props.open === true) { this.setState({ css: true }) }


        }



    }
    getContent = (key) => {
        var child = this.props.children.filter(function (obj) {

            if (!obj.type)
                return false

            return obj.type.displayName === key;
        })[0];



        if (child)
            return React.cloneElement(child, child.children)

        return null

    }
    onClose = () => {
        this.props.onClickClose()
        // enableBodyScroll()
    }
    modalOnunload = () => {
        this.props.onClickClose()
    }
    getCss = () => {
        console.log('getCss')
        const { name } = this.props
        console.log('>>>>>>>>>>>>>>>>>name.>>', name)
        switch (name) {
            case 'eventModal':
                return 'electronModalEvent'
            case 'appSettings':
                return 'electronModalSettings'
            case 'selectusersModal':
                return 'electronModalSelectuser'
            case 'selectuser_schedual':
                return 'electronModalSelectuser'
            case 'sendinvites':
                return 'electronModalSendinvites'

            default:
                return 'electronModal'
        }


    }
    render() {


        const { children, onClickClose, hideCloseButton, ...rest } = this.props
        const { tipPos } = this.state

        if (this.props.open !== true) {
            return (null)
        }

        const content = this.getContent('Content')
        const actions = this.getContent('Actions')
        const header = this.getContent('Header')

        if (isElectronApp) {
            return (
                <ElectronWindowPortal name={this.props.name} modalOnunload={this.modalOnunload} doRerender={() => this.setState({})}>
                    {this.state.css &&
                        <div className={this.props.open === true ? 'fadeIn2' : 'fadeOut2'}  >
                            {/*  */}
                            <div className={this.getCss()}
                                style={{

                                    marginLeft: tipPos.x === 'right' && tipPos.showArrow !== false ? '10px' : '',
                                    marginRight: tipPos.x === 'left' && tipPos.showArrow !== false ? '10px' : ''
                                }}
                            >
                                {this.props.name === 'eventModal' && tipPos.x !== 'left' && tipPos.showArrow !== false && <div className='pointerDivLeft'></div>}
                                {this.props.name === 'eventModal' && tipPos.x === 'left' && tipPos.showArrow !== false && <div className='pointerDivRight'></div>}

                                <div style={{ xbackgroundColor: 'var(--color-bg1)' }} className='dragWindow'>
                                    {header && <div style={{ paddingLeft: '20px', paddingTop: '10px' }}>
                                        <h2>{header}</h2>
                                    </div>}
                                </div>

                                <div className='modalBodyScrollElectron'  >
                                    <div style={{ paddingTop: '0px', marginBottom: this.props.headerspace ? this.props.headerspace : '0px' }}>
                                        {content}

                                        {actions &&
                                            <div style={{ marginTop: '8px', border: 'solid 0px green', position: 'fixed', bottom: '8px', left: '8px', right: '8px' }}>
                                                {actions}
                                            </div>
                                        }
                                    </div>
                                </div>

                            </div>
                            <button onClick={() => this.setState({})} style={{ display: 'none' }}> </button>
                            {/* HACK to make open.window and portals work */}
                        </div>
                    }
                </ElectronWindowPortal>
            )
        }



        if (isMobile === true)
            return (
                <div {...rest} className='mobileModal'  >
                    <div style={{ backgroundColor: '#fff', height: '40px' }}>
                        {this.props.hideCloseButton !== true &&
                            <Button compact className='closeButton' size='huge' onClick={this.onClose} floated='right' icon='close' style={{ zIndex: 100, position: 'absolute', top: '12px', right: '16px' }} />
                        }
                        <div style={{ paddingLeft: '15px', paddingTop: '10px', fontWeight: '700', fontSize: '20px' }}>{this.getContent('Header')}
                        </div>
                    </div>
                    <div className='modalBodyScroll' id={'modalBodyScroll_' + this.props.name || ''} >
                        <div style={{ padding: '20px' }}>
                            {this.getContent('Content')}
                        </div>
                    </div>
                    <div className='fixed' style={{ width: '100%', height: '60px', paddingLeft: '7px', paddingTop: '10px', marginLeft: '0x', textAlign: 'right', marginTop: '8px', position: 'fixed', bottom: '0px', background: '#fff', borderTop: 'solid 1px silver', zIndex: '3' }}>
                        {this.getContent('Actions')}
                    </div>
                </div>
            )



        return (
            <Modal {...rest} >
                <Modal.Header>
                    {this.props.hideCloseButton !== true &&
                        <Button compact className='closeButton' size='huge' onClick={this.onClose} floated='right' icon='close' />
                    }
                    {this.getContent('Header')}
                </Modal.Header>
                <Modal.Content >
                    {this.getContent('Content')}
                </Modal.Content>
                <Modal.Actions >
                    {this.getContent('Actions')}
                    <div style={{ clear: 'both', height: '10px' }}></div>
                </Modal.Actions>

            </Modal>
        )




    }
}





