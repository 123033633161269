import React, { Component } from "react";
import PropTypes from 'prop-types'

import { Form, Input, Image, Loader, Button, Icon } from "../../common/component/Button";
import { connect } from "react-redux";
import { closeEventModal } from "../../redux/action/modalAction";
import { addTemplateShift } from "../../redux/action/eventAction";
import { getTimeFromMins, getDateKey } from '../../common/util/helpers'
import { isMobile } from 'react-device-detect';
import FirestoreListnerHelper from '../../common/util/FirestoreListnerHelper'
import { FireHelper } from '../../common/util/FireHelper'
import { openEventModal, openModal } from "../../redux/action/modalAction";
import InputToggle from '../../common/form/InputToggle'

import { toastr } from 'react-redux-toastr'

import { setSchedule, copyScheduleShift } from "../../redux/action/currentScheduelDataAction";
import { Field, reduxForm } from "redux-form";
import moment from 'moment'
import { renderTimeLable, getUserImage } from '../../common/util/helpers';
import noUserImage from '../../../img/noimg.png'


import TimeSlider from '../../common/form/TimeSlider'
import ColorSlider from '../../common/form/ColorSlider'
import BreakSlider from '../../common/form/BreakSlider'
import ModalFrame from './ModalFrame'

var isElectronApp = (process.env.REACT_APP_ELECTRON === '1')
isElectronApp = !!window.require //TEMP



class EditEventModal extends Component {
  static contextTypes = { store: PropTypes.object.isRequired }
  state = { showDate1: false, showDate2: false, showColor: false, showBreake: false }
  isSave = true;
  constructor(props) {
    super(props);
    this.isSave = true
  }


  componentWillUnmount = () => {


  }

  componentDidMount = () => {


  }

  componentDidUpdate = async (prevProps, prevState, snapshot) => {
    if (prevProps && prevProps.isOpen === true && this.props.isOpen === false) {
      if (isElectronApp) {


        if (this.props.formState.initial.isNew) {
          //save the new shift
          console.log('<<save the new shift', prevProps.eventObj)
          this.isSave = true
          this.onSubmitOnClose(this.props.formState.values, prevProps.eventObj)
        }
        else if (this.props.formState.values !== this.props.formState.initial) {
          //if change save the old shift
          console.log('<<if change save the old shift')
          this.onSubmitOnClose(this.props.formState.values, prevProps.eventObj)

        }
        else {
          // dont save if old and not changed!
          console.log('<<dont save if old and not changed!')
          this.isSave = false
          this.setState({ showColor: false, showDate1: false, showDate2: false, showBreake: false })
          this.props.onCloseModal()
        }
      }
    }

    const { doLoad } = this.props
    if (doLoad) {
      const dateFirstDayOfWeek = moment.utc(doLoad.dateStr).startOf('week');
      const dateKey = getDateKey(dateFirstDayOfWeek)
      const eventDataKey = doLoad.shedualKey + '_' + dateKey
      const shiftKey = doLoad.key
      const loaded = this.props.fireLoaded[`projects/${this.props.projectKey}/schedules/${doLoad.shedualKey}/events/${dateKey}`]

      // if (loaded !== true)
      FirestoreListnerHelper.setListner(this.context, dateFirstDayOfWeek, doLoad.shedualKey)

      if (loaded) {
        var events = this.props.fireData[eventDataKey].events || {}
        var event = events[shiftKey]
        if (event) {
          this.props.openEventModal({ ...events[shiftKey], standALone: { scheduleKey: doLoad.shedualKey, dateKey } })
        }
        else {
          console.log("can't find Shift", shiftKey)
        }

      }
    }
  }

  bodyClick = () => {
    console.log('bodyClick')
  }
  onSubmitOnClose = (values, eventObj) => {
    console.log('<<onSubmit values>', values)
    const { onAddTemplateShift, curScheduleView, onSetSchedule } = this.props;
    this.getTotalMin();//TOOD fix tthis its fro , calculate values 
    if (eventObj.standALone) {
      var standAloneObj = eventObj.standALone
      delete eventObj.standALone
      delete values.standALone
      console.log('values', values)
      FireHelper.setShiftData(this.props.projectKey, standAloneObj.scheduleKey, standAloneObj.dateKey, eventObj.key, { ...values })

    }
    else {
      if (values.template) {
        console.log('save temaplele')
        var newTemplateShift = { ...eventObj, ...values };
        console.log('before')
        onAddTemplateShift(newTemplateShift);
        console.log('after')
      }
      else {

        var currentEvent = curScheduleView.scheduleEventsList[eventObj.key];
        console.log('<<eventObj', eventObj)
        if (currentEvent) {
          var newEvent = { ...currentEvent, ...values, dirty: true, isNew: false };
          console.log('<< SAVE newEvent', newEvent)
          //TODO parse @start/@end here 
          var scheduleEventsList = { ...curScheduleView.scheduleEventsList, [newEvent.key]: newEvent };
          onSetSchedule({ ...curScheduleView, dirty: true, scheduleEventsList }, "EditEventModal.onSubmit");
        }


      }

    }
    this.setState({ showColor: false, showDate1: false, showDate2: false, showBreake: false })
    this.isSave = true
    this.props.onCloseModal();
  };
  onSubmit = values => {
    console.log('onSubmit values>', values)
    const { onAddTemplateShift, eventObj, curScheduleView, onSetSchedule } = this.props;
    this.getTotalMin();//TOOD fix tthis its fro , calculate values 
    if (eventObj.standALone) {
      var standAloneObj = eventObj.standALone
      delete eventObj.standALone
      delete values.standALone
      console.log('values', values)
      FireHelper.setShiftData(this.props.projectKey, standAloneObj.scheduleKey, standAloneObj.dateKey, eventObj.key, { ...values })

    }
    else {
      if (values.template) {
        console.log('save temaplele')
        var newTemplateShift = { ...eventObj, ...values };
        console.log('before')
        onAddTemplateShift(newTemplateShift);
        console.log('after')
      }
      else {

        var currentEvent = curScheduleView.scheduleEventsList[eventObj.key];
        console.log('eventObj', eventObj)
        if (currentEvent) {
          var newEvent = { ...currentEvent, ...values, dirty: true, isNew: false };
          console.log('>> SAVE newEvent', newEvent)
          //TODO parse @start/@end here 
          var scheduleEventsList = { ...curScheduleView.scheduleEventsList, [newEvent.key]: newEvent };
          onSetSchedule({ ...curScheduleView, dirty: true, scheduleEventsList }, "EditEventModal.onSubmit");
        }


      }

    }
    this.isSave = true
    this.props.onCloseModal();
  };


  removeEvent = () => {
    const { curScheduleView, eventObj, onSetSchedule } = this.props;
    if (eventObj.standALone) {
      var standAloneObj = eventObj.standALone
      delete eventObj.standALone
      FireHelper.removeShiftData(this.props.projectKey, standAloneObj.scheduleKey, standAloneObj.dateKey, eventObj.key)

    }
    else {
      var currentEvent = curScheduleView.scheduleEventsList[eventObj.key];
      if (currentEvent) {
        var newEvent = { ...currentEvent, dirty: true, isNew: false, removed: true };
        var scheduleEventsList = { ...curScheduleView.scheduleEventsList, [newEvent.key]: newEvent };
        onSetSchedule({ ...curScheduleView, dirty: true, scheduleEventsList }, "EditEventModal.removeEvent");
      }
    }
    this.isSave = true
    this.props.onCloseModal();

  };





  pasteShift = (e) => {
    e.preventDefault()
    const copyObj = this.props.copyObj
    this.props.change('label', copyObj.label)
    this.props.change('comment', copyObj.comment)
    this.props.change('color', copyObj.color)

    this.props.change('hours', copyObj.hours)
    this.props.change('minutes', copyObj.minutes)
    this.props.change('startDate', copyObj.startDate)
    this.props.change('stopDate', copyObj.stopDate)
    this.props.change('timeEnd', copyObj.timeEnd)
    this.props.change('timeStart', copyObj.timeStart)
    this.props.change('breakMin', copyObj.break)



    //setTimeout(() => {
    //this.props.handleSubmit(this.onSubmit)()
    //}, 1000)

    //this.closeModule()
    //this.isSave = true
    //this.props.onCloseModal()
  }
  copyShift = (e) => {
    e.preventDefault()
    this.props.onCopyScheduleShift(this.props.eventObj)
    toastr.success('', `Shift is copied`);
    this.isSave = true
    this.props.onCloseModal()

  }
  closeModule = () => {
    console.log('<< vcloseModule ')
    if (this.isSave === false) {
      const { curScheduleView, eventObj, onSetSchedule } = this.props;
      if (eventObj.isNew) {
        var currentEvent = curScheduleView.scheduleEventsList[eventObj.key];
        if (currentEvent) {
          var newEvent = { ...currentEvent, dirty: true, isNew: false, removed: false }; //JENS change from remove:true to false
          var scheduleEventsList = { ...curScheduleView.scheduleEventsList, [newEvent.key]: newEvent };
          onSetSchedule({ ...curScheduleView, dirty: true, scheduleEventsList }, "EditEventModal.closeModule");
        }
      }
    }
    //alert('closed')
    this.props.onCloseModal()
  }
  getTimeSum = () => {
    const totalMin = Number(this.getTotalMin() || 0);
    const totalBrake = Number(this.props.formState.values.breakMin) || 0 //this.getBreakTime();
    const total = totalMin - totalBrake
    //console.log('total', total)
    // const totalHours = (totalMin / 60)
    // const totalHours2 = (total / 60)
    const h = getTimeFromMins(total)
    //`(${totalMin}min / ${totalHours}h)   - ${totalBrake}m = 

    // if (total < 0)
    //   return (<span>There is more break then time scheduled</span>)

    return (
      <React.Fragment>
        {/* <span>{total}min / </span> */}
        <span style={{ color: 'gray' }}>{h}h</span>
      </React.Fragment>)
  }

  getTotalMin = () => {
    const { formState, eventObj } = this.props
    if (eventObj) {
      const dateStr = moment.utc(eventObj.date).format('YYYY-MM-DD')
      var startDate = moment.utc(`${dateStr} ${formState.values.timeStart}`)
      var stopDate = moment.utc(`${dateStr} ${formState.values.timeEnd}`)
      var brake = Number(formState.values.breakMin)
      if (startDate > stopDate)
        stopDate.add(1, 'days')


      var duration = moment.duration(stopDate.diff(startDate));
      var minutes = duration.asMinutes();

      //small hack , here we set the start and stop date to save 
      formState.values.startDate = startDate.utc().unix() * 1000
      formState.values.stopDate = stopDate.utc().unix() * 1000
      formState.values.minutes = minutes - brake
      formState.values.break = brake



      return Number(minutes)
    }

    return 0
  }

  getLabel = () => {
    //const startDate = moment.utc(this.props.formState.values.startDate).format('HH:mm')
    //const stopDate = moment.utc(this.props.formState.values.stopDate).format('HH:mm')
    return renderTimeLable(this.props.formState.values.label || '', moment.utc(this.props.formState.values.startDate), moment.utc(this.props.formState.values.stopDate), this.props.AmPm)

    //return  //lbl.replace('@start', startDate).replace('@end', stopDate)
  }

  doScroll = (top) => {
    var div = document.getElementById('modalBodyScroll')
    if (div) {
      div.scrollTo(0, top)
      console.log('top', top)
      // window.log('scroll to ', top)
    }
  }

  onFocus = (e) => {

    // const target = e.target
    // var top = target.parentNode.offsetTop
    // if (document.getElementById('modalBodyScroll')) {
    //   document.getElementById('modalBodyScroll').scrollTo(0, top);
    //   setTimeout(() => { this.doScroll(top) }, 100)
    //   setTimeout(() => { this.doScroll(top) }, 250)
    //   setTimeout(() => { this.doScroll(top) }, 500)
    // }
  }
  onBlur = (e) => {


    // if (document.getElementById('modalBodyScroll')) {
    //   document.getElementById('modalBodyScroll').scrollTo(0, 0);
    //   setTimeout(() => { this.doScroll(0) }, 100)
    //   setTimeout(() => { this.doScroll(0) }, 250)
    //   setTimeout(() => { this.doScroll(0) }, 500)
    // }
  }
  selectUser = () => {
    this.props.openModal('selectUsers')
  }
  changeUser = (user) => {
    this.props.formState.values.uid = user.key
    this.props.eventObj.uid = user.key
    this.props.change('uid', user.key);
    this.setState({ reRender: true })
    return true
  }
  setDay = (day) => (e) => {
    var d = day
    // if (day === '0')
    //   d = 7


    var rDate = this.props.currentScheduelData.renderDayDate.clone().add(d, 'days')

    this.props.formState.values.date = +rDate
    this.props.eventObj.date = +rDate
    this.props.change('date', +rDate);





    this.setState({ reRender: true })
    if (e)
      e.preventDefault()
  }

  toggleColor = (e) => {
    console.log('toggleColor', this.state.showColor)
    this.setState({ showColor: !this.state.showColor, showDate1: false, showDate2: false, showBreake: false })
    if (e)
      e.preventDefault();
  }


  toggleshowBreake = (e) => {
    this.setState({ showBreake: !this.state.showBreake, showDate1: false, showDate2: false, showColor: false })
    e.preventDefault();
  }
  toggleshowDate1 = (e) => {
    this.setState({ showDate1: !this.state.showDate1, showBreake: false, showDate2: false, showColor: false })
    e.preventDefault();
  }
  toggleshowDate2 = (e) => {
    this.setState({ showDate2: !this.state.showDate2, showBreake: false, showDate1: false, showColor: false })
    e.preventDefault();
  }
  hideAllPopups = (e) => {
    this.setState({ showDate2: false, showBreake: false, showDate1: false, showColor: false })
  }

  render() {

    // if (this.props.isOpen === false) {
    //   console.log('EMPTY RENDER === blinek ? ')

    //   return ''
    // }



    let { eventObj, handleSubmit, formState, copyObj, AmPm, doLoad, projectKey, project } = this.props;
    if (!formState || !formState.values) return null;
    if (!eventObj) return null;
    const cache = project.cache || {}
    const user = cache[eventObj.uid] ? { ...cache[eventObj.uid] } : { n: 'unknown', i: '', sk: null }
    var firstDayOfWeek = this.props.viewstate.firstDayOfWeek
    var shiftDayOfWeek = moment(eventObj.date).day()


    return (
      <React.Fragment>

        <ModalFrame
          name='eventModal'
          className='modalAniSlideUp'
          open={this.props.isOpen}
          //onClose={this.handleClose}
          dimmer='inverted'
          onClickClose={this.closeModule}
        >


          <ModalFrame.Content>
            <div style={{ border: 'solid 0px red', padding: '6px', overflow: 'hidden', xminHeight: '180px' }} onMouseDown={this.hideAllPopups}>
              {doLoad &&
                <div style={{ textAlign: 'center', marginTop: '50px' }}><Loader inline active>{window._getText('loading')}</Loader></div>
              }
              {!doLoad &&
                <Form onSubmit={handleSubmit(this.onSubmit)} style={{ margin: '0px' }}>

                  <div style={{ display: 'flex', flexDirection: 'row', }}>

                    <div>
                      {eventObj.uid !== 'openshifts' &&
                        <Image floated='left' src={user.i ? getUserImage(projectKey, eventObj.uid, user.i) : noUserImage} circular size="mini" style={{ width: '22px', height: '22px', }} draggable="false" onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }} />
                      }
                      {eventObj.uid === 'openshifts' &&

                        <Image floated='left' src={noUserImage} circular size="mini" draggable="false" onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }} style={{ width: '22px', height: '22px', }} />

                      }
                    </div>
                    <div style={{ flexGrow: '1' }}>
                      <select onChange={(e) => this.changeUser({ key: e.target.value })} value={eventObj.uid} className='selectEventForm' >
                        {this.props.optionList}
                      </select>
                    </div>



                    <div style={{ paddingLeft: '10px' }}>
                      <b>Sick:</b>
                      <Field name="sick" component={InputToggle} />
                    </div>
                  </div>


                  <div style={{ border: 'solid px red', display: 'flex', flexDirection: 'row', flexGrow: '1', height: '25px' }}>

                    <div style={{ border: 'solid 0px green', display: 'flex', flexDirection: 'row', paddingRight: '4px' }}>
                      <div style={{ paddingRight: '7px', marginTop: '-3px' }}>
                        <Field
                          name="color"
                          component={ColorSlider}
                          isMobile={isMobile}
                          showColor={this.state.showColor}
                          toggle={this.toggleColor}
                        />

                      </div>
                      <div style={{ paddingRight: '4px', paddingLeft: '2px' }}>



                        {firstDayOfWeek === 0 &&
                          <select onChange={(e) => this.setDay(e.target.value)()} value={shiftDayOfWeek} className='selectEventForm'>
                            <option value={0}>Sun</option>
                            <option value={1}>Mon</option>
                            <option value={2}>Tus</option>
                            <option value={3}>Wen</option>
                            <option value={4}>Thu</option>
                            <option value={5}>Fri</option>
                            <option value={6}>Sat</option>
                          </select>
                        }
                        {firstDayOfWeek === 1 &&
                          <select onChange={(e) => this.setDay(e.target.value === '0' ? 6 : e.target.value - 1)()} value={shiftDayOfWeek} className='selectEventForm'>
                            <option value={1}>Mon</option>
                            <option value={2}>Tus</option>
                            <option value={3}>Wen</option>
                            <option value={4}>Thu</option>
                            <option value={5}>Fri</option>
                            <option value={6}>Sat</option>
                            <option value={0}>Sun</option>
                          </select>
                        }
                      </div>

                      <div style={{ border: 'solid 0px green' }}>
                        <Field
                          name="timeStart"
                          AmPm={AmPm}
                          component={TimeSlider}
                          lable='Shift start'
                          toggle={this.toggleshowDate1}
                          show={this.state.showDate1}
                        />


                      </div>
                      <div style={{ paddingLeft: '5px', paddingRight: '5px' }}>-</div>
                      <div style={{ border: 'solid 0px green', }}>
                        <Field
                          right={true}
                          name="timeEnd"
                          AmPm={AmPm}
                          component={TimeSlider}
                          lable='Shift end'
                          toggle={this.toggleshowDate2}
                          show={this.state.showDate2}
                        />
                      </div>

                      <div style={{ border: 'solid 0px green', }}>
                        <Field
                          name="breakMin"
                          AmPm={AmPm}
                          sumLable=''
                          component={BreakSlider}
                          toggle={this.toggleshowBreake}
                          show={this.state.showBreake}

                        />

                      </div>
                    </div>
                    <div style={{ border: 'solid 0px green', flexGrow: '1', textAlign: 'right' }}>{this.getTimeSum()}</div>
                    {/* <div style={{ border: 'solid 0px green' }}>
                      <div style={{ display: 'flex', marginLeft: '140px' }}>
                        {firstDayOfWeek === 0 &&
                          <React.Fragment>
                            <div className="btn-group">
                              <button onClick={this.setDay(0)} className={shiftDayOfWeek === 0 ? "btn btn-default active" : "btn btn-default"}>
                                <span>S</span>
                              </button>
                              <button onClick={this.setDay(1)} className={shiftDayOfWeek === 1 ? "btn btn-default active" : "btn btn-default"}>
                                <span>M</span>
                              </button>
                              <button onClick={this.setDay(2)} className={shiftDayOfWeek === 2 ? "btn btn-default active" : "btn btn-default"}>
                                <span>T</span>
                              </button>
                              <button onClick={this.setDay(3)} className={shiftDayOfWeek === 3 ? "btn btn-default active" : "btn btn-default"}>
                                <span>W</span>
                              </button>
                              <button onClick={this.setDay(4)} className={shiftDayOfWeek === 4 ? "btn btn-default active" : "btn btn-default"}>
                                <span>T</span>
                              </button>
                              <button onClick={this.setDay(5)} className={shiftDayOfWeek === 5 ? "btn btn-default active" : "btn btn-default"}>
                                <span >F</span>
                              </button>
                              <button onClick={this.setDay(6)} className={shiftDayOfWeek === 6 ? "btn btn-default active" : "btn btn-default"}>
                                <span >S</span>
                              </button>



                            </div>

                          
                          </React.Fragment>
                  }
                        {firstDayOfWeek === 1 &&
                    <React.Fragment>
                      <div className="btn-group">

                        <button onClick={this.setDay(0)} className={shiftDayOfWeek === 1 ? "btn btn-default active" : "btn btn-default"}>
                          <span>M</span>
                        </button>
                        <button onClick={this.setDay(1)} className={shiftDayOfWeek === 2 ? "btn btn-default active" : "btn btn-default"}>
                          <span>T</span>
                        </button>
                        <button onClick={this.setDay(2)} className={shiftDayOfWeek === 3 ? "btn btn-default active" : "btn btn-default"}>
                          <span>W</span>
                        </button>
                        <button onClick={this.setDay(3)} className={shiftDayOfWeek === 4 ? "btn btn-default active" : "btn btn-default"}>
                          <span>T</span>
                        </button>
                        <button onClick={this.setDay(4)} className={shiftDayOfWeek === 5 ? "btn btn-default active" : "btn btn-default"}>
                          <span >F</span>
                        </button>
                        <button onClick={this.setDay(5)} className={shiftDayOfWeek === 6 ? "btn btn-default active" : "btn btn-default"}>
                          <span >S</span>
                        </button>
                        <button onClick={this.setDay(6)} className={shiftDayOfWeek === 0 ? "btn btn-default active" : "btn btn-default"}>
                          <span>S</span>
                        </button>


                      </div>



                     
                    </React.Fragment>
                  }
                      </div>
                    </div> */}




                  </div>










                  <div style={{ display: 'flex', flexDirection: 'row', border: 'solid 0px red' }}>
                    <div style={{ fontWeight: 'bold', textAlign: 'right', width: '24px', marginRight: '4px' }} tooltip={'Shift label'}>
                      <Icon name='calendar outline' style={{ fontSize: '15px', color: '#888' }}></Icon>
                    </div>
                    <Field
                      autoComplete="new-password"
                      name="label"
                      component={Input}
                      className='fieldInput'
                      type="text"
                      placeholder="@start - @end"
                      maxLength={30}
                      style={{ width: '100%', maxWidth: '395px', borderBottom: '0px solid var(--color-border)' }}
                    />
                    <div style={{ flexGrow: '1' }}></div>


                  </div>


                  <div style={{ borderBottom: 'solid 0px var(--color-border)', display: 'flex' }}>
                    <div style={{ fontWeight: 'bold', textAlign: 'right', width: '24px', marginRight: '4px' }} tooltip={window._getText('shiftuserinput.comment')}>
                      <Icon name='comment outline' style={{ fontSize: '15px', color: '#888' }}></Icon>
                    </div>


                    <Field
                      style={{
                        width: '100%', maxWidth: '395px',
                        borderBottom: '0px solid var(--color-border)'
                      }}
                      className='fieldInput'
                      autoComplete="new-password"
                      maxLength={100}
                      name="comment"
                      component={Input}
                      type="text"
                      placeholder={window._getText('shiftuserinput.comment')}

                    // onFocus={this.onFocus}
                    // onBlur={this.onBlur}
                    />
                  </div>
                  {eventObj.userCom &&

                    <div>           <Field
                      style={{
                        width: '100%', maxWidth: '395px', borderBottom: '1px solid var(--color-border)'
                      }}
                      className='fieldInput'
                      autoComplete="new-password"
                      maxLength={100}
                      name="userCom"
                      component={Input}
                      type="text"
                      placeholder=""
                    // onFocus={this.onFocus}
                    // onBlur={this.onBlur}
                    /></div>
                  }






















                  <Field name="startDate" component={Input} type="hidden" />
                  <Field name="stopDate" component={Input} type="hidden" />
                  <Field name="minutes" component={Input} type="hidden" />
                  <Field name="break" component={Input} type="hidden" />
                  <div style={{ display: 'flex' }}>
                    <Button
                      style={{ marginLeft: 'auto' }}
                      compact
                      type="submit"
                      onClick={handleSubmit(this.onSubmit)}
                      icon='checkmark'
                      positive
                    >
                    </Button>
                  </div>
                </Form>

              }
            </div>
          </ModalFrame.Content>

          <ModalFrame.Actions>
            {(!doLoad && !isElectronApp) &&
              <React.Fragment>
                {/* <span style={{ float: 'left' }} > */}
                <button onClick={this.removeEvent} className={"btn btn-default"} style={{ float: 'left', marginRight: '10px', marginLeft: '6px' }}>
                  <span className="ico ico-trash" ></span>
                </button>
                <div className="btn-group" style={{ float: 'left' }}>



                  {!eventObj.standALone && !eventObj.isNew &&
                    <button onClick={this.copyShift} className={"btn btn-default"}>
                      <span className="ico ico-star" ></span>
                    </button>}

                  {!eventObj.standALone && copyObj &&
                    <button onClick={this.pasteShift} className={"btn btn-default"}>
                      <span className="ico ico-star-empty" ></span>
                    </button>}


                </div>



                {/* <Form.Button compact icon='trash alternate outline' color='red' style={{ float: 'left' }} onClick={this.removeEvent} />

                  {!eventObj.standALone && copyObj && <Form.Button compact color='blue' style={{ float: 'left' }} inverted onClick={this.pasteShift}>Paste</Form.Button>}
                  {!eventObj.standALone && !eventObj.isNew && <Form.Button inverted color='blue' style={{ float: 'left' }} onClick={this.copyShift}>Copy</Form.Button>} */}
                {/* </span> */}
                <Button
                  compact
                  type="submit"
                  onClick={handleSubmit(this.onSubmit)}
                  icon='checkmark'
                  positive
                >
                </Button>
              </React.Fragment>
            }
          </ModalFrame.Actions>

        </ModalFrame >

        {/* <SelectUsers name='shift' isMultiSelect={true} onSelect={this.changeUser}></SelectUsers> */}

      </React.Fragment >

    );
  }
}

const mapActions = {

  onCloseModal: closeEventModal,
  onSetSchedule: setSchedule,
  onCopyScheduleShift: copyScheduleShift,
  onAddTemplateShift: addTemplateShift,
  openEventModal,
  openModal,
};

const mapStateToProps = (state, ownProps) => {
  let eventObj = state.modal.event || {};
  let copyObj = {}

  var allUsers = FireHelper.getUserlist(state.firestore.data.users, state.firebase.profile.projectKey).userList;
  var optionList;
  if (allUsers) {
    allUsers = allUsers.filter(u => u.act === true)
    allUsers = allUsers.sort((a, b) => {
      if (!a.text || !b.text)
        return 0

      const aName = (a.text || '').toLowerCase()
      const bName = (b.text || '').toLowerCase()

      if (aName < bName) return -1
      if (aName > bName) return 1
      return 0
    })

    var ABC = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
    var mapAbcUsers = {}
    allUsers.forEach(u => {
      var firstChar = (u.text || '').substring(0, 1).toUpperCase()
      if (!mapAbcUsers[firstChar])
        mapAbcUsers[firstChar] = []

      mapAbcUsers[firstChar].push(u)

    })

    optionList = ABC.map(char => {

      if (mapAbcUsers[char])
        return (

          <optgroup label={char} key={char}>
            {mapAbcUsers[char].map(u => <option value={u.key} key={u.key}>{u.text}</option>)}
          </optgroup>
        )
      else
        return null
    })

    optionList.unshift(<optgroup label='Unassigned shifts' key='openshifts1' ><option value='openshifts' key='openshifts'>Open</option></optgroup>)
    //console.log('optionList', optionList)
  }

  if (eventObj.doLoad) {

  }
  else {
    copyObj = state.currentScheduelData.copyShiftObject;

    var curScheduleView = state.currentScheduelData[state.currentScheduelData.selectedKey + '_' + state.currentScheduelData.selectedDateKey];
    if (!eventObj) {
      eventObj = null; //TODO default values
    }
    if (eventObj) {
      if (!eventObj.timeStart)
        eventObj.timeStart = '00:00'

      if (!eventObj.timeEnd)
        eventObj.timeEnd = '00:00'

      if (!eventObj.breakMin)
        eventObj.breakMin = 0

      if (!eventObj.sick)
        eventObj.sick = false
    }
  }


  return {
    optionList: optionList,
    fireData: state.firestore.data,
    fireLoaded: state.firestore.status.requested,
    projectKey: state.firebase.profile.projectKey,
    project: state.firestore.data.project,

    doLoad: eventObj.doLoad,
    copyObj,
    isOpen: state.modal.eventModalOpen,
    enableReinitialize: true,
    initialValues: eventObj,
    eventObj,
    curScheduleView,
    formState: state.form.eventForm,
    AmPm: state.firestore.data.project.s ? state.firestore.data.project.s.AmPm || false : false,
    viewstate: state.viewstate,
    currentScheduelData: state.currentScheduelData,// WE only need this fro the render date , maybe we can do this better ?
  };
};
export default connect(
  mapStateToProps,
  mapActions
)(reduxForm({ form: "eventForm" })(EditEventModal));
