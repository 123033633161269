export const MODAL_OPEN = 'modal:open'
export const MODAL_CLOSE = 'modal:close'
export const MODAL_EVENT_OPEN = 'modal:openevent'
export const MODAL_EVENT_CLOSE = 'modal:closeevent'
export const MODAL_COPY_SCHEDULE_OPEN = 'modal:opencopyschedule'
export const MODAL_COPY_SCHEDULE_CLOSE = 'modal:closecopyschedule'
export const MODAL_EDIT_SCHEDULE_OPEN = 'modal:openeditschedule'
export const MODAL_EDIT_SCHEDULE_CLOSE = 'modal:closeeditschedule'

export function openModal(modalKey, data) {
    return {
        type: MODAL_OPEN,
        payload: { modalKey, open: true, data }
    }
}

export function closeModal(modalKey) {
    return {
        type: MODAL_CLOSE,
        payload: { modalKey, open: false, data: null }
    }
}


export function openEventModal(event) {

    return {
        type: MODAL_EVENT_OPEN,
        payload: { eventModalOpen: true, event }
    }
}

export function closeEventModal() {
    return {
        type: MODAL_EVENT_CLOSE,
        payload: { eventModalOpen: false, event: null }
    }
}



export function openEditScheduleModal(key, name) {
    return {
        type: MODAL_EDIT_SCHEDULE_OPEN,
        payload: { editShedualModalOpen: true, editShedualModalKey: key, editShedualModalName: name }
    }
}

export function closeEditScheduleModal() {
    return {
        type: MODAL_EDIT_SCHEDULE_CLOSE,
        payload: { editShedualModalOpen: false }
    }
}

export function openCopyScheduleModal() {
    return {
        type: MODAL_COPY_SCHEDULE_OPEN,
        payload: { copyShedualModalOpen: true }
    }
}

export function closeCopyScheduleModal() {
    return {
        type: MODAL_COPY_SCHEDULE_CLOSE,
        payload: { copyShedualModalOpen: false }
    }
}