import React, { Component } from 'react'
import { Icon } from "../../common/component/Button";
import { closeModal } from "../../redux/action/modalAction";
import { setTitle } from "../../redux/action/mobileHeaderAction";

import { connect } from "react-redux";
import ModalFrame from './ModalFrame'

const modalKey = 'selectschedules'
export class SelectSchedules extends Component {


    componentDidUpdate(prevProps, prevState, snapshot) {

        //on open
        if (prevProps.isOpen === false && this.props.isOpen === true) {
            this.setState({ isLoading: false, valueName: this.props.sName, isNew: (this.props.sKey === null), error: (this.props.sName < 1) })
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            isNew: true,
            valueName: this.props.sName,
            error: true
        };
    }
    onCloseModal = () => {
        this.props.onCloseModal(modalKey)
    }

    onClickDelete = () => {
        this.setState({ isLoading: true })
        // doShit.then(() => {
        //     this.setState({ isLoading: false })
        //     this.onCloseModal()
        // })

    }

    onChangeText = (e) => {
        this.setState({ valueName: e.target.value, error: (e.target.value.length < 1) });
    }
    onClickSave = () => {

        this.setState({ isLoading: true })
        // doShit.then(() => {
        //     this.setState({ isLoading: false })
        //     this.onCloseModal()
        // })
    }

    onSelectSchedual = (name, key) => () => {
        this.props.onSetTitle(name)
        this.props.onChangeSchema(name, key)
        this.props.onCloseModal(modalKey)
    }
    render() {
        const { schedules, isOpen } = this.props

        if (!isOpen)
            return ('')

        return (


            <ModalFrame dimmer='inverted' size='large' open={this.props.isOpen} className='modalAniSlideUp' onClickClose={this.onCloseModal} >
                <ModalFrame.Header>
                    <span>Select schedule</span>

                </ModalFrame.Header>
                <ModalFrame.Content>
                    {/* <Input error={this.state.error} maxLength={40} placeholder="Name" label='Name' value={this.state.valueName || ''} onChange={this.onChangeText} /> */}

                    {schedules && Object.keys(schedules).map((key, index) => {
                        var schedule = schedules[key]
                        if (!schedule)
                            return ''

                        return (
                            <div key={key} onClick={this.onSelectSchedual(schedule.name, key)} style={{ padding: '10px', border: 'solid 1px silver', margin: '5px', borderRadius: '5px', cursor: 'pointer', fontWeight: '900' }}>
                                <Icon name='calendar'></Icon> {schedule.name}
                            </div>
                        )
                    })}


                </ModalFrame.Content>

                {/* 
                    <ModalFrame.Actions>
                    <Button positive icon='checkmark' labelPosition='right' content='Save' onClick={this.onClickSave} loading={this.state.isLoading} disabled={this.state.isLoading || this.state.error} />
                    </ModalFrame.Actions> 
                */}
            </ModalFrame>)

    }
}


const mapActions = {
    onCloseModal: closeModal,
    onSetTitle: setTitle,
};

const mapStateToProps = state => {

    return {
        isOpen: state.modal.modals[modalKey].open || false,
        data: state.modal.modals[modalKey].data || {},
        schedules: state.firestore.data.schedules
    };
};
export default connect(
    mapStateToProps,
    mapActions
)(SelectSchedules);



