import React, { Component } from 'react';
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'


const times = {
  '00': ['12', 'am'],
  '01': ['1', 'am'],
  '02': ['2', 'am'],
  '03': ['3', 'am'],
  '04': ['4', 'am'],
  '05': ['5', 'am'],
  '06': ['6', 'am'],
  '07': ['7', 'am'],
  '08': ['8', 'am'],
  '09': ['9', 'am'],
  '10': ['10', 'am'],
  '11': ['11', 'am'],
  '12': ['12', 'pm'],
  '13': ['1', 'pm'],
  '14': ['2', 'pm'],
  '15': ['3', 'pm'],
  '16': ['4', 'pm'],
  '17': ['5', 'pm'],
  '18': ['6', 'pm'],
  '19': ['7', 'pm'],
  '20': ['8', 'pm'],
  '21': ['9', 'pm'],
  '22': ['10', 'pm'],
  '23': ['11', 'pm'],
}

export default class TimePicker extends Component {
  state = { h: '00', m: '00' }

  componentDidMount = () => {
    this.setValue()
  }
  componentDidUpdate = (prevProps, prevState, snapshot) => {

    if (prevProps.input.value !== this.props.input.value)
      this.setValue()


  }
  setValue = () => {

    const { input } = this.props
    if (input.value) {
      //  console.log('input.value)', input.value)


      var hour = Number(input.value.substr(0, 2)) || 0;
      var min = Number(input.value.substr(3, 2)) || 0;

      var mRounded = Math.ceil(Number(min) / 5) * 5
      var mRoundedLbl = mRounded

      if (mRounded === 60) {
        mRounded = 0
        hour++;
      }


      if (mRounded.toString().length === 1)
        mRoundedLbl = '0' + mRounded

      if (hour.toString().length === 1)
        hour = '0' + hour


      this.setState({ h: hour, m: mRoundedLbl }, () => {
        //console.log(this.state.h + ': ' + this.state.m)
        this.props.onChangeTime(this.state.h + ':' + this.state.m)
      })
    }
  }
  setHour = (val) => () => {
    let h = Number(this.state.h) + Number(val) || 0

    if (h > 23)
      h = 0

    if (h < 0)
      h = 23

    var lbl = h
    if (h < 10)
      lbl = '0' + h

    this.setState({ h: lbl }, () => {
      console.log(this.state.h + ':' + this.state.m)
      this.props.onChangeTime(this.state.h + ':' + this.state.m)
    })
  }


  setMin = (val) => () => {
    let m = Number(this.state.m) + Number(val) || 0
    //console.log('m', m)

    if (m > 55)
      m = 0
    if (m < 0)
      m = 55

    var lbl = m
    if (m < 10)
      lbl = '0' + m

    this.setState({ m: lbl }, () => {
      console.log(this.state.h + ':' + this.state.m)
      this.props.onChangeTime(this.state.h + ':' + this.state.m)
    })
  }
  getLable = (val) => {
    var { AmPm } = this.props
    if (AmPm === true)
      return times[val][0]

    return val

  }
  getLableAmPm = (val) => {
    var { AmPm } = this.props
    if (AmPm === true)
      return times[val][1]

    return ''

  }





  render() {
    const { input, style, placeholder, maxLength, meta: { touched, error } } = this.props

    return (
      <Form.Field error={touched && !!error} style={style}  >
        <div style={{ width: '110px' }}>
          <div><b>{this.getLable(this.state.h)}:{this.state.m} {this.getLableAmPm(this.state.h)}</b></div>
          <div style={{ width: '50px', border: 'solid 0px red', float: 'left', padding: '0px', marginRight: '5px' }}>
            <button className='ui compact icon button' style={{ width: '100%', margin: '0px' }} onClick={this.setHour(1)}><i aria-hidden="true" className="angle up icon"></i></button>

            <br />
            <b style={{ padding: '15px' }}>{this.getLable(this.state.h)}</b>
            <input type='hidden' readOnly value={this.state.h} placeholder={placeholder} maxLength={maxLength} style={{ width: '100%', margin: '2px', border: 'none' }} /><br />
            <button className='ui compact icon button' style={{ width: '100%', margin: '0px' }} onClick={this.setHour(-1)}><i aria-hidden="true" className="angle down icon"></i></button>
          </div>
          <div style={{ float: 'left', marginTop: '46px' }}>:</div>
          <div style={{ width: '50px', border: 'solid 0px red', float: 'left', padding: '0px' }}>
            <button className='ui compact icon button' style={{ width: '100%', margin: '0px' }} onClick={this.setMin(5)}><i aria-hidden="true" className="angle up icon"></i></button>

            <br />
            <b style={{ padding: '15px' }}>{this.state.m}</b>
            <input readOnly value={this.state.m} placeholder={placeholder} type='hidden' maxLength={maxLength} style={{ width: '100%', margin: '2px', border: 'none' }} /><br />
            <button className='ui compact icon button' style={{ width: '100%', margin: '0px' }} onClick={this.setMin(-5)}><i aria-hidden="true" className="angle down icon"></i></button>

          </div>
          <span style={{ clear: 'both' }}></span>
        </div>
      </Form.Field>
    )

  }
}