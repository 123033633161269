export const TOGGLE_TEMPLATES = 'viewstate:toggletemplate'
export const ORIENTATION = 'viewstate:orientation'
export const KEYBOARD_OPEN = 'viewstate:keyboardopen'
export const RENDER_INIT = 'viewstate:renderdateinit'
export const RENDER_DATE = 'viewstate:renderdate'
export const RENDER_DATE_NOW = 'viewstate:renderdatenow'
export const RENDER_DATE_ADD_DAYS = 'viewstate:renderdateadddays'

export function renderDateAddDays(days) {
    return {
        type: RENDER_DATE_ADD_DAYS,
        payload: days
    }
}

export function initRenderDate(firstDayOfWeek) {
    return {
        type: RENDER_INIT,
        payload: firstDayOfWeek
    }
}

export function setRenderDate(date) {
    return {
        type: RENDER_DATE,
        payload: date
    }
}
export function setRenderNow() {
    return {
        type: RENDER_DATE_NOW
    }
}

export function setOrientation(isLandscape) {
    return {
        type: ORIENTATION,
        payload: isLandscape
    }
}
export function setKeyboardOpen(open) {
    return {
        type: KEYBOARD_OPEN,
        payload: open
    }
}

export function toggelTemplates() {
    return {
        type: TOGGLE_TEMPLATES
    }
}
