import { toastr } from 'react-redux-toastr'
import shortid from 'shortid'
import "firebase/storage";

// import $ from 'jquery'
// import faker from "faker";

export const UPDATE_USER = 'users:updateUsers'
export const UPDATE_ERROR = 'users:showError'

// set the state data here
export function showError() {
    console.log('action.showError');

    return {
        type: UPDATE_ERROR,
        payload: { name: 'error user' }
    }
}

export const uploadProfileImage = (file, userKey, projectKey) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    // const imageName = shortid.generate();
    const firebase = getFirebase();
    // const firestore = getFirestore();
    //const projectKey = firebase.auth().currentUser.projectKey;
    const path = `p/${projectKey}/t`;
    const options = {
        public: true, name: userKey,
        cacheControl: 'public,max-age=300'
    };
    // 

    let downloadURL = ''
    try {
        //  dispatch(asyncActionStart());
        // upload the file to firebase storage
        let uploadedFile = await firebase.uploadFile(path, file, null, options);
        // get url of the image
        downloadURL = await uploadedFile.uploadTaskSnapshot.ref.getDownloadURL()
        // get userdoc
        // let userDoc = await firestore.get(`users/${user.uid}`);
        // check if user has photo, if not update profile with new image
        // if (!userDoc.data().photoURL) {
        //     // await firebase.updateProfile({
        //     //     photoURL: downloadURL
        //     // });
        //     // await user.updateProfile({
        //     //     photoURL: downloadURL
        //     // });
        // }
        // add the new photo to photos collection
        // await firestore.add(
        //     {
        //         collection: 'users',
        //         doc: user.uid,
        //         subcollections: [{ collection: 'photos' }]
        //     },
        //     {
        //         name: imageName,
        //         url: downloadURL
        //     }
        // );
        //dispatch(asyncActionFinish());
        console.log('DOEN UPLOADING!', downloadURL)
    } catch (error) {
        console.log(error);
        //dispatch(asyncActionError());
        throw new Error('Problem uploading photo');
    }
    return downloadURL;
};


export const deleteUser = user => async (dispatch, getState, { getFirebase, getFirestore }) => {

    try {
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;
        const delUser = { ...user }

        await firestore.doc(`projects/${projectKey}/users/userlist`).set({ [delUser.key]: firestore.FieldValue.delete() }, { merge: true })
        if (delUser.image) {
            var imgRef = getFirebase().storage().ref(`p/${projectKey}/t/${delUser.key}`);
            imgRef.delete().then(function () {
                console.log('delet done');
            }).catch(function (error) {
                console.log('#err Deleting file , ', error);
            });
        }

        toastr.success(`User '${delUser.name}' was successfully deleted`);
    } catch (error) {
        console.log(error)
        toastr.error('Error', error);
    }

}
export const addUser = user => async (dispatch, getState, { getFirebase, getFirestore }) => {
    let r = { success: true, msg: '' }

    try {
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;

        const newUser = {
            ...user,
            updatedAt: firestore.FieldValue.serverTimestamp()
        }

        if (!newUser.key)
            newUser.key = shortid.generate()

        delete newUser.newUser
        delete newUser.isDeleted

        await firestore.doc(`projects/${projectKey}/users/userlist`).set({ [newUser.key]: newUser }, { merge: true })

        toastr.success(`User '${newUser.name}' was successfully created`);

    } catch (error) {
        console.log('error', error)
        toastr.error('Error', error);
        r.success = false;
        r.msg = error.message
    }
    return r

}

export const updateUser = user => async (dispatch, getState, { getFirebase, getFirestore }) => {

    let r = { success: true, msg: '' }
    try {
        const firestore = getFirestore();
        delete user.newUser
        const updatedUser = {
            ...user,
            updatedAt: firestore.FieldValue.serverTimestamp()
        }
        const projectKey = getState().firebase.profile.projectKey;


        // var existingUser = await firestore.collection(`users`).where('email', '==', user.email).get()

        // console.log('user', user)
        // console.log('existingUser', existingUser)
        // if (existingUser.empty === true)
        await firestore.doc(`projects/${projectKey}/users/userlist`).set({ [updatedUser.key]: updatedUser }, { merge: true })
        // else {
        //     if (existingUser.docs[0]) {
        //         var existingUserData = existingUser.docs[0].data()
        //     }
        //     console.log('existingUserData', existingUserData)
        //     r.success = false
        //     r.msg = 'dup2'
        // }


        toastr.success('Saved!');
        //dispatch(closeModal())
    } catch (error) {
        console.log(error);
        toastr.error('Error', error);
        r.success = false;
        r.msg = error.message
        //   throw new SubmissionError({
        //     _error: error.message
        //   });
    }
    return r
};






























// export const deleteUser1 = user => async (dispatch, getState, { getFirebase, getFirestore }) => {
//     try {
//         const firestore = getFirestore();
//         const projectKey = getState().firebase.profile.projectKey;
//         const delUser = { ...user }
//         await firestore.delete(`projects/${projectKey}/users/${delUser.key}`, delUser)
//         toastr.success('Yes!', `User '${delUser.name}' was successfully deleted`);
//     } catch (error) {
//         console.log(error)
//         toastr.error('Error', error);
//     }

// }
// export const addUser1 = user => async (dispatch, getState, { getFirebase, getFirestore }) => {
//     try {
//         const firestore = getFirestore();
//         const projectKey = getState().firebase.profile.projectKey;

//         const newUser = {
//             key: shortid.generate(),
//             ...user,
//             updatedAt: firestore.FieldValue.serverTimestamp()
//         }

//         await firestore.set(`projects/${projectKey}/users/${newUser.key}`, newUser)
//         toastr.success('Yes!', `User '${newUser.name}' was successfully created`);
//         return newUser
//     } catch (error) {
//         console.log(error)
//         toastr.error('Error', error);
//         return null;
//     }

// }

// export const updateUser1 = user => async (dispatch, getState, { getFirebase, getFirestore }) => {


//     try {
//         const firestore = getFirestore();


//         //    let id = shortid.generate()
//         //    let newUser = {
//         //        name:faker.name.findName(),
//         //        image: faker.image.avatar(),
//         //        createdAt: firestore.FieldValue.serverTimestamp(),
//         //    }
//         //    await firestore.set(`projects/${projectKey}/users/${id}`, {...newUser})

//         delete user.newUser

//         const updatedUser = {
//             ...user,
//             updatedAt: firestore.FieldValue.serverTimestamp()
//         }
//         const projectKey = getState().firebase.profile.projectKey;

//         await firestore.update(`projects/${projectKey}/users/${updatedUser.key}`, updatedUser)

//         toastr.success('Success!', 'We got your data!');
//         //dispatch(closeModal())
//     } catch (error) {
//         console.log(error);
//         toastr.error('Error', error);
//         //   throw new SubmissionError({
//         //     _error: error.message
//         //   });
//     }
// };

// export const registerUser = user => async (dispatch, getState, { getFirebase, getFirestore }) => {
//     // const firebase = getFirebase();
//     const firestore = getFirestore();
//     try {
//         // create the user in firebase auth
//         //let createdUser = await firebase
//         //  .auth()
//         //  .createUserWithEmailAndPassword(user.email, user.password);
//         //  console.log(createdUser);
//         // update the auth profile
//         // await createdUser.updateProfile({
//         //     displayName: user.displayName
//         // })
//         // create a new profile in firestore

//         const projectKey = getState().firebase.profile.projectKey;
//         let id =shortid.generate()
//         let newUser = {
//             name: faker.name.findName(),
//             image: faker.image.avatar(),
//             createdAt: firestore.FieldValue.serverTimestamp(),
//         }
//         await firestore.set(`projects/${projectKey}/users/${id}`, { ...newUser })
//         toastr.success('Success!', 'We got your data!');
//         //dispatch(closeModal())
//     } catch (error) {
//         console.log(error);
//         toastr.error('Error', error);
//         //   throw new SubmissionError({
//         //     _error: error.message
//         //   });
//     }
// };

// //get data from the API 
// export function apiRequest() {

//     return dispatch => {
//         $.ajax({
//             url: 'http://www.google.se',
//             success() {
//                 toastr.success('Success!', 'We got your data!');
//             },
//             error() {
//                 console.log('error')
//                 toastr.error('Error', 'Error downloading you data!');
//             },
//         })
//     }
// }