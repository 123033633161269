import React, { Component } from "react";
import { connect } from 'react-redux'
import TextMessages from './TextMessages'
import { openModal } from "../../redux/action/modalAction";
import { FireHelper } from '../../common/util/FireHelper'
//import memoizeOne from 'memoize-one';

// import MobileHead from '../mobile/MobileHead'


class Chat extends Component {





  render() {
    return (
      <TextMessages isLandscape={this.props.isLandscape} isKeyboardOpen={this.props.isKeyboardOpen} currentUser={this.props.currentUser} openModal={this.props.openModal} userlist={this.props.userlist} />
    );
  }
}


const mapStateToProps = (state) => {


  var userList = []
  var userListData = FireHelper.getUserlist(state.firestore.data.users, state.firebase.profile.projectKey) // JSON.parse(JSON.stringify(FireHelper.getUserlist(state.firestore.data.users, state.firebase.profile.projectKey)))
  if (userListData)
    userList = (userListData.userList || []).map(a => ({ ...a })) //userListData.userList


  userList = userList.filter(u => u.act === true && u.sysKey)


  return {
    userlist: userList,
    authUser: state.firebase.auth,
    currentUser: state.firebase.profile,
    firebase: state.firebase,
    requestData: state.firestore.ordered['requestAllListner'],
    isKeyboardOpen: state.viewstate.isKeyboardOpen,
    isLandscape: state.viewstate.isLandscape,


  };
}
const mapActions = {
  openModal: openModal,

}
export default connect(mapStateToProps, mapActions)(Chat);

