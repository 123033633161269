import { TOGGLE_TEMPLATES, ORIENTATION, RENDER_DATE, RENDER_DATE_ADD_DAYS, RENDER_DATE_NOW, RENDER_INIT, KEYBOARD_OPEN } from '../action/viewstatAction'
import { createReducer } from '../../common/util/reducerUtil'
import moment from 'moment'
import { getDateKey } from '../../common/util/helpers'
const initState = {
    template: false,
    isLandscape: true,
    firstDayOfWeek: 0,
    isKeyboardOpen: false,
    renderDate: null,
    renderDateKey: null,
}
export const initRenderDate = (state, payload) => {
    //console.log('initRenderDate')
    const firstDayOfWeek = payload
    const utc = new Date();//.toJSON().slice(0, 10).replace(/-/g, '');
    if (firstDayOfWeek === 0)
        moment.locale('en')
    else
        moment.locale('en-gb')


    var renderDate = moment.utc(utc).startOf('week');
    const dateKey = getDateKey(renderDate)
    return { ...state, initRenderDate: renderDate, renderDate: renderDate, renderDateKey: dateKey, firstDayOfWeek }
}

export const setRenderNow = (state, payload) => {
    const firstDayOfWeek = payload
    const utc = new Date();//.toJSON().slice(0, 10).replace(/-/g, '');
    if (firstDayOfWeek === 0)
        moment.locale('en')
    else
        moment.locale('en-gb')


    var renderDate = moment.utc(utc).startOf('week');
    const dateKey = getDateKey(renderDate)
    return { ...state, renderDate: renderDate, renderDateKey: dateKey }
}
export const setRenderDate = (state, payload) => {
    const rDate = moment(payload)
    const dateKey = getDateKey(rDate)
    return { ...state, renderDate: rDate, renderDateKey: dateKey }
}
export const renderDateAddDays = (state, payload) => {
    const rDate = moment.utc(state.renderDate).add(payload, 'days')
    const dateKey = getDateKey(rDate)

    return { ...state, renderDate: rDate, renderDateKey: dateKey }
}
export const toggleTemplates = (state, payload) => {
    return { ...state, template: !state.template }
}

export const setOrientation = (state, payload) => {
    return { ...state, isLandscape: payload }
}
export const setKeyboardOpen = (state, payload) => {
    return { ...state, isKeyboardOpen: payload }
}

export default createReducer(initState, {
    [TOGGLE_TEMPLATES]: toggleTemplates,
    [ORIENTATION]: setOrientation,
    [RENDER_DATE]: setRenderDate,
    [RENDER_DATE_ADD_DAYS]: renderDateAddDays,
    [RENDER_DATE_NOW]: setRenderNow,
    [RENDER_INIT]: initRenderDate,
    [KEYBOARD_OPEN]: setKeyboardOpen,
})






