import React, { PureComponent } from 'react'
import { renderTimeLable } from '../../../common/util/helpers';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'

class ScheduleCell extends PureComponent {
  state = { popupIsOpen: false, labelText: null, labelTextRaw: null, labelHeight: '14px' }

  componentDidDataUpdate = (prevProps) => {
    // if (prevProps && prevProps.event) {
    //   if (
    //     ((prevProps.event.label !== this.props.event.label) ||
    //       (prevProps.event.startDate !== this.props.event.startDate) ||
    //       (prevProps.event.stopDate !== this.props.event.stopDate)
    //     )
    //   ) {
    //     this.getLabel(true)
    //   }
    // }
    // else if (this.props.event && !this.state.labelText) {
    //   this.getLabel(false)
    // }

  }
  // componentDidMount = () => this.componentDidDataUpdate()
  //componentDidUpdate = (prevProps) => this.componentDidDataUpdate(prevProps)

  // handleOpen = () => {
  //   this.setState({ popupIsOpen: true })
  // }

  // handleClose = () => {
  //   this.setState({ popupIsOpen: false })
  // }
  onCellDragStart = (e) => {

    this.setState({ popupIsOpen: false })
    this.props.onDragStart(e);
  }
  onCellClick = (e) => {
    this.props.onOpenEventModal(e, this.props.event);
  }
  getLabel = (forceUpdate) => {
    // console.log('render lable')
    //     if (!this.state.labelText || forceUpdate === true) {

    //       var shift = this.props.event
    //       var lbl = renderTimeLable(shift.label || '', shift.startDate, shift.stopDate)
    //       this.setState({ labelText: lbl, labelTextRaw: shift.label })
    //     }


  }
  renderLabel = () => {
    const { event, userkey, onDragOverHideDropZone, AmPm } = this.props
    var labelText = renderTimeLable(event.label || '', event.startDate, event.stopDate, AmPm)
    //console.log('event', event.sick)
    return (
      <div
        // ref={r => this.content = r}

        onClick={this.onCellClick}
        // onDoubleClick={this.onCellClick}

        draggable
        userkey={userkey}
        onDragStart={this.onCellDragStart}
        onDragEnd={onDragOverHideDropZone}
        id={event.key}
        className={'cellMulti dragable ui label large dragMe x' + (event.sick ? '' : event.color)}
        color={event.color}
      >


        <div style={{ color: event.sick ? 'silver' : '' }}>{labelText}{event.dirty && " *"}</div>
        {event.comment && <span style={{ color: event.sick ? 'silver' : '' }}>{event.comment}</span>}
        {event.meta && <br />}
        {event.userCom && <span><Icon name='comment' size='small' />{event.userCom}<br /></span>}
        {/* {event.meta && event.meta['deviation'] && <span> <Icon name='tag' size='small' /></span>}
        {event.meta && event.meta['comment'] && <span> <Icon name='comment' size='small' /></span>}
        {event.meta && event.meta['change'] && <span> <Icon name='comments' size='small' /></span>}
        {event.meta && event.meta['sick'] && <span> <Icon name='bed' size='small' /></span>} */}
        {event.sick && <span> <Icon name='bed' size='small' /></span>}

      </div>
    )
  }

  render() {



    return this.renderLabel()


  }
}

export default ScheduleCell;
