import { toastr } from 'react-redux-toastr'
import shortid from 'shortid'
import { setIsLoadingSchedule } from './currentScheduelDataAction'
import object from "lodash/object";
var pickBy = object.pickBy

export const addevent = event => async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;
        const newEvent = { ...event, key: shortid.generate(), updatedAt: firestore.FieldValue.serverTimestamp() }
        await firestore.set(`projects/${projectKey}/events/${newEvent.key}`, newEvent)
        toastr.success('Yes!', `new Event '${newEvent.label}' was successfully created`);
    } catch (error) {
        console.log(error)
        toastr.error('Error', error);
    }

}

export const addTemplateShift = shift => async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
        console.log('addTemplateShift to DB')
        const firestore = getFirestore();
        const state = getState()
        const projectKey = state.firebase.profile.projectKey;
        const scheduleKey = state.currentScheduelData.selectedKey;
        const newShift = { ...shift, updatedAt: firestore.FieldValue.serverTimestamp() }
        await firestore.doc(`projects/${projectKey}/schedules/${scheduleKey}`).set({ templates: { [shift.key]: newShift } }, { merge: true })
        toastr.success(`Template is saved!`);
    } catch (error) {
        console.log(error)
        toastr.error('Error', error);
    }

}

export const removeSchedule = key => async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
        console.log('removeSchedule')
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;
        console.log('kill', `projects/${projectKey}/schedules/${key}`)
        await firestore.delete({
            storeA: 'delete_' + key,
            collection: 'projects',
            doc: projectKey,
            subcollections: [{
                collection: "schedules",
                doc: key
            }],

        })

        //await firestore.delete(`projects/${projectKey}/schedules/${key}`)
        toastr.success('Okej!', `we removed the schedule successfully`);
    } catch (error) {
        console.log(error)
        toastr.error('Error', error);
    }
}

export const addSchedule = schedule => async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;
        const newSchedule = { ...schedule, updatedAt: firestore.FieldValue.serverTimestamp(), createdAt: firestore.FieldValue.serverTimestamp() }
        await firestore.set(`projects/${projectKey}/schedules/${newSchedule.key}`, newSchedule)
        toastr.success('Yes!', `new schedule was successfully created`);
    } catch (error) {
        console.log(error)
        toastr.error('Error', error);
    }

}

export const updateEventBatch2 = (events, schedule, dateKey, sortedUserList, dividerList) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
        console.log('>>!updateEventBatch2', events)

        dispatch(setIsLoadingSchedule(true));
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;
        //console.log('scheduleToUpdate > sortedUserList', sortedUserList)
        var batch = firestore.batch();

        if (schedule) {
            const newSchedule = { ...schedule, updatedAt: firestore.FieldValue.serverTimestamp() }



            const scheduleRef = firestore.collection("projects").doc(projectKey).collection("schedules").doc(newSchedule.key);
            batch.set(scheduleRef, newSchedule)//, { merge: true }

            //console.log('events', events)

            if (events) {
                const scheduleEventsRef = firestore.collection("projects").doc(projectKey).collection("schedules").doc(newSchedule.key).collection('events').doc(dateKey);

                var newEventList = {}
                Object.keys(events).forEach(key => {
                    let shift = { ...events[key] }
                    if (!shift.removed) {
                        shift.updatedAt = firestore.FieldValue.serverTimestamp()
                        delete shift.meta
                        delete shift.dirty
                        delete shift.isNew
                        delete shift.date2
                        events[shift.key] = shift
                        newEventList[shift.key] = shift
                    }
                })

                // var newEventList = object.pickBy(events, obj => {

                //     obj.updatedAt = firestore.FieldValue.serverTimestamp()
                //     delete obj.dirty
                //     delete obj.isNew
                //     delete obj.date2
                //     //console.log('o', o)
                //     //console.log('obj', obj)
                //     //   events[obj.key] = obj
                //     // console.log('o', o)
                //     if (obj.removed) return false;

                //     return true;
                // });

                var dividerListSave = pickBy(dividerList, obj => {
                    delete obj.dirty

                    if (obj.removed) return false;

                    return true;
                });


                var saveObj = { events: newEventList }
                console.log('sortedUserList', sortedUserList)
                if (sortedUserList)
                    saveObj.sortedUserList = sortedUserList

                if (dividerListSave)
                    saveObj.dividerList = dividerListSave

                saveObj.scheduleName = newSchedule.name // wee need this to week views 
                // console.log('scheduleEventsRef', JSON.stringify(saveObj))
                batch.set(scheduleEventsRef, saveObj) // used for saving do docs
            }
        }


        batch.commit().then(function () {
            console.log('Success!');

            //  console.log('before clean!', events)
            if (events) { // clean all flags in the current events
                //this have never worked !

                // Object.keys(events).forEach(event => {
                //     console.log('clean>>', event)
                //     var shift = { ...event }
                //     delete shift.isNew
                //     delete shift.dirty
                //     delete shift.removed
                //     shift.lable = 'sss'
                //     event = shift
                //     console.log('clean>> can update ? ')
                // });
                // for (let index = 0; index < events.length; index++) {
                //     console.log('clean', events)
                //     delete events[index].isNew
                //     delete events[index].dirty
                //     delete events[index].removed

                // }
            }
            if (events)
                toastr.success('Saved!', `Update successfully`)
            else
                toastr.success('Saved!', `Created schedule ${schedule.name} successfully`)

            dispatch(setIsLoadingSchedule(false));
        });



    } catch (error) {
        console.log(error)
        toastr.error('Error', error);
    }

}

export const updateEventBatchOLD = (events, schedule, dateKey, sortedUserList, dividerList) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {

        dispatch(setIsLoadingSchedule(true));
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;
        //console.log('scheduleToUpdate > sortedUserList', sortedUserList)
        var batch = firestore.batch();

        if (schedule) {
            const newSchedule = { ...schedule, updatedAt: firestore.FieldValue.serverTimestamp() }

            const scheduleRef = firestore.collection("projects").doc(projectKey).collection("schedules").doc(newSchedule.key);
            batch.set(scheduleRef, newSchedule)



            if (events) {
                const scheduleEventsRef = firestore.collection("projects").doc(projectKey).collection("schedules").doc(newSchedule.key).collection('events').doc(dateKey);

                var newEventList = pickBy(events, obj => {
                    obj.updatedAt = firestore.FieldValue.serverTimestamp()
                    delete obj.dirty
                    delete obj.isNew

                    if (obj.removed) return false;

                    return true;
                });

                var dividerListSave = pickBy(dividerList, obj => {
                    delete obj.dirty

                    if (obj.removed) return false;

                    return true;
                });


                var saveObj = { events: newEventList }

                if (sortedUserList)
                    saveObj.sortedUserList = sortedUserList

                if (dividerListSave)
                    saveObj.dividerList = dividerListSave

                batch.set(scheduleEventsRef, saveObj)
            }
        }


        batch.commit().then(function () {


            if (events) { // clean all flags in the current events
                for (let index = 0; index < events.length; index++) {
                    delete events.isNew
                    delete events.dirty
                    delete events.removed
                }
            }
            if (events)
                toastr.success('Saved!', `Update    successfully`)
            else
                toastr.success('Saved!', `Created schedule ${schedule.name} successfully`)

            dispatch(setIsLoadingSchedule(false));
        });



    } catch (error) {
        console.log(error)
        toastr.error('Error', error);
    }

}
export const updateEventBatch = (events, schedule) => async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
        console.log('setIsLoadingSchedule', true)
        dispatch(setIsLoadingSchedule(true));
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;

        var batch = firestore.batch();
        console.log('@schedule', schedule)
        console.log('@schedule', events)
        if (schedule) {
            const newSchedule = { ...schedule, updatedAt: firestore.FieldValue.serverTimestamp() }
            console.log('##newSchedule', newSchedule)
            const scheduleRef = firestore.collection("projects").doc(projectKey).collection("schedules").doc(newSchedule.key);
            batch.set(scheduleRef, newSchedule)


        }
        if (events) {
            for (let index = 0; index < events.length; index++) {
                const event = events[index];

                const newEvent = { ...event, updatedAt: firestore.FieldValue.serverTimestamp() }
                delete newEvent.dirty
                const eventRef = firestore.collection("projects").doc(projectKey).collection("events").doc(newEvent.key);



                if (newEvent.isNew) {
                    delete newEvent.isNew
                    batch.set(eventRef, newEvent)
                }
                else if (newEvent.removed)
                    batch.delete(eventRef)
                else
                    batch.update(eventRef, newEvent)

            }
        }

        batch.commit().then(function () {


            if (events) { // clean all flags in the current events
                for (let index = 0; index < events.length; index++) {
                    delete events.isNew
                    delete events.dirty
                    delete events.removed
                }
            }
            if (events)
                toastr.success('Saved!', `Update successfully`)
            else
                toastr.success('Saved!', `Created schedule ${schedule.name} successfully`)

            dispatch(setIsLoadingSchedule(false));
        });



    } catch (error) {
        console.log(error)
        toastr.error('Error', error);
    }

}
export const updateEvent = event => async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
        const firestore = getFirestore();
        const projectKey = getState().firebase.profile.projectKey;
        const newEvent = {
            ...event,

            updatedAt: firestore.FieldValue.serverTimestamp()
        }

        if (newEvent.isNew) {
            delete newEvent.isNew
            await firestore.set(`projects/${projectKey}/events/${newEvent.key}`, newEvent)
            toastr.success('Yes!', `new Event '${newEvent.label}' was successfully created`);
        }
        else if (newEvent.removed) {
            await firestore.delete(`projects/${projectKey}/events/${newEvent.key}`, newEvent)
            toastr.success('Okej!', `We removed event '${newEvent.label}'`)
        }
        else {
            await firestore.update(`projects/${projectKey}/events/${newEvent.key}`, newEvent)
            toastr.success('Yes!', `update event '${newEvent.label}' was successfully`)
        }



    } catch (error) {
        console.log(error)
        toastr.error('Error', error);
    }

}
