import React, { Component } from 'react';
import ReactDOM from 'react-dom'

import moment from 'moment'
import { isBrowser } from 'react-device-detect';



var isElectronApp = (process.env.REACT_APP_ELECTRON === '1')
let ipcRenderer
isElectronApp = !!window.require //TEMP
if (isElectronApp) {
  ipcRenderer = window.require('electron').ipcRenderer
}



// const modalRoot = document.getElementById('modal-root');
export default class TimeSlider extends Component {
  state = { sliderHoursStart: 0, sliderMinStart: 0 }
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount = () => {

    console.log(' window.top.dpWindow', window.top.dpWindow)


    if (!window.top.dpWindow && isElectronApp) {
      console.log('CREATE tiempicker')
      window.top.dpWindow = window.open('/timepicker.htm', 'timepicker', 'transparent=true')
    }
    //window.top.document.write("<p>This is the source window!</p>");

    document.body.appendChild(this.el);
    this.setValue()

  }
  componentWillUnmount() {
    document.body.removeChild(this.el);
  }
  componentDidUpdate = (prevProps, prevState, snapshot) => {

    if (prevProps.input.value !== this.props.input.value)
      this.setValue()


  }
  setValue = () => {
    const { input } = this.props
    if (input.value) {
      const hour = input.value.substr(0, 2);
      const min = input.value.substr(3, 2);
      this.setState({ sliderHoursStart: Number(hour), sliderMinStart: Number(min) }, () => {
        const min = this.state.sliderMinStart < 10 ? '0' + this.state.sliderMinStart : this.state.sliderMinStart
        const hour = this.state.sliderHoursStart < 10 ? '0' + this.state.sliderHoursStart : this.state.sliderHoursStart
        input.value = `${hour}:${min}`
      })
    }

  }
  changeValue = () => {
    const min = this.state.sliderMinStart < 10 ? '0' + this.state.sliderMinStart : this.state.sliderMinStart
    const hour = this.state.sliderHoursStart < 10 ? '0' + this.state.sliderHoursStart : this.state.sliderHoursStart
    this.props.input.onChange(`${hour}:${min}`)
    window.top.dpWindow.h = this.state.sliderHoursStart
    window.top.dpWindow.m = this.state.sliderMinStart
  }
  handleChange1 = (event) => this.setState({ sliderMinStart: Number(event.target.value) }, () => { this.changeValue() });
  handleChange = (event) => this.setState({ sliderHoursStart: Number(event.target.value) }, () => { this.changeValue() });

  setMin = (min) => (e) => {
    e.preventDefault();
    this.setState({ sliderMinStart: Number(min) }, () => { this.changeValue() })
  }
  setHour = (hour) => (e) => {
    e.preventDefault();

    this.setState({ sliderHoursStart: Number(hour) }, () => { this.changeValue() })
  }
  renderLable = () => {
    const { input, AmPm } = this.props
    if (AmPm)
      return moment('2019-01-28 ' + input.value).format('h:mma')



    return input.value
  }

  style = {
    marginRight: '2px',
    marginBottom: '4px',
    width: '60px',
    textAling: 'center',
    flexDirection: 'column',
    flexGrow: '1',
    maxWidth: '100%'
  }





  TimeSliderMobile = (input, show, sliderHoursStart, AmPm, sliderMinStart) => {
    return (
      ReactDOM.createPortal(
        (<div>
          {true &&
            <div style={{ position: 'absolute', top: '0px', bottom: '0px', left: '0px', right: '0px', width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.51)', zIndex: '4999' }}>
              <div className='modalAniTimepickerSlideUp' style={{ border: 'solid 0px red', padding: '10px', position: 'fixed', left: '0px', right: '0px', bottom: '0px', zIndex: '5000', background: '#fff' }}>
                <div style={{ fontWeight: '900', fontSize: '50px', padding: '10px', paddingTop: '20px', textAlign: 'center' }}><b>{this.renderLable()}</b></div>
                <div><b>Hour</b></div>
                <div style={{
                  marginBottom: '6px',
                  display: 'flex',

                  flexWrap: 'wrap',
                }} >




                  <button style={this.style} className={sliderHoursStart === 1 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(1)} >{AmPm ? '\u00A01am' : '01'}</button>
                  <button style={this.style} className={sliderHoursStart === 2 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(2)} >{AmPm ? '\u00A02am' : '02'}</button>
                  <button style={this.style} className={sliderHoursStart === 3 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(3)} >{AmPm ? '\u00A03am' : '03'}</button>
                  <button style={this.style} className={sliderHoursStart === 4 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(4)} >{AmPm ? '\u00A04am' : '04'}</button>
                  <button style={this.style} className={sliderHoursStart === 5 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(5)} >{AmPm ? '\u00A05am' : '05'}</button>
                  <button style={this.style} className={sliderHoursStart === 6 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(6)} >{AmPm ? '\u00A06am' : '06'}</button>
                  <button style={this.style} className={sliderHoursStart === 7 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(7)} >{AmPm ? '\u00A07am' : '07'}</button>
                  <button style={this.style} className={sliderHoursStart === 8 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(8)} >{AmPm ? '\u00A08am' : '08'}</button>
                  <button style={this.style} className={sliderHoursStart === 9 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(9)} >{AmPm ? '\u00A09am' : '09'}</button>
                  <button style={this.style} className={sliderHoursStart === 10 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(10)} >{AmPm ? '10am' : '10'}</button>
                  <button style={this.style} className={sliderHoursStart === 11 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(11)} >{AmPm ? '11am' : '11'}</button>
                  <button style={this.style} className={sliderHoursStart === 12 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(12)} >{AmPm ? '12pm' : '12'}</button>
                  <button style={this.style} className={sliderHoursStart === 13 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(13)} >{AmPm ? '\u00A01pm' : '13'}</button>
                  <button style={this.style} className={sliderHoursStart === 14 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(14)} >{AmPm ? '\u00A02pm' : '14'}</button>
                  <button style={this.style} className={sliderHoursStart === 15 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(15)} >{AmPm ? '\u00A03pm' : '15'}</button>
                  <button style={this.style} className={sliderHoursStart === 16 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(16)} >{AmPm ? '\u00A04pm' : '16'}</button>
                  <button style={this.style} className={sliderHoursStart === 17 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(17)} >{AmPm ? '\u00A05pm' : '17'}</button>
                  <button style={this.style} className={sliderHoursStart === 18 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(18)} >{AmPm ? '\u00A06pm' : '18'}</button>
                  <button style={this.style} className={sliderHoursStart === 19 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(19)} >{AmPm ? '\u00A07pm' : '19'}</button>
                  <button style={this.style} className={sliderHoursStart === 20 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(20)} >{AmPm ? '\u00A08pm' : '20'}</button>
                  <button style={this.style} className={sliderHoursStart === 21 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(21)} >{AmPm ? '\u00A09pm' : '21'}</button>
                  <button style={this.style} className={sliderHoursStart === 22 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(22)} >{AmPm ? '10pm' : '22'}</button>
                  <button style={this.style} className={sliderHoursStart === 23 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(23)} >{AmPm ? '11pm' : '23'}</button>
                  <button style={this.style} className={sliderHoursStart === 0 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setHour(0)} >{AmPm ? '12am' : '00'}</button>

                </div>
                <div><b>Min</b></div>
                <div style={{
                  display: 'flex',
                  flexWrap: 'wrap'
                }}>

                  <button style={this.style} className={sliderMinStart === 0 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setMin(0)} >00</button>
                  <button style={this.style} className={sliderMinStart === 5 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setMin(5)} >05</button>
                  <button style={this.style} className={sliderMinStart === 10 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setMin(10)} >10</button>
                  <button style={this.style} className={sliderMinStart === 15 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setMin(15)} >15</button>
                  <button style={this.style} className={sliderMinStart === 20 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setMin(20)} >20</button>
                  <button style={this.style} className={sliderMinStart === 25 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setMin(25)} >25</button>
                  <button style={this.style} className={sliderMinStart === 30 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setMin(30)} >30</button>
                  <button style={this.style} className={sliderMinStart === 35 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setMin(35)} >35</button>
                  <button style={this.style} className={sliderMinStart === 40 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setMin(40)} >40</button>
                  <button style={this.style} className={sliderMinStart === 45 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setMin(45)} >45</button>
                  <button style={this.style} className={sliderMinStart === 50 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setMin(50)} >50</button>
                  <button style={this.style} className={sliderMinStart === 55 ? 'ui compact icon button blue' : 'ui compact icon button '} onClick={this.setMin(55)} >55</button>
                </div>
                <div>
                  <button style={{ width: '100%', marginTop: '8px' }} className='ui compact icon button green' onClick={this.toggleDP} ><i aria-hidden="true" className="check icon"></i></button>
                </div>
              </div>
            </div>
          }
        </div>), this.el)
    )
  }
  foo = (id, h, m) => {
    //console.log('foo', { id, h, m })
    this.setState({ sliderMinStart: Number(m), sliderHoursStart: Number(h) }, () => { this.changeValue() })
  }
  setRecreateWindows = false;
  openTimePicker = (e) => {
    console.log('window.top.dpWindow-->', window.top.dpWindow)
    // if (this.setRecreateWindows === true) {
    //   console.log('RECREATE timepicker')
    //   this.setRecreateWindows = false
    //   window.top.dpWindow = window.open('/timepicker.htm', 'timepicker', 'transparent=true')
    // }
    console.log('window.top.dpWindow ', window.top.dpWindow)
    // window.top.dpWindow = window.open('/timepicker.htm', 'timepicker', 'transparent=true')

    var { x, y, width, height, top, left, right } = e.target.getBoundingClientRect()

    window.top.dpWindow.h = this.state.sliderHoursStart
    window.top.dpWindow.m = this.state.sliderMinStart
    window.top.dpWindow.xid = 'dp1'
    window.datePickerCallBack = this.foo
    window.top.dpWindow.onunload = () => {
      try {
        console.log('>>>re open Time Picker')
        // this.setRecreateWindows = true;
        // window.top.dpWindow = window.open('/timepicker.htm', 'timepicker', 'transparent=true')
        // 
      } catch (error) {

      }

    }
    window.top.dpWindow.onload = () => {
      if (window.top.dpWindow.initValues !== undefined) {

        window.top.dpWindow.initValues()
      }
    }

    if (window.top.dpWindow.initValues !== undefined) {

      window.top.dpWindow.initValues()

      if (ipcRenderer)
        ipcRenderer.send('renderOpen', { x, y, width, height, top, left, right, clientX: e.clientX, clientY: e.clientY })

    }





    //    dpWindow.onclose = () => { console.log('CLOSE ') }


    // dpWindow.setValue('23','h')
    // dpWindow.setValue('15','m')



  }

  render() {
    const { sliderHoursStart, sliderMinStart } = this.state
    const { input, AmPm, right, show, toggle } = this.props

    if (isBrowser) {
      return (
        <React.Fragment>
          <div onClick={this.openTimePicker}>{this.renderLable()}</div>
          <div style={{ position: 'relative', border: 'solid 0px red', paddingTop: '0px', marginBottom: '15px', cmarginLeft: right ? AmPm ? '54px' : '40px' : '0px', }} onMouseDown={(e) => { e.stopPropagation() }}>
            <input onChange={(param, data) => input.onChange(data.value)} {...input} type='hidden' />



            {/* <div className='render' > <div style={{ border: 'solid 0px red', fontWeight: '900', fontSize: '14px', color: 'var( --color-font)', }}  >
              {right && <span style={{ paddingLeft: '4px', paddingRight: '4px', fontSize: '14px' }}>-</span>}
              {AmPm === 99 &&
                <select onChange={this.handleChange} value={this.state.sliderHoursStart || 0} className='selectEventForm' style={{ display: 'inline', paddingRight: '1px', textAlignLast: 'right' }} >

                  <option value={0} >00</option>
                  <option value={1} >01</option>
                  <option value={2} >02</option>
                  <option value={3} >03</option>
                  <option value={4} >04</option>
                  <option value={5} >05</option>
                  <option value={6} >06</option>
                  <option value={7} >07</option>
                  <option value={8} >08</option>
                  <option value={9} >09</option>
                  <option value={10} >10</option>
                  <option value={11} >11</option>
                  <option value={12} >12</option>
                  <option value={13} >13</option>
                  <option value={14} >14</option>
                  <option value={15} >15</option>
                  <option value={16} >16</option>
                  <option value={17} >17</option>
                  <option value={18} >18</option>
                  <option value={19} >19</option>
                  <option value={20} >20</option>
                  <option value={21} >21</option>
                  <option value={22} >22</option>
                  <option value={23} >23</option>
                </select>
              }
              {AmPm === true &&

                <select onChange={this.handleChange} value={this.state.sliderHoursStart || 0} className='selectEventForm' style={{ display: 'inline', paddingRight: '1px', textAlignLast: 'right' }} >
                  <optgroup label='AM'>
                    <option value={0} >12</option>
                    <option value={1} >1</option>
                    <option value={2} >2</option>
                    <option value={3} >3</option>
                    <option value={4} >4</option>
                    <option value={5} >5</option>
                    <option value={6} >6</option>
                    <option value={7} >7</option>
                    <option value={8} >8</option>
                    <option value={9} >9</option>
                    <option value={10} >10</option>
                    <option value={11} >11</option>
                  </optgroup>
                  <optgroup label='PM'>
                    <option value={12} >12</option>
                    <option value={13} >1</option>
                    <option value={14} >2</option>
                    <option value={15} >3</option>
                    <option value={16} >4</option>
                    <option value={17} >5</option>
                    <option value={18} >6</option>
                    <option value={19} >7</option>
                    <option value={20} >8</option>
                    <option value={21} >9</option>
                    <option value={22} >10</option>
                    <option value={23} >11</option>
                  </optgroup>
                </select>

              }

              :
            <select onChange={this.handleChange1} value={this.state.sliderMinStart || 0} className='selectEventForm' style={{ display: 'inline', paddingRight: '1px' }} >
                <option value={0} >00</option>
                <option value={5} >05</option>
                <option value={10} >10</option>
                <option value={15} >15</option>
                <option value={20} >20</option>
                <option value={25} >25</option>
                <option value={30} >30</option>
                <option value={35} >35</option>
                <option value={40} >40</option>
                <option value={45} >45</option>
                <option value={50} >50</option>
                <option value={55} >55</option>
              </select>



              {AmPm === true &&
                <span>
                  {this.state.sliderHoursStart <= 11 && 'AM'}
                  {this.state.sliderHoursStart > 11 && 'PM'}</span>
              }
            </div></div> */}





            {
              false &&
              <div style={{ marginTop: '20px', position: 'absolute', top: '5px', left: right ? '-105px' : '-55px', width: '415px', xheight: '150px', boxShadow: '1px 2px 2px 0px rgba(0, 0, 0, 0.2), 1px 3px 15px 2px rgba(0, 0, 0, 0.2)', border: 'solid 1px var(--color-border)', padding: '6px', background: '#FAFAFA', zIndex: '4', borderRadius: '4px' }}>
                <div style={{ width: '10px', height: '10px', background: '#FAFAFA', position: 'absolute', top: '-6px', transform: 'rotate(45deg)', left: right ? '121px' : '58px', borderTop: 'solid 1px rgba(187,187,187,0.6)', borderLeft: 'solid 1px rgba(187,187,187,0.6)' }}></div>

                <div style={{ position: 'relative', border: 'solid 0px green', marginBottom: '20px', marginTop: '20px' }}>
                  <input
                    type="range"
                    min="0" max="23"
                    onTouchStart={(e) => e.target.focus()}
                    value={this.state.sliderHoursStart || 0}
                    onChange={this.handleChange}
                    step="1"
                    style={{ width: '100%', position: 'relative', left: '0px', top: '0px', margin: '0px', zIndex: '3' }}
                  />
                  {AmPm === false &&
                    <div className="ticks" style={{ width: '100%', position: 'absolute', left: '0px', top: '-7px', margin: '0px', zIndex: '2' }}>
                      <span className="tick">00</span>
                      <span className="tick1"></span>
                      <span className="tick">02</span>
                      <span className="tick1"></span>
                      <span className="tick">04</span>
                      <span className="tick1"></span>
                      <span className="tick">06</span>
                      <span className="tick1"></span>
                      <span className="tick">08</span>
                      <span className="tick1"></span>
                      <span className="tick">10</span>
                      <span className="tick1"></span>
                      <span className="tick">12</span>
                      <span className="tick1"></span>
                      <span className="tick">14</span>
                      <span className="tick1"></span>
                      <span className="tick">16</span>
                      <span className="tick1"></span>
                      <span className="tick">18</span>
                      <span className="tick1"></span>
                      <span className="tick">20</span>
                      <span className="tick1"></span>
                      <span className="tick">22</span>
                      <span className="tick1"></span>
                    </div>
                  }
                  {AmPm === true &&
                    <div className="ticks" style={{ width: '100%', position: 'absolute', left: '0px', top: '-7px', margin: '0px', zIndex: '2' }}>
                      <span className="tick">12am</span>
                      <span className="tick1"></span>
                      <span className="tick">2am</span>
                      <span className="tick1"></span>
                      <span className="tick">4am</span>
                      <span className="tick1"></span>
                      <span className="tick">6am</span>
                      <span className="tick1"></span>
                      <span className="tick">8am</span>
                      <span className="tick1"></span>
                      <span className="tick">10am</span>
                      <span className="tick1"></span>
                      <span className="tick">12pm</span>
                      <span className="tick1"></span>
                      <span className="tick">2pm</span>
                      <span className="tick1"></span>
                      <span className="tick">4pm</span>
                      <span className="tick1"></span>
                      <span className="tick">6pm</span>
                      <span className="tick1"></span>
                      <span className="tick">8pm</span>
                      <span className="tick1"></span>
                      <span className="tick">10pm</span>
                      <span className="tick1"></span>
                    </div>
                  }
                </div>
                <div style={{ position: 'relative', border: 'solid 0px blue', margin: '0px', padding: '0px' }}>
                  <input
                    type="range"
                    min="0" max="55"
                    onTouchStart={(e) => e.target.focus()}
                    value={this.state.sliderMinStart || 0}
                    onChange={this.handleChange1}
                    step="5"
                    style={{ width: '100%', position: 'relative', left: '0px', margin: '0px', zIndex: '3' }}
                  />
                  <div className="ticks" style={{ width: '100%', position: 'absolute', left: '0px', top: '-7px', margin: '0px', zIndex: '2' }}>
                    <span className="tick">00</span>
                    <span className="tick1"></span>
                    <span className="tick">10</span>
                    <span className="tick1"></span>
                    <span className="tick">20</span>
                    <span className="tick1"></span>
                    <span className="tick">30</span>
                    <span className="tick1"></span>
                    <span className="tick">40</span>
                    <span className="tick1"></span>
                    <span className="tick">50</span>
                    <span className="tick1"></span>
                  </div>
                  {/* <div className='noSelect' style={{ position: 'absolute', left: '-20px', top: '5px', color: 'silver', fontSize: '12px' }}>00</div>
          <div className='noSelect' style={{ position: 'absolute', right: '-20px', top: '5px', color: 'silver', fontSize: '12px' }}>55</div> */}
                </div>

                {/* <button style={{ width: '100%', marginTop: '6px' }} icon='check' color='green' onClick={toggle} ></button> */}

              </div>
            }
          </div>
        </React.Fragment>
      )
    }
    else {
      console.log('show', show)
      if (show === true)
        return (this.TimeSliderMobile(input, show, sliderHoursStart, AmPm, sliderMinStart))
      else
        return <input onChange={(param, data) => input.onChange(data.value)} {...input} type='text' />

    }




  }
}