import React, { Component } from "react";
//import { shallowEqual } from 'shouldcomponentupdate-children';

import { Label, Button, Input, Menu } from "../../common/component/Button";
import { toastr } from 'react-redux-toastr'
//import Swipe from '../../common/component/Swipe';//'react-swipe-component'
//import memoizeOne from 'memoize-one';
import moize from 'moize'

import { connect } from "react-redux";
import { ScheduleMenu } from "./ScheduleMenu";

import PrintHelper from '../../common/util/PrintHelper'

import EditScheduleModule from '../modal/EditScheduleModule'
import SelectSchedules from '../modal/SelectSchedules'
import SelectUsers from '../modal/SelectUsers'
//import imgLogo from '../../../img/32x32.png'
import Schedule1 from "./schedule1/Schedule1";
import array from "lodash/array";
import object from "lodash/object";
import collection from "lodash/collection";
import lang from 'lodash/lang'
import { openEventModal, openEditScheduleModal, openModal, closeEventModal } from "../../redux/action/modalAction";
import { toggelTemplates } from "../../redux/action/viewstatAction";
import { addevent, updateEventBatch, updateEventBatch2, addSchedule } from "../../redux/action/eventAction";
import { getSchedule, setSchedule, setRenderDayDateSchedule, copyScheduleShift } from "../../redux/action/currentScheduelDataAction";
import { DataHelper } from "../../common/util/DataHelper";
import shortid from 'shortid'
import moment from 'moment'
import PropTypes from 'prop-types'
//import noUserImage from '../../../img/noimg.png'
import FirestoreListnerHelper from '../../common/util/FirestoreListnerHelper'
import { FireHelper } from '../../common/util/FireHelper'
import { getDateKey, getLocalDate, renderTimeLable } from '../../common/util/helpers'
import { createSelector } from 'reselect';
import EditEventModal from "../modal/EditEventModal";
import ShiftAdminRequest from "../modal/ShiftAdminRequest";
import Locked from "../schedule/settings/Locked";
import ModalFrame from "../modal/ModalFrame";


var isElectronApp = (process.env.REACT_APP_ELECTRON === '1')
let ipcRenderer
isElectronApp = !!window.require //TEMP
if (isElectronApp) {
  ipcRenderer = window.require('electron').ipcRenderer
}



class Schedule extends Component {

  static contextTypes = { store: PropTypes.object.isRequired }
  constructor(props) {
    super(props);
    this.doOnce = true;
    this.state = {
      copyObj: { eventsList: null, sortedUserList: null, dividerList: null },
      showWeekPicker: false,
      renderDayCount: 7,
      scheduleDropdownName: null,
      scheduleDropdownKey: null,
      setInitState: true,
      openDivderModal: false,
      divierModalNameValue: '',
      dividerKey: null,
      slideAni: '',
    };


  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log('-------shouldComponentUpdate')
    // console.log(this.props)
    // Object.keys(this.props).forEach(key => {
    //   try {
    //     if (this.props[key] !== nextProps[key]) {
    //       console.log('key', key)
    //       console.log('props', this.props[key] === nextProps[key])
    //     }
    //   } catch (error) {
    //     console.log('err', error)
    //   }

    // });
    // console.log('props', this.props === nextProps)
    // console.log('state', this.state === nextState)
    return true
    // const r = shallowEqual(this.props, nextProps, this.state, nextState);
    // console.log('====shouldComponentUpdate>', r)
    // return r
  }

  componentUpdateData = () => {
    // console.log('>>componentUpdateData schedule')

    if (this.state.setInitState === true && this.props.project) {

      // this will onlY run once

      if (!this.props.currentScheduelData.renderDayDate) {
        //   console.log('####0')

        var renderDate = this.props.viewstateRenderDate
        //console.log('INIT 2 DATe', renderDate.format('YYYY-MM-DD'))
        this.props.setRenderDayDateSchedule(renderDate)
      } else if (this.props.isLoadedSchedule === true) {



        if (this.props.scheduleDropDownList && this.props.scheduleDropDownList.length !== 0) {
          // console.log('####1', this.props.scheduleDropDownList[0].text)
          var scheduleDropdownName = this.props.scheduleDropDownList[0].text
          var scheduleDropdownKey = this.props.scheduleDropDownList[0].key
          if (this.props.scheduleDropDownList && this.props.scheduleDropDownList[0] && this.state.setInitState === true) {
            // console.log('>>#5 SET INIT STATE')


            if (this.state.scheduleDropdownKeySaved) {
              var indexOfKey = this.props.scheduleDropDownList.findIndex(item => item.key === this.state.scheduleDropdownKeySaved)
              if (indexOfKey > -1) {
                scheduleDropdownName = this.state.scheduleDropdownNameSaved
                scheduleDropdownKey = this.state.scheduleDropdownKeySaved
              }
            }

            this.setState({ setInitState: false, scheduleDropdownName: scheduleDropdownName, scheduleDropdownKey: scheduleDropdownKey })
            this.loadFireListner(scheduleDropdownKey)
            this.setSchedule(scheduleDropdownKey);



          }
        }
        else {

          if (this.state.noSchedual === undefined) {
            //   console.log('####2 OPEM!')

            // console.log('####this.props.isOpen_EditShedualModalOpen', this.props.isOpen_EditShedualModalOpen)
            this.setState({ noSchedual: true }, this.openEditScheduleModal('__empty', null))


          }
        }

      }


      //THIS GIVES ININITY LOOP ON RE LOGIN , IS INIT STATE RESET ? 
      //if (this.state.setInitState === true && this.props.haveData === true) {
      //load fist schedule
      // if (this.props.scheduleDropDownList && this.props.scheduleDropDownList[0]) {
      //   console.log('#4')
      //   this.setSchedule(this.props.scheduleDropDownList[0].value);
      // }
      //}
    }
    else {

    }
  }

  componentWillUnmount = () => {

    // if (isElectronApp) {
    //   ipcRenderer.removeListener('onCloseBlur', this.onCloseBlur)
    // }


    window.removeEventListener("mobileSaveButton", this.onMobileSaveButton)
    const saveData = { scheduleDropdownNameSaved: this.state.scheduleDropdownName, scheduleDropdownKeySaved: this.state.scheduleDropdownKey }
    localStorage.setItem('savedStateVars', JSON.stringify(saveData))
    window.removeEventListener('onTopAddEvent', this.onContextMenuNewShift);



    window.removeEventListener('pasteshift', this.onContextMenuPasteShift);
    window.removeEventListener('copyshift', this.onContextMenuCopyShift);
    window.removeEventListener('newshift', this.onContextMenuNewShift);
    window.removeEventListener('newuser', this.onClickToolsAddUser);
    window.removeEventListener('newdivider', this.onClickToolsAddDivider);
    window.removeEventListener('newschedule', this.openEditScheduleModal);
    window.removeEventListener('copyschedule', this.onClickToolsCopy);
    window.removeEventListener('pasteschedule', this.onClickToolsPaste);
    window.removeEventListener('clearshifts', this.onClickToolsClear);
    window.removeEventListener('undochanges', this.onClickToolsReload);
    window.removeEventListener('onRemoveEvent', this.onRemoveEvent2);
    window.removeEventListener('onCreateShiftRequest', this.onContextMenuCreateShiftRequest);


  };


  // onCloseBlur = (event, arg) => {
  //   console.log('onCloseBlur')
  //   this.props.onCloseModal();
  // }

  componentDidMount = () => {


    // if (isElectronApp) {
    //   console.log('load ipcRenderer')
    //   ipcRenderer.on('onCloseBlur', this.onCloseBlur)
    // }

    window.addEventListener('pasteshift', this.onContextMenuPasteShift);
    window.addEventListener('copyshift', this.onContextMenuCopyShift);
    window.addEventListener('newshift', this.onContextMenuNewShift);
    window.addEventListener('newuser', this.onClickToolsAddUser);
    window.addEventListener('newdivider', this.onClickToolsAddDivider);
    window.addEventListener('newschedule', this.openEditScheduleModal);
    window.addEventListener('copyschedule', this.onClickToolsCopy);
    window.addEventListener('pasteschedule', this.onClickToolsPaste);
    window.addEventListener('clearshifts', this.onClickToolsClear);
    window.addEventListener('undochanges', this.onClickToolsReload);
    window.addEventListener('onRemoveEvent', this.onRemoveEvent2);
    window.addEventListener('onCreateShiftRequest', this.onContextMenuCreateShiftRequest);



    if (ipcRenderer)
      ipcRenderer.send('setMenu', '/schedule')



    window.addEventListener('onTopAddEvent', this.onContextMenuNewShift);
    window.addEventListener("mobileSaveButton", this.onMobileSaveButton)
    var jsonData = localStorage.getItem('savedStateVars');
    if (jsonData) {
      const rehydrateSaveData = JSON.parse(jsonData)
      if (rehydrateSaveData && rehydrateSaveData.scheduleDropdownName && rehydrateSaveData.scheduleDropdownKey) {
        this.props.scheduleDropDownList[0].text = rehydrateSaveData.scheduleDropdownName
        this.props.scheduleDropDownList[0].key = rehydrateSaveData.scheduleDropdownKey
      }
      this.setState({ ...rehydrateSaveData || {} }, () => {
        this.componentUpdateData()
      })
    }
    else
      this.componentUpdateData()


  };


  componentDidUpdate(prevProps, prevState, snapshot) {

    //    console.log('componentDidUpdate')


    this.componentUpdateData()

    if (this.state.setInitState === false) {
      //var dateKey = this.getDateKey();
      // console.log('this.props.match.params.key', this.props.match.params.key)
      if (this.props.match.params.key && this.props.match.params.key !== 'default' && (this.props.match.params.key !== prevProps.currentScheduelData.selectedKey)) {
        //    console.log('>>>>>this.props.match.params.key', this.props.match.params.key)
        this.onChangeSchema2('', this.props.match.params.key); //change data 
      }
      else if (prevProps.currentScheduelData.selectedKey !== this.props.currentScheduelData.selectedKey) {
        // console.log('#1 componentDidUpdate')
        this.setSchedule(this.props.currentScheduelData.selectedKey); //change data 
      }
      else if (!lang.isEqual(prevProps.eventsList, this.props.eventsList)) {
        // console.log('#2 componentDidUpdate')
        //toTop()
        this.setSchedule(this.props.currentScheduelData.selectedKey); // changedata
      }
      else if (this.props.schedules !== prevProps.schedules) {
        // console.log('#3 componentDidUpdate', this.props.currentScheduelData.selectedKey)
        this.setSchedule(this.props.currentScheduelData.selectedKey); // we have new schduela data , refresh!
      }
      else if (this.props.scheduleWeekDataMeta !== prevProps.scheduleWeekDataMeta) {
        // console.log('#4 componentDidUpdate')
        this.setSchedule(this.props.currentScheduelData.selectedKey); // we have new schduela data , refresh!
        // }
      }
    }
  }














  listnerKey = (key, renderDayDate) => {

    var dateKey = this.getDateKey(renderDayDate);
    return `projects/${this.props.currentProjectKey}/schedules/${key}/events/${dateKey}`
  }
  getDateKey = (date) => {
    var r = ''
    if (date) {
      r = getDateKey(date);// moment.utc(date).add(6, 'days').format("YYYY-WW")
      // console.log('r1 -> ', r)
      return r
    }
    //console.log('this.props.currentScheduelData.renderDayDate', this.props.currentScheduelData.renderDayDate.format("YYYY-MM-dd"))
    r = getDateKey(this.props.currentScheduelData.renderDayDate)// moment.utc(this.props.currentScheduelData.renderDayDate).add(6, 'days').format("YYYY-WW")

    // console.log('the date is -> ', r)
    return r;
  }


  setSortedUserList = (userKeyList, moveKey) => {
    console.log('setSortedUserList')
    const isDiv = moveKey.startsWith("div_")
    if (isDiv) {
      this.getCurScheduleView().dividerList[moveKey].dirty = true
    }
    //todo set user to dirty!

    this.updateCurScheduleView({ ...this.getCurScheduleView(), dirty: true, sortedUserList: userKeyList, selectedSortedUserList: userKeyList }, 'setSortedUserList');
  };

  updateCurScheduleView = (data, caller) => {
    console.log('>>updateCurScheduleView>', this.getCurScheduleView())
    this.props.setCurrentScheduleData({ ...this.getCurScheduleView(), ...data });
  };

  setCurScheduleView = (data, caller) => {
    //console.log('>setCurScheduleView>', caller)
    this.props.setCurrentScheduleData(data);
  };


  getScheduleEmpty = (key, dateKey) => {
    console.log('>--->-->getScheduleEmpty>>', key)
    return DataHelper.initSchedule(key, dateKey);
  };
  getScheduleByKey = key => {

    return DataHelper.getScheduleByKey(this.props, key);
  }

  getCurScheduleView = () => {

    return this.getCurScheduleView2(this.props.curScheduleView);
  }

  getCurScheduleView2 = moize((curScheduleView) => {

    var r = DataHelper.getCurScheduleView(curScheduleView);
    return r;
  }, { maxSize: 5 })


  onAddTemplateShift = (toUser, toDate, eventKey) => {
    console.log('add here!')
    console.log('//TODO TEMP FIX !!')
    //TODO TEMP FIX !!
    const { schedules, currentScheduelData } = this.props
    if (schedules && currentScheduelData && schedules[currentScheduelData.selectedKey]) {
      var templates = schedules[currentScheduelData.selectedKey].templates


      var curScheduleView = this.getCurScheduleView()
      var currentEvent = { ...templates[eventKey] }; //get it from templates


      if (currentEvent) {


        var newShift = { ...currentEvent }
        newShift.key = shortid.generate()
        newShift.isNew = true
        delete newShift.template

        newShift.uid = toUser;
        newShift.date = toDate;
        newShift.dirty = true;
        console.log('newShift', newShift)
        var scheduleEventsList = { ...curScheduleView.scheduleEventsList, [newShift.key]: newShift }


        this.updateCurScheduleView({ dirty: true, scheduleEventsList }, "onMoveEvent");
      }
    }
  };
  onMoveEvent = (toUser, toDate, eventKey, clone) => {
    var curScheduleView = this.getCurScheduleView()
    var currentEvent = { ...curScheduleView.scheduleEventsList[eventKey] };


    if (currentEvent) {

      if (clone) {
        currentEvent = { ...currentEvent }
        currentEvent.key = shortid.generate()
        currentEvent.isNew = true
      }


      const dateStr = moment.utc(Number(toDate)).format('YYYY-MM-DD')
      var startDate = +moment.utc(`${dateStr} ${currentEvent.timeStart}`)
      var stopDate = +moment.utc(`${dateStr} ${currentEvent.timeEnd}`)


      currentEvent.uid = toUser;
      currentEvent.date = toDate;
      currentEvent.startDate = startDate;
      currentEvent.stopDate = stopDate;
      currentEvent.dirty = true;

      console.log('toDate', toDate)

      var scheduleEventsList = { ...curScheduleView.scheduleEventsList, [currentEvent.key]: currentEvent }


      this.updateCurScheduleView({ dirty: true, scheduleEventsList }, "onMoveEvent");
    }
  };

  getScheduleUserlistKeys = scheduleEventsList => {
    return array.uniq(collection.map(scheduleEventsList, "uid")); // get users from events
  };

  getScheduleUserlistKeysWithActiveEvents = scheduleEventsList => {
    var list = object.pickBy(scheduleEventsList, obj => {
      if (obj.removed) return false;

      return true;
    });

    return array.uniq(collection.map(list, "uid"));

    //    var listWithOutRemovedEvents = collection.map(scheduleEventsList,(o)=>!o.removed)
    //  return array.unionBy(listWithOutRemovedEvents,'uid')
    //return array.uniq(collection.map(scheduleEventsList, "uid")); // get users from events
  };


  setSortedSchedulelist = () => {
    // console.log('>setSortedSchedulelist ??')
    this.props.setCurrentScheduleData({
      ...this.getCurScheduleView(),
      dirty: true,
      sortedUserList: this.getMergedScheduleUserlist()
    });
  };

  getMergedScheduleUserlistWithSelectedUsers = (selectedUsers) => {

    var scheduel = this.getCurScheduleView();

    var userKeysFormEventList = this.getScheduleUserlistKeysWithActiveEvents(
      scheduel.scheduleEventsList
    ); // get users from events
    var sortedUsers = scheduel.sortedUserList || []; //TODO this shude not be null its miss its default value ?

    var userKeyList = array.uniq([...selectedUsers, ...sortedUsers, ...userKeysFormEventList], "uid");

    return userKeyList;
  }

  creatFireListner = (scheduleKey, date) => {
    if (date) {
      FirestoreListnerHelper.setListner(this.context, date, scheduleKey)
    }
  }

  loadFireListner = (key, renderDayDate) => {
    renderDayDate = renderDayDate || this.props.currentScheduelData.renderDayDate
    this.creatFireListner(key, renderDayDate)
    this.creatFireListner(key, moment.utc(renderDayDate).add(7, 'days'))//preload next and prevweek
    this.creatFireListner(key, moment.utc(renderDayDate).add(-7, 'days'))//preload next and prevweek
  }

  getMergedScheduleUserlist = () => {
    //console.log('>>>>>>getMergedScheduleUserlist>>>>>>>>>>>>>')

    var scheduel = this.getCurScheduleView();
    return this.getMergedScheduleUserlist2(scheduel)
  }


  onAddUserToSchedule = (users) => {


    const schedule = this.getCurScheduleView()
    var scheduleUserListRemoved = [...schedule.scheduleUserListRemoved]
    var selectedUsers = [...schedule.selectedUsers]
    var doUpdate = false
    for (let index = 0; index < users.length; index++) {
      const user = users[index];

      // console.log('user', user.selected)
      if (user && user.selected === true) {

        doUpdate = true
        //  delete user.selected
        scheduleUserListRemoved = array.remove([...scheduleUserListRemoved], (o) => o !== user.key);
        selectedUsers.unshift(user.key)
      } else if (user && user.selected === false) {
        // delete user.selected
      }
    }
    if (doUpdate) {
      var mergedUsers = this.getMergedScheduleUserlistWithSelectedUsers(selectedUsers);
      this.updateCurScheduleView({ selectedUsers, scheduleUserListRemoved, sortedUserList: mergedUsers }, "onAddUserToSchedule");
    }

  };
  onChangeSchema2 = (text, value) => {
    console.log('text', text)
    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>value', value)
    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>value', this.state.scheduleDropdownKey)
    if (value !== this.state.scheduleDropdownKey) {
      this.setState({ scheduleDropdownName: text, scheduleDropdownKey: value }, () => {
        const saveData = { scheduleDropdownNameSaved: this.state.scheduleDropdownName, scheduleDropdownKeySaved: this.state.scheduleDropdownKey }
        localStorage.setItem('savedStateVars', JSON.stringify(saveData))

      })
    }
    this.loadFireListner(value)
    this.setSchedule(value);


  };
  onChangeSchema = (e, { value, text }) => {
    var selectedObject = collection.find(this.props.scheduleDropDownList, o => {
      if (!o)
        return false

      return o.value === value
    });
    if (!selectedObject) {
      return // exit whitout doing anything 
    }
    this.onChangeSchema2(text, value)
  };


  setSchedule = async (scheduelKey, clean) => {
    //console.log('>>--------setSchedule')
    var selectedScheduleData = this.props.schedules[scheduelKey];
    if (selectedScheduleData) {

      var dateKey = this.getDateKey();
      var eList = this.props.eventsList || {}
      var uListSorted = this.props.sortedUserList2 || {}
      var dList = this.props.dividerList || {}

      var isEmpty = false;

      //get this schedule evetns 
      var eventsList = eList//[dateKey]


      if (!eventsList) {
        isEmpty = true
        console.log('THE LISTIS EMPTY');

      }

      //get currentScheduleView
      const currentScheduleView = clean ? { ...this.getScheduleEmpty(scheduelKey, dateKey) } : { ...this.getScheduleByKey(scheduelKey + '_' + dateKey) }

      //lit of removed users
      var scheduleUserListRemoved = currentScheduleView.scheduleUserListRemoved || []

      var selectedSortedUserList = currentScheduleView.selectedSortedUserList || []
      //if (currentScheduleData.key !== scheduelKey)
      //  currentScheduleData = { selectedUsers: [], sortedUserList: [] } //if we change schedule we dont want old select data

      // selectedusers 
      var selectedUsers = currentScheduleView.selectedUsers

      // sorted user
      var sortedUserList = uListSorted ? [...uListSorted] : []

      // get dividerLit
      var dividerList = { ...dList, ...currentScheduleView.dividerList } || {}

      // get the selected/unsaved divs
      var selectedDividerList = { ...currentScheduleView.selectedDividerList } || {}

      //remove users that have been removed but not saved
      if (scheduleUserListRemoved.length > 0) {
        selectedSortedUserList = array.difference(selectedSortedUserList, scheduleUserListRemoved)
        sortedUserList = array.difference(sortedUserList, scheduleUserListRemoved)
      }



      // get sortedusers
      sortedUserList = array.uniq([...selectedSortedUserList, ...sortedUserList] || []) //, 

      //const { eventsList } = this.props; //all events 
      var dirty = false; // schedule dirty flag

      //get evetn thata belongs to the selected scheduel
      var scheduleEventsList = eventsList;//object.pickBy(eventsList, obj => obj && obj.sid === scheduelKey);


      if (clean) {
        dividerList = collection.forEach(dividerList,
          obj => {
            delete obj.removed
            delete obj.dirty;
            return { ...obj };
          }
        );

        scheduleEventsList = collection.forEach(scheduleEventsList,
          obj => {
            delete obj.removed
            delete obj.dirty;
            return { ...obj };
          }
        );
      }

      // get previus events that are not saved
      const oldEventList = object.pickBy(currentScheduleView.scheduleEventsList, 'dirty');


      const mergedEventList = { ...scheduleEventsList, ...oldEventList }

      //get all events that are not marked removed 
      const newEventList = mergedEventList;//object.pickBy(mergedEventList, o => o && !o.removed);


      // set schedule.dirty flag to true if a events is dirty
      dirty = object.findKey(newEventList, 'dirty') ? true : false

      //check if dividerlist is dirty
      if (!dirty)
        dirty = object.findKey(dividerList, 'dirty') ? true : false

      if (!dirty)
        dirty = (scheduleUserListRemoved.length > 0)

      if (!dirty)
        dirty = (selectedSortedUserList.length > 0)




      if (false) {
        //debug data
        console.log(' ')
        console.log('>>>>>setSchedule eventsList', eventsList)
        console.log('>>>>>setSchedulex ??', { ...scheduleEventsList, ...oldEventList })
        console.log('>>>>>setSchedulex scheduleEventsList', scheduleEventsList)
        console.log('>>>>>setSchedulex oldEventList', oldEventList)
        console.log('>>>>>setSchedulex newEventList', newEventList)
        console.log('>>>>>setSchedulex dividerList', dividerList)
        console.log('>>>>>>>>>>>>>this.props.scheduleWeekDataMeta', this.props.scheduleWeekDataMeta)
        console.log('>>>>>setSchedulex')
      }




      var newScheduleView = {
        selectedUsers: selectedUsers,
        isEmpty,
        loading: false,
        key: scheduelKey,
        dateKey: dateKey,
        dirty,
        selectedSchedule: selectedScheduleData, //TODO remove this
        scheduleEventsList: newEventList,
        scheduleUserList: this.getScheduleUserlistKeys(scheduleEventsList),
        sortedUserList: sortedUserList,
        dividerList,
        selectedDividerList,
        selectedSortedUserList,
        scheduleUserListRemoved,
        metaData: this.props.scheduleWeekDataMeta,

      };
      //      console.log('>>newScheduleView', newScheduleView);
      //save to redux 

      this.setCurScheduleView(newScheduleView, "setSchedule");
    }
  };

  onMobileSaveButton = (e) => {
    if (window.location.pathname === '/schedule/schedule')
      this.onSaveSchedule()
  }

  onSaveSchedule = () => {
    var curScheduleView = this.getCurScheduleView();

    //get all events 
    var scheduleEventsList = curScheduleView.scheduleEventsList;
    var mergedUsers = this.getMergedScheduleUserlist()


    var scheduleToUpdate = {
      ...curScheduleView.selectedSchedule
      , sortedUserList: mergedUsers
    };

    if (scheduleToUpdate.key === 'NEW-TEMP-ID') {

      scheduleToUpdate.key = shortid.generate()
      this.setState({ scheduleDropdownName: scheduleToUpdate.name, scheduleDropdownKey: scheduleToUpdate.key })

    }

    //save current user list 
    // scheduleToUpdate.sortedUserList = scheduleToUpdate.scheduleUserList

    //console.log('scheduleToUpdate', scheduleToUpdate.scheduleUserList)
    //save to firestore 
    var dateKey = this.getDateKey();
    this.props.updateEventBatch2(scheduleEventsList, scheduleToUpdate, dateKey, curScheduleView.sortedUserList, curScheduleView.dividerList);

    //update redux state
    Object.keys(scheduleEventsList).forEach(key => {
      var shift = scheduleEventsList[key]
      if (shift) {
        if (shift.removed === true)
          delete scheduleEventsList[key]
      }
    });
    // console.log('scheduleEventsList', scheduleEventsList)

    this.updateCurScheduleView({ dirty: false, selectedSortedUserList: [], scheduleEventsList, selectedUsers: [], scheduleUserListRemoved: [] }, "onSaveSchedule");


  };

  onRemoveRow = userKey => {
    var curScheduleView = this.getCurScheduleView();

    if (!userKey)
      return

    const isDiv = userKey.startsWith("div_");

    var scheduleUserList = array.pull(curScheduleView.scheduleUserList, userKey);
    var sortedUserList = array.pull(curScheduleView.sortedUserList, userKey);
    var selectedUsers = array.pull(curScheduleView.selectedUsers, userKey);
    var scheduleUserListRemoved = [...curScheduleView.scheduleUserListRemoved, userKey];
    var scheduleEventsList = curScheduleView.scheduleEventsList
    var scheduleDividerList = curScheduleView.dividerList


    // console.log('urScheduleView.scheduleEventsList', curScheduleView.scheduleEventsList)

    if (isDiv) {
      scheduleDividerList[userKey] = { ...scheduleDividerList[userKey], dirty: true, removed: true }
    }
    else {
      collection.forEach(curScheduleView.scheduleEventsList,
        (o, key) => {
          var obj = { ...o }
          if (obj.uid === userKey) {
            obj.removed = true;
            obj.dirty = true;
            scheduleEventsList[key] = obj
          }
        }
      );
    }



    this.updateCurScheduleView(
      {
        ...curScheduleView,
        selectedUsers,
        scheduleEventsList,
        scheduleUserList,
        sortedUserList,
        scheduleUserListRemoved,
        dirty: true
      },
      "onRemoveRow"
    );
  };

  onRemoveTemplate = shiftKey => {
    FireHelper.removeTemplateShift(shiftKey, this.props.currentProjectKey, this.props.currentScheduelData.selectedKey)
    // var currentEvent = { ...this.getCurScheduleView().scheduleEventsList[eventKey] };
    // if (currentEvent) {
    //   currentEvent.removed = true;
    //   currentEvent.dirty = true;


    //   this.updateCurScheduleView(
    //     {
    //       scheduleEventsList: {
    //         ...this.getCurScheduleView().scheduleEventsList,
    //         [currentEvent.key]: currentEvent
    //       },
    //       dirty: true
    //     },
    //     "onRemoveEvent"
    //   );
    // }
  };

  onRemoveEvent2 = args => {
    console.log('args.detail.eventId', args.detail.eventId)
    if (args.detail.eventId)
      this.onRemoveEvent(args.detail.eventId)

  };

  onRemoveEvent = eventKey => {

    var currentEvent = { ...this.getCurScheduleView().scheduleEventsList[eventKey] };
    if (currentEvent) {
      currentEvent.removed = true;
      currentEvent.dirty = true;

      this.updateCurScheduleView(
        {
          scheduleEventsList: {
            ...this.getCurScheduleView().scheduleEventsList,
            [currentEvent.key]: currentEvent
          },
          dirty: true
        },
        "onRemoveEvent"
      );
    }
  };



  onContextMenuPasteShift = (args) => {

    console.log('>>>>>>>>>>>>>>>>>>>>>', this.props.currentScheduelData.copyShiftObject)
    var cloneObj = this.props.currentScheduelData.copyShiftObject
    if (cloneObj) {
      var mergedUsers = this.getMergedScheduleUserlist()
      var userKey = args.detail.userkey || 'openshifts'
      var date = Number(args.detail.dataedate) || cloneObj.date
      console.log('args.detail.dataedate', args.detail.dataedate)

      const dateStr = moment.utc(Number(date)).format('YYYY-MM-DD')
      console.log(date, dateStr)
      var startDate = +moment.utc(`${dateStr} ${cloneObj.timeStart}`)
      var stopDate = +moment.utc(`${dateStr} ${cloneObj.timeEnd}`)

      var newEvent = { ...cloneObj, isNew: true, uid: userKey, key: shortid.generate(), date, startDate, stopDate };

      console.log('***************')
      console.log('this.props.currentScheduelData', cloneObj)
      console.log('newEvent', newEvent)
      console.log('***************')

      this.updateCurScheduleView({
        dirty: true,
        scheduleEventsList: { ...this.getCurScheduleView().scheduleEventsList, [newEvent.key]: newEvent },
        sortedUserList: mergedUsers
      }, "onContextMenuPasteShift");

    }
    else {
      toastr.success('', `You need to copy a shift first`);
    }

  }

  onContextMenuCreateShiftRequest = (args) => {
    if (args && args.detail && args.detail.eventId) {
      var eList = this.getCurScheduleView().scheduleEventsList || {}
      var orginalShift = eList[args.detail.eventId]

      var shift = orginalShift ? {
        c: orginalShift.color,
        k: orginalShift.key,
        l: renderTimeLable(orginalShift.label || '', orginalShift.startDate, orginalShift.stopDate, this.props.AmPm),
        d: moment(orginalShift.date).format('YYYY-MM-DD')
      } : null;


      if (orginalShift.minutes)
        shift.m = orginalShift.minutes

      if (orginalShift.break)
        shift.b = orginalShift.break

      if (orginalShift.comment)
        shift.co = orginalShift.comment

      if (orginalShift.timeStart)
        shift.t1 = orginalShift.timeStart

      if (orginalShift.timeEnd)
        shift.t2 = orginalShift.timeEnd

      if (orginalShift.sick)
        shift.sik = orginalShift.sick

      if (orginalShift.timeOf)
        shift.tof = orginalShift.timeOf



      console.log('>>>>>>>>>>>>shift------------->>>>>>>>>>shiftadminrequest------>', shift)

      this.props.onOpenModal('shiftadminrequest', { shift: { ...shift }, sKey: this.props.currentScheduelData.selectedKey, meta: null, })
    }
  }

  onContextMenuCopyShift = (args) => {
    //this.props.onCopyScheduleShift()

    var currentEvent = { ...this.getCurScheduleView().scheduleEventsList[args.detail.eventId] };
    if (currentEvent) {
      console.log('args.detail.eventId', currentEvent)
      this.props.onCopyScheduleShift(currentEvent)
      toastr.success('', `Shift is copied`);
    }
  }
  onContextMenuNewShift = (args) => {

    var target = window._clickedTarget
    var userkey = args.detail.userkey || null
    var isRoot = false
    var shiftWidth = 500
    if (!target || userkey === null) {
      target = document.getElementById('root')
      isRoot = true
    }
    var { x, y, width, height, top, left, right } = target.getBoundingClientRect()
    x = left
    y = top
    if (isRoot === true) {
      x = width * .5 + (shiftWidth * .5)
      left = width * .5 + (shiftWidth * .5)
    }



    if (ipcRenderer)
      ipcRenderer.send('renderWindowTargetPos', { x, y, width, height, top, left, right, showArrow: !isRoot })
    delete window._clickedTarget

    this.onAddEvent(args.detail.dataedate ? Number(args.detail.dataedate) : null, userkey)()

  }

  onAddEvent = (date, userKey) => () => {
    console.log('onAddEvent', date)
    var mergedUsers = this.getMergedScheduleUserlist()

    if (date == null) {
      date = +this.props.currentScheduelData.renderDayDate

    }
    if (userKey == null) {
      userKey = 'openshifts'
    }

    //console.log('date', date)
    // //this shude be a INIT_Shift file, so we can reuse it!
    var newEvent = { color: 'blue', date: date, dirty: true, hours: 0, label: "@start - @end", uid: userKey, key: shortid.generate(), isNew: true };
    //console.log('newEvent', newEvent)


    this.updateCurScheduleView({
      dirty: true,
      scheduleEventsList: { ...this.getCurScheduleView().scheduleEventsList, [newEvent.key]: newEvent },
      sortedUserList: mergedUsers
    }, "onAddEvent");

    this.props.openEventModal(newEvent)

  };
  onAdNewScheduled = (e, sender) => {

    var key = shortid.generate()
    const newScheduel = { name: sender.value, value: key, key }
    this.props.addSchedule(newScheduel).then(() => {
      this.setState({ scheduleDropdownName: sender.value, scheduleDropdownKey: key })
      this.loadFireListner(key)
      this.setSchedule(key);

    })


  };

  onClickChangeDate2 = (date) => (event) => {
    var renderDayDate = moment.utc(date)
    this.props.setRenderDayDateSchedule(renderDayDate)
    this.loadFireListner(this.props.currentScheduelData.selectedKey, renderDayDate)
    this.setSchedule(this.props.currentScheduelData.selectedKey);
  }

  onClickChangeDate = (days) => () => {
    //   console.log('onClickChangeDate', days)
    var renderDayDate = moment.utc(this.props.currentScheduelData.renderDayDate).add(days, 'days')
    this.props.setRenderDayDateSchedule(renderDayDate)
    this.loadFireListner(this.props.currentScheduelData.selectedKey, renderDayDate)
    this.setSchedule(this.props.currentScheduelData.selectedKey);
  }
  preventDefault = event => () => {
    event.preventDefault();
  };
  onClickShowWeekPicker = () => {
    this.setState({ showWeekPicker: this.state.showWeekPicker ? false : true })
  }

  onClickToolsAddDivider = () => {
    var dividerId = 'div_' + shortid.generate()
    var selectedUsers = [...this.getCurScheduleView().selectedUsers];

    selectedUsers.unshift(dividerId); // add to list of selected but unsaved users/divs
    var mergedUsers = this.getMergedScheduleUserlistWithSelectedUsers(selectedUsers);


    this.updateCurScheduleView({ selectedUsers, sortedUserList: mergedUsers }, "onClickToolsAddDivider");
    this.setState({ openDivderModal: true, dividerKey: dividerId })
  }


  onClickToolsReload = () => {

    this.setSchedule(this.props.currentScheduelData.selectedKey, true);
  }
  onClickToolsCopy = () => {
    console.log('**onClickToolsCopy**')
    //const dateKey = this.getDateKey();
    const eList = this.props.eventsList || {}
    const uList = this.props.sortedUserList2 || {}
    const dList = this.props.dividerList || {}
    //get this schedule evetns 
    const eventsList = eList//[dateKey]
    const sortedUserList = uList//[dateKey]
    const dividerList = dList//[dateKey]

    if (collection.size(eventsList) > 0 || sortedUserList.length > 0) {
      console.log('eventsList', eventsList)
      console.log('---<>', { eventsList, sortedUserList, dividerList })
      this.setState({ copyObj: { eventsList, sortedUserList, dividerList } })
      toastr.success('', `Schedule is copied`);
    }
  }
  onClickToolsPaste = () => {
    console.log('**onClickToolsPaste schedual**')
    const curScheduleView = this.getCurScheduleView()
    const { eventsList, sortedUserList, dividerList } = this.state.copyObj
    const rDate = moment.utc(this.props.currentScheduelData.renderDayDate)

    var cloneList = {}
    if (eventsList) {
      collection.forEach(eventsList, function (shift) {

        var dayOfWeek = moment(shift.date)
        var renderDate = rDate.clone()
        var days = dayOfWeek.weekday()
        var newShift = { ...shift, date: +renderDate.add(days, 'days'), dirty: true, key: shortid.generate(), isNew: true }
        cloneList[newShift.key] = newShift
      })



      var scheduleEventsList = { ...curScheduleView.scheduleEventsList, ...cloneList }
      console.log('Clone', scheduleEventsList)

      this.updateCurScheduleView({ dirty: true, scheduleEventsList, sortedUserList, dividerList }, "onClickToolsPaste");
    }

  }
  onClickToolsPrint = () => {

  }
  onClickToolsClear = () => {
    var curScheduleView = { ...this.getCurScheduleView() };
    console.log('curScheduleView.scheduleEventsList', curScheduleView.scheduleEventsList)

    // const dateKey = this.getDateKey();
    // var eList = this.props.eventsList || {}
    // var eventsList = eList[dateKey]
    // eventsList = Object.keys(eventsList).map((key, index) => {
    //   var obj = eventsList[key]
    //   obj.removed = true;
    //   obj.dirty = true;
    //   return obj
    // })
    // curScheduleView.scheduleEventsList = eventsList

    collection.forEach(curScheduleView.scheduleEventsList,
      obj => {
        var o = { ...obj }
        try {
          o.removed = true;
          o.dirty = true;
        } catch (error) {
          console.log(error)
        }

        obj = o
        return o;
      }
    );
    curScheduleView.scheduleEventsList = {}
    // console.log('curScheduleView.scheduleEventsList', curScheduleView.scheduleEventsList)

    collection.forEach(curScheduleView.dividerList,
      obj => {
        var o = { ...obj }
        o.removed = true;
        o.dirty = true;
        obj = o
        return o;
      }
    );
    curScheduleView.dividerList = {}
    //TODO JENS fix so its empty on also when changing view
    this.updateCurScheduleView(
      {
        ...curScheduleView,
        selectedUsers: [],
        scheduleUserListRemoved: curScheduleView.scheduleUserList,
        scheduleUserList: [],
        sortedUserList: [],
        dirty: true
      },
      "onClickToolsClear"
    );
  }

  onClickToolsAddOpenShifts = () => {
    console.log('onClickToolsAddOpenShifts')

    var key = 'openshifts'
    var selectedUsers = [...this.getCurScheduleView().selectedUsers];

    selectedUsers.unshift(key); // add to list of selected but unsaved users/divs
    var mergedUsers = this.getMergedScheduleUserlistWithSelectedUsers(selectedUsers);


    this.updateCurScheduleView({ selectedUsers, sortedUserList: mergedUsers }, "onClickToolsAddOpenShifts");
    // this.setState({ openDivderModal: true, dividerKey: key })

  }
  onClickToolsAddUser = () => {
    //this.props.onOpenModal('user');
    this.props.onOpenModal('selectUsers')
  }
  onClickToolsViewTemplate = () => {
    this.props.toggelTemplates()
  }

  onSwipedLeft = () => {
    this.onClickChangeDate(7)()
    this.setState({ slideAni: 'slideAniLeft' })
  }
  onSwipedRight = () => {
    this.onClickChangeDate(-7)()
    this.setState({ slideAni: 'slideAniRight' })
  }
  animationEnd = (e) => {
    if (e.target.className === 'slideAniLeft') {
      this.setState({ slideAni: '' })
    }
    else if (e.target.className === 'slideAniRight') {
      this.setState({ slideAni: '' })
    }
  }
  openEditScheduleModal = (key, name) => {
    console.log('###>>openEditScheduleModal', typeof key === 'object')

    if (typeof key === 'object') {
      if (key.detail && key.detail.schedule) { this.props.openEditScheduleModal(key.detail.schedule.key, key.detail.schedule.name) }
      else { this.props.openEditScheduleModal(null, null) }
    }
    else { this.props.openEditScheduleModal(key, name) }
  }
  openSelectSchedualModal = () => { this.props.onOpenModal('selectschedules') }
  getPrintButton = () => {
    return (<PrintHelper trigger={() => <Menu.Item icon='print' />} content={() => this.componentRef} />)
  }

  editScheduleModuleOnRemove = () => {
    if (this.props.scheduleDropDownList) {
      var fistSchedule = this.props.scheduleDropDownList[0];
      if (fistSchedule) {
        this.setState({ scheduleDropdownName: fistSchedule.text, scheduleDropdownKey: fistSchedule.key });
        this.loadFireListner(fistSchedule.value)
        this.setSchedule(fistSchedule.value);
      }
    } else {
      this.setState({ scheduleDropdownName: undefined, scheduleDropdownKey: undefined, noSchedual: undefined, setInitState: true });


    }

  }
  editScheduleModuleOnSave = (key, name) => {
    this.setState({ scheduleDropdownName: name, scheduleDropdownKey: key })
    this.loadFireListner(key)
    this.setSchedule(key);
  }
  getClassNamesSchedule1 = () => {
    return this.state.slideAni + ' divSchedual1'
  }

  getAvailableUsersList = moize.deep((users, mergedUsers) => {
    // console.log('>>>memoizeOne>>>>>>getAvailableUsersList')
    return collection.filter(users, obj => {
      if (obj.act === false)
        return false

      return array.findIndex(mergedUsers, x => {
        if (!obj) obj = { key: null }
        return x === obj.key
      }) === -1;
    });
  }, { maxSize: 5 })


  getMergedScheduleUserlist2 = moize.deep((scheduel) => {
    // console.log('>>memoizeOne>>>>getMergedScheduleUserlist>>>>>>>>>>>>>')
    const selectedUsers = scheduel.selectedUsers;
    var userKeysFormEventList = this.getScheduleUserlistKeysWithActiveEvents(scheduel.scheduleEventsList); // get users from events
    var sortedUsers = scheduel.sortedUserList || []; //TODO this shude not be null its miss its default value ?
    var userKeyList = array.uniq([...sortedUsers, ...userKeysFormEventList, ...selectedUsers], "uid");

    if (false) {
      console.log('**selectedUsers', selectedUsers)
      console.log('**userKeysFormEventList', userKeysFormEventList)
      console.log('**sortedUsers', sortedUsers)
      console.log('**userKeyList', userKeyList)
      console.log('********************')
    }

    return userKeyList;
  }, { maxSize: 5 })


  doOnce = true
  render() {
    // console.log('schedule>RENDER> ');

    var { project } = this.props


    const curScheduleView = this.getCurScheduleView();



    var listnerKey = this.listnerKey(curScheduleView.key, curScheduleView.renderDayDate);// `projects/${this.props.user.projectKey}/schedules/${key}/events/${dateKey}` 
    var isLoadingEvents = (this.props.listnersStatusRequested[listnerKey] === false && this.props.listnersStatusRequesting[listnerKey] === true)


    // if (curScheduleView && curScheduleView.isEmpty && collection.size(curScheduleView.scheduleEventsList) === 0 && this.props.listnersStatusRequested[listnerKey] === true && this.props.listnersStatusRequesting[listnerKey] === false)
    //   console.log('IM EMPTY FOR REAL!', curScheduleView.isEmpty);


    const { scheduleDropdownName, scheduleDropdownKey } = this.state;
    // console.log('scheduleDropdownName', scheduleDropdownName)
    const { users, scheduleDropDownList, haveData, currentScheduelData, isLandscape } = this.props;
    var updatedAt = moment.utc(new Date());

    if (curScheduleView.selectedSchedule && curScheduleView.selectedSchedule.updatedAt) {
      updatedAt = moment.unix(curScheduleView.selectedSchedule.updatedAt.seconds);
    }

    var mergedUsers = this.getMergedScheduleUserlist(); //this.state.currentScheduleData.userList;

    //console.log('>>getAvailableUsersList')
    const availableUsersList = this.getAvailableUsersList(users, mergedUsers)

    //this list is for the user dropdown

    // if (haveData === false) {
    //   //this.setState({ok:true})
    //   return('<span>xxx</span>')
    //   return (

    //     <Dimmer active inverted >
    //       <div style={{ alignContent: 'center', alignItems: 'center', }}>
    //         <Loader  >
    //           <h2>
    //             {window._getText('loading')}
    //           </h2>
    //         </Loader>
    //       </div>
    //     </Dimmer>

    //   );
    // } 



    if (haveData === true && window._doOnce === true) {
      // console.log('onAppReady dispatch')
      //setTimeout(() => {
      window._doOnce = false;

      // this.setState({})
      //  }, 900);

      // window.dispatchEvent(new CustomEvent("onAppReady", { detail: { } }));
      // setTimeout(() => {
      if (isElectronApp) {
        ipcRenderer.send('hidesplash', 'schedual')
      }
      else { window._hidesplash = true }
      // }, 500);

    }



    if (project && project.disabled === true)
      return <Locked />


    return (
      <React.Fragment>
        {/* {window._doOnce === true &&
          <div style={{ xtransition: 'opacity 0.3s ease, transform 0.3s ease', opacity: '0.99', background: '#FFF', position: 'fixed', top: '0px', left: '0px', bottom: '0px', right: '0px', zIndex: '1500' }}>
            <div className='cc'>
              <img alt='TimeToWork' src='/128x128.png' />
            </div>
          </div>
        } */}


        <ScheduleMenu
          isLandscape={isLandscape}
          onClickToolsViewTemplate={this.onClickToolsViewTemplate}
          firstDayOfWeekNumber={this.props.project.firstDay}
          onClickToolsAddUser={this.onClickToolsAddUser}
          onClickToolsAddOpenShifts={this.onClickToolsAddOpenShifts}
          onClickToolsAddDivider={this.onClickToolsAddDivider}
          onClickToolsReload={this.onClickToolsReload}
          onClickToolsCopy={this.onClickToolsCopy}
          onClickToolsPaste={this.onClickToolsPaste}
          onClickToolsClear={this.onClickToolsClear}
          onClickToolsPrint={this.onClickToolsPrint}
          onClickShowWeekPicker={this.onClickShowWeekPicker}
          onClickChangeDate={this.onClickChangeDate}
          onClickChangeDate2={this.onClickChangeDate2}
          showWeekPicker={this.state.showWeekPicker}
          curScheduleView={curScheduleView}
          onSaveSchedule={this.onSaveSchedule}
          onAdNewScheduled={this.onAdNewScheduled}
          onChangeSchema={this.onChangeSchema}
          currentScheduelData={currentScheduelData}
          scheduleDropdownName={scheduleDropdownName}
          scheduleDropdownKey={scheduleDropdownKey}
          scheduleDropDownList={scheduleDropDownList}
          openEditScheduleModal={this.openEditScheduleModal}
          openSelectSchedualModal={this.openSelectSchedualModal}
          getPrintButton={this.getPrintButton()}
        />





        {/* <Swipe
          delta={100}
          className='swipeDiv'
          onSwipedLeft={this.onSwipedLeft}
          onSwipedRight={this.onSwipedRight}
        > */}
        <div className={this.getClassNamesSchedule1()} onAnimationEnd={this.animationEnd}>
          {haveData === true &&
            <Schedule1
              showWeekPicker={this.state.showWeekPicker}
              schedules={this.props.schedules}
              currentScheduelData={currentScheduelData}

              ref={el => (this.componentRef = el)}
              isLoadingEvents={isLoadingEvents}

              renderDayDate={currentScheduelData.renderDayDate}
              renderDayCount={this.state.renderDayCount}

              setSortedUserList={this.setSortedUserList}
              onRemoveTemplate={this.onRemoveTemplate}
              onRemoveRow={this.onRemoveRow}
              onRemoveEvent={this.onRemoveEvent}
              onMoveEvent={this.onMoveEvent}
              onAddTemplateShift={this.onAddTemplateShift}
              mergedUsers={mergedUsers}
              // availableUsers={availableUsersList}
              //onChangeUser={this.onChangeUser}
              //onAddNewUseFromDropdown={this.onAddNewUseFromDropdown}
              onAddEvent={this.onAddEvent}
              currentScheduleData={curScheduleView}
              openDivierModal={this.openDivierModal}
            />
          }
        </div>
        {/* </Swipe> */}

        {/* <Dimmer active={this.props.currentScheduelData.isLoading} inverted>
            <Loader>Saving..</Loader>
          </Dimmer> */}


        {this.renderChangeDivierModal()}
        <EditScheduleModule onSave={this.editScheduleModuleOnSave} onRemove={this.editScheduleModuleOnRemove}  ></EditScheduleModule>
        {!this.props.isOpen_EditEventModal && <SelectUsers canCreateNew={true} name='selectuser_schedual' availableUsers={availableUsersList} onAddSelectedUsers={this.onAddUserToSchedule} ></SelectUsers>}
        <SelectSchedules onChangeSchema={this.onChangeSchema2}></SelectSchedules>

        <Label className='lblLastChange noPrint'>
          {window._getText('schedual.last')} : {getLocalDate(updatedAt, "LLLL")}
        </Label>


        {<EditEventModal ></EditEventModal>}
        <ShiftAdminRequest></ShiftAdminRequest>



      </React.Fragment>
    );
  }




  // -------------- Dvider start ---------------------------
  openDivierModal = (key, value) => {
    this.setState({ openDivderModal: !this.state.openDivderModal, dividerKey: key, divierModalNameValue: value })
  }
  onClickDeleteDevider = () => {
    const key = this.state.dividerKey
    if (key)
      this.onRemoveRow(key)

    this.setState({ openDivderModal: false })
  }
  onClickCloseDevider = () => {
    this.setState({ openDivderModal: false })
  }
  onChangeDivierModal = (e) => {
    this.setState({ divierModalNameValue: e.target.value });
  }
  onClickSaveDevider = () => {
    const name = this.state.divierModalNameValue
    const key = this.state.dividerKey
    if (name && name.length > 1) {
      const curScheduleView = this.getCurScheduleView()

      if (!curScheduleView.dividerList[key])
        curScheduleView.dividerList[key] = { key, name, dirty: true }
      else {
        curScheduleView.dividerList[key] = { ...curScheduleView.dividerList[key], name, dirty: true }
      }
      this.updateCurScheduleView({ dirty: true, dividerList: { ...curScheduleView.dividerList } }, "onClickSaveDevider");
    }
    this.setState({ openDivderModal: false, divierModalNameValue: '' })

  }


  renderChangeDivierModal = () => {

    return (
      // onClose={() => { this.setState({ openDivderModal: false }) }}
      <ModalFrame name='addDivider' dimmer='inverted' size='mini' open={this.state.openDivderModal} onClickClose={this.onClickCloseDevider} >

        < ModalFrame.Content >
          <div style={{ fontWeight: 'bold' }}>
            Add divider
        </div>
          {/* <label>{window._getText('editdivider.title')}</label> <br /> */}
          <Input placeholder={window._getText('editdivider.title')} value={this.state.divierModalNameValue} onChange={this.onChangeDivierModal} />
        </ModalFrame.Content >
        <ModalFrame.Actions>
          <div style={{ display: 'flex', }}>
            <div>
              <Button onClick={this.onClickDeleteDevider} icon='trash' content='Remove' ></Button>
            </div>
            <div style={{ marginLeft: 'auto', }}>
              <Button icon='checkmark' content='Add' onClick={this.onClickSaveDevider} />
            </div>
          </div>
        </ModalFrame.Actions>
      </ModalFrame >)
  }

  // -------------- Dvider stop ---------------------------
}


const getScheduleDropdown = createSelector(
  state => state.firestore.data.schedules,
  state => state.firebase.profile.projectKey,
  (schedules) => {
    var scheduleDropDownList = [];
    if (schedules) {
      // console.log('grenerate scheduleDropDownList')
      //TODO MOVE THIS MAP AND ONLY REDO IT WHEN ITS UPDATED !
      scheduleDropDownList = Object.keys(schedules).map(key => {
        if (!schedules[key])
          return null

        return { text: schedules[key].name, key: key, value: key };
      });

      scheduleDropDownList = array.without(scheduleDropDownList, null) //MOVE THIS ONLY DO WHEN NEEDED
      scheduleDropDownList.sort((a, b) => { return a.text > b.text })//MOVE THIS ONLY DO WHEN NEEDED

      return scheduleDropDownList || []
    }
  })



const getEventObject = createSelector(
  state => state.firestore.data,
  state => state.currentScheduelData.selectedKey,
  state => state.currentScheduelData.renderDayDate,
  (data, selectedKey, renderDayDate) => {

    var dateKey = getDateKey(renderDayDate);
    return data[selectedKey + '_' + dateKey] || { events: {}, sortedUserList: [] };


  })

const mapStateToProps = (state, ownProps) => {

  //TODO MOVE TO DID UPDATE ? OR MAKE SURE WE DONT RECALULATE DATA THA IS NOT CHANGED!

  var userList = [];
  //var userFullList = [];
  var scheduleDropDownList = [];
  var schedules = {};
  var eventsList = {};
  var dividerList = {};
  var sortedUserList2 = {};
  var haveData = false;
  var isLoadedSchedule = false;

  const currentProjectKey = state.firebase.profile.projectKey || "";


  if (state.firestore.data.users && currentProjectKey) {
    var userSelector = FireHelper.getUserlist(state.firestore.data.users, state.firebase.profile.projectKey)
    userList = userSelector.userList
    userList = userList.filter(u => u.act === true)
    //userFullList = userSelector.userFullList
  }
  //SCHEDULES
  //console.log('state.firestore.data.schedules>>>>>>>>', state.firestore.data.schedules)
  if (state.firestore.data.schedules) {
    schedules = state.firestore.data.schedules;
  }
  // //TODO MOVE THIS MAP AND ONLY REDO IT WHEN ITS UPDATED !
  // scheduleDropDownList = Object.keys(schedules).map(key => {
  //   if (!schedules[key])
  //     return null

  //   return { text: schedules[key].name, key: key, value: key };
  // });

  // scheduleDropDownList = array.without(scheduleDropDownList, null) //MOVE THIS ONLY DO WHEN NEEDED
  // scheduleDropDownList.sort((a, b) => { return a.text > b.text })//MOVE THIS ONLY DO WHEN NEEDED
  scheduleDropDownList = getScheduleDropdown(state)

  //}




  //EVENTS
  var dateKey = getDateKey(state.currentScheduelData.renderDayDate);//moment.utc(state.currentScheduelData.renderDayDate).add(6, 'days').format("YYYY-WW")
  var eventObject = getEventObject(state)
  // state.firestore.data[state.currentScheduelData.selectedKey + '_' + dateKey] || {events: {}, sortedUserList: [] };
  //console.log('eventObject',eventObject)
  eventsList = eventObject.events //we culd user cache herer insted
  sortedUserList2 = eventObject.sortedUserList || []
  dividerList = eventObject.dividerList || {}

  if (state.firestore.status.requested[`projects/${currentProjectKey}/users`] && state.firestore.status.requested[`projects/${currentProjectKey}`])
    haveData = true;

  if (state.firestore.status.requested[`projects/${currentProjectKey}/schedules`])
    isLoadedSchedule = true;


  // if (isLoadedSchedule === true && scheduleDropDownList.length === 0) {
  //   //MOVE THIS ONLY DO WHEN NEEDED
  //   const newKey = 'NEW-TEMP-ID';
  //   const tempObj = { text: 'Schedule 1', key: newKey, value: newKey }
  //   scheduleDropDownList.push(tempObj)
  //   schedules[newKey] = { key: newKey, name: tempObj.text, sortedUserList: [], updatedAt: new Date() }
  // }



  //console.log('state.currentScheduelData', state.currentScheduelData)

  var curScheduleView = state.currentScheduelData[state.currentScheduelData.selectedKey + '_' + dateKey];
  if (!curScheduleView)
    curScheduleView = DataHelper.initSchedule(state.currentScheduelData.selectedKey, dateKey)



  const metaData = state.firestore.data['weekview_' + dateKey + '_meta'] || {}
  var newMetaData = metaData[state.currentScheduelData.selectedKey]  // getMetaData(metaData, state.currentScheduelData.selectedKey)

  //console.log('>>>>>schedules', schedules)

  return {
    isOpen_CopyScheduleModal: state.modal.copyShedualModalOpen,

    isOpen_AppMenu: state.modal.modals['appMenu'].open || false,
    isOpen_EditEventModal: state.modal.eventModalOpen || false,
    isOpen_EditShedualModalOpen: state.modal.editShedualModalOpen || false,

    // isOpen_UserList: state.modal.modals.selectUsers || false,
    project: state.firestore.data.project,
    isLoadedSchedule,
    currentProjectKey,
    sortedUserList2,
    listners: state.firestore.listeners.byId,
    listnersStatusRequesting: state.firestore.status.requesting,
    listnersStatusRequested: state.firestore.status.requested,
    //isReadyEvents: state.firestore.status.requested[`projects/${currentProjectKey}/events`], REMOVE THIS
    //isReadyUsers: state.firestore.status.requested[`projects/${currentProjectKey}/users`], REMOVE THIS
    //isReadyScheduels: state.firestore.status.requested[`projects/${currentProjectKey}`], REMOVE THIS
    haveData,
    //firestore: state.firestore, DO WEE NEED THIS ?  REMOVE THIS
    currentScheduelData: state.currentScheduelData,
    user: state.user, //current user
    //userFullList, // this needs to change with userList
    users: userList, // all users
    scheduleDropDownList: scheduleDropDownList, // dd List of scheduels
    schedules,
    eventsList: eventsList,
    dividerList,
    isLandscape: state.viewstate.isLandscape,
    curScheduleView,
    viewstateRenderDate: state.viewstate.renderDate,
    scheduleWeekDataMeta: newMetaData,
    AmPm: state.firestore.data.project.s ? state.firestore.data.project.s.AmPm || false : false

  };
};
const mapActionsFromProps = {
  onCloseModal: closeEventModal,
  onOpenModal: openModal,
  addSchedule,
  addevent,
  updateEventBatch,
  updateEventBatch2,
  getCurrentScheduleData: getSchedule,
  setCurrentScheduleData: setSchedule,
  onCopyScheduleShift: copyScheduleShift,
  setRenderDayDateSchedule: setRenderDayDateSchedule,
  openEventModal,
  openEditScheduleModal,
  toggelTemplates
};

export default connect(
  mapStateToProps,
  mapActionsFromProps
)(Schedule);
