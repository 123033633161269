import React from "react";
import ReactDOM from "react-dom";
//import messaging from 'firebase/messaging'
//import messaging from 'firebase/messaging';
//import firebase from './app/config/firebase';


import { BrowserRouter } from "react-router-dom";
import ReduxToastr from "react-redux-toastr";
//import "semantic-ui-css/semantic.min.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { isMobile } from 'react-device-detect'; //isIOS
//import { initializePush } from './initializePush';
import './css/semantic.css'
import "./css/index.css"
import "./css/schedual1.css"
import './css/macos.css'
import './css/semanticoveride.css'
import './css/colors.css'
import './css/schedual.css'
import './css/print.css'
import './css/login.css'
import './css/slider.css'
import './css/mobile.css'
import './css/toastr.css'
import './css/noprint.css'



import App from "./App";
import ReactGA from 'react-ga';
import './cordo'
//
//import registerServiceWorker from "./registerServiceWorker";
import * as serviceWorker from './serviceWorker';

import ScrollToTop from "./app/common/util/ScrollToTop";

import { Provider } from "react-redux";
import { configStore } from "./app/redux/store/configStore";
import { init, setLang } from './app/common/util/Lang'
// import initReactFastclick from 'react-fastclick';
// initReactFastclick();


// class PleaseUseApp extends React.Component {

//   render() {
//     const image = require('./img/ios.png')
//     return <div style={{ padding: '40px', textAlign: 'center' }}>

//       For Ipads and Iphones <br />
//       please use the app from the app store<br /><br />
//       <img alt='Download app' src={image} />

//     </div>;
//   }
// }


window._baseUrl = process.env.PUBLIC_URL || '.'



const store = configStore();

if (module.hot) {
  module.hot.accept("./App", () => {
    setTimeout(render);
  });
}


let isElectron = false;
if (process.env.REACT_APP_ELECTRON) {
  //   const electron = window.require("electron")
  //   try {
  //     var currentWindow = electron.remote.getCurrentWindow();
  //     console.log('currentWindow.rootUrl', currentWindow.rootUrl);
  //   } catch (error) {
  //     console.log(error)
  //   }

  isElectron = true
}



// console.log('Im a Electron app ', isElectron)



let render = () => {
  if (isMobile)
    document.getElementsByTagName('body')[0].classList.add('mobile')

  // if (isMobile && isIOS && isElectron === false) {
  //   ReactDOM.render(<PleaseUseApp />, document.getElementById("root"))
  // }
  // else {
  ReactDOM.render(

    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop>
          <ReduxToastr
            timeOut={2000}
            preventDuplicates
            position="bottom-center"
            closeOnToastrClick={true}

          />
          <App />
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
    ,
    document.getElementById("root")
  );
}
//};




const startApp = () => {
  init();
  //setLang('sv')
  setLang('en')
  render();
  ReactGA.initialize('UA-127758857-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  if (isElectron === false)
    serviceWorker.register();
  // registerServiceWorker();
}



if (window.cordova) {
  document.addEventListener('deviceready', startApp, false);
} else {
  startApp();
}


// if ('serviceWorker' in navigator) {
//   //
// console.log(`${process.env.PUBLIC_URL || '.'}/firebase-messaging-sw.js`)
//   navigator.serviceWorker.register(`${process.env.PUBLIC_URL || '.'}/firebase-messaging-sw.js`)
//     .then(function (registration) {
//       // console.log('firebase', firebase.messaging)
//       // console.log('messaging', messaging)

//       try {
//         firebase.messaging().useServiceWorker(registration);
//       } catch (error) {
//         console.log(error)
//       }


//       initializePush()

//       console.log("!!!!!!This push service worker is registered!!!!!!!!")
//     }).catch(function (err) {
//       console.log('Service worker registration failed, error:', err);
//     });
// }


// console.log('-------------')
// Notification.requestPermission(function (status) {
//   console.log('Notification permission status:', status);
// });
// console.log('-------------')

// const displayNotification = () => {
//   console.log('Notification.permission', Notification.permission)
//   if (Notification.permission == 'granted') {
//     // navigator.serviceWorker.getRegistration().then(function (reg) {
//     //   reg.showNotification('Hello world!');
//     // });
//   }
// }
// displayNotification()