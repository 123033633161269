import React, { Component, Suspense } from "react";
import PropTypes from 'prop-types'
import ReactSvgPieChart from "react-svg-piechart"

import { Image, Icon, Loader } from "../../common/component/Button";
import { openEventModal } from "../../redux/action/modalAction";

import { connect } from 'react-redux'
import { time24hToAmPm, getUserImage, getDateKey, getLocalDateStr2, getTimeFromMins } from '../../common/util/helpers'
import FirestoreListnerHelper from '../../common/util/FirestoreListnerHelper'
import EditEventModal from "../modal/EditEventModal";



import noUserImage from '../../../img/noimg.png'
import moment from 'moment';
import moize from "moize";
import _Math from "lodash/math";
// import DownloadHoursExcel from '../../common/component/DownloadHoursExcel'
// import DownloadHoursExcelAll from '../../common/component/DownloadHoursExcelAll'

import { DownloadHoursExcel, DownloadHoursExcelAll } from '../router/Loader'//

var isElectronApp = (process.env.REACT_APP_ELECTRON === '1')
let ipcRenderer
isElectronApp = !!window.require //TEMP
if (isElectronApp) {
  ipcRenderer = window.require('electron').ipcRenderer
}

// const DownloadHoursExcelAll = React.lazy(() => import(/*
//   webpackChunkName: "report",
//   webpackPrefetch: true
// */ '../../common/component/DownloadHoursExcelAll'))

// const DownloadHoursExcel = React.lazy(() => import(/*
//   webpackChunkName: "report",
//   webpackPrefetch: true
// */ '../../common/component/DownloadHoursExcel'))




// const getSum = moize((userEvents) => {
//   if (userEvents) {
//     var minutes2 = math.sumBy(userEvents, function (o) {
//       if (o.removed)
//         return 0
//       if (!o.minutes)
//         return 0
//       return Number(o.minutes)
//     });
//     // console.log('redner cal',getTimeFromMins(minutes2))
//     return getTimeFromMins(minutes2);
//     //this.setState({ minutes: getTimeFromMins(minutes2) })
//   }
// })
const flatMap = moize.deep((scheduleWeekData, projectKey, cache, month, AmPm) => {


  let users = {}

  for (const scheduleWeekDataItem of scheduleWeekData) {
    const dataArray = scheduleWeekDataItem.data
    const rDate = scheduleWeekDataItem.date
    for (const shedualKey of Object.keys(dataArray)) {
      const schedual = dataArray[shedualKey]
      for (const row of schedual.rows) {
        if (!users[row.k])
          users[row.k] = []


        for (const shiftKey of Object.keys(row.s)) {
          const shift = row.s[shiftKey]
          const newDate = rDate.clone().add(shiftKey - 1, 'days')
          const date = newDate.format('YYYY-MM-DD')
          const dStr = getLocalDateStr2(newDate)


          if (newDate.month() === month) {
            for (const shiftItem of shift) {
              var time = ''
              var breakx = getTimeFromMins(shiftItem.b)
              var hours = getTimeFromMins(shiftItem.m)
              var scheduleName = (cache[shedualKey] || {}).n || '#unknown'
              var sik = shiftItem.sik === true ? true : false
              if (AmPm) {
                time = time24hToAmPm(shiftItem.t1) + '-' + time24hToAmPm(shiftItem.t2)
              }
              else {
                time = shiftItem.t1 + ' - ' + shiftItem.t2
              }

              const newShiftdata = { ...shiftItem, date, shedualKey, dStr, time, break: breakx, hours, scheduleName, sik }
              users[row.k].push(newShiftdata)
            }
          }
        }

      }

    }
  }

  var newUserList = []
  for (const userKey of Object.keys(users)) {
    if (userKey !== 'openshifts' && !userKey.startsWith('div_')) {
      const userData = users[userKey]
      const userInfo = cache[userKey]
      const mins = _Math.sumBy(userData, function (o) {
        //if(o.sick)
        return o.m || 0
      })

      // if (userKey === 'cjoqevtbf00033r5xvjxcwpwu')
      //   console.log('!userInfo', userInfo)



      if (userInfo)
        newUserList.push({
          hours: getTimeFromMins(mins),
          image: userInfo.i ? getUserImage(projectKey, userKey, userInfo.i) : noUserImage,
          name: userInfo.n,
          key: userKey,
          active: userInfo.a,
          data: userData.sort((a, b) => {
            if (a.date < b.date) return -1
            if (a.date > b.date) return 1
            return 0
          })
        })
    }

  }

  return newUserList
}, { maxSize: 5 })

class Reports extends Component {
  static contextTypes = { store: PropTypes.object.isRequired }

  state = { cbShowOnlyActiveUsers: false, rDate: moment().startOf('month') }

  styles = {
    meny: { border: 'none', borderBottom: 'solid 1px #f1f1f1', position: 'fixed', height: '50px', zIndex: '10' },
    input1: { width: '200px' },
    width160: { width: '125px' },
    s1: { paddingLeft: '5px', paddingRight: '5px' },
    s2: { border: 'none', paddingTop: '55px' },
  }


  loadData = (rDateStr) => {

    const cache = this.props.project.cache || {}
    const AmPm = this.props.AmPm

    const d = moment(rDateStr)
    const month = d.month()
    const date = d.weekday(0)
    const dkey = getDateKey(date)
    const loaded = this.props.fireLoaded[`projects/${this.props.projectKey}/weekview/${dkey}`]
    const scheduleWeekData = this.props.fireData['weekview_' + dkey] || {}

    const date2 = date.clone().add(1, 'week')
    const dkey2 = getDateKey(date2)
    // console.log('dkey2', dkey2)
    const loaded2 = this.props.fireLoaded[`projects/${this.props.projectKey}/weekview/${dkey2}`]
    const scheduleWeekData2 = this.props.fireData['weekview_' + dkey2] || {}

    const date3 = date.clone().add(2, 'week')
    const dkey3 = getDateKey(date3)
    //console.log('dkey3', dkey3)
    const loaded3 = this.props.fireLoaded[`projects/${this.props.projectKey}/weekview/${dkey3}`]
    const scheduleWeekData3 = this.props.fireData['weekview_' + dkey3] || {}

    const date4 = date.clone().add(3, 'week')
    const dkey4 = getDateKey(date4)
    //console.log('dkey4', dkey4)
    const loaded4 = this.props.fireLoaded[`projects/${this.props.projectKey}/weekview/${dkey4}`]
    const scheduleWeekData4 = this.props.fireData['weekview_' + dkey4] || {}

    const date5 = date.clone().add(4, 'weeks')
    const dkey5 = getDateKey(date5)
    //console.log('dkey4', dkey4)
    const loaded5 = this.props.fireLoaded[`projects/${this.props.projectKey}/weekview/${dkey5}`]
    const scheduleWeekData5 = this.props.fireData['weekview_' + dkey5] || {}

    const date6 = date.clone().add(5, 'weeks')
    const dkey6 = getDateKey(date6)
    //console.log('dkey4', dkey4)
    const loaded6 = this.props.fireLoaded[`projects/${this.props.projectKey}/weekview/${dkey6}`]
    const scheduleWeekData6 = this.props.fireData['weekview_' + dkey6] || {}

    // console.log('--------------')
    // console.log('date', date.format('YYYY-MM-DD hh:mm'))
    // console.log('date2', date2.format('YYYY-MM-DD hh:mm'))
    // console.log('date3', date3.format('YYYY-MM-DD hh:mm'))
    // console.log('date4', date4.format('YYYY-MM-DD hh:mm'))
    // console.log('date5', date5.format('YYYY-MM-DD hh:mm'))
    // console.log('date6', date6.format('YYYY-MM-DD hh:mm'))
    // console.log('--------------')





    FirestoreListnerHelper.setWeekViewListner(this.context, date)
    FirestoreListnerHelper.setWeekViewListner(this.context, date2)
    FirestoreListnerHelper.setWeekViewListner(this.context, date3)
    FirestoreListnerHelper.setWeekViewListner(this.context, date4)
    FirestoreListnerHelper.setWeekViewListner(this.context, date5)
    FirestoreListnerHelper.setWeekViewListner(this.context, date6)

    if (loaded && loaded2 && loaded3 && loaded4 && loaded5 && loaded6) {
      let r = []
      r = flatMap([{ data: scheduleWeekData, date: date }, { data: scheduleWeekData2, date: date2 }, { data: scheduleWeekData3, date: date3 }, { data: scheduleWeekData4, date: date4 }, { data: scheduleWeekData5, date: date5 }, { data: scheduleWeekData6, date: date6 }], this.props.projectKey, cache, month, AmPm)
      r = r.sort((a, b) => {
        if (!a.name || !b.name)
          return 0

        const aName = (a.name || '').toLowerCase()
        const bName = (b.name || '').toLowerCase()

        if (aName < bName) return -1
        if (aName > bName) return 1
        return 0
      })
      return r
    }


    return null
  }
  tbSearchChange = e => {
    this.setState({ searchText: e.target.value, userKey: null });
  };
  onClickOpenShift = (shift) => (e) => {

    var target = e.target
    var isRoot = false
    var shiftWidth = 500
    if (!target) {
      target = document.getElementById('root')
      isRoot = true
    }
    var { x, y, width, height, top, left, right } = target.getBoundingClientRect()
    x = left
    y = top
    if (isRoot === true) {
      x = width * .5 + (shiftWidth * .5)
      left = width * .5 + (shiftWidth * .5)
    }

    if (ipcRenderer)
      ipcRenderer.send('renderWindowTargetPos', { x, y, width, height, top, left, right, showArrow: !isRoot })

    this.props.openEventModal({ doLoad: { key: shift.k, shedualKey: shift.shedualKey, dateStr: shift.date } })
    e.stopPropagation()
  }
  toggleView = (key) => () => {
    console.log('key', key)
    this.setState({ userKey: key })
  }
  onClickChangeDate = (month) => () => {
    this.setState({ rDate: this.state.rDate.add(month, 'month') })
  }

  onDownloadReport = (data) => (e) => {
    //onDownloadReport
    e.preventDefault()
    // console.log('data', data)


  }

  getPie = (sumHours, sumHoursSick) => {

    const data = [
      { label: "Worked", title: "Worked", value: sumHours, color: "rgb(251, 189, 8)" },
      { label: "Sick", title: "Sick", value: sumHoursSick, color: "#E8E8E8" },
    ]

    return < ReactSvgPieChart sectorStrokeWidth={2}
      expandOnHover
      shrinkOnTouchEnd data={data} />
  }

  render() {
    // const { AmPm } = this.props
    //  const cache = this.props.project.cache || {}

    var userlist = this.loadData(this.state.rDate.format('YYYY-MM-DD'))
    var { userKey, searchText } = this.state
    var reportData = (userlist || []).find(u => u.key === userKey)




    if (userlist) {



      userlist = userlist.sort((a, b) => {

        if (!a.name || !b.name)
          return 0

        const aName = (a.name || '').toLowerCase()
        const bName = (b.name || '').toLowerCase()

        if (aName < bName) return -1
        if (aName > bName) return 1
        return 0
      })


      if (searchText && searchText !== '') {
        userlist = userlist.filter(item => {
          console.log('??', item)
          if (!item)
            return null

          return (item.name || '').toLowerCase().indexOf(searchText) > -1
        })
      }

    }

    if (!userKey && userlist) {
      reportData = userlist[0]
      if (reportData)
        userKey = reportData.key
    }


    console.log('moment.locale', moment.locale())

    var sumHours = 0
    var sumHoursSick = 0
    return (

      <React.Fragment>


        <div style={{ flexDirection: 'row', display: 'flex', height: '100%', width: '100%' }}>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', border: 'solid 0px red', display: 'flex', flexGrow: '1', alignItems: 'stretch' }}>
            <div style={{ maxWidth: '250px', minWidth: '250px', background: 'rgba(204,204,204,0.1)', borderRight: 'solid 1px var(--color-border)' }}>
              <div style={{ display: 'flex', paddingTop: '12px', paddingBottom: '12px', paddingLeft: '14px', paddingRight: '5px', borderBottom: 'solid 1px var(--color-border)', color: '#000', height: '50px', overflow: 'hidden', }}>

                <input
                  className="form-control"
                  icon="search"
                  value={this.state.searchText}
                  placeholder={window._getText('search')}
                  onChange={this.tbSearchChange}
                  style={{ height: '10px', marginRight: '8px' }}
                />


              </div>
              <div style={{ overflowY: 'auto', overflowX: 'hidden', height: 'calc(100% - 50px)', border: 'solid 0px green' }}>
                <div style={{}}>


                  {userlist && userlist.map(u => {

                    if (u.active === true)
                      return (
                        <div key={u.key} style={{ borderLeft: userKey === u.key ? 'solid 3px rgb(251, 189, 8)' : 'solid 3px transparent', background: userKey === u.key ? 'rgba(204, 204, 204, 0.3)' : '', borderRadius: '0px', borderBottom: 'dotted 1px var(--color-border)', margin: '0px', padding: '7px' }}>
                          <div onClick={this.toggleView(u.key)} style={{ cursor: 'pointer', }}>
                            <div key={u.key} style={{ display: 'flex' }}>


                              <div style={{ flexGrow: '1', border: 'solid 0px red', marginRight: '5px', overflow: 'hidden' }}>
                                <Image circular size='mini' floated='left' src={u.image} alt='' style={{ margin: '0px', marginRight: '8px' }} />
                                <b>{u.name}</b>
                                <br />
                                <span style={{ fontSize: '12px', }}> {u.data && u.data.length} shifts</span>
                              </div>

                              <div style={{ marginRight: '5px', }}>
                                <b style={{ color: '#888' }}>{u.hours}h</b>
                              </div>

                            </div>
                          </div>



                        </div>
                      )
                    else
                      return ''
                  })}
                </div>




              </div>
              {/* <div style={{ fontSize: '9px', flexDirection: 'row', display: 'flex', paddingLeft: '14px', padding: '5px', borderTop: 'solid 1px var(--color-border)', color: '#000', overflow: 'hidden', }}>
                <div style={{ flexGrow: '1' }}>
                  Active users: 22
                </div>
                <div>Hours:343</div>

              </div> */}
            </div>

            <div style={{ width: 'calc(100% - 250px)', flexGrow: '1', display: 'flex', flexDirection: 'column' }}>




              <React.Fragment>
                <div style={{ borderBottom: 'solid 1px var(--color-border)', paddingLeft: '0px', minHeight: '50px', height: '50px', overflow: 'hidden', display: 'flex', flexDirection: 'column' }} >
                  <div style={{ border: 'solid 0px blue', display: 'flex', flexDirection: 'row', paddingLeft: '10px', paddingTop: '8px', paddingRight: '10px', }}>

                    <div style={{ width: '200px', flexDirection: 'row', display: 'flex' }}>
                      <div onClick={this.onClickChangeDate(-1)} >
                        <Icon style={{ fontSize: '20px', paddingTop: '5px' }} name='left angle'></Icon>
                      </div>
                      <div style={{ padding: '0px', flexGrow: '1' }} >
                        <div className='topMenuWeekDateMainDiv' >
                          <h2>
                            {window._getText(this.state.rDate.local().format("MMM"))} {this.state.rDate.format('YYYY')}
                          </h2>
                        </div>
                      </div>
                      <div onClick={this.onClickChangeDate(1)} >
                        <Icon style={{ fontSize: '20px', paddingTop: '5px' }} name='right angle'></Icon>
                      </div>
                    </div>
                    <div style={{ flexGrow: '1' }}></div>
                    <div >

                      {reportData &&
                        <Suspense>
                          <DownloadHoursExcel dataSet={reportData.data} username={reportData.name} filename={reportData.name + '_' + this.state.rDate.local().format("YYYYMM")}></DownloadHoursExcel>
                        </Suspense>
                      }

                    </div>

                  </div>
                </div>
                <div style={{ paddingTop: '10px', border: 'solid 0px red', display: 'flex', flexDirection: 'column', flexGrow: '1' }}>
                  <div style={{ flexGrow: '1', overflowX: 'hidden', overflowY: 'auto', height: 'calc(100%-360px)', paddingLeft: '10px', paddingRight: '10px' }}>


                    <table className='tblReport' style={{ marginBottom: '10px' }}>
                      <thead>
                        <tr style={{ borderBottom: 'solid 1px var(--color-border)' }}>
                          <th style={{ border: '0px ', width: '30px' }}></th>
                          <th style={{ border: 'red solid 0px', textAlign: 'left', fontWeight: 'bold' }}>
                            {window._getText('date')}
                          </th>
                          <th className='hideOnSmallScreen' style={{ border: 'red solid 0px', textAlign: 'center', fontWeight: 'bold' }}>
                            {window._getText('time')}
                          </th>
                          <th className='hideOnSmallScreen' style={{ border: 'red solid 0px', textAlign: 'center', fontWeight: 'bold' }}>
                            {window._getText('break')}
                          </th>
                          <th style={{ border: 'red solid 0px', textAlign: 'center', fontWeight: 'bold' }}>
                            {window._getText('hours')}
                          </th>
                          <th style={{ border: 'red solid 0px', textAlign: 'center', fontWeight: 'bold' }}>
                            {window._getText('sick')}
                          </th>
                          <th style={{ border: 'red solid 0px', textAlign: 'left', fontWeight: 'bold' }}>
                            {window._getText('schedule')}
                          </th>
                        </tr>
                      </thead>
                      <tbody  >

                        {reportData &&

                          reportData.data.map(d => {
                            if (d.sik === true) { sumHoursSick += Number(d.m || 0) }
                            else { sumHours += Number(d.m || 0) }

                            return (

                              <tr key={d.k} >
                                <td style={{ width: '30px', cursor: 'pointer', }} onClick={this.onClickOpenShift(d)} >
                                  <Icon name='edit outline'  ></Icon>
                                </td>
                                <td style={{ xwidth: '100px', border: 'red solid 0px' }}>
                                  {d.dStr} <br />
                                </td>
                                <td className='hideOnSmallScreen' style={{ color: '#ccc', xwidth: '160px', border: 'red solid 0px', textAlign: 'center' }}>
                                  {d.time}
                                </td>
                                <td className='hideOnSmallScreen' style={{ color: '#ccc', xwidth: '100px', border: 'red solid 0px', textAlign: 'center' }}>
                                  {d.break}
                                </td>
                                <td style={{ xwidth: '100px', border: 'red solid 0px', textAlign: 'center' }}>
                                  {d.hours}
                                </td>
                                <td style={{ xwidth: '100px', border: 'red solid 0px', textAlign: 'center' }}>
                                  {d.sik === true && <Icon name='check'></Icon>}
                                </td>
                                <td style={{ color: '#ccc', xwidth: '150px', border: 'red solid 0px' }}>
                                  {d.scheduleName}
                                </td>
                              </tr>


                            )
                          })
                        }

                      </tbody>
                    </table>


                    {!userlist &&
                      <div style={{ textAlign: 'center', marginTop: '50px' }}><Loader inline active>{window._getText('loading')}</Loader></div>
                    }
                    {userlist && userlist.length === 0 &&
                      <div style={{ textAlign: 'center', marginTop: '50px' }}>
                        <Icon name='folder open outline' style={{ fontSize: '50px' }}></Icon><br />
                        No scheduled hours this month
                    </div>}
                  </div>
                  <div style={{}}>


                    <div style={{ width: '300px', border: 'solid 0px red', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}>{this.getPie(sumHours, sumHoursSick)}</div>
                    <div style={{ paddingLeft: '15px' }}>
                      Total <b>{getTimeFromMins(sumHours + sumHoursSick)}h</b> ,
                    Worked <b>{getTimeFromMins(sumHours)}h</b> ,
                    Sick  <b>{getTimeFromMins(sumHoursSick)}h</b>
                    </div>

                  </div>
                </div>
              </React.Fragment>

            </div>
          </div>
        </div >



        <DownloadHoursExcelAll btnName={window._getText('report.downloadall')} dataSet={userlist} filename={'ALL_' + this.state.rDate.local().format("YYYYMM")}></DownloadHoursExcelAll>
        <EditEventModal></EditEventModal>
      </React.Fragment >


    );
  }
}

const mapStateToProps = state => {


  return {

    project: state.firestore.data.project,
    projectKey: state.firebase.profile.projectKey,
    currentUser: state.firebase.profile,
    //    renderDateKey: state.viewstate.renderDateKey,
    // viewstate: state.viewstate,
    fireData: state.firestore.data,
    fireLoaded: state.firestore.status.requested,
    AmPm: state.firestore.data.project.s ? state.firestore.data.project.s.AmPm || false : false,
  }
}

const mapActionsFromProps = {
  openEventModal,
};


export default connect(mapStateToProps, mapActionsFromProps)(Reports)

