import moment from 'moment';
import moize from "moize";
import { config } from '../../config/firebase'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)

export const toTop = (height = 0) => {
    window.scrollTo(0, height);
    if (document.body.scrollTo)
        document.body.scrollTo(0, height);
}
export const getWeekObject = (date, firstDayOfWeek) => {

    if (firstDayOfWeek === 0)
        moment.locale('en')
    else
        moment.locale('en-gb')

    var renderDate = moment.utc(date).startOf('week');
    //const dateKey = getDateKey(renderDate)
    return { firstday: renderDate }//, renderDateKey: dateKey }
}
export const short = (str, n) => {
    if (!str)
        return ''
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str
}
export const objectToArray = (object) => {
    if (object) {
        return Object.entries(object).map(e => Object.assign(e[1], { id: e[0] }))
    }
}
export const times = {
    '00': ['12', 'am'],
    '01': ['1', 'am'],
    '02': ['2', 'am'],
    '03': ['3', 'am'],
    '04': ['4', 'am'],
    '05': ['5', 'am'],
    '06': ['6', 'am'],
    '07': ['7', 'am'],
    '08': ['8', 'am'],
    '09': ['9', 'am'],
    '10': ['10', 'am'],
    '11': ['11', 'am'],
    '12': ['12', 'pm'],
    '13': ['1', 'pm'],
    '14': ['2', 'pm'],
    '15': ['3', 'pm'],
    '16': ['4', 'pm'],
    '17': ['5', 'pm'],
    '18': ['6', 'pm'],
    '19': ['7', 'pm'],
    '20': ['8', 'pm'],
    '21': ['9', 'pm'],
    '22': ['10', 'pm'],
    '23': ['11', 'pm'],
}
export const time24hToAmPm = (time) => {
    var t = (time || '').split(":")
    if (t.length === 2) {
        const amPm = times[t[0]]

        return amPm[0] + ':' + t[1] + amPm[1]

    }
    return time;
}





export const getUserImage = (projectKey, userKey, imgUrl) => {
    var imgBase = config.imgBase
    //`https://storage.googleapis.com/static.timeto.work/p/${projectKey}/t/${userKey}?${imgUrl}`  
    //console.log(`https://us-central1-simpleschedule-9ae21.cloudfunctions.net/ImgCache?p=${projectKey}&i=${userKey}&r=${imgUrl}`)
    return `https://${imgBase}/ImgCache?p=${projectKey}&i=${userKey}&r=${imgUrl}`
}
export const getDateKey = (date) => {

    if (date) {
        if (typeof date === 'string') { return getDateKey2(date) }
        else { return getDateKey2(date.format('YYYY-MM-DD')) }

    }
    else
        return getDateKey2(moment().format('YYYY-MM-DD'))
}

const getDateKey2 = moize((date) => {

    var d = moment(date);
    var d2 = d.clone().add(6, 'days');
    var r = d.format('YYYY-WW') + '_' + d2.format('YYYY-WW')
    return r
})

export const renderTimeLable = moize((lbl, startDate, stopDate, AmPm = false) => {


    let s = lbl || ''
    if (s.indexOf('@') > -1) {
        if (startDate) {
            const sDate = moment.utc(startDate).format(AmPm ? 'h:mma' : 'H:mm')
            //console.log('startDate', startDate)
            //console.log('sDate', moment.utc(startDate).format('YYYYMMDD'))
            s = s.replace('@start', sDate)
        }
        else
            s = s.replace('@start', '')
        if (stopDate) {
            const eDate = moment.utc(stopDate).format(AmPm ? 'h:mma' : 'H:mm')
            s = s.replace('@end', eDate)
        }
        else
            s = s.replace('@end', '')
    }

    s = s.replace(/m/g, '')
    s = s.replace(/:00/g, '')
    if (s === '0 - 0' || s === '12a - 12a')
        s = ' '


    return s
})

export const getTimeFromMins = moize((mins) => {
    //console.log('get time from min', mins)
    //return mins;
    // var m = Number(mins)

    // var hours = Math.floor(m / 60);
    // var min = Math.floor(60 * ((m / 60) - hours))

    // // var minutes = Math.floor(seconds / (60));
    // //var decimal = min - Math.floor(min)
    // return (hours + ':' + min);
    var dur = moment.duration(mins, 'minutes').format('HH:mm')

    if (dur.indexOf(':') === -1)
        dur = '0:' + dur
    return dur
})

export const getLocalDate = (mDate, format) => {


    if (!window._moment) {
        var language = window.navigator.userLanguage || window.navigator.language;
        var m = moment()
        m.locale(language)
        window._moment = m
    }

    return mDate.clone().set({
        'year': mDate.get('year'),
        'month': mDate.get('month'),
        'day': mDate.get('day'),
        'minute': mDate.get('minute'),
        'second': mDate.get('second')
    }).format(format)

}

export const getLocalDateStr = (mDate) => {


    if (!window._mFormat) {
        var language = window.navigator.userLanguage || window.navigator.language;
        var m = moment()
        m.locale(language)
        var formatL = m.localeData().longDateFormat('ll');
        // console.log('m1_>>>>>>>>>>>', m.locale())
        // console.log('formatL', formatL)
        // console.log('m.locale', m.locale())
        window._moment = m
        window._mFormat = formatL.replace(/Y/g, ' ').replace(/^\W|\W$|\W\W/, ' ');
    }

    var format = window._mFormat

    return mDate.clone().format(format || 'MMM DD')

}

export const getLocalDateStr2 = (mDate) => {


    if (!window._mFormat2) {
        var language = window.navigator.userLanguage || window.navigator.language;
        console.log('languag', language)
        var m = moment()
        m.locale(language)
        var formatL = m.localeData().longDateFormat('llll');
        console.log('m1_>>>>>>>>>>>', m.locale())
        console.log('formatL', formatL)
        console.log('m.locale', m.locale())
        window._moment = m
        window._mFormat2 = formatL.replace(/Y/g, ' ').replace(/^\W|\W$|\W\W/, ' ').replace(/HH:mm/, '').replace(/h:mm A/, '').replace(/,/, '').replace(/MMM/, '');

        console.log('window._mFormat2 >>>>> ', window._mFormat2)

    }
    var format = window._mFormat2

    return mDate.format(format || 'MMM DD')

}
export const getTimeFromMins1 = (mins) => {
    var dur = moment.duration(mins, 'minutes').format('HH:mm')

    if (dur.indexOf(':') === -1)
        dur = '0:' + dur
    return dur
}

// export const createNewEvent = (user, photoURL, event) => {
//     event.date = moment.utc(event.date).toDate();
//     return {
//         ...event,
//         hostUid: user.uid,
//         hostedBy: user.displayName,
//         hostPhotoURL: photoURL || '/assets/user.png',
//         created: Date.now(),
//         attendees: {
//             [user.uid]: {
//                 going: true,
//                 joinDate: Date.now(),
//                 photoURL: photoURL || '/assets/user.png',
//                 displayName: user.displayName,
//                 host: true
//             }
//         }
//     }
// }

// export const createDataTree = dataset => {
//     let hashTable = Object.create(null);
//     dataset.forEach(a => hashTable[a.id] = { ...a, childNodes: [] });
//     let dataTree = [];
//     dataset.forEach(a => {
//         if (a.parentId) hashTable[a.parentId].childNodes.push(hashTable[a.id]);
//         else dataTree.push(hashTable[a.id])
//     });
//     return dataTree
// };
