import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types'
import { actionTypes } from 'redux-firestore'


import { auth } from '../../config/firebase';
import { resetSchedule } from '../../redux/action/currentScheduelDataAction'
import FirestoreListnerHelper from '../../common/util/FirestoreListnerHelper'
import { FireHelper } from '../../common/util/FireHelper'


var isElectronApp = (process.env.REACT_APP_ELECTRON === '1')
let ipcRenderer
isElectronApp = !!window.require
if (isElectronApp) {
  ipcRenderer = window.require('electron').ipcRenderer
}

class SignOut extends Component {
  static contextTypes = { store: PropTypes.object.isRequired }

  componentDidMount = () => {
    this.props.onResetSchedule() // this is nerver calles all the way!
    this.props.dispatch({ type: 'currentScheduleData:reset' });//TEMP fix 

    FirestoreListnerHelper.clearListners(this.context);
    console.log('actionTypes.CLEAR_DATA')
    this.props.dispatch({ type: actionTypes.CLEAR_DATA })

  }
  componentWillUnmount = () => {
    //Clear all js data
    //momnet ? 
    //firstday ? 
    //language ? 


  }
  animationEnd = (e) => {
    if (e.target.className === 'fadeOut') {
      console.log('>>Clear data on signout')
      FirestoreListnerHelper.clearListners(this.context);

      if (window._webToken) {
        FireHelper.removeUserPushToken(window._webToken)
        delete window._webToken
        console.log('delete _webToken')
      }

      delete window._moment
      delete window._projectKey
      localStorage.removeItem('savedStateVars');
      console.log('actionTypes.CLEAR_DATA')
      this.props.dispatch({ type: actionTypes.CLEAR_DATA })



      auth.signOut()


      if (isElectronApp) {
        ipcRenderer.send('reset-hidesplash', 'login')
      }

      //setTimeout(() => {
      //  window.location.href = '/'
      //}, 100);
    }
  }
  test = () => {
    console.log('clikc')
    this.props.dispatch({ type: 'currentScheduleData:reset' })
    this.props.onResetSchedule()
  }
  render() {




    return (
      //
      <div style={{ height: '100%', background: 'rgb(69, 69, 68)' }} >
        <div className='fadeOut' onAnimationEnd={this.animationEnd}  >
          <div className='cc' >

            <h2 style={{ width: '200px', marginTop: '100px', color: '#fff' }} className='fadeIn'>
              {window._getText('logginout')}

            </h2>
          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {

  return {
    onResetSchedule: resetSchedule,
    dispatch
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignOut)

