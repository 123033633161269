import React, { Component } from 'react';




export default class BreakSlider extends Component {
  state = { breakMin: 0, show: false }


  componentDidMount = () => {
    this.setValue()
  }
  componentDidUpdate = (prevProps, prevState, snapshot) => {

    if (prevProps.input.value !== this.props.input.value)
      this.setValue()


  }
  setValue = () => {
    const { input } = this.props
    if (input.value) {
      this.setState({ breakMin: Number(input.value) })
    }

  }
  changeValue = () => {
    this.props.input.onChange(Number(this.state.breakMin))
  }
  handleChange = (event) => this.setState({ breakMin: Number(event.target.value) }, () => { this.changeValue() });

  render() {
    const { input, lable, sumLable, toggle, show, AmPm } = this.props

    return (
      <div style={{ position: 'relative', xleft: AmPm ? '125px' : '95px', border: 'solid 0px red', paddingTop: '0px', marginBottom: '15px', marginLeft: '4px' }}>
        <input onChange={(param, data) => input.onChange(Number(data.value))} {...input} type='hidden' />
        {/* <div onClick={toggle}>
          <span style={{ color: 'silver' }}  >
            <b tooltip_t={'break'} style={{ color: 'var( --color-font)', fontWeight: '900', fontSize: '14px' }}>({this.state.breakMin})</b>
            {lable && lable}
          </span>
        </div> */}


        (<select onChange={this.handleChange} value={this.state.breakMin || 0} className='selectEventForm' style={{ display: 'inline', paddingRight: '1px', textAlignLast: 'center' }} >
          <option value={0} >00</option>
          <option value={5} >05</option>
          <option value={10} >10</option>
          <option value={15} >15</option>
          <option value={20} >20</option>
          <option value={25} >25</option>
          <option value={30} >30</option>
          <option value={35} >35</option>
          <option value={40} >40</option>
          <option value={45} >45</option>
          <option value={50} >50</option>
          <option value={55} >55</option>
          <option value={60} >60</option>
          <option value={65} >65</option>
          <option value={70} >70</option>
          <option value={80} >80</option>
          <option value={80} >80</option>
          <option value={85} >85</option>
          <option value={90} >90</option>
          <option value={95} >95</option>
          <option value={100} >100</option>
          <option value={105} >105</option>
          <option value={110} >110</option>
          <option value={115} >115</option>
          <option value={120} >120</option>
        </select>)



        {false &&
          <div onMouseDown={(e) => { e.stopPropagation() }} style={{ left: '-150px', marginTop: '20px', position: 'absolute', top: '5px', width: '400px', xheight: '150px', background: '#FAFAFA', boxShadow: '1px 3px 3px 0px rgba(0, 0, 0, 0.2), 1px 3px 15px 2px rgba(0, 0, 0, 0.2)', border: 'solid 1px var(--color-border)', padding: '6px', zIndex: '4', borderRadius: '4px' }}>
            <div style={{ width: '10px', height: '10px', background: '#FAFAFA', position: 'absolute', top: '-6px', transform: 'rotate(45deg)', left: '153px', borderTop: 'solid 1px rgba(187,187,187,0.6)', borderLeft: 'solid 1px rgba(187,187,187,0.6)' }}></div>

            <div style={{ position: 'relative', border: 'solid 0px green', marginBottom: '20px', marginTop: '20px' }}>
              <input
                onTouchStart={(e) => e.target.focus()}
                type="range"
                min="0" max="120"
                value={this.state.breakMin || 0}
                onChange={this.handleChange}
                step="5"
                style={{ width: '100%', position: 'relative', left: '0px', top: '0px', margin: '0px', zIndex: '3', }}
              />
              <div className="ticks" style={{ width: '100%', position: 'absolute', left: '0px', top: '-7px', margin: '0px', zIndex: '2' }}>
                <span className="tick">00</span>
                <span className="tick1"></span>
                <span className="tick">10</span>
                <span className="tick1"></span>
                <span className="tick">20</span>
                <span className="tick1"></span>
                <span className="tick">30</span>
                <span className="tick1"></span>
                <span className="tick">40</span>
                <span className="tick1"></span>
                <span className="tick">50</span>
                <span className="tick1"></span>
                <span className="tick">60</span>
                <span className="tick1"></span>
                <span className="tick">70</span>
                <span className="tick1"></span>
                <span className="tick">80</span>
                <span className="tick1"></span>
                <span className="tick">90</span>
                <span className="tick1"></span>
                <span className="tick">100</span>
                <span className="tick1"></span>
                <span className="tick">110</span>
                <span className="tick1"></span>
                <span className="tick">120</span>


              </div>

              {sumLable && <span style={{ float: 'right' }}>{sumLable}</span>}

            </div>

          </div>
        }

      </div>

    )

  }
}