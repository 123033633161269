import React, { PureComponent } from "react";
// import PropTypes from "prop-types";
import { Header, Image } from "../../../common/component/Button";
import ScheduleCell from "./ScheduleCell";
import moment from "moment";
import collection from "lodash/collection";
import math from "lodash/math";
import noUserImage from '../../../../img/noimg.png'
import { getTimeFromMins, getUserImage } from '../../../common/util/helpers'
// import { shallowEqual } from 'shouldcomponentupdate-children';


import lang from 'lodash/lang'
import moize from "moize";

const getShifts = moize((shifts, renderDay) => {
  var r = collection.filter(shifts, event => event.date2.isSame(renderDay, "day")) || [];
  if (r) {
    r = r.sort((a, b) => {
      if (!a.timeStart || !b.timeStart)
        return 0

      if (a.timeStart < b.timeStart) return -1
      if (a.timeStart > b.timeStart) return 1
      return 0
    })
  }

  return r
})
const getSum = moize((userEvents) => {
  if (userEvents) {
    var minutes2 = math.sumBy(userEvents, function (o) {
      if (o.removed)
        return 0
      if (!o.minutes)
        return 0
      return Number(o.minutes)
    });
    // console.log('redner cal',getTimeFromMins(minutes2))
    return getTimeFromMins(minutes2);
    //this.setState({ minutes: getTimeFromMins(minutes2) })
  }
})

export class ScheduleRow extends PureComponent {

  static propTypes = {};
  constructor(props) {
    super(props);





    this.state = {
      showAdd: {},
      minutes: null,
      rData: []
    }



  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   // console.log('-------')
  //   // console.log(this.props)
  //   Object.keys(this.props).forEach(key => {
  //     try {
  //       if (this.props[key] !== nextProps[key]) {
  //         console.log('====key', key)
  //         console.log('====props', this.props[key] === nextProps[key])
  //       }
  //     } catch (error) {
  //       console.log('err', error)
  //     }

  //   });
  //   // console.log('props',this.props===nextProps)
  //   // console.log('state',this.state===nextState)

  //   const r = shallowEqual(this.props, nextProps, this.state, nextState);
  //   console.log('==== menushouldComponentUpdate>', r)
  //   return r
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log('>>componentDidUpdate>>>>>>this.props.userMeta', this.props.metaDataList)


    if (this.props.userEvents !== prevProps.userEvents) {
      if (!lang.isEqual(this.props.userEvents, prevProps.userEvents)) {
        this.renderCells(true)
      }
      else if (!lang.isEqual(this.props.metaDataList, prevProps.metaDataList)) {
        // console.log('meta is changed! 1')
        this.renderCells(true)
      }
    }
    else if (!lang.isEqual(this.props.metaDataList, prevProps.metaDataList)) {
      //console.log('meta is changed! 2')
      this.renderCells(true)
    }

  }

  componentDidMount = () => {
    //if (!this.state.minutes) {
    // this.getSum()
    //}
    //console.log('componentDidMount')
    this.renderCells(true)
  }


  preventDefault = event => () => {
    event.preventDefault();
  };
  onDragStart = e => {
    console.log('onDragStart', e.target.id)
    this.hideAddBtn()
    var clone = e.metaKey || e.ctrlKey
    e.dataTransfer.setData("text", JSON.stringify({ t: "cell", id: e.target.id, clone: clone }));
    this.props.onDragOverShowDropZone();
  };
  onDragOver = e => {
    //console.log('onDragOver')
    e.stopPropagation();
    e.preventDefault();
  };
  onDrop = eventDate => e => {
    console.log('onDrop')
    e.preventDefault();

    this.props.onDragOverHideDropZone();
    var data;
    var clone = false;
    try {
      data = JSON.parse(e.dataTransfer.getData("text"));
      clone = data.clone
    } catch (error) {
      console.log("JSON.parse error");
      return;
    }

    if (data.t === "cell") {
      this.props.onMoveEvent(this.props.userKey, eventDate, data.id, clone);
    }
    else if (data.t === "template") {
      this.props.onAddTemplateShift(this.props.userKey, eventDate, data.id);
    }



  };



  // getCss = events => {
  //   var cssClass = "large cellOne";
  //   const eventCounts = collection.size(events);
  //   if (eventCounts === 2) cssClass = "small cellMulti";
  //   if (eventCounts > 2) cssClass = "mini cellMulti";
  //   return cssClass;
  // };

  lastDate = null
  showAddBtn = (eventDate) => (e) => {
    //console.log('showAddBtn')
    //console.log(e.clientY)
    // console.log(eventDate.format('YYYY-MM-DD'))
    if (e.target.tagName.toLowerCase() === 'td')
      this.props.showAddShiftButton(e.target.getBoundingClientRect(), +eventDate, this.props.userKey)
    else if (e.target.parentElement.tagName.toLowerCase() === 'td')
      this.props.showAddShiftButton(e.target.parentElement.getBoundingClientRect(), + eventDate, this.props.userKey)
    else if (e.target.parentElement.parentElement.tagName.toLowerCase() === 'td')
      this.props.showAddShiftButton(e.target.parentElement.parentElement.getBoundingClientRect(), + eventDate, this.props.userKey)

    // console.log('rectObject', e.target.offsetLeft)
    // console.log('showAddBtn1', e.target.offsetLeft)
    // console.log('showAddBtn2', eventDate)

    // this.lastDate = eventDate
    // var showAdd = { ...this.state.showAdd }
    // showAdd[eventDate] = true
    // this.setState({ showAdd })
  }


  hideAddBtn = (eventDate) => (e) => {
    //console.log('hideAddBtn')
    //console.log('e.target.tagName')
    //if (e.target.tagName === 'TD')
    this.props.hideAddShiftButton()
    // else if (e.target.parentElement.tagName === 'TD')
    //   this.props.showAddShiftButton(null, null, null)
    // else if (e.target.parentElement.parentElement.tagName === 'TD')
    //   this.props.showAddShiftButton(null, null, null)
    // var showAdd = { ...this.state.showAdd }
    // showAdd[eventDate] = false
    // this.setState({ showAdd })

  }

  renderCells = (force) => {
    //console.log('renderCells', force)

    if (!this.state.rData || force === true) {

      var rData = []
      var { renderDayDate, renderDayCount, userEvents, metaDataList } = this.props
      if (!metaDataList)
        metaDataList = {}
      var shifts = userEvents.map(shift => {
        return { ...shift, date2: moment.utc(new Date(shift.date)), meta: metaDataList[shift.key] || {} }
      })

      var startDate = moment.utc(renderDayDate).utcOffset(0);
      startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      startDate.toISOString()
      for (let i = 0; i < renderDayCount; i++) {
        rData.push(this.renderCell(moment.utc(startDate).add(i, 'days'), shifts))
      }
      //console.log('rData', rData)
      this.setState({ rData })
    }

  }

  renderCell = (eventDate, shifts) => {
    const {
      userKey,
      //onAddEvent,

      onDragOverHideDropZone,
      onOpenEventModal
    } = this.props;


    // console.log('eventDate', eventDate)
    const renderDay = new Date(eventDate);
    // console.log('renderDay', renderDay)
    // renderDay.setHours(0, 0, 0, 0)
    // console.log('renderDay2', renderDay)
    // console.log('render cell-> match dates ')
    var cellEvents = getShifts(shifts, renderDay);// collection.filter(shifts, event => event.date2.isSame(renderDay, "day")) || [];
    // var cssClass = this.getCss(cellEvents);

    return (
      <td

        onDragEnd={onDragOverHideDropZone}
        width={2}
        onDrop={this.onDrop(+eventDate)}
        onDragOver={this.onDragOver}
        draggable="false"
        userkey={userKey}
        key={userKey + eventDate}
        className='two wide scheduelCell'
        dataedate={eventDate}
        onMouseMove={this.showAddBtn(eventDate)} //TODO this is not good when we render so mush 
      // onMouseLeave={this.hideAddBtn(eventDate)}

      >

        {/* <span className='addShiftBtn' >
          {this.state.showAdd[eventDate] &&
            <Button onClick={onAddEvent(+eventDate, userKey)} circular icon='add' size='tiny' />
          }
        </span> */}
        <div className='flexDiv'>



          {cellEvents.map(event => {
            if (event.removed) return null;

            return (
              <ScheduleCell
                AmPm={this.props.AmPm}
                onOpenEventModal={onOpenEventModal}
                //cssClass={cssClass}
                key={event.key}
                userKey={userKey}
                date={event.date}
                onDragStart={this.onDragStart}
                onDragOverHideDropZone={onDragOverHideDropZone}
                event={event}
              />
            );

          })}
        </div>
      </td>
    );
  };
  onOpenUserModal = key => () => this.props.openUser(key)
  onOpenDivierModal = (key, value) => () => this.props.openDivierModal(key, value)


  tblRowCss = (isOpenshifts) => ({ background: isOpenshifts ? 'rgba(239, 239, 219, 0.20)' : '' })
  style = {
    header: { display: 'inline', margin: '0px', padding: '0px' }
  }

  preventDefault = (e) => e.preventDefault()

  render() {

    var { imgUrl } = this.props;
    const { name, userKey, dragStartRow, dragOverRow, dropRow, onDragOverHideDropZone, order, dividerList, projectKey } = this.props;



    if (!userKey)
      return null // <tr><td colSpan='8'>#user is missing</td></tr>

    const isDiv = userKey.startsWith("div_");
    var dividerName = '[click to set divider name]'
    var dividerNameData = ''
    var dividerDirty = false;
    var dividerRemoved = false;

    if (dividerList[userKey]) {
      dividerName = dividerList[userKey].name
      dividerNameData = dividerList[userKey].name
      dividerDirty = dividerList[userKey].dirty ? true : false
      dividerRemoved = dividerList[userKey].removed ? true : false
    }
    var minutes = getSum(this.props.userEvents)

    const isOpenshifts = userKey.startsWith("openshifts");



    return (
      <tr draggable="false" key={userKey} id={userKey} order={order} className='sRowTbl' style={this.tblRowCss((isOpenshifts || isDiv))}  >
        {isOpenshifts &&
          <React.Fragment>
            <td
              onDrop={dropRow}
              onDragOver={dragOverRow}
              draggable="false"
              className='two wide scheduelCell'
              onMouseMove={this.hideAddBtn()}
              style={{ borderLeft: 'none' }}
            >

              {minutes && <label className='sRowCellLbl noSelect' size='tiny' >{minutes}h</label>}
              <Header as='h4' className='dragMe sRowCellHeader' image draggable onDragStart={dragStartRow} onDragEnd={onDragOverHideDropZone} floated='left'  >
                <div  >{window._getText('schedual.openshifts')}</div>
              </Header>

            </td>

            {this.state.rData}

          </React.Fragment>
        }

        {!isDiv && !isOpenshifts &&
          <React.Fragment>
            <td
              draggable onDragStart={dragStartRow} onDragEnd={onDragOverHideDropZone}
              onDrop={dropRow}
              onDragOver={dragOverRow}
              // draggable="false"
              className='two wide scheduelCell'
              onMouseMove={this.hideAddBtn()}
              style={{ borderLeft: 'none' }}
            >

              {minutes && <label className='sRowCellLbl noSelect' size='tiny' >{minutes}h</label>}
              <div draggable onDragStart={dragStartRow} onDragEnd={onDragOverHideDropZone} className='dragMe schedualUsername' style={{ display: 'inline', margin: '0px', padding: '0px', touchAction: 'none', fontSize: '14px', fontWeight: 'bold' }}  >
                <Image circular style={{ touchAction: 'none', width: '35px', height: '35px' }} draggable onDragStart={dragStartRow} onDragEnd={onDragOverHideDropZone} onContextMenu={this.preventDefault} src={imgUrl ? getUserImage(projectKey, userKey, imgUrl) : noUserImage} size="mini" onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }} />
                <div onClick={this.onOpenUserModal(userKey)}  >{name}</div>
              </div>
            </td>
            {/* */}
            {this.state.rData}

          </React.Fragment>
        }


        {isDiv && !dividerRemoved &&
          < td colSpan={8}
            onDrop={dropRow}
            onDragOver={dragOverRow}
            draggable="false"
            className='scheduleDivider'
            style={{ borderLeft: 'none' }}
          >
            <Header onClick={this.onOpenDivierModal(userKey, dividerNameData)} as='h4' className='dragMe sRowCellHeader' image draggable onDragStart={dragStartRow} onDragEnd={onDragOverHideDropZone} floated='left'  >
              {/* <Image src={imgUrl ? imgUrl : img} circular size="mini" onClick={this.onOpenUserModal(userKey)} draggable="false" style={{ marginRight: '8px',cursor: 'grab' }} /> */}
              {dividerName}{dividerDirty && '*'}
            </Header>

          </td>
        }




      </tr>
    );
  }
}
