import React, { Component } from "react";
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox'
import { connect } from 'react-redux'
import { FireHelper } from '../../../common/util/FireHelper'

class AppSettings extends Component {
  state = {
    dev: false,
    AmPm: false,
    com: false,
    sCan: false,
    sMov: false,
    sSwp: false,
    sCha: false,
    sik: false
  }
  componentDidMount = () => {
    const { project } = this.props;
    const settings = project.s || {}
    this.setState({
      AmPm: settings.AmPm || false,
      dev: settings.dev || false,
      com: settings.com || false,
      sCan: settings.sCan || false,
      sMov: settings.sMov || false,
      sSwp: settings.sSwp || false,
      sCha: settings.sCha || false,
      sik: settings.sik || false,
    })
  }

  onSettingsCheck = (e, { name, value, checked }) => {
    //console.log('? ' + [name], checked)
    this.setState({ [name]: checked }, () => {
      FireHelper.setProject(this.props.projectKey, { s: { [name]: checked } })
    })

  }

  render() {
    return (
      <div style={{ paddingLeft: '10px' }}>
        {/* <h2 style={{ paddingTop: '10px' }}>{window._getText('set.settings.title')}</h2> */}
        <b>{window._getText('set.settings.time')}</b>
        <div style={{ display: 'flex', flex: 'row', }}>
          <div style={{ textAlign: 'right', width: '40%', marginRight: '8px', fontWeight: 'bold', }}>
            {this.state.AmPm === true && <span> 24h {window._getText('or')} <b>AM/PM</b></span>}
            {this.state.AmPm === false && <span> <b>24h</b> {window._getText('or')} AM/PM</span>}
          </div>
          <div style={{}} >
            <Checkbox toggle name='AmPm' onChange={this.onSettingsCheck} checked={this.state.AmPm} />

          </div>
        </div>
        <div style={{ fontSize: '10px', marginBottom: '10px', color: 'silver' }}>({window._getText('set.settings.timeWarning')}) </div>

        <b>{window._getText('set.settings.auto')}</b>
        <div style={{ display: 'flex', flexDirection: 'column', border: 'dotted 0px var(--color-border)' }}>




          <div style={{ display: 'flex', flex: 'row', paddingBottom: '8px' }}>
            <div style={{ textAlign: 'right', width: '40%', marginRight: '8px', fontWeight: 'bold' }}>
              {window._getText('set.settings.btn.dev')}
            </div>
            <div  >
              <Checkbox toggle name='dev' onChange={this.onSettingsCheck} checked={this.state.dev} />
            </div>
          </div>

          <div style={{ display: 'flex', flex: 'row', paddingBottom: '8px' }}>
            <div style={{ textAlign: 'right', width: '40%', marginRight: '8px', fontWeight: 'bold', }}>
              {window._getText('set.settings.btn.com')}
            </div>
            <div>
              <Checkbox toggle name='com' onChange={this.onSettingsCheck} checked={this.state.com} />
            </div>
          </div>

          <div style={{ display: 'flex', flex: 'row', paddingBottom: '8px' }}>
            <div style={{ textAlign: 'right', width: '40%', marginRight: '8px', fontWeight: 'bold', }}>
              {window._getText('set.settings.btn.sCan')}
            </div>
            <div>
              <Checkbox toggle name='sCan' onChange={this.onSettingsCheck} checked={this.state.sCan} />
            </div>
          </div>
          <div style={{ display: 'flex', flex: 'row', paddingBottom: '8px' }}>
            <div style={{ textAlign: 'right', width: '40%', marginRight: '8px', fontWeight: 'bold', }}>
              {window._getText('set.settings.btn.sMov')}
            </div>
            <div>
              <Checkbox toggle name='sMov' onChange={this.onSettingsCheck} checked={this.state.sMov} />
            </div>
          </div>
          <div style={{ display: 'flex', flex: 'row', paddingBottom: '8px' }}>
            <div style={{ textAlign: 'right', width: '40%', marginRight: '8px', fontWeight: 'bold', }}>
              {window._getText('set.settings.btn.sSwp')}
            </div>
            <div>
              <Checkbox toggle name='sSwp' onChange={this.onSettingsCheck} checked={this.state.sSwp} />
            </div>
          </div>
          <div style={{ display: 'flex', flex: 'row', paddingBottom: '8px' }}>
            <div style={{ textAlign: 'right', width: '40%', marginRight: '8px', fontWeight: 'bold', }}>
              {window._getText('set.settings.btn.sCha')}
            </div>
            <div>
              <Checkbox toggle name='sCha' onChange={this.onSettingsCheck} checked={this.state.sCha} />
            </div>
          </div>
          <div style={{ display: 'flex', flex: 'row', paddingBottom: '8px' }}>
            <div style={{ textAlign: 'right', width: '40%', marginRight: '8px', fontWeight: 'bold', }}>
              {window._getText('set.settings.btn.sik')}
            </div>
            <div>
              <Checkbox toggle name='sik' onChange={this.onSettingsCheck} checked={this.state.sik} />
            </div>
          </div>






        </div>
      </div >
    );
  }
}


const mapStateToProps = state => {
  return {
    projectKey: state.firebase.profile.projectKey,
    project: state.firestore.data.project,
    currentUser: state.firebase.profile,

  }
}

const mapDispatchToProps = {

}
export default connect(mapStateToProps, mapDispatchToProps)(AppSettings)
