export const DataHelper = {
  emptySchedule: {
    name: "new",
    isEmpty: true,
    key: null,
    dateKey: null,
    isNew: false,
    dirty: false,
    selectedSchedule: { key: "key", name: "name" },
    scheduleEventsList: {}, //events belongs to schedule

    scheduleUserList: [], //users belongs to schedule
    selectedUsers: [], // new users selected from the full userslist
    sortedUserList: [], //users keys sorted
    selectedSortedUserList: [],// unsaved sorted user list 
    scheduleUserListRemoved: [], //this is array with ids of remove users 
    selectedDividerList: {}, // list of selected/creatred divs that are not saved
    dividerList: {} // list of dividers 
  },
  initSchedule: (key, dateKey) => {
    //console.log('-->initSchedule', key)
    return { ...DataHelper.emptySchedule, key, dateKey };
  },
  getScheduleByKey: (props, key) => {
    if (!props.currentScheduelData)
      return { ...DataHelper.emptySchedule, key: key }; // current list dose not exist yet

    var schedule = props.currentScheduelData[key]; // try to get a full schema from the currentList
    if (!schedule) {
      // console.log("%%%curScheduleView IS null, we this shulde only happen first load", schedule);
      return { ...DataHelper.emptySchedule, key: key };
    }

    return { ...schedule, key: key };
  },
  getCurScheduleView: curScheduleView => {
    if (!curScheduleView) {
      //  console.log("curScheduleView IS null, we this shulde only happen first load", curScheduleView);
      return DataHelper.emptySchedule;
    }
    return curScheduleView;
  }
};
