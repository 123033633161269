import React, { Component } from "react";
// import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer'
// import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader'
// import AppLoggedInRouter from './AppLoggedInRouter'
import AppLoggedInRouterElectron from './AppLoggedInRouterElectron'

class AppRouter extends Component {

  render() {
    //console.log('->AppRouter')
    const { currentUser } = this.props

    if (currentUser && currentUser.isLoaded === false) {
      return (
        <div>...</div>
        // <Dimmer active inverted >
        //   <div style={{ alignContent: 'center', alignItems: 'center', }}>
        //     <Loader  >
        //       <h2>
        //         {window._getText('logginin')}
        //       </h2>
        //     </Loader>
        //   </div>
        // </Dimmer>
      )
    }

    //console.log('AppRouter', this.props.token)
    return (
      <AppLoggedInRouterElectron token={this.props.token} currentUser={currentUser} />
    );
  }
}

export default (AppRouter)


