import React, { Component } from 'react'
import Label from 'semantic-ui-react/dist/commonjs/elements/Label'
import Menu from 'semantic-ui-react/dist/commonjs/collections/Menu'
import Image from 'semantic-ui-react/dist/commonjs/elements/Image'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import { withRouter } from "react-router-dom";

import { NavLink } from 'react-router-dom'
// import imgLogo from '../../../img/icon.svg'
//import imgLogo from '../../../img/128x128.png'

import { isMobile, osName } from 'react-device-detect';

import { connect } from 'react-redux'
import noUserImage from '../../../img/noimg.png'
import { getUserImage } from '../../common/util/helpers'
import PropTypes from 'prop-types'


var isElectronApp = (process.env.REACT_APP_ELECTRON === '1')
let ipcRenderer
isElectronApp = !!window.require
if (isElectronApp) {
  ipcRenderer = window.require('electron').ipcRenderer
}

let isMacOs = (process.env.REACT_APP_MACOS === '1')


class MenuSchema extends Component {
  state = { isLandscape: true, msgCount: 0, inOnline: true, leftMenuOpen: true }
  static contextTypes = { router: PropTypes.object }



  componentDidMount = () => {
    window.addEventListener('online', this.onlineStatus)
    window.addEventListener('offline', this.onlineStatus)
  }
  componentWillUnmount = () => {
    window.removeEventListener('online', this.onlineStatus)
    window.removeEventListener('offline', this.onlineStatus)
  }

  onlineStatus = () => {
    console.log('navigator.onLine', navigator.onLine)
    this.setState({ inOnline: navigator.onLine })
  }

  msgCounterTimer = null
  msgCounter = 0
  requestCount = 0
  msgCount = (alerts) => {

    var msgCount = 0
    if (alerts && alerts.m)
      Object.keys(alerts.m).forEach(o => {
        var obj = alerts.m[o]
        msgCount += Number(obj.m || 0)
      })

    // console.log('----')
    // console.log('-- this.msgCounter', this.msgCounter)
    // console.log('-- msgCount', msgCount)
    // console.log('----')
    if (this.msgCounterTimer)
      clearTimeout(this.msgCounterTimer)

    if (this.msgCounter !== msgCount) {
      if (window.FirebasePlugin || isElectronApp) {
        this.msgCounterTimer = setTimeout(() => {


          this.setOsBadge()


        }, 1000);

      }
    }

    this.msgCounter = msgCount
    return msgCount
  }

  trigger = (
    <span>
      <Icon size='large' name="user circle" />
    </span>
  );

  timerGetMsgCount = null
  getMsgCount = () => {
    if (this.timerGetMsgCount)
      clearTimeout(this.timerGetMsgCount)

    this.timerGetMsgCount = setTimeout(() => {
      //console.log('>>> timerGetMsgCount')
      const alerts = this.props.currentUser.alerts || {}
      let msgCount = 0
      if (alerts.m)
        Object.keys(alerts.m).forEach(o => {
          var obj = alerts.m[o]
          msgCount += Number(obj.m || 0)
        })
      if (this.state.msgCount !== msgCount) {
        console.log('>>> RE SET MSGCOUNT')
        this.setState({ msgCount })
      }
    }, 1000);
  }

  setOsBadge = () => {
    if (window.FirebasePlugin)
      window.FirebasePlugin.setBadgeNumber(Number(this.msgCounter + this.requestCount))

    if (isElectronApp) {

      ipcRenderer.send('setBadge', Number(this.msgCounter + this.requestCount))
    }
  }


  innerMenu = (isLandscape) => {
    console.log('>>>innerMenu')
    const { currentUser, requestData } = this.props
    const role = currentUser.role || 0
    const isAdmin = role > 101
    const alerts = currentUser.alerts || {}
    const requestCount = requestData ? requestData.length : 0




    //this.getMsgCount()
    //let msgCount = this.state.msgCount


    let msgCount = this.msgCount(alerts)


    if ((msgCount + requestCount) !== (this.msgCount + this.requestCount)) {
      this.requestCount = requestCount
      this.setOsBadge()
    }
    //console.log('>>this.msgCount>', msgCount)
    // if (alerts.m)
    //   Object.keys(alerts.m).forEach(o => {
    //     var obj = alerts.m[o]
    //     msgCount += Number(obj.m || 0)
    //   })


    //   console.log('currentUser', currentUser)

    return (
      <React.Fragment>
        {/* {isLandscape &&
          <Menu.Item name='Work' >
            <Image src={imgLogo} size='mini' alt='TimeTo.Work' style={{ padding: '0px', nargin: '0px' }} />
          </Menu.Item>
        } */}
        {!isAdmin &&
          <React.Fragment>
            <Menu.Item as={NavLink} to="/home" name='Home'  >
              <Icon name="home" size='large' className='menuAnimation' />
              {false && 'Home'}
            </Menu.Item>
            <Menu.Item as={NavLink} to="/schedules" name='Schedule'  >
              <Icon name="calendar alternate" size='large' className='menuAnimation' />
              {false && 'Schedule'}
            </Menu.Item>
            <Menu.Item as={NavLink} to="/chat1" >
              <Icon name="comments" size='large' className='menuAnimation' />
              {false && 'Messages'}
              {msgCount > 0 && <Label color='purple' className='lblMsgCountAni' style={{ position: 'absolute', top: '8px', left: '0px', fontSize: '9px' }}>{msgCount}</Label>}
            </Menu.Item>
            <Menu.Item as={NavLink} to="/requests1" >
              <Icon name="inbox" size='large' className='menuAnimation' />
              {false && 'Messages'}
              {requestCount > 0 && <Label color='teal' className='lblMsgCountAni' style={{ position: 'absolute', top: '8px', left: '0px', fontSize: '9px' }}>{requestCount}</Label>}
            </Menu.Item>

          </React.Fragment>
        }
        {isAdmin &&
          <React.Fragment>
            <Menu.Item as={NavLink} to="/schedule/schedule">
              <Icon name="calendar alternate" size='large' className='menuAnimation' />
              {false && 'Schedule'}
            </Menu.Item>
            <Menu.Item as={NavLink} to="/schedule/users">
              <Icon name="users" size='large' className='menuAnimation' />
              {false && 'Users'}
            </Menu.Item>
            <Menu.Item as={NavLink} to="/reports" >
              <Icon name="clipboard" size='large' className='menuAnimation' />
              {false && 'Reports'}
            </Menu.Item>

            <Menu.Item as={NavLink} to="/chat" >
              <Icon name="mail" size='large' className='menuAnimation' />
              {msgCount > 0 && <Label color='purple' className='lblMsgCountAni' style={{ position: 'absolute', top: '8px', right: '8px', fontSize: '9px' }}>{msgCount}</Label>}
              {requestCount > 0 && <Label color='teal' className='lblMsgCountAni' style={{ position: 'absolute', top: '8px', left: '-2px', fontSize: '9px' }}>{requestCount}</Label>}
              {false && 'Messages'}

            </Menu.Item>
            {/* <Menu.Item as={NavLink} to="/schedule/settings" >
              <Icon name="setting" size='large' className='menuAnimation' />
              {false && 'Settings'}
            </Menu.Item> */}

          </React.Fragment>
        }
        {/* {isMobile ? 'MOBILE' : 'Vanlig'}<br />
        {isLandscape ? 'isLandscape' : 'portrait'} */}


        <Menu.Item style={{ height: '50px' }} as={NavLink} to="/appmenu" >
          {currentUser.extraData &&
            <Image style={{ margin: '0', marginLeft: '6px', borderRadius: '25px', border: 'solid 1px #ccc', width: '29px', height: '29px' }} size='mini' floated='left' src={currentUser.extraData.image ? getUserImage(currentUser.projectKey, currentUser.inSysUserKey, currentUser.extraData.image) : noUserImage} alt='' />
          }
          {!currentUser.extraData &&
            <Icon name="user" size='large' className='menuAnimation' />
          }
          {false && 'Account'}
        </Menu.Item>

        {/* <Menu.Item name='Settings'>
          <Dropdown
            className='menuAnimation'
            inline
            trigger={this.trigger}
            pointing="top left"
            icon={null}
          >
            <Dropdown.Menu pointing="top left" icon={null}>
              <Dropdown.Item text="Account" as={NavLink} to="/user/account" />
              <Dropdown.Item text="Settings" as={NavLink} to="/user/settings" />
              <Dropdown.Item text="Sign Out" as={NavLink} to="/signout" />
            </Dropdown.Menu>
          </Dropdown>
          {!isLandscape && 'Me'}
        </Menu.Item> */}


      </React.Fragment>
    )
  }

  goTo = (url) => () => {
    console.log('url', url)

    this.context.router.history.push(url)
    if (ipcRenderer) {
      if (url.startsWith('/schedule/schedule/')) { ipcRenderer.send('setMenu', '/schedule') }
      else { ipcRenderer.send('setMenu', url) }
    }


  }




  getBg = (key, index) => {


    if (window.location.pathname === '/schedule/schedule/default' && this.props.defaultScheduleKey === 'default' && index === 0) {
      return 'rgba(35, 33, 30, 0.6)'
    } else if (window.location.pathname === '/schedule/schedule/default') {
      return this.props.defaultScheduleKey === key ? 'rgba(35, 33, 30, 0.6)' : ''
    }
    else {

      return window.location.pathname === '/schedule/schedule/' + key ? 'rgba(35, 33, 30, 0.6)' : ''
    }
  }
  toggleLeftMenu = () => {
    this.props.onToggleLeftMenuOpen()
    this.setState({ leftMenuOpen: !this.state.leftMenuOpen })
  }

  render() {
    const colors = ['#2185d0', '#6435c9', '#a333c8', '#fbbd08', '#b5cc18', '#21ba45', '#02c39a', '#2185d0', '#6435c9', '#a333c8', '#fbbd08', '#b5cc18', '#21ba45', '#02c39a', '#2185d0', '#6435c9', '#a333c8', '#fbbd08', '#b5cc18', '#21ba45', '#02c39a', '#2185d0', '#6435c9', '#a333c8', '#a333c8', '#fbbd08', '#b5cc18', '#21ba45', '#02c39a', '#2185d0', '#6435c9', '#a333c8']
    const { currentUser, requestData, isLandscape, isKeyboardOpen, project, leftMenuOpen } = this.props

    console.log('>>render MenuSchema')
    // const role = currentUser.role || 0
    //const isAdmin = role > 101
    const alerts = currentUser.alerts || {}
    //const requestCount = requestData ? requestData.length : 0




    //this.getMsgCount()
    //let msgCount = this.state.msgCount
    const role = currentUser.role || 0

    const isAdmin = role > 101

    let msgCount = this.msgCount(alerts)


    console.log('>>isMacOs', isMacOs)


    if (isMacOs === true) {

      //var credits = '-'
      var userCount = '-'
      var requestCount = '-'

      if (project) {
        //credits = project.credits
        var count = 0
        if (project.cache)
          Object.keys(project.cache).forEach(key => count += project.cache[key].a === true ? 1 : 0)
        userCount = count
      }

      if (requestData) {
        requestCount = requestData ? requestData.length : 0
      }


      if ((msgCount + requestCount) !== (this.msgCount + this.requestCount)) {
        this.requestCount = requestCount
        this.setOsBadge()
      }

      var schedualArrayKeys = Object.keys(this.props.schedules || {})
      var schedualArray = []
      schedualArrayKeys.forEach(key => {
        schedualArray.push(this.props.schedules[key])
      });


      // console.log('schedualArray>>>', schedualArray)
      schedualArray = schedualArray.sort((item1, item2) => {
        if (!item1 || !item2)
          return 0

        if (!item1.createdAt || !item2.createdAt)
          return 0
        console.log(item1.createdAt)

        if (item1.createdAt.seconds < item2.createdAt.seconds) return -1
        if (item1.createdAt.seconds > item2.createdAt.seconds) return 1
        return 0
      })



      // console.log('schedualArray>>>', schedualArray)

      // var doRedirect = false
      // schedualArray.forEach(schedual => {
      //   if (schedual === null)
      //     doRedirect = true
      // })

      // if (doRedirect === true) {
      //   this.goTo('/schedule/schedule/default')
      //   return (null)
      // }


      return (
        <React.Fragment>
          <div className='noPrint ' style={{ transition: 'all .1s ease', width: leftMenuOpen ? '200px' : '70px', minWidth: leftMenuOpen ? '200px' : '70px', position: 'relative' }}>


            <div style={{ border: 'solid 0px red', transition: 'all .1s ease', display: 'flex', height: osName === 'Windows' ? 'calc(100% - 22px)' : '100%', flexDirection: 'column', position: 'fixed', background: '#454544', overflow: 'hidden', width: leftMenuOpen ? '200px' : '70px', minWidth: leftMenuOpen ? '200px' : '70px', paddingTop: '20px', paddingBottom: '0px' }}>




              {isAdmin &&
                <div style={{ color: '#dbdada', fontSize: '14px', padding: '0px', display: 'flex', paddingLeft: leftMenuOpen ? '20px' : '20px', marginTop: '4px' }}>
                  {/* <i aria-hidden="true" onClick={this.toggleLeftMenu} className="calendar alternate meduim icon" style={{ fontSize: leftMenuOpen ? '18px' : '26px' }} ></i> */}
                  {leftMenuOpen &&
                    <React.Fragment>
                      <span style={{ flexGrow: '1', color: 'gray', fontSize: '9px', marginLeft: '5px' }}> SCHEDULES</span>
                    </React.Fragment>
                  }
                </div>}




              {isAdmin && this.props.schedules && schedualArray.map((schedule, index) => {

                if (!schedule)
                  return null

                var scheduleKey = schedule.key
                const selected = this.getBg(scheduleKey, index)

                return (
                  <div key={scheduleKey} style={{ minHeight: '28px', minWidth: '180px', color: '#dbdada', display: 'flex', padding: '8px', paddingBottom: leftMenuOpen ? '4px' : '6px', paddingTop: leftMenuOpen ? '4px' : '10px', fontSize: '14px', paddingLeft: leftMenuOpen ? '20px' : '20px', background: selected }} onClick={this.goTo('/schedule/schedule/' + scheduleKey)}>
                    <i aria-hidden="true" className={selected ? "circle  meduim icon" : "circle outline meduim icon"} style={{ color: colors[index], fontSize: leftMenuOpen ? '18px' : '26px' }} ></i>
                    {leftMenuOpen &&
                      <React.Fragment>
                        <div style={{ marginLeft: '5px', flexGrow: '1', color: '#dbdada', fontSize: '12px' }}>{schedule.name || '#unknown'}</div>
                        {selected &&
                          // <div tooltip={window._getText("schedual.m.edit")}>
                          <span className="ico ico-cog" style={{ marginRight: '0px', color: '#dbdada' }} onClick={() => { window.dispatchEvent(new CustomEvent("newschedule", { detail: { schedule } })) }}></span>
                          // </div>
                        }
                      </React.Fragment>}
                  </div>
                )
              }
              )}








              <div style={{ paddingBottom: '6px', background: 'rgba(0,0,0,0.05)', marginTop: isAdmin ? 'auto' : '', borderTop: 'solid 1px var(--color-border)', borderBottom: 'solid 1px var(--color-border)', paddingTop: '6px', color: '#dbdada', display: 'flex', flexDirection: 'column', alignSelf: 'flex-end', width: '100%', xminHeight: '130px' }}>


                {!isAdmin &&
                  <React.Fragment>

                    <div style={{ position: 'relative', paddingLeft: leftMenuOpen ? '20px' : '20px', paddingRight: '20px', border: 'solid 0px red', color: '#dbdada', paddingBottom: leftMenuOpen ? '4px' : '6px', paddingTop: leftMenuOpen ? '4px' : '10px', display: 'flex', fontSize: '14px', background: window.location.pathname === '/home' ? 'rgba(35, 33, 30, 0.6)' : '' }} onClick={this.goTo('/home')}>
                      <i aria-hidden="true" className="home meduim icon" style={{ fontSize: leftMenuOpen ? '18px' : '26px' }}></i>

                      {leftMenuOpen &&
                        <React.Fragment>
                          <div style={{ marginLeft: '10px', flexGrow: '1', color: '#dbdada', fontSize: '14px' }}>Home</div>
                          <span style={{ xbackgroundColor: '#f1f1f1', width: '25px', textAlign: 'center', borderRadius: '20px', fontSize: '9px', color: '#dbdada' }}>


                          </span>
                        </React.Fragment>}
                    </div>


                    <div style={{ position: 'relative', paddingLeft: leftMenuOpen ? '20px' : '20px', paddingRight: '20px', border: 'solid 0px red', color: '#dbdada', paddingBottom: leftMenuOpen ? '4px' : '6px', paddingTop: leftMenuOpen ? '4px' : '10px', display: 'flex', fontSize: '14px', background: window.location.pathname === '/schedules' ? 'rgba(35, 33, 30, 0.6)' : '' }} onClick={this.goTo('/schedules')}>
                      <i aria-hidden="true" className="calendar meduim icon" style={{ fontSize: leftMenuOpen ? '18px' : '26px' }}></i>

                      {leftMenuOpen &&
                        <React.Fragment>
                          <div style={{ marginLeft: '10px', flexGrow: '1', color: '#dbdada', fontSize: '14px' }}>Schedual</div>
                          <span style={{ xbackgroundColor: '#f1f1f1', width: '25px', textAlign: 'center', borderRadius: '20px', fontSize: '9px', color: '#dbdada' }}>


                          </span>
                        </React.Fragment>}
                    </div>

                    <div style={{ position: 'relative', paddingLeft: leftMenuOpen ? '20px' : '20px', paddingRight: '20px', border: 'solid 0px red', color: '#dbdada', paddingBottom: leftMenuOpen ? '4px' : '6px', paddingTop: leftMenuOpen ? '4px' : '10px', display: 'flex', fontSize: '14px', background: window.location.pathname === '/chat1' ? 'rgba(35, 33, 30, 0.6)' : '' }} onClick={this.goTo('/chat1')}>
                      <i aria-hidden="true" className="comments meduim icon" style={{ fontSize: leftMenuOpen ? '18px' : '26px' }}></i>

                      {leftMenuOpen &&
                        <React.Fragment>
                          <div style={{ marginLeft: '10px', flexGrow: '1', color: '#dbdada', fontSize: '14px' }}>Chat</div>
                          <span style={{ xbackgroundColor: '#f1f1f1', width: '25px', textAlign: 'center', borderRadius: '20px', fontSize: '9px', color: '#dbdada' }}>
                            {msgCount > 0 && <Label color='purple' style={{ fontSize: '9px' }}>{msgCount}</Label>}
                            {msgCount < 1 && <Label color='black' style={{ fontSize: '9px' }}>{msgCount}</Label>}

                          </span>
                        </React.Fragment>}
                    </div>


                    <div style={{ paddingLeft: leftMenuOpen ? '20px' : '20px', paddingRight: '20px', border: 'solid 0px red', color: '#dbdada', paddingBottom: leftMenuOpen ? '4px' : '6px', paddingTop: leftMenuOpen ? '4px' : '10px', display: 'flex', fontSize: '14px', background: window.location.pathname === '/requests1' ? 'rgba(35, 33, 30, 0.6)' : '' }} onClick={this.goTo('/requests1')}>
                      <i aria-hidden="true" className="inbox icon" style={{ fontSize: leftMenuOpen ? '18px' : '26px' }}></i>
                      {leftMenuOpen &&
                        <React.Fragment>
                          <div style={{ marginLeft: '10px', flexGrow: '1', color: '#dbdada', fontSize: '14px' }}>Request</div>
                          <span style={{ xbackgroundColor: '#f1f1f1', width: '25px', textAlign: 'center', borderRadius: '20px', fontSize: '9px', color: '#dbdada' }}>
                            {requestCount > 0 && <Label color='teal' style={{ fontSize: '9px' }}> {requestCount}</Label>}
                            {requestCount < 1 && <Label color='black' style={{ fontSize: '9px' }}> {requestCount}</Label>}

                          </span>
                        </React.Fragment>}
                    </div>
                  </React.Fragment>
                }


                {isAdmin &&
                  <React.Fragment>

                    <div style={{ position: 'relative', paddingLeft: leftMenuOpen ? '20px' : '20px', paddingRight: '20px', border: 'solid 0px red', color: '#dbdada', paddingBottom: leftMenuOpen ? '4px' : '6px', paddingTop: leftMenuOpen ? '4px' : '10px', display: 'flex', fontSize: '14px', background: window.location.pathname === '/chat' ? 'rgba(35, 33, 30, 0.6)' : '' }} onClick={this.goTo('/chat')}>
                      <i aria-hidden="true" className="comments meduim icon" style={{ fontSize: leftMenuOpen ? '18px' : '26px' }}></i>

                      {leftMenuOpen &&
                        <React.Fragment>
                          <div style={{ marginLeft: '10px', flexGrow: '1', color: '#dbdada', fontSize: '14px' }}>Chat</div>
                          <span style={{ xbackgroundColor: '#f1f1f1', width: '25px', textAlign: 'center', borderRadius: '20px', fontSize: '9px', color: '#dbdada' }}>
                            {msgCount > 0 && <Label color='purple' style={{ fontSize: '9px' }}>{msgCount}</Label>}
                            {msgCount < 1 && <Label color='black' style={{ fontSize: '9px' }}>{msgCount}</Label>}

                          </span>
                        </React.Fragment>}
                    </div>


                    <div style={{ paddingLeft: leftMenuOpen ? '20px' : '20px', paddingRight: '20px', border: 'solid 0px red', color: '#dbdada', paddingBottom: leftMenuOpen ? '4px' : '6px', paddingTop: leftMenuOpen ? '4px' : '10px', display: 'flex', fontSize: '14px', background: window.location.pathname === '/requests' ? 'rgba(35, 33, 30, 0.6)' : '' }} onClick={this.goTo('/requests')}>
                      <i aria-hidden="true" className="inbox icon" style={{ fontSize: leftMenuOpen ? '18px' : '26px' }}></i>
                      {leftMenuOpen &&
                        <React.Fragment>
                          <div style={{ marginLeft: '10px', flexGrow: '1', color: '#dbdada', fontSize: '14px' }}>Request</div>
                          <span style={{ xbackgroundColor: '#f1f1f1', width: '25px', textAlign: 'center', borderRadius: '20px', fontSize: '9px', color: '#dbdada' }}>
                            {requestCount > 0 && <Label color='teal' style={{ fontSize: '9px' }}> {requestCount}</Label>}
                            {requestCount < 1 && <Label color='black' style={{ fontSize: '9px' }}> {requestCount}</Label>}

                          </span>
                        </React.Fragment>}
                    </div>



                    <div style={{ paddingLeft: leftMenuOpen ? '20px' : '20px', paddingRight: '20px', border: 'solid 0px red', color: '#dbdada', paddingBottom: leftMenuOpen ? '4px' : '6px', paddingTop: leftMenuOpen ? '4px' : '10px', display: 'flex', fontSize: '14px', background: window.location.pathname === '/schedule/users' ? 'rgba(35, 33, 30, 0.6)' : '' }} onClick={this.goTo('/schedule/users')}>
                      <i aria-hidden="true" className="users meduim icon" style={{ fontSize: leftMenuOpen ? '18px' : '26px' }}></i>
                      {leftMenuOpen &&
                        <React.Fragment>
                          <div style={{ marginLeft: '10px', flexGrow: '1', color: '#dbdada', fontSize: '14px' }}>Users</div>
                          <span style={{ xbackgroundColor: '#f1f1f1', width: '25px', textAlign: 'center', borderRadius: '20px', fontSize: '9px', color: '#dbdada' }}>{userCount}</span>
                        </React.Fragment>}
                    </div>
                    <div style={{ paddingLeft: leftMenuOpen ? '20px' : '20px', paddingRight: '20px', border: 'solid 0px red', color: '#dbdada', paddingBottom: leftMenuOpen ? '4px' : '6px', paddingTop: leftMenuOpen ? '4px' : '10px', display: 'flex', fontSize: '14px', background: window.location.pathname === '/reports' ? 'rgba(35, 33, 30, 0.6)' : '' }} onClick={this.goTo('/reports')}>
                      <i aria-hidden="true" className="list alternate meduim icon" style={{ fontSize: leftMenuOpen ? '18px' : '26px' }}></i>
                      {leftMenuOpen &&
                        <React.Fragment>
                          <div style={{ marginLeft: '10px', flexGrow: '1', color: '#dbdada', fontSize: '14px' }}>Reports</div>
                          {/* <span style={{ xbackgroundColor: '#f1f1f1', width: '25px', textAlign: 'center', borderRadius: '20px', fontSize: '9px', color: '#dbdada' }}>{credits}</span> */}
                        </React.Fragment>}
                    </div>
                  </React.Fragment>}
                {/* <div style={{ paddingLeft: leftMenuOpen ? '20px' : '20px', paddingRight: '20px', border: 'solid 0px red', color: '#dbdada', paddingBottom: leftMenuOpen ? '4px' : '6px', paddingTop: leftMenuOpen ? '4px' : '10px', display: 'flex', fontSize: '14px', background: window.location.pathname === '/credits' ? 'rgba(35, 33, 30, 0.6)' : '' }} onClick={this.goTo('/credits')}>
                  <i aria-hidden="true" className="money meduim icon" style={{ fontSize: leftMenuOpen ? '18px' : '26px' }}></i>
                  {leftMenuOpen &&
                    <React.Fragment>
                      <div style={{ marginLeft: '10px', flexGrow: '1', color: '#dbdada', fontSize: '14px' }}>Credits</div>
                      <span style={{ xbackgroundColor: '#f1f1f1', width: '25px', textAlign: 'center', borderRadius: '20px', fontSize: '9px', color: '#dbdada' }}>{credits}</span>
                    </React.Fragment>}
                </div> */}


              </div>
              <div style={{ marginTop: isAdmin ? '0px' : 'auto' }}> </div>
              <div style={{ borderTop: 'solid 1px var(--color-border)', display: 'flex', paddingTop: '5px' }}>

                <div style={{ flexGrow: '1', }}>
                  {isAdmin &&
                    <div style={{ paddingLeft: '8px' }} onClick={() => { window.dispatchEvent(new CustomEvent("newschedule", { detail: {} })) }}>
                      <div tooltip_t={window._getText("schedual.m.addNewSchedule")} style={{ display: 'inline' }}>
                        <span className="ico ico-plus" style={{ marginRight: '8px', color: '#ccc', fontSize: '20px' }} ></span>
                      </div>
                    </div>
                  }
                </div>
                <div style={{ border: 'solid 0px red', }}>
                  <div style={{ paddingRight: '8px', paddingLeft: '8px' }} onClick={this.toggleLeftMenu} >

                    {leftMenuOpen && <span className="ico ico-left-open-mini" style={{ color: '#ccc', fontSize: '20px' }}></span>}
                    {!leftMenuOpen && <span className="ico ico-right-open-mini" style={{ color: '#ccc', fontSize: '20px' }}></span>}


                  </div>
                </div>


              </div>

            </div>




          </div>

          {this.state.inOnline === false &&
            <div style={{ position: 'fixed', top: '1px', left: '70px', fontSize: '10px', color: 'solver' }}  >
              {/* <span><Icon name='exclamation' color='red' />
              <div style={{ fontSize: '10px', display: 'inline' }}>Offline</div></span>
               */}
              Offline
            </div>
          }


        </React.Fragment>

      )

    }

    console.log('>>>isMacOs end!', isMacOs)

    if (!isLandscape) {



      return (
        <React.Fragment>

          <div style={{ backgroundColor: 'var(--color-bg2)', textAlign: 'center', width: '100%', borderTop: 'solid 0px rgba(34, 36, 38, 0.15)', position: isKeyboardOpen ? 'absolute' : 'fixed', bottom: '0px', left: (isKeyboardOpen && isMobile) ? '-4000px' : '0px', zIndex: '100', }}>
            <div style={{ display: 'inline-block' }} className='menuPortrait' >
              <Menu icon='labeled' style={{ borderRight: 'solid 0px silver' }}  >
                {this.innerMenu(isLandscape)}
              </Menu>

            </div>
          </div>

        </React.Fragment>
      )

    } else {
      return (
        <React.Fragment>

          <div className='menuLandscape' >
            <Menu pointing icon attached='top' vertical style={{ borderTop: 'none', borderBottom: 'none', borderRight: 'solid 1px var(--color-bg1)' }} >
              {this.innerMenu(isLandscape)}

            </Menu>
            {this.state.inOnline === false &&
              <div style={{ position: "fixed", bottom: '0px', padding: '3px' }}>
                <span><Icon name='exclamation circle' color='red' /><div style={{ fontSize: '11px', display: 'inline' }}>Offline</div></span>
              </div>
            }
          </div>

        </React.Fragment>
      )
    }
  }
}



const mapStateToProps = (state) => {
  let schedules = null
  if (state.firestore.data.schedules) {
    schedules = state.firestore.data.schedules;
  }
  return {

    currentUser: state.firebase.profile,
    requestData: state.firestore.ordered['requestAllListner'],
    isKeyboardOpen: state.viewstate.isKeyboardOpen,
    isLandscape: state.viewstate.isLandscape,
    project: state.firestore.data.project,
    schedules,

  };
}
const mapActions = {

}
export default withRouter(connect(mapStateToProps, mapActions)(MenuSchema))
//export default MenuSchema;
