import React, { Component } from 'react'
import { Button, Image, Loader, Message, Checkbox, div } from "../../common/component/Button";
import { closeModal } from "../../redux/action/modalAction";
import { connect } from "react-redux";
import { isMobile, isAndroid } from 'react-device-detect';
import PropTypes from 'prop-types'
import { FireHelper } from '../../common/util/FireHelper'
import noUserImage from '../../../img/noimg.png'
import AvatarEditor from 'react-avatar-editor'
import { uploadProfileImage } from "../../redux/action/userAction";
import { toastr } from 'react-redux-toastr'
import { auth } from '../../config/firebase'
import { getUserImage } from '../../common/util/helpers'




var isElectronApp = (process.env.REACT_APP_ELECTRON === '1')

const shortid = require('shortid');
const modalKey = 'appdiv'
export class Appdiv extends Component {
    static contextTypes = { router: PropTypes.object }

    componentDidUpdate(prevProps, prevState, snapshot) {

        //on open
        if (prevProps.isOpen === false && this.props.isOpen === true) {
            this.setState({ isLoading: false, push: this.prop.currentUser.push, valueName: this.props.sName, isNew: (this.props.sKey === null), error: (this.props.sName < 1) })
        }

        try {
            if (this.props.currentUser && prevProps.currentUser.extraData !== this.props.currentUser.extraData) {
                if (!prevProps.currentUser && this.props.currentUser.extraData && this.props.currentUser.extraData.phone)
                    this.setState({ phone: this.props.currentUser.extraData.phone })
                else if ((this.props.currentUser.extraData && this.props.currentUser.extraData.phone) && prevProps.currentUser.extraData.phone !== this.props.currentUser.extraData.phone)
                    this.setState({ phone: this.props.currentUser.extraData.phone })
                else if (!this.props.currentUser.extraData || !this.props.currentUser.extraData.phone)
                    this.setState({ phone: '' })

                if (!prevProps.currentUser && this.props.currentUser.extraData && this.props.currentUser.extraData.phone2)
                    this.setState({ phone2: this.props.currentUser.extraData.phone2 })
                else if ((this.props.currentUser.extraData && this.props.currentUser.extraData.phone2) && prevProps.currentUser.extraData.phone2 !== this.props.currentUser.extraData.phone2)
                    this.setState({ phone2: this.props.currentUser.extraData.phone2 })
                else if (!this.props.currentUser.extraData || !this.props.currentUser.extraData.phone2)
                    this.setState({ phone2: '' })

            }
        } catch (error) {
            console.log(error)
        }

    }

    constructor(props) {
        super(props);
        //this.fileRef = React.createRef();
        var phone = ''
        var phone2 = ''
        var email = ''
        var push = false
        if (this.props.currentUser && this.props.currentUser.extraData && this.props.currentUser.extraData.phone)
            phone = this.props.currentUser.extraData.phone
        if (this.props.currentUser && this.props.currentUser.extraData && this.props.currentUser.extraData.phone2)
            phone2 = this.props.currentUser.extraData.phone2

        if (this.props.currentUser && this.props.currentUser.email)
            email = this.props.currentUser.email

        if (this.props.currentUser.push === true)
            push = true

        this.state = {
            email: email,
            isLoading: false,
            isNew: true,
            phone: phone,
            phone2: phone2,
            error: true,
            image: null,
            pass: '',
            rotate: 0,
            savingImage: false,
            push: push
        };
    }
    onCloseModal = () => {
        this.props.onCloseModal(modalKey)
    }

    onClickDelete = () => {
        this.setState({ isLoading: true })
        // doShit.then(() => {
        //     this.setState({ isLoading: false })
        //     this.onCloseModal()
        // })

    }

    onChangeText = (e) => {
        this.setState({ valueName: e.target.value, error: (e.target.value.length < 1) });
    }
    onClickSave = () => {

        this.setState({ isLoading: true })
        // doShit.then(() => {
        //     this.setState({ isLoading: false })
        //     this.onCloseModal()
        // })
    }

    signOut = () => {

        this.props.onCloseModal(modalKey)
        //this.props.onSignOut()
        this.context.router.history.push('/signout')
    }
    onSave = async (e) => {
        if (e) {
            e.preventDefault()
            e.target.focus()
        }

        if (!this.props.currentUser.inSysUserKey) {
            toastr.error('Error', window._getText('usernotmapped_txt1'), { timeOut: 30000 });
            return
        }

        const { currentUser } = this.props
        this.setState({ isLoading: true })

        if (this.state.phone || this.state.phone2) {
            var newData = {}
            if (this.state.phone)
                newData.phone = this.state.phone
            if (this.state.phone2)
                newData.phone2 = this.state.phone2

            await FireHelper.updateInAppUser(newData, currentUser)
            //console.log(res)
            toastr.success('Saved');
        }


    }

    onSaveALL = async (e) => {
        if (e) {
            e.preventDefault()
            e.target.focus()
        }

        if (!this.props.currentUser.inSysUserKey) {
            toastr.error('Error', window._getText('usernotmapped_txt1'), { timeOut: 30000 });
            return
        }

        const { currentUser } = this.props
        this.setState({ isLoading: true })

        // if (this.state.phone || this.state.phone2) {
        var newData = {}
        //if (this.state.phone)
        newData.phone = this.state.phone
        //if (this.state.phone2)
        newData.phone2 = this.state.phone2

        if (this.state.name && this.state.name.length > 1)
            newData.name = this.state.name

        await FireHelper.updateInAppUser(newData, currentUser)
        // console.log(res)
        //  toastr.success('Saved');
        // }


        // if (this.state.image) {
        //     if (this.editor) {
        //         this.setState({ savingImage: true })
        //         const { currentUser } = this.props
        //         try {
        //             this.editor.getImageScaledToCanvas().toBlob(async (blob) => {
        //                 await this.props.uploadProfileImage(blob, currentUser.inSysUserKey, this.props.projectKey)
        //                 await FireHelper.updateInAppUser({ image: shortid.generate() }, currentUser)
        //                 setTimeout(() => {
        //                     this.setState({ savingImage: false, image: false })
        //                 }, 2000);

        //             }, 'image/jpg');
        //             //  toastr.success('Saved');
        //         } catch (error) {
        //             this.setState({ savingImage: false })
        //             if (error.message)
        //                 toastr.error('Error', error.message, { timeOut: 30000 });
        //             else
        //                 toastr.error('Error', error, { timeOut: 30000 });

        //             return
        //         }
        //     }
        // }

        if (this.state.email) {
            if (this.state.email.length > 5 && this.state.email.indexOf('@') > -1) {
                const email = this.state.email
                const { currentUser } = this.props
                auth.currentUser.updateEmail(email).then(async () => {
                    if (currentUser.inSysUserKey) {
                        await FireHelper.updateInAppUser({ email }, currentUser)
                        // console.log('res', res)
                    } else {
                        //update only user TODO
                    }
                    // toastr.success('Email is updated');
                }).catch(function (error) {
                    if (error.message)
                        toastr.error('Error', error.message, { timeOut: 30000 });
                    else
                        toastr.error('Error', error, { timeOut: 30000 });

                    return

                });
            }
            else {
                toastr.error('Error', 'This is not a vaild Email', { timeOut: 30000 });
                return
            }
        }




        if (this.state.pass) {
            if (this.state.pass.length > 5) {
                var pass = this.state.pass
                this.setState({ pass: '' })
                auth.currentUser.updatePassword(pass).then(() => {
                    // Update successful.
                    // toastr.success('Password is updated');

                }).catch(function (error) {
                    if (error.message)
                        toastr.error('Error', error.message, { timeOut: 30000 });
                    else
                        toastr.error('Error', error, { timeOut: 30000 });

                    return

                });
            }
            else {
                toastr.error('Error', 'Minimum 6 chars', { timeOut: 30000 });
                return
            }
        }



        toastr.success('Saved');

    }


    tbChange = (tb) => (e) => this.setState({ tb: e.target.value })

    setEditorRef = (editor) => this.editor = editor

    handleChange = (event) => this.setState({ sliderValue: Number(event.target.value) });

    onRotateImage = (e) => {
        e.preventDefault()
        this.setState({ rotate: this.state.rotate + 90 });
    }

    onSaveImage = () => {
        if (!this.props.currentUser.inSysUserKey) {
            toastr.error('Error', window._getText('usernotmapped_txt1'), { timeOut: 30000 });
            return
        }
        if (this.state.image) {
            if (this.editor) {
                this.setState({ savingImage: true })
                const { currentUser } = this.props
                try {
                    this.editor.getImageScaledToCanvas().toBlob(async (blob) => {
                        await this.props.uploadProfileImage(blob, currentUser.inSysUserKey, this.props.projectKey)
                        await FireHelper.updateInAppUser({ image: shortid.generate() }, currentUser)
                        setTimeout(() => {
                            this.setState({ savingImage: false, image: false })
                        }, 2000);

                    }, 'image/jpg');
                    toastr.success('Saved');
                } catch (error) {
                    this.setState({ savingImage: false })
                    if (error.message)
                        toastr.error('Error', error.message, { timeOut: 30000 });
                    else
                        toastr.error('Error', error, { timeOut: 30000 });
                }
            }
        }
    }

    onInputChange = (e) => {

    }
    onFocus = (e) => {
        // const target = e.target
        // var top = target.parentNode.offsetTop
        // if (document.getElementById('modalBodyScroll')) {
        //     document.getElementById('modalBodyScroll').scrollTo(0, top);
        //     setTimeout(() => { document.getElementById('modalBodyScroll').scrollTo(0, top) }, 100)
        //     setTimeout(() => { document.getElementById('modalBodyScroll').scrollTo(0, top) }, 250)
        //     setTimeout(() => { document.getElementById('modalBodyScroll').scrollTo(0, top) }, 500)
        // }
        // const target = e.target
        // target.scrollIntoView();
        // setTimeout(() => { target.scrollIntoView() }, 100)
        // setTimeout(() => { target.scrollIntoView() }, 250)
        // setTimeout(() => { target.scrollIntoView() }, 500)
    }
    onChangeEmail = (e) => {


        if (e)
            e.preventDefault()
        if (!this.props.currentUser.inSysUserKey) {
            toastr.error('Error', window._getText('usernotmapped_txt1'), { timeOut: 30000 });
            return
        }
        if (this.state.email) {
            if (this.state.email.length > 5 && this.state.email.indexOf('@') > -1) {
                const email = this.state.email
                const { currentUser } = this.props
                auth.currentUser.updateEmail(email).then(async () => {
                    if (currentUser.inSysUserKey) {
                        await FireHelper.updateInAppUser({ email }, currentUser)
                        //console.log('res', res)
                    } else {
                        //update only user TODO
                    }
                    toastr.success('Email is updated');
                }).catch(function (error) {
                    if (error.message)
                        toastr.error('Error', error.message, { timeOut: 30000 });
                    else
                        toastr.error('Error', error, { timeOut: 30000 });

                });
            }
            else
                toastr.error('Error', 'This is not a vaild Email', { timeOut: 30000 });
        }


    }
    onSavePassword = (e) => {
        if (e)
            e.preventDefault()

        if (!this.props.currentUser.inSysUserKey) {
            toastr.error('Error', window._getText('usernotmapped_txt1'), { timeOut: 30000 });
            return
        }
        if (this.state.pass) {
            if (this.state.pass.length > 5) {
                var pass = this.state.pass
                this.setState({ pass: '' })
                auth.currentUser.updatePassword(pass).then(() => {
                    // Update successful.
                    toastr.success('Password is updated');

                }).catch(function (error) {
                    if (error.message)
                        toastr.error('Error', error.message, { timeOut: 30000 });
                    else
                        toastr.error('Error', error, { timeOut: 30000 });

                });
            }
            else
                toastr.error('Error', 'Minimum 6 chars', { timeOut: 30000 });
        }

    }
    onBlur = () => {
        if (isMobile)
            this.onSave()
    }
    onBlurEmail = () => {
        if (isMobile)
            this.onChangeEmail()
    }
    onBlurPass = () => {
        if (isMobile)
            this.onSavePassword()
    }
    onSettingsCheck = async (e, { name, value, checked }) => {
        e.preventDefault()
        if (!this.props.currentUser.inSysUserKey) {
            toastr.error('Error', window._getText('usernotmapped_txt1'), { timeOut: 30000 });
            return
        }

        const { currentUser } = this.props
        this.setState({ push: checked })
        await FireHelper.addUserData({ push: checked }, currentUser)


    }
    handleFileUpload = (e) => {
        // const file = files[0]

        if (e.target.files) {
            var file = e.target.files[0]
            this.setState({ image: file })
            //this.props.userObj.image = file;
        }
    }
    render() {
        // if (this.props.isOpen === false)
        //     return ''

        const { currentUser, projectKey } = this.props
        let isCordovaAndroid = isAndroid && window.isCordovaApp
        let isMapped = !!currentUser.inSysUserKey


        if (this.state.name === undefined && currentUser.extraData)
            this.setState({ name: currentUser.extraData.name || '#unknown' })


        return (
            <div >


                {!isMapped &&

                    <Message>
                        <Message.Header>{window._getText('usernotmapped_title')}</Message.Header>
                        <p>{window._getText('usernotmapped_txt1')} <br />
                            {window._getText('usernotmapped_txt2')}</p>
                    </Message>

                }

                <div style={{ display: 'flex' }} className='dragWindow'  >
                    {/* <div className='txt' style={{ flexGrow: '1' }}>
                        <Icon name="user" size='large' />  {currentUser.extraData && currentUser.extraData.name && currentUser.extraData.name}
                    </div> */}
                    <Button style={{ marginLeft: 'auto' }} compact onClick={() => { this.signOut() }} color='blue' content={window._getText('signout')} icon='log out' ></Button>
                </div>

                <div>


                    {/* <Icon name='user' />{window._getText('picture')} */}
                    {this.state.savingImage && <Loader inline >{window._getText('uploading_image')}</Loader>}

                    <form>

                        {/* {!this.state.savingImage && !this.state.image && (currentUser.extraData && currentUser.extraData.image && currentUser.extraData.image) &&
              

                        } */}



                        {!this.state.savingImage && this.state.image &&

                            <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                                <AvatarEditor
                                    style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                                    ref={this.setEditorRef}
                                    image={URL.createObjectURL(this.state.image)}
                                    width={90}
                                    height={90}
                                    border={20}
                                    borderRadius={50}
                                    scale={Number(this.state.sliderValue || 1)}
                                    rotate={this.state.rotate}
                                />
                                <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '4px' }}>

                                    <Button
                                        style={{ marginRight: '5px', maxHeight: '30px' }}
                                        compact
                                        onClick={() => this.setState({ image: null })}
                                        icon="x"
                                        disabled={this.state.savingImage}
                                    />
                                    <input


                                        id="typeinp"
                                        type="range"
                                        min="0.5" max="5"
                                        defaultValue="1"
                                        value={this.state.value}
                                        onChange={this.handleChange}
                                        step="0.01"
                                        style={{ width: '330px', float: 'right' }}
                                    />

                                    <Button
                                        style={{ marginLeft: '5px', maxHeight: '30px' }}
                                        compact
                                        onClick={this.onRotateImage}
                                        icon="redo"
                                        disabled={this.state.savingImage}
                                    />
                                    <Button style={{ marginLeft: 'auto', maxHeight: '30px' }} compact onClick={this.onSaveImage} color='green' content={window._getText('upload')} ></Button>

                                </div>

                            </div>

                        }

                        {!this.state.savingImage && !this.state.image &&
                            <div style={{ padding: '10px' }}>




                                <label htmlFor='upload' style={{ width: '100%', display: isCordovaAndroid ? 'none' : '' }}>

                                    <div style={{ height: '130px' }}>
                                        <Image style={{ maxWidth: '100px', maxHeight: '100px', marginLeft: 'auto', marginRight: 'auto' }}
                                            src={currentUser.extraData.image ?
                                                getUserImage(projectKey, currentUser.inSysUserKey, currentUser.extraData.image)
                                                : noUserImage} circular
                                            onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }}

                                        />
                                    </div>

                                </label>
                                <input className={isCordovaAndroid ? 'custom-file-input' : ''} type="file" ref={this.fileRef} id="upload" accept="image/*" onChange={(e) => { this.handleFileUpload(e) }} style={{ display: isCordovaAndroid ? 'inline-block' : 'none', border: 'none' }} />



                            </div>
                        }



                        <div style={{ display: 'flex', flexDirection: 'column', }}>

                            <div style={{ display: 'flex', flexDirection: 'row', borderBottom: 'dotted 1px var(--color-border)', minHeight: '35px', marginLeft: '30px', marginRight: '30px' }}>
                                <div style={{ paddingTop: '11px', paddingRight: '8px', width: '150px', textAlign: 'right', fontWeight: 'bold', }}>
                                    {window._getText('name')} :
                                </div>
                                <div style={{ paddingTop: '11px', }}>
                                    <input autoComplete="new-password" type='text' maxLength={40} value={this.state.name} onChange={e => this.setState({ name: e.target.value })} style={{ border: 'none', width: '250px' }} />
                                </div>

                            </div>


                            <div style={{ display: 'flex', flexDirection: 'row', borderBottom: 'dotted 1px var(--color-border)', minHeight: '35px', marginLeft: '30px', marginRight: '30px' }}>
                                <div style={{ paddingTop: '11px', paddingRight: '8px', width: '150px', textAlign: 'right', fontWeight: 'bold', }}>
                                    {window._getText('email')} :
                                </div>
                                <div style={{ paddingTop: '11px', }}>
                                    <input autoComplete="new-password" type='mail' maxLength={40} value={this.state.email} onChange={e => this.setState({ email: e.target.value })} style={{ border: 'none', width: '250px' }} />
                                </div>

                            </div>


                            <div style={{ display: 'flex', flexDirection: 'row', borderBottom: 'dotted 1px var(--color-border)', minHeight: '35px', marginLeft: '30px', marginRight: '30px' }}>
                                <div style={{ paddingTop: '11px', paddingRight: '8px', width: '150px', textAlign: 'right', fontWeight: 'bold', }}>
                                    {window._getText('phone')} :
                                </div>
                                <div style={{ paddingTop: '11px', }}>
                                    <input autoComplete="new-password" type='tel' maxLength={40} value={this.state.phone} onChange={e => this.setState({ phone: e.target.value })} style={{ border: 'none', width: '250px' }} />

                                </div>
                            </div>




                            <div style={{ display: 'flex', flexDirection: 'row', borderBottom: 'dotted 1px var(--color-border)', minHeight: '35px', marginLeft: '30px', marginRight: '30px' }}>
                                <div style={{ paddingTop: '11px', paddingRight: '8px', width: '150px', textAlign: 'right', fontWeight: 'bold', }}>
                                    {window._getText('phone')} 2 :
                            </div>
                                <div style={{ paddingTop: '11px', }}>
                                    <input autoComplete="new-password" type='tel' maxLength={40} value={this.state.phone2} onChange={e => this.setState({ phone2: e.target.value })} style={{ border: 'none', width: '250px' }} />
                                </div></div>


                            <div style={{ display: 'flex', flexDirection: 'row', borderBottom: 'dotted 1px var(--color-border)', minHeight: '35px', marginLeft: '30px', marginRight: '30px' }}>
                                <div style={{ paddingTop: '11px', paddingRight: '8px', width: '150px', textAlign: 'right', fontWeight: 'bold', }}>
                                    {window._getText('appdiv.changepass')} :
                                </div>
                                <div style={{ paddingTop: '11px', }}>
                                    <input autoComplete="new-password" type='text' maxLength={40} value={this.state.pass} onChange={e => this.setState({ pass: e.target.value })} style={{ border: 'none', width: '250px' }} />
                                </div>
                            </div>
                        </div>

                        <div style={{ visibility: isElectronApp === true ? 'hidden' : '', display: 'flex', flexDirection: 'row', borderBottom: 'dotted 1px var(--color-border)', minHeight: '35px', marginLeft: '30px', marginRight: '30px' }}>
                            <div style={{ paddingTop: '11px', paddingRight: '8px', width: '150px', textAlign: 'right', fontWeight: 'bold', }}>
                                {window._getText('appdiv.pushmessages')} :
                            </div>
                            <div style={{ paddingTop: '11px', }}>
                                {window._getText('off')} <Checkbox toggle name='push' onChange={this.onSettingsCheck} checked={this.state.push} /> {window._getText('on')}

                            </div>


                        </div>



                        <div style={{ textAlign: 'right', position: 'fixed', bottom: '10px', right: '10px' }}>
                            <Button color='green' onClick={this.onSaveALL} content='Done' compact></Button>

                        </div>






                        {/* <div> */}
                        {/* <div style={{ marginBottom: '5px' }}><Icon name='user' />{window._getText('appdiv.user')}</div> */}
                        {/* <div style={{ padding: '5px' }}>

                            {currentUser.extraData && currentUser.extraData.name && currentUser.extraData.name} <br />
                        </div>
                    </div> */}
                        {/* <div>
                        <div style={{ marginBottom: '5px' }}><Icon name='mail' />{window._getText('email')}</div>
                        <div style={{ padding: '5px' }}>
                            <form>
                                <Input autoComplete="new-password" onBlur={this.onBlurEmail} type='mail' maxLength={40} value={this.state.email} onChange={e => this.setState({ email: e.target.value })} />
                                <Button compact type="submit" onClick={this.onChangeEmail} style={{ position: isMobile ? 'absolute' : '', left: '-1000px', marginLeft: '30px' }}>{window._getText('update')}</Button>
                            </form>
                        </div>
                    </div> */}
                        {/* <div>
                        <div style={{ marginBottom: '5px' }}><Icon name='phone' />{window._getText('phone')}</div>
                        <div style={{ padding: '5px' }}>
                            <form>
                                <Input autoComplete="new-password" onBlur={this.onBlur} type='tel' maxLength={40} value={this.state.phone} onChange={e => this.setState({ phone: e.target.value })} />
                                <Button compact type="submit" onClick={this.onSave} style={{ position: isMobile ? 'absolute' : '', left: '-1000px', marginLeft: '30px' }}>{window._getText('update')}</Button>
                            </form>
                        </div>
                    </div> */}
                        {/* <div>
                        <div style={{ marginBottom: '5px' }}><Icon name='phone' />{window._getText('phone')} 2</div>
                        <div style={{ padding: '5px' }}>
                            <form>
                                <Input autoComplete="new-password" onBlur={this.onBlur} type='tel' maxLength={40} value={this.state.phone2} onChange={e => this.setState({ phone2: e.target.value })} />
                                <Button compact type="submit" onClick={this.onSave} style={{ position: isMobile ? 'absolute' : '', left: '-1000px', marginLeft: '30px' }}>{window._getText('update')}</Button>
                            </form>
                        </div>
                    </div> */}


                        {/* <div>
                        <div style={{ marginBottom: '5px' }}><Icon name='lock' />{window._getText('appdiv.changepass')}</div>
                        <div style={{ padding: '5px' }}>
                            <form>
                                <Input autoComplete="new-password" onBlur={this.onBlurPass} type='text' maxLength={40} value={this.state.pass} onChange={e => this.setState({ pass: e.target.value })} />
                                <Button compact type="submit" onClick={this.onSavePassword} style={{ position: isMobile ? 'absolute' : '', left: '-1000px', marginLeft: '30px' }}>{window._getText('update')}</Button>
                            </form>
                        </div>
                    </div> */}
                        {/* 
                    <div>
                        <div style={{ marginBottom: '5px' }}><Icon name='lock' />{window._getText('appdiv.pushmessages')}</div>
                        <div style={{ padding: '5px' }}>
                            <form>
                                {window._getText('off')} <Checkbox toggle name='push' onChange={this.onSettingsCheck} checked={this.state.push} /> {window._getText('on')} <br />
                            </form>
                        </div>
                    </div> */}



                    </form>
                    {/* <span style={{ color: '#fff' }}>{currentUser.inSysUserKey} | {currentUser.key}</span> */}
                </div>

            </div >
        )

    }
}


const mapActions = {
    onCloseModal: closeModal,
    uploadProfileImage,
};

const mapStateToProps = state => {

    return {
        // authUser: state.firebase.auth,

        projectKey: state.firebase.profile.projectKey,
        currentUser: state.firebase.profile,
        //isOpen: state.modal.modals[modalKey].open || false,
        //data: state.modal.modals[modalKey].data || {}
    };
};
export default connect(
    mapStateToProps,
    mapActions
)(Appdiv);



