import React, { Component } from 'react'
// import Account from '../user/account/Account'
// import UserSettings from '../user/settings/UserSettings'
import AppSettings from '../schedule/settings/AppSettings'
import Billing from '../schedule/settings/Billing'
import Wallet from '../schedule/settings/Wallet'
import AppMenu from "../modal/AppMenu";
import { Icon } from '../../common/component/Button'
import ModalFrame from '../modal/ModalFrame'

const modalKey = 'appSettings'
export default class SettingsModal extends Component {
    state = { tabIndex: 1 }
    setTabIndex = (index) => () => {
        this.setState({ tabIndex: index })
    }
    styleTab = { borde: 'solid 1px red', padding: '10px' }
    style = {
        tab: { borde: 'solid 1px red', padding: '10px', height: '70px', marginLeft: '2px', textAlign: 'center', paddingTop: '15px', minWidth: '80px' },
        tabSelected: { borde: 'solid 1px red', padding: '10px', background: 'rgba(0,0,0,0.05)', height: '70px', borderTopRightRadius: '8px', borderTopLeftRadius: '8px', marginLeft: '2px', textAlign: 'center', paddingTop: '15px', minWidth: '80px' }
    }

    handleClose = () => { }
    onCloseModal = () => {
        this.props.closeModal(modalKey);
    }
    render() {
        console.log('appSettings >> render')
        return (


            <ModalFrame
                name={modalKey}
                // className='modalAniSlideUp'
                open={this.props.isOpen}
                onClose={this.onCloseModal}
                // dimmer='inverted'
                onClickClose={this.onCloseModal}
            >


                <ModalFrame.Content style={{ padding: '0px' }}>
                    <div style={{ display: 'flex', border: 'solid 0px red', flexDirection: 'column' }}>
                        <header className="toolbar toolbar-header" style={{}}>
                            <h1 className="title" style={{ marginTop: '10px' }}>Preferences</h1>

                            <div style={{ display: 'flex', border: 'solid 0px red', paddingLeft: '8px', }}>
                                <div style={this.state.tabIndex === 1 ? this.style.tabSelected : this.style.tab} onClick={this.setTabIndex(1)}>

                                    <Icon name='user circle' style={{ fontSize: '30px', height: 'auto', paddingBottom: '8px' }}></Icon><br />
                                    Account</div>
                                {this.props.isAdmin === true &&
                                    <React.Fragment>
                                        <div style={this.state.tabIndex === 2 ? this.style.tabSelected : this.style.tab} onClick={this.setTabIndex(2)}>
                                            <Icon name='cog' style={{ fontSize: '30px', height: 'auto', paddingBottom: '8px' }}></Icon><br />
                                            Settings</div>

                                        <div style={this.state.tabIndex === 3 ? this.style.tabSelected : this.style.tab} onClick={this.setTabIndex(3)}>
                                            <Icon name='money' style={{ fontSize: '30px', height: 'auto', paddingBottom: '8px' }}></Icon><br />
                                            Billing</div>
                                    </React.Fragment>
                                }
                                {/* <div style={this.state.tabIndex === 4 ? this.style.tabSelected : this.style.tab} onClick={this.setTabIndex(4)}>
                                    <Icon name='archive' style={{ fontSize: '40px', height: 'auto', paddingBottom: '8px' }}></Icon><br />
                                    Wallet</div> */}
                            </div>
                        </header>
                        <div style={{ felxGrow: '1', border: 'solid 0px red', padding: '8px', height: 'calc(100% - 86px)', overflow: 'auto' }}>
                            {this.state.tabIndex === 1 && <AppMenu></AppMenu>}
                            {this.state.tabIndex === 2 && <AppSettings></AppSettings>}
                            {this.state.tabIndex === 3 && <Billing></Billing>}
                            {this.state.tabIndex === 4 && <Wallet></Wallet>}
                        </div>

                    </div>
                </ModalFrame.Content>
                <ModalFrame.Actions></ModalFrame.Actions>
            </ModalFrame>
        )
    }
}
