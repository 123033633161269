import firebase, { db, auth } from '../../config/firebase'
import { toastr } from 'react-redux-toastr'
import shortid from 'shortid'
import moment from 'moment'
import { createSelector } from 'reselect';
import moize from "moize";

import { getUserImage } from './helpers'

import noUserImage from '../../../img/noimg.png'

//const collection = require('lodash/collection')

export const FireHelper = {
    getUserlist: moize.deep((users, projectKey) => {
        ////console.log('#>>>>>>>>getUserlist', users)
        var userList = [];
        var userFullList = [];

        if (users) {
            userFullList = users.userlist || {};
            userList = Object.keys(userFullList).map(key => {
                if (userFullList[key] == null) return null;
                if (userFullList[key].isActive == null) return null;
                // if (userFullList[key].sysUserKey == null) return null; // TODO JENS FIX THIS IN THE CHATT!

                const u = userFullList[key]
                const image = u.image ? getUserImage(projectKey, key, u.image) : noUserImage
                return { text: u.name, key: key, sysKey: u.sysUserKey, value: key, image, act: userFullList[key].isActive };
            });
            userList = userList.filter(u => u !== null)
            return { userList: userList || [], userFullList: userFullList || {} }
        }
        else
            return { userList: [], userFullList: {} }

    }, { maxSize: 5 }),

    getUserlistOld: createSelector(
        state => state.firestore.data.users,
        state => state.firebase.profile.projectKey,
        (users, projectKey) => {
            //console.log('#>>>>>>>>>getUserlist', users)
            var userList = [];
            var userFullList = [];

            if (users) {
                userFullList = users.userlist || {};
                userList = Object.keys(userFullList).map(key => {
                    if (userFullList[key] == null) return null;
                    if (userFullList[key].isActive == null) return null;
                    // if (userFullList[key].sysUserKey == null) return null; // TODO JENS FIX THIS IN THE CHATT!

                    const u = userFullList[key]
                    const image = u.image ? getUserImage(projectKey, key, u.image) : noUserImage
                    return { text: u.name, key: key, sysKey: u.sysUserKey, value: key, image, act: userFullList[key].isActive };
                });
                userList = userList.filter(u => u !== null)
                return { userList: userList || [], userFullList: userFullList || {} }
            }
            else
                return { userList: [], userFullList: {} }

        }),

    approveSwapShift: async (shiftKey, shiftKey2, docKey, docKey2, schedualKey, schedualKey2, shiftOwnerKey, shiftOwnerKey2, uid, uid2, rType, profile, msg) => {
        //console.log('approveSwapShift')

        try {
            const projectKey = profile.projectKey
            var batch = db.batch();
            const message = msg ? msg : 'Request approved!'
            //if(deviation)
            //remove the old metadata
            batch.set(db.doc(`projects/${projectKey}/shiftMetaData/${docKey}`), { [schedualKey]: { [shiftKey]: { [rType]: firebase.firestore.FieldValue.delete() } } }, { merge: true })

            batch.set(db.doc(`projects/${projectKey}/schedules/${schedualKey}/events/${docKey}`), { events: { [shiftKey]: { uid: uid2 } }, sortedUserList: firebase.firestore.FieldValue.arrayUnion(uid2) }, { merge: true })
            batch.set(db.doc(`projects/${projectKey}/schedules/${schedualKey2}/events/${docKey2}`), { events: { [shiftKey2]: { uid: uid } }, sortedUserList: firebase.firestore.FieldValue.arrayUnion(uid) }, { merge: true })


            await batch.commit().then(() => {
                toastr.success('', message);
                FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, message)
                FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey2, message)
            }).catch(err => console.log('#Err 5fcGDG' + err))
        } catch (error) {
            //console.log(error)
            toastr.error('Error', error);
        }

    },
    approveChangeShift: async (shiftKey, docKey, scheduleKey, reqDocKey, shiftOwnerKey, newData, rType, profile, msg) => {
        //console.log('approveChangeShift')
        try {
            console.log('approveChangeShift', newData)
            console.log('shiftKey', shiftKey)
            console.log('docKey', docKey)
            console.log('scheduleKey', scheduleKey)
            console.log('reqDocKey', reqDocKey)
            console.log('shiftOwnerKey', shiftOwnerKey)
            console.log('newData', newData)
            console.log('rType', rType)

            console.log('profile', profile)






            const projectKey = profile.projectKey
            var batch = db.batch();
            const message = msg ? msg : 'Request approved!'
            //if(deviation)
            //remove the old metadata
            batch.set(db.doc(`projects/${projectKey}/shiftMetaData/${docKey}`), { [scheduleKey]: { [shiftKey]: { [rType]: firebase.firestore.FieldValue.delete() } } }, { merge: true })

            //remove the old request
            //batch.delete(db.doc(`projects/${projectKey}/requests/${reqDocKey}`))


            if (newData) {
                //update the shift in the schedual
                if (newData.uid)
                    batch.set(db.doc(`projects/${projectKey}/schedules/${scheduleKey}/events/${docKey}`), { events: { [shiftKey]: newData }, sortedUserList: firebase.firestore.FieldValue.arrayUnion(newData.uid) }, { merge: true })
                else
                    batch.set(db.doc(`projects/${projectKey}/schedules/${scheduleKey}/events/${docKey}`), { events: { [shiftKey]: newData } }, { merge: true })

            }

            await batch.commit().then(() => {
                // toastr.success('', message);
                FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, message)
            }).catch(err => console.log('#Err 5fcGDG' + err))
        } catch (error) {
            //console.log(error)
            toastr.error('Error', error);
        }

    },

    removeShift: async (shiftKey, docKey, scheduleKey, reqDocKey, shiftOwnerKey, rType, profile) => {
        //console.log('removeShift')

        try {
            const projectKey = profile.projectKey
            var batch = db.batch();
            //batch.delete(db.doc(`projects/${projectKey}/requests/${reqDocKey}`))
            batch.set(db.doc(`projects/${projectKey}/shiftMetaData/${docKey}`), { [scheduleKey]: { [shiftKey]: { [rType]: firebase.firestore.FieldValue.delete() } } }, { merge: true })

            batch.set(db.doc(`projects/${projectKey}/schedules/${scheduleKey}/events/${docKey}`), { events: { [shiftKey]: firebase.firestore.FieldValue.delete() } }, { merge: true })
            await batch.commit().then(() => {
                toastr.success('', 'shift is removed');
                FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, 'Shift cancelation is approved!')
            }).catch(err => console.log('#Err 5fcGDG' + err))
        } catch (error) {
            //console.log(error)
            toastr.error('Error', error);
        }

    },

    removeRequest: async (docKey, scheduleKey, shiftKey, shiftOwnerKey, rType, profile, msg) => {
        //console.log('removeRequest')
        try {
            const projectKey = profile.projectKey
            const message = msg ? msg : 'Request is declined'
            var batch = db.batch();
            batch.set(db.doc(`projects/${projectKey}/shiftMetaData/${docKey}`), { [scheduleKey]: { [shiftKey]: { [rType]: firebase.firestore.FieldValue.delete() } } }, { merge: true })
            //todo edit metadata!
            await batch.commit().then(() => {
                // toastr.success(message);
                FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, message)
            }).catch(err => console.log('#Err 53456vfG' + err))
        } catch (error) {
            //console.log(error)
            toastr.error('Error', error);
        }

    },
    removeRequestDirect: async (reqDocKey, shiftOwnerKey, rType, profile, msg) => {
        try {
            const projectKey = profile.projectKey
            var batch = db.batch();
            const message = msg ? msg : 'Request is declined'
            //console.log('reqDocKey', reqDocKey)
            batch.delete(db.doc(`projects/${projectKey}/requests/${reqDocKey}`))
            //todo edit metadata!
            await batch.commit().then(() => {
                // toastr.success(message);
                FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, message)
            }).catch(err => console.log('#Err 53456vfG' + err))
        } catch (error) {
            //console.log(error)
            toastr.error('Error', error);
        }

    },
    removeParticipantRequest: async (docKey, scheduleKey, shiftKey, rType, shiftOwnerKey, profile) => {
        try {
            //console.log('removeParticipantRequest', shiftOwnerKey)
            const projectKey = profile.projectKey
            const userKey = profile.inSysUserKey

            var oldDoc = await db.doc(`projects/${projectKey}/shiftMetaData/${docKey}`).get()
            //Maybe use the firebase.firestore.FieldValue.arrayRemove('east_coast')
            if (oldDoc.exists) {
                var oldData = oldDoc.data()
                var newUser = oldData[scheduleKey][shiftKey][rType].sh.newU || []
                oldData[scheduleKey][shiftKey][rType].sh.newU = newUser.filter(x => x !== userKey) // remove current user
                if (oldData[scheduleKey][shiftKey][rType].sh.newU.length === 0) {
                    //ALL partisipants have decliend the request , now we delete it and systemMsg the sender !
                    delete oldData[scheduleKey][shiftKey][rType]
                    const keyCount = Object.keys(oldData[scheduleKey][shiftKey]).length
                    if (keyCount <= 1) {
                        //it is empty , remove it 
                        // delete oldData[scheduleKey][shiftKey]
                        // const keyCount2 = Object.keys(oldData[scheduleKey]).length
                        // alert(keyCount2)
                        oldDoc.ref.set({ [scheduleKey]: { [shiftKey]: firebase.firestore.FieldValue.delete() } }, { merge: true })
                            .then(() => {
                                toastr.success('You declined the offer ');
                                FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, 'All participants have declined your shift change request')
                            }).catch(err => console.log('#Err 53456vfG' + err))
                    }
                    else {
                        oldDoc.ref.set({ [scheduleKey]: { [shiftKey]: { [rType]: firebase.firestore.FieldValue.delete() } } }, { merge: true })
                            .then(() => {
                                toastr.success('You declined the offer ');
                                FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, 'All participants have declined your shift change request')
                            }).catch(err => console.log('#Err 53456vfG' + err))
                    }
                    // oldDoc.ref.set(oldData, { merge: false }).then(() => {
                    //     toastr.success('You declined the offer ');
                    //     FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, 'All participants have declined your shift change request')
                    // }).catch(err => console.log('#Err 53456vfG' + err))
                }
                else {
                    // oldDoc.ref.set(oldData, { merge: false }).then(() => {
                    //     toastr.success('You declined the offer ');
                    //     //FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, 'User XXX have decliend your shift request')
                    // }).catch(err => console.log('#Err 53456vfG' + err))
                    oldDoc.ref.set({ [scheduleKey]: { [shiftKey]: { [rType]: { sh: { newU: firebase.firestore.FieldValue.arrayRemove(userKey) } } } } }, { merge: true })
                        .then(() => {
                            toastr.success('You declined the offer ');
                            //FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, 'User XXX have decliend your shift request')
                        }).catch(err => console.log('#Err 53456vfG' + err))

                }
            }
        } catch (error) {
            //console.log(error)
            toastr.error('Error', error);
        }

    },
    setToAdminRequest: async (docKey, scheduleKey, shiftKey, rType, profile) => {
        try {
            const projectKey = profile.projectKey
            const userKey = profile.inSysUserKey
            const shiftOwnerKey = profile.key

            db.doc(`projects/${projectKey}/shiftMetaData/${docKey}`).set(
                { [scheduleKey]: { [shiftKey]: { [rType]: { adm: true, sh: { newU: [userKey] } } } } },
                { merge: true }).then(() => {
                    toastr.success('Request is sent to admin for approval');
                    FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, 'Request is sent to admin for approval')
                }).catch(err => console.log('#Err 53456vfG' + err))


        } catch (error) {
            //console.log(error)
            toastr.error('Error', error);
        }

    },
    // setToAdminRequestPld: async (docKey, scheduleKey, shiftKey, rType, profile) => {
    //     try {
    //         //console.log('setToAdminRequest')
    //         const projectKey = profile.projectKey
    //         const userKey = profile.inSysUserKey
    //         const shiftOwnerKey = profile.key
    //         var oldDoc = await db.doc(`projects/${projectKey}/shiftMetaData/${docKey}`).get()
    //         //Maybe use the firebase.firestore.FieldValue.arrayRemove('east_coast')
    //         if (oldDoc.exists) {
    //             var oldData = oldDoc.data()
    //             oldData[scheduleKey][shiftKey][rType].adm = true //set to admin
    //             oldData[scheduleKey][shiftKey][rType].sh.newU = [userKey] // remove old user and add only current
    //             oldDoc.ref.set(oldData, { merge: false }).then(() => {
    //                 toastr.success('Request is xxxxxx');
    //                 FireHelper.setSystemLogMsg(projectKey, shiftOwnerKey, 'XXXXXXX')
    //             }).catch(err => console.log('#Err 53456vfG' + err))

    //         }
    //     } catch (error) {
    //         //console.log(error)
    //         toastr.error('Error', error);
    //     }

    // },




    reloadToken: (reload) => {
        // //console.log('reloadToken')


        if (!auth.currentUser)
            return new Promise((resolve, reject) => { resolve({ claims: { role: '', pKey: '' } }) })//THIS IS WRONG

        auth.currentUser.reload();

        return auth.currentUser.getIdTokenResult(reload)

    },

    removeTemplateShift: async (key, projectKey, scheduleKey) => {
        try {
            //console.log('remoevTemplateShift')
            //console.log('projectKey', projectKey)
            //console.log('scheduleKey', scheduleKey)
            //console.log('key', key)
            await db.doc(`projects/${projectKey}/schedules/${scheduleKey}`).set({ templates: { [key]: firebase.firestore.FieldValue.delete() } }, { merge: true })
            //console.log('-->', res)
            toastr.success('Template is removed');
        } catch (error) {
            //console.log(error)
            toastr.error('Error', error);
        }

    },

    getCompanyId: async (publicKey) => {
        var error = ''
        var success = true
        var data = {}
        await db.collection(`projects`).where('publicKey', '==', publicKey).limit(1)
            .get().then(docRef => {
                if (docRef && docRef.docs[0])
                    data = { ...docRef.docs[0].data(), projectKey: docRef.docs[0].id }
                else
                    success = false
            })
            .catch(err => {
                error = err
                success = false
            }

            );

        return { success, error, data }
    },

    getUserData: async () => {
        const user = auth.currentUser
        var error = ''
        var success = true
        var data = {}
        await db.doc(`users/${user.uid}`).get().then(docRef => {
            data = docRef.data()
        })
            .catch(err => {
                error = err
                success = false
            }

            );
        return { success, error, data }
    },
    removeShiftData: async (projectKey, scheduleKey, dateKey, shiftKey) => {
        var error = ''
        var success = true
        db.doc(`projects/${projectKey}/schedules/${scheduleKey}/events/${dateKey}`).set({ events: { [shiftKey]: firebase.firestore.FieldValue.delete() } }, { merge: true })
        //console.log('>>setShiftData', error)
        return { success, error }
    },
    setShiftData: async (projectKey, scheduleKey, dateKey, shiftKey, data) => {
        var error = ''
        var success = true
        db.doc(`projects/${projectKey}/schedules/${scheduleKey}/events/${dateKey}`).set({ events: { [shiftKey]: data } }, { merge: true })
        //console.log('>>setShiftData', error)
        return { success, error }
    },
    setShiftMetaData: async (projectKey, userKey, schedulaKey, dateKey, shiftKey, dType, data, isForAdmin, autoData) => {
        var error = ''
        var success = true

        // await db.doc(`projects/${projectKey}/shiftMetaData/${dateKey}`).set({ [userKey]: { [schedulaKey]: { [shiftKey]: { [dType]: { key: userKey, dType, data: { ...data } } } } } }, { merge: true }).catch(err => {
        //     error = err
        //     success = false

        // });
        console.log('-----------------------')
        console.log('projectKey', projectKey)
        console.log('userKey', userKey)
        console.log('schedulaKey', schedulaKey)
        console.log('dateKey', dateKey)
        console.log('shiftKey', shiftKey)
        console.log('dType', dType)
        console.log('data', data)
        console.log('isForAdmin', isForAdmin)
        console.log('autoData', autoData)
        console.log('-----------------------')


        if (autoData) {
            if (autoData.delete === true) {
                await db.doc(`projects/${projectKey}/schedules/${schedulaKey}/events/${dateKey}`).set({ events: { [shiftKey]: firebase.firestore.FieldValue.delete() } }, { merge: true }).catch(err => {
                    error = err
                    success = false
                })
            }
            else {
                await db.doc(`projects/${projectKey}/schedules/${schedulaKey}/events/${dateKey}`).set({ events: { [shiftKey]: autoData } }, { merge: true }).catch(err => {
                    error = err
                    success = false
                })
            }
        }
        else {
            console.log('------------->', { uk: userKey, [dType]: { ...data, adm: isForAdmin, k: shiftKey + '_' + dType } })

            await db.doc(`projects/${projectKey}/shiftMetaData/${dateKey}`).set({ [schedulaKey]: { [shiftKey]: { uk: userKey, [dType]: { ...data, adm: isForAdmin, k: shiftKey + '_' + dType } } } }, { merge: true }).catch(err => {
                error = err
                success = false

            })
        }
        if (error !== '')
            console.log('#setShiftMetaData', error)
        //console.log('>>setShiftMetaData', error)
        return { success, error }
    },
    setInviteMailBulk: async (projectKey, users, publicKey, companyName) => {
        var error = ''
        var success = false
        var batch = db.batch();

        for (let index = 0; index < users.length; index++) {
            const user = users[index];
            if (user) {
                var key = shortid.generate()
                //const userRef = firestore.doc(`projects/${projectKey}/mailbox/${key}`)
                const userRef = db.collection('projects').doc(projectKey).collection('mailbox').doc(key)
                batch.set(userRef, { key, user, companyName, publicKey, t: 'invite' })
            }

        }
        await batch.commit().then(() => {
            success = true;
        }).catch(err => error = err)

        toastr.success('Mails sent');
        //console.log('>>setInviteMail', error)
        //if (success)
        //  await FireHelper.addUserData(user)

        return { success, error }
    },


    setInviteMail: async (projectKey, user, publicKey, companyName) => {
        var error = ''
        var success = true
        var key = shortid.generate()
        await db.doc(`projects/${projectKey}/mailbox/${key}`).set({ key, user, companyName, publicKey, t: 'invite' }).catch(err => {
            error = err
            success = false

        });
        //console.log('>>setInviteMail', error)
        //if (success)
        //  await FireHelper.addUserData(user)

        return { success, error }
    },


    // updateInAppUserMail: async (email, profile) => {
    //     const key = profile.inSysUserKey
    //     const projectKey = profile.projectKey
    //     var error = ''
    //     var success = true
    //     await db.doc(`projects/${projectKey}/users/userlist`).set({ [key]: { email }, saveKey: key }, { merge: true }).catch(err => {
    //         error = err
    //         success = false
    //     }

    //     );
    //     return { success, error }
    // },

    removeInAppUserMapping: async (profile) => {
        const key = profile.inSysUserKey
        const projectKey = profile.projectKey
        var error = ''
        var success = true

        await db.doc(`projects/${projectKey}/users/userlist`).set({ [key]: { email: firebase.firestore.FieldValue.delete(), sysUserKey: firebase.firestore.FieldValue.delete() }, saveKey: key }, { merge: true }).catch(err => {
            error = err
            success = false
        }

        );
        return { success, error }
    },
    updateInAppUser: async (data, profile) => {
        const key = profile.inSysUserKey
        if (key) {
            const projectKey = profile.projectKey
            var error = ''
            var success = true
            console.log('profile', profile)
            await db.doc(`projects/${projectKey}/users/userlist`).set({ [key]: { ...data }, saveKey: key }, { merge: true }).catch(err => {
                error = err
                success = false
            }

            );
            return { success, error }
        }
        success = false
        error = 'inSysUserKey is null'
        return { success, error }
    },
    removeUserPushToken: async (token) => {
        if (auth) {
            const user = auth.currentUser
            var error = ''
            var success = true
            await db.doc(`users/${user.uid}`).set({ token: firebase.firestore.FieldValue.arrayRemove(token) }, { merge: true }).catch(err => {
                error = err
                success = false

            });
            //console.log('>> removeUserPushToken', error)
            //if (success)
            //  await FireHelper.addUserData(user)
            return { success, error }
        }
        else
            return { success: false, error: 'no auth' }

    },
    setUserPushToken: async (token) => {
        if (auth) {
            const user = auth.currentUser
            var error = ''
            var success = true
            await db.doc(`users/${user.uid}`).set({ token: firebase.firestore.FieldValue.arrayUnion(token) }, { merge: true }).catch(err => {
                error = err
                success = false

            });
            //console.log('>> setUserPushToken', error)
            //if (success)
            //  await FireHelper.addUserData(user)
            return { success, error }
        }
        else
            return { success: false, error: 'no auth' }

    },
    setUserPublicKey: async (key) => {
        const user = auth.currentUser
        var error = ''
        var success = true
        await db.doc(`users/${user.uid}`).set({ publicKey: key }, { merge: true }).catch(err => {
            error = err
            success = false

        });
        //console.log('okej setUserPublicKey', error)
        //if (success)
        //  await FireHelper.addUserData(user)

        return { success, error }
    },

    bulkImportEmployees: async (userList, projectKey) => {
        var error = ''
        var success = false

        if (userList) {
            var batch = db.batch();
            const userRef = db.collection('projects').doc(projectKey).collection('users').doc('userlist')
            for (let index = 0; index < userList.length; index++) {
                const user = userList[index];
                if (user && user.key && user.name && user.isValid === true) {
                    var saveUser = { ...user, isActive: true }
                    delete saveUser.isValid
                    if (saveUser.phone === '')
                        delete saveUser.phone
                    if (saveUser.phone2 === '')
                        delete saveUser.phone2
                    batch.set(userRef, { [saveUser.key]: saveUser }, { merge: true })
                }
            }
            await batch.commit().then(() => {
                success = true;
            }).catch(err => error = err)
        }

        return { success, error }

    },
    addUserData: async (userData) => {
        const user = auth.currentUser
        var error = ''
        var success = true
        //console.log(`users/${user.uid}`, userData)
        await db.doc(`users/${user.uid}`).set({ ...userData }, { merge: true }).catch(err => {
            error = err
            success = false
        });
        //console.log('done!', success)
        return { success, error }
    },
    setUser: async (user) => {
        var error = ''
        var success = true
        await db.doc(`users/${user.uid}`).set({ ...user }).catch(err => {
            error = err
            success = false
        }

        );
        return { success, error }
    },

    creatUser: async (userData) => {
        const user = auth.currentUser
        var error = ''
        var success = true
        var newUser = { ...userData, key: user.uid }
        //console.log('newUser', newUser)
        await db.doc(`users/${user.uid}`).set(newUser).catch(err => {
            error = err
            success = false
        }

        );
        return { success, error, user: newUser }
    },
    setProject: async (projectKey, data) => {

        var error = ''
        var success = true
        ////console.log('setProject', data)
        await db.doc(`projects/${projectKey}`).set({ ...data }, { merge: true })
            .then(docRef => {
                toastr.success('', `Saved`)
            })
            .catch(err => {
                error = err
                success = false
            })

        return { success, error }
    },
    createProject: async (name) => {

        const key = shortid.generate();
        const newProject = { name, key, publicKey: shortid.generate(), enabled: true }
        var error = ''
        var success = true
            //console.log('createProject', newProject)
            //await db.doc(`projects/${key}`).set(newProject)
            .then(docRef => {
                //console.log('ss')
                toastr.success('Yes!', `Your new project was successfully created`)
            })
            .catch(err => {
                //console.log('ssss')
                error = err
                success = false
            })

        return { success, error, project: newProject }
    },
    setChatName: async (chatName, chatKey, currentUser) => {
        // //console.log('createChat')
        const projectKey = currentUser.projectKey
        var error = ''
        var success = true
        await db.doc(`projects/${projectKey}/messages/${chatKey}`).set(
            { newName: chatName },
            { merge: true }).catch(err => {
                error = err
                success = false
            });

        return { success, error }
    },

    deleteChat: async (chatKey, currentUser) => {
        //console.log('deleteChat')
        const projectKey = currentUser.projectKey
        var error = ''
        var success = true
        await db.doc(`projects/${projectKey}/messages/${chatKey}`).set(
            { delete: true },
            { merge: true }).catch(err => {
                error = err
                success = false
            });

        return { success, error }
    },
    modifyChat: async (partisipantsNames, chatKey, users, currentUser) => {
        //console.log('modifyChat')
        const projectKey = currentUser.projectKey
        var error = ''
        var success = true
        await db.doc(`projects/${projectKey}/messages/${chatKey}`).set(
            { p: users, pn: partisipantsNames, key: chatKey, reset: true },
            { merge: true }).catch(err => {
                error = err
                success = false
            });

        return { success, error }
    },
    createChat: async (chatName, partisipantsNames, chatKey, users, currentUser) => {
        //console.log('createChat')
        const projectKey = currentUser.projectKey
        var error = ''
        var success = true
        await db.doc(`projects/${projectKey}/messages/${chatKey}`).set(
            { p: users, n: chatName, pn: partisipantsNames, key: chatKey },
            { merge: true }).catch(err => {
                error = err
                success = false
            });

        return { success, error }
    },
    setSystemLogMsg: async (projectKey, userKey, msg) => {
        await db.doc(`projects/${projectKey}/messages/sys_${userKey}`).set(
            { p: [userKey], n: 'system', pn: '', key: `sys_${userKey}`, m: firebase.firestore.FieldValue.arrayUnion({ m: msg, d: moment().utc().unix() * 1000 }) },
            { merge: true }).catch(err => {
                //console.log('#Err 4g¤%#g44 ', err)
                // error = err
                // success = false
            }

            );
    },
    setChatMesage: async (cKey, msg, currentUser) => {
        //console.log('setChatMesage')
        const key = currentUser.inSysUserKey
        const role = currentUser.role || 0

        //const img = currentUser.extraData ? currentUser.extraData.image : ''
        // const name = currentUser.extraData ? currentUser.extraData.name : ''
        //const key = currentUser.inSysUserKey
        const projectKey = currentUser.projectKey
        var error = ''
        var success = true
        await db.doc(`projects/${projectKey}/messages/${cKey}`).set(
            { m: firebase.firestore.FieldValue.arrayUnion({ r: role, m: msg, uk: key, d: moment().utc().unix() * 1000 }) }, //,  
            { merge: true }).catch(err => {
                error = err
                success = false
            }

            );
        return { success, error }
    },

    setChatMesageRead: async (chanleKey, currentUser) => {
        //console.log('>>setChatMesageRead')
        var error = ''
        var success = true
        await db.doc(`users/${currentUser.key}`).set(
            { alerts: { m: { [chanleKey]: { m: 0 } } } },
            { merge: true }).catch(err => {
                error = err
                success = false
            }

            );
        return { success, error }
    },

    addCmd: async (cmdData, profile) => {
        const projectKey = profile.projectKey
        var error = ''
        var success = true
        var key = shortid.generate()
        await db.doc(`projects/${projectKey}/cmd/${key}`).set({ ...cmdData }, { merge: true }).catch(err => {
            error = err
            success = false
        });
        //console.log('done!', success)
        return { success, error }
    },


}