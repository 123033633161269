import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
// import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer'
// import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader'
import { connect } from "react-redux";
import PropTypes from 'prop-types'
import { isMobile, osName } from 'react-device-detect';
import { initRenderDate } from "../../redux/action/viewstatAction"


//import EditEventModal from '../modal/EditEventModal'

//mport Home from "../home/Home";
//import { lNewUserRedirecter, lDev, lSchedule, lAccount, lMessages, lScheduleUser, lSettings, lSignOut, lUserHome, lUserSettings, lUsers, lReports } from './Loader'
import { lSignOut } from './Loader'
import SettingsModal from './SettingsModal'
import AppMenu from "../modal/AppMenu";
import Schedule from "../schedule/Schedule";
import Users from "../schedule/users/Users";
import Settings from "../schedule/settings/Settings";
//import Account from '../user/account/Account'
//import UserSettings from '../user/settings/UserSettings'
import SignOut from '../login/SignOut'
import UserHome from '../userhome/Userhome'
import ScheduleUser from '../userhome/ScheduleUser'
import Messages from '../messages/Messages'
import Requests from '../messages/Requests'
import RequestsMy from '../messages/RequestsMy'

import Chat from '../messages/Chat'
import Dev from '../dev/Dev'
import LoginRouter from './LoginRouter'
import { closeModal, openModal } from '../../redux/action/modalAction'
import firebase from '../../config/firebase';
import { FireHelper } from '../../common/util/FireHelper'
import MenuSchema from '../../common/menu/MenuSchema'
import NewUserRedirecter from '../userhome/NewUserRedirecter'
import Warning from '../schedule/settings/Warning';
import Help from '../help/Help'
import Reports from '../reports/Reports'
import ScheduleToday from '../schedule/ScheduleToday'
import ElectronTodayPortal from '../modal/ElectronTodayPortal';
//import MobileHead from '../mobile/MobileHead'
//Modalas
import UserModal from "../modal/UserModal";

import CopyScheduleModal from "../modal/CopyScheduleModal";

import ReactGA from 'react-ga';
import FirestoreListnerHelper from '../../common/util/FirestoreListnerHelper'
import Billing from "../schedule/settings/Billing";

var isElectronApp = (process.env.REACT_APP_ELECTRON === '1')
isElectronApp = !!window.require
let isMacOs = (process.env.REACT_APP_MACOS === '1')

class AppLoggedInRouter extends Component {
  static contextTypes = { store: PropTypes.object.isRequired }
  state = { msgCount: 0, leftMenuOpen: true }
  listners = {}
  constructor(props) {
    super(props);
    const { currentUser } = this.props
    const firstDayOfWeekNumber = Number(currentUser.firstDay) //sunday 0 , monday 1
    this.props.initRenderDate(firstDayOfWeekNumber)
  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {
    //const { currentUser } = this.props
    // const role = currentUser ? currentUser.role : 0
    // console.log('>>>>>componentDidUpdate>>>role>>', currentUser.role)
    if (prevProps && prevProps.currentUser && this.props.currentUser && prevProps.currentUser.role !== this.props.currentUser.role) {
      this.counter = 0
      this.updateClaim()
    } else if (prevProps.token !== this.props.token) {
      this.checkPushToken()
    }



  }

  componentWillUnmount = () => {
    window.removeEventListener('preferences', this.onClickPreferences);
    window.removeEventListener('signout', this.onClickSignout);
    const { currentUser } = this.props
    const role = currentUser.role || 0

    // console.log('########>> AppLoggedInRouter.componentDidUnmount UNLISTEN')
    if (this.listners['projects'])
      this.context.store.firestore.unsetListener(this.listners['projects'])

    if (role > 100) {

      if (this.listners['users'])
        this.context.store.firestore.unsetListener(this.listners['users'])

      if (this.listners['schedules'])
        this.context.store.firestore.unsetListener(this.listners['schedules'])

      FirestoreListnerHelper.clearListners(this.context)
      //TODO here is the place to destroy all listners 

      //TODO KILL request listner here!
    }




  }

  onToggleLeftMenuOpen = () => {
    this.setState({ leftMenuOpen: !this.state.leftMenuOpen })
  }

  onClickSignout = () => {
    this.goTo('/signout')()
  }

  onClickPreferences = () => {
    this.props.openModal('appSettings')

    //this.goTo('/appmenu')()
  }

  checkPushToken = () => {
    var { token, currentUser } = this.props

    if (!token && window._webToken)
      token = window._webToken

    if (token && currentUser) {
      console.log('>>new token ? ', token)
      var tokens = [...currentUser.token || []]
      console.log('check token --> ', token)
      if (tokens.indexOf(token) === -1) {
        console.log('Add token=!>', token)
        FireHelper.setUserPushToken(token)
      }
      else {
        console.log('Token exists')
      }

    }
  }
  listnerIsNotSet = false
  scheduleKey = 'default'
  componentDidMount = () => {
    if (isElectronApp === true) {
      //window.eventWindow = window.open('', 'eventModal', 'transparent=true')
      //window.eventWindow.document.body.innerHTML = 'hmm';
      //window.eventWindow.document.body.style.backgroundColor = 'transparent'
      // window.dpWindow = window.open('/timepicker.htm', 'timepicker', 'transparent=true')
      // window.dpWindow.document.body.innerHTML = '';
      // window.dpWindow.document.body.style.backgroundColor = 'transparent'


    }
    window.addEventListener('preferences', this.onClickPreferences);
    window.addEventListener('signout', this.onClickSignout);



    const { currentUser } = this.props
    // var token = this.props.token
    const role = currentUser.role || 0
    const projectKey = currentUser.projectKey;
    window._projectKey = projectKey


    var jsonData = localStorage.getItem('savedStateVars');

    if (jsonData) {
      const rehydrateSaveData = JSON.parse(jsonData)
      if (rehydrateSaveData && rehydrateSaveData.scheduleDropdownKeySaved) {

        this.scheduleKey = rehydrateSaveData.scheduleDropdownKeySaved

      }
    }


    //    console.log('########>> AppLoggedInRouter.componentDidMount', currentUser)


    if (role > 49) {
      this.setListners()
      this.checkPushToken()
    }
    else
      this.listnerIsNotSet = true



  }
  setListners = () => {
    // console.log('setListners')
    const { currentUser } = this.props
    const role = currentUser.role || 0
    const projectKey = currentUser.projectKey;

    this.listners['projects'] = { collection: "projects", doc: projectKey, storeAs: 'project' }
    this.context.store.firestore.onSnapshot(this.listners['projects']);

    FirestoreListnerHelper.setRequestAllListner(this.context, role, currentUser.inSysUserKey)
    FirestoreListnerHelper.setRequestMyListner(this.context, currentUser.inSysUserKey)


    if (role > 100) {

      this.listners['users'] = { collection: "projects", doc: projectKey, subcollections: [{ collection: "users" }], storeAs: 'users' }
      this.context.store.firestore.onSnapshot(this.listners['users']);

      this.listners['schedules'] = { collection: "projects", doc: projectKey, subcollections: [{ collection: "schedules" }], storeAs: 'schedules' }
      this.context.store.firestore.onSnapshot(this.listners['schedules']);
    }

  }
  styles = {
    s1: { paddingLeft: '10px', paddingTop: isMobile ? '45px' : '0px' }
  }
  counter = 0
  updateClaim = () => {
    //console.log('updateClaim', this.counter)
    //const { currentUser } = this.props
    //const role = currentUser.role || 0

    firebase.reloadAuth().then(() => FireHelper.reloadToken(true).then(u => {
      if (u.claims.role > 50 && u.claims.pKey) {

        if (this.listnerIsNotSet === true) {
          this.listnerIsNotSet = true
          this.setListners()
        }

      } else {
        if (this.counter < 5) {
          this.counter++
          console.log('again')
          setTimeout(this.updateClaim, 1000 * this.counter)
        }
      }


    }));
  }

  onSignOut = () => {
    console.log('onSignOut')
    this.setState({})
  }



  goTo = (url) => () => {
    this.props.history.push(url)
  }



  getMsgCount = () => {
    if (this.timerGetMsgCount)
      clearTimeout(this.timerGetMsgCount)

    this.timerGetMsgCount = setTimeout(() => {
      // console.log('>>> timerGetMsgCount')
      const alerts = this.props.currentUser.alerts || {}
      let msgCount = 0
      if (alerts.m)
        Object.keys(alerts.m).forEach(o => {
          var obj = alerts.m[o]
          msgCount += Number(obj.m || 0)
        })
      if (this.state.msgCount !== msgCount) {
        console.log('>>> RE SET MSGCOUNT')
        this.setState({ msgCount })
      }
    }, 400);
  }

  windowClose = () => {
    console.log('windowClose')
    window.close()
  }

  render() {
    this.getMsgCount()
    // this.updateClaim()
    //console.log('->AppLoggedInRouter')
    //TODO FIX COLORS

    const { currentUser } = this.props
    const role = currentUser.role || 0
    const isAdmin = role > 101
    ReactGA.pageview(window.location.pathname + window.location.search + '/' + currentUser.projectKey);
    // console.log('role', role)
    // console.log('this.props', this.props)
    // console.log('this.props.project', this.props.project)



    if (window.location.pathname !== '/signout') {
      if (!this.props.project) {

        return ('')
        //   <div className='cc'>
        //     <img alt='TimeToWork' src='/128x128.png' width='60' />
        //   </div>
        // )

        // return (
        //   <Dimmer active inverted >
        //     <div style={{ alignContent: 'center', alignItems: 'center', }}>
        //       <Loader  >
        //         <h2>
        //           {window._getText('logginin')}
        //         </h2>
        //       </Loader>
        //     </div>
        //   </Dimmer>
        // )
      }
    }
    //console.log('role', role)
    if (role < 100)
      console.log('>>>>>Role ios NOT OK!')

    //    console.log('window.location.pathname', window.location.pathname)





    if (window.location.pathname === '/login' || window.location.pathname === '/setup') //
      return (
        <Route path="*" render={() => (
          <Switch>
            <Route path="/login" component={LoginRouter} />
            <Route path="/newuser" component={LoginRouter} />
            <Route path="/setup" component={LoginRouter} />
            <Redirect to="/login" />
          </Switch>
        )} />
      )
    if (window.location.pathname === '/signout') {
      return (
        <React.Fragment>
          {/* <div className='moveOutLeft' >
            <MenuSchema />
          </div> */}

          <Route path="*" render={() => (
            <Switch>
              <Route path="/signout" component={lSignOut} />
              <Redirect to="/signout" />
            </Switch>
          )} />

        </React.Fragment>
      )
    }

    if (isMobile)
      document.body.classList.add('mobile');
    else
      document.body.classList.remove('mobile');

    if (this.props.isLandscape)
      document.body.classList.add('landscape');
    else
      document.body.classList.remove('landscape');


    if (window.location.pathname === '/today') {
      return (
        <Route path="*" render={() => (
          <Switch>
            <Route path="/today" component={ScheduleToday} />
            <Redirect to="/today" />
          </Switch>
        )} />
      )
    }



    return (
      <React.Fragment>

        {/* {!this.props.isLandscape && */}
        {/* <div style={{ backgroundColor: '#fff', color: '#000', display: 'flex' }}>
          <NavLink style={{ marginLeft: 'auto', fontSize: '14px' }} to="/signout">
            <span style={{ marginRight: '10px' }}><Icon name='user' /> {this.props.currentUser.email} | {this.props.currentUser.companyName}</span>
          </NavLink>
        </div> */}
        {/* } */}

        {/*  */}
        {osName === 'Windows' &&
          <div className='dragWindow' style={{ border: 'solid 0px red', height: '22px', backgroundColor: 'rgb(96,96,96)', display: 'flex', paddingLeft: '6px', paddingTop: '1px' }}>
            <img alt='TimeToWork' src='/128x128.png' width='16' height='16' style={{ paddingTop: '2px' }} />
            <div style={{ fontSize: '11px', paddingLeft: '12px', paddingRight: '6px', color: '#f1f1f1' }}>TimeTo.work</div>
            <div style={{ fontSize: '11px', paddingLeft: '6px', paddingRight: '6px', color: '#f1f1f1' }}>File</div>
            <div style={{ fontSize: '11px', paddingLeft: '6px', paddingRight: '6px', color: '#f1f1f1' }}>Edit</div>
            <div style={{ fontSize: '11px', paddingLeft: '6px', paddingRight: '6px', color: '#f1f1f1' }}>Export</div>

            <div className='onDrag' onClick={this.windowClose} style={{ marginLeft: 'auto', fontSize: '11px', paddingLeft: '6px', paddingRight: '6px', color: '#f1f1f1', border: 'solid 0px red' }} >x</div>

          </div>
        }

        {isAdmin &&
          <React.Fragment>

            {/* {(isMobile) &&
              <MobileHead title='' pathname={window.location.pathname} onOpenModal={this.props.openModal} isLandscape={this.props.isLandscape}></MobileHead>
            } */}
            {/* <header class="toolbar toolbar-header" style={{ height: '40px' }}>
              <h1 class="title"></h1>
            </header> */}

            {/* marginBottom: !this.props.isLandscape ? '50px' : '0px', */}

            <div className='main' style={{ paddingTop: '0px', marginBottom: !this.props.isLandscape ? '50px' : '0px', display: 'flex', height: '100%' }}>
              <MenuSchema defaultScheduleKey={this.scheduleKey} onOpenModal={this.props.openModal} onToggleLeftMenuOpen={this.onToggleLeftMenuOpen} leftMenuOpen={this.state.leftMenuOpen} />

              <div id='submain' style={{ flexGrow: '1', paddingLeft: isMacOs === false && this.props.isLandscape ? '70px' : '0px', maxWidth: this.state.leftMenuOpen ? 'calc(100% - 200px)' : 'calc(100% - 70px)', height: '100%' }}>
                <Route path="*" render={() => (
                  <Switch>

                    <Route path="/signout" component={SignOut} />
                    <Route path="/schedule/users" component={Users} />
                    <Route path="/schedule/settings" component={Settings} />
                    <Route path="/schedule/schedule/:key" component={Schedule} />

                    {/* <Route path="/user/account" component={Account} />
                  <Route path="/user/settings" component={UserSettings} /> */}


                    <Route path="/requests" component={Requests} />
                    <Route path="/requestsmy" component={RequestsMy} />
                    <Route path="/chat" component={Chat} />
                    <Route path="/messages" component={Messages} />
                    <Route path="/credits" component={Billing} />



                    <Route path="/help" component={Help} />
                    <Route path="/reports" component={Reports} />
                    <Route path="/today" component={ScheduleToday} />

                    {/* <Route path="/user/signout" component={SignOut} /> */}
                    {/* <Route exact path="/user" component={Account} /> */}
                    <Route path="/AppMenu" component={AppMenu} />
                    <Route path="/dev" component={Dev} />


                    <Redirect exact from='/' to="/schedule/schedule/default" />

                  </Switch>
                )} />
              </div>
            </div>
            {this.props.isOpen_UserModal && <UserModal></UserModal>}
            {this.props.isOpen_CopyScheduleModal && <CopyScheduleModal></CopyScheduleModal>}
            {isElectronApp === true && <ElectronTodayPortal></ElectronTodayPortal>}



            {/* {this.props.isOpen_AppMenu && <AppMenu onSignOut={this.onSignOut}></AppMenu>} */}


            <Warning></Warning>
          </React.Fragment>
        }

        {!isAdmin &&
          <React.Fragment>

            {/* {(isMobile) &&
              <MobileHead title='' pathname={window.location.pathname} onOpenModal={this.props.openModal} isLandscape={this.props.isLandscape}></MobileHead>
            } */}
            <div className='main' style={{ paddingTop: '0px', marginBottom: !this.props.isLandscape ? '50px' : '0px', display: 'flex', height: osName === 'Windows' ? 'calc(100% - 22px)' : '100%' }}>

              <MenuSchema defaultScheduleKey={this.scheduleKey} onOpenModal={this.props.openModal} onToggleLeftMenuOpen={this.onToggleLeftMenuOpen} leftMenuOpen={this.state.leftMenuOpen} />

              <div id='submain' style={{ flexGrow: '1', paddingLeft: isMacOs === false && this.props.isLandscape ? '62px' : '0px', maxWidth: this.state.leftMenuOpen ? 'calc(100% - 0px)' : 'calc(100% - 70px)', height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
                <Route path="*" render={() => (
                  <Switch>
                    <Route path="/newuser" component={NewUserRedirecter} />
                    <Route path="/setup" component={NewUserRedirecter} />
                    <Route path="/signout" component={SignOut} />
                    <Route path="/home" component={UserHome} />
                    <Route path="/schedules" component={ScheduleUser} />
                    <Route path="/chat1" component={Chat} />
                    {/* <Route path="/user/account" component={Account} />
                  <Route path="/user/settings" component={UserSettings} /> */}
                    {/* <Route path="/user/signout" component={SignOut} /> */}
                    {/* <Route path="/user" component={Account} /> */}
                    <Route path="/requests1" component={Requests} />

                    <Route path="/help" component={Help} />
                    <Route path="/dev" component={Dev} />
                    <Route path="/AppMenu" component={AppMenu} />
                    <Redirect to="/home" />
                  </Switch>
                )} />
              </div>
            </div>

            {this.props.isOpen_AppMenu && <AppMenu fixPopUp={this.fixPopUp}></AppMenu>}
          </React.Fragment>
        }



        {this.props.isOpen_AppSettings && <SettingsModal isAdmin={isAdmin} isOpen={this.props.isOpen_AppSettings} closeModal={this.props.closeModal}></SettingsModal>}



      </React.Fragment>
    );
  }

}




const mapStateToProps = (state, ownProps) => {
  let schedules = null
  if (state.firestore.data.schedules) {
    schedules = state.firestore.data.schedules;
  }

  return {
    isOpen_CopyScheduleModal: state.modal.copyShedualModalOpen,
    isOpen_UserModal: state.modal.modals['user'].open || false,
    isOpen_AppMenu: state.modal.modals['appMenu'].open || false,
    isOpen_AppSettings: state.modal.modals['appSettings'].open || false,

    isLandscape: state.viewstate.isLandscape,
    project: state.firestore.data.project,
    schedules,
    requestData: state.firestore.ordered['requestAllListner'],

  }
};
const mapActionsFromProps = {
  initRenderDate,
  openModal,
  closeModal
};
export default withRouter(connect(
  mapStateToProps,
  mapActionsFromProps
)(AppLoggedInRouter))


