import React, { Component } from "react";
import { Icon, Button, Loader } from "../../common/component/Button";
import { connect } from 'react-redux'
import FirestoreListnerHelper from '../../common/util/FirestoreListnerHelper'
import { FireHelper } from '../../common/util/FireHelper'
import PropTypes from 'prop-types'
import noUserImage from '../../../img/noimg.png'
import { getUserImage } from '../../common/util/helpers'
import ShiftContainer from './ShiftContainer'



class RequestsMy extends Component {
  static contextTypes = { store: PropTypes.object.isRequired }
  state = { requestClick: {} }

  componentDidMount = () => {
    //console.log('componentDidMount> set listner')
    //  FirestoreListnerHelper.setRequestAllListner(this.context)
    FirestoreListnerHelper.setRequestMyListner(this.context, this.props.currentUser.inSysUserKey)
  }
  componentDidUpdate = (prevProps, prevState, snapshot) => {

    if (prevProps.requestData && this.props.requestData) {
      const a = this.props.requestData
      for (let index = 0; index < prevProps.requestData.length; index++) {
        const item = prevProps.requestData[index];
        if (!a.find(x => x.id === item.id)) {
          // console.log('$kill ', item.id)
          var requestClick = { ...this.state.requestClick }
          delete requestClick[item.id]
          this.setState({ requestClick })
        }
      }
    }
  }




  onDenyRequest = (doc, shiftOwnerKey, shiftChanged) => () => {
    var msg = 'You cancel the ' + doc.type + ' request for shift ' + doc.data.sh.d + '>' + doc.data.sh.l + ' @ ' + (this.props.project.cache[doc.schedualKey].n || '')
    const { shiftKey, docKey, schedualKey, id, n } = doc
    var requestClick = { ...this.state.requestClick }
    requestClick[id] = true
    this.setState({ requestClick }, () => {
      FireHelper.removeRequest(docKey, schedualKey, shiftKey, shiftOwnerKey, n, this.props.currentUser, msg)
    })
  }




  render() {

    const { requestData, project, projectKey, currentUser } = this.props

    if (currentUser && currentUser.role < 100)
      return (<div></div>)

    if (!requestData || !project)
      return (<div style={{ textAlign: 'center', marginTop: '50px' }}><Loader inline active>{window._getText('loading')}</Loader></div>)



    const requestsKeys = Object.keys(requestData)
    return (
      <div>
        {requestsKeys.length === 0 &&

          <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <Icon name='folder open outline' style={{ fontSize: '50px' }}></Icon><br />
            {window._getText('message.out.empty')}
          </div>
        }
        {requestsKeys.map((docKey, index) => {
          const doc = requestData[docKey]

          if (!doc)
            return ('')

          const schedualName = project.cache[doc.schedualKey] ? project.cache[doc.schedualKey].n : '-'
          var user = project.cache[doc.uk] ? { ...project.cache[doc.uk] } : { n: 'unknown', i: '', sk: null }
          user.img = user.i ? getUserImage(projectKey, doc.uk, user.i) : noUserImage
          var userChanged = { ...user };
          var shiftOrginal = { ...doc.data.sh } || {}
          var shiftChanged = { ...doc.data.sh } || {}
          //console.log('doc', doc)
          switch (doc.n) {
            case 'deviation':

              shiftChanged.l = doc.data.t1 + ' - ' + doc.data.t2
              shiftChanged.t1 = doc.data.t1
              shiftChanged.t2 = doc.data.t2
              break;
            case 'change':
              if (doc.data.ct === 1) {
                shiftChanged.l = <span style={{ textDecoration: 'line-through' }}>{shiftChanged.l}</span>
              }
              else if (doc.data.ct === 3 && shiftChanged.newU) {
                var newUserKey = shiftChanged.newU[0] || ''
                if (!doc.data.adm) {
                  //this is not an ADmin , so we sett current user here !
                  newUserKey = this.props.currentUser.inSysUserKey
                }
                userChanged = { ...project.cache[newUserKey] }


                if (userChanged)
                  userChanged.img = userChanged.i ? getUserImage(projectKey, newUserKey, userChanged.i) : noUserImage
                else {
                  userChanged = { n: 'unknown', i: '', sk: null, img: noUserImage }
                }
              }
              else {
                shiftChanged.l = doc.data.t1 + ' - ' + doc.data.t2
              }

              shiftChanged.t1 = doc.data.t1
              shiftChanged.t2 = doc.data.t2
              break;
            default:
              break;
          }

          const isClicked = this.state.requestClick[doc.id] || false

          return (
            <div key={doc.id + '_' + index} className={isClicked ? 'requestClickAni' : ''}>

              <div style={{ float: 'left' }}>

                {(doc.n === 'deviation') && <Icon name='tag' size='small' />}
                {(doc.n === 'comment') && <Icon name='comment' size='small' />}
                {(doc.n === 'change') && <Icon name='comments' size='small' />}
                {(doc.n === 'sick') && <Icon name='bed' size='small' />}
              </div>
              <b>{doc.n}</b><br />
              <ShiftContainer schedualName={schedualName} user={user} shiftOrginal={shiftOrginal} ></ShiftContainer>



              <div style={{ border: 'solid 0px red', float: 'left', padding: '5px', marginTop: '20px' }}>
                <Icon name='arrow right' size='big'></Icon>
              </div>

              {/* {(doc.n === 'deviation') && */}
              <ShiftContainer schedualName={schedualName} user={userChanged} shiftOrginal={shiftChanged} ></ShiftContainer>





              <div style={{ border: 'solid 0px red', float: 'right', paddingLeft: '5px', marginTop: '25px' }}>
                <Button compact loading={isClicked} disabled={isClicked} color='blue' icon='remove' onClick={this.onDenyRequest(doc, user.sk, shiftChanged)} style={{ marginRight: '10px' }}   ></Button>
                {/* <Button color='green' icon='check' onClick={this.onApproveCancle(doc.shiftKey, doc.docKey, doc.schedualKey, doc.id, user.sk)} content='Cancle' ></Button> */}
                {/* <Button loading={isClicked} disabled={isClicked} color='green' icon='check' onClick={this.onApproveChange(doc, user.sk, shiftChanged)} content='Yes' ></Button> */}

              </div>
              <div style={{ border: 'solid 0px red', clear: 'both' }}>
                {doc.data.com && <div style={{ paddingLeft: '22px', fontSize: '12px' }}><Icon name='comment outline' /> {doc.data.com}</div>}
              </div>
              <div style={{ borderBottom: 'dotted 1px  var(--color-border)', paddingTop: '10px', marginBottom: '5px' }}></div>

            </div>
          )
        })
        }



      </div>
    )
  }
}



const mapActions = {};
const mapStateToProps = (state, ownProps, ownState) => {

  return {
    projectKey: state.firebase.profile.projectKey,
    project: state.firestore.data.project,
    requestData: state.firestore.ordered['requestMyListner'],
    currentUser: state.firebase.profile,

  };
};
export default connect(
  mapStateToProps,
  mapActions
)(RequestsMy);


