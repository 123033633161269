import React, { Component } from 'react'
import { Button, Icon } from "../../common/component/Button";
import { closeModal } from "../../redux/action/modalAction";
import { connect } from "react-redux";
import CtrlTimeDeviation from '../userhome/modalActions/ctrlTimeDeviation'
import CtrlAskForChange from '../userhome/modalActions/ctrlAskForChange'
import CtrlAddComment from '../userhome/modalActions/ctrlAddComment'
import CtrlMarkAsSick from '../userhome/modalActions/ctrlMarkAsSick'
import ModalFrame from './ModalFrame'

import { isMobile } from 'react-device-detect';
import MobileHeadModal from '../mobile/MobileHeadModal'

var isElectronApp = (process.env.REACT_APP_ELECTRON === '1')
isElectronApp = !!window.require

const modalKey = 'shiftuserinput'
const INITIAL_STATE = { subIndex: -1, activeIndex: -1, isLoading: false, isNew: true, error: false, title: '', doSave: false }
export class ShiftUserInput extends Component {

    constructor(props) {
        super(props);
        console.log('this.props.activeIndex ', this.props.activeIndex)
        this.state = { ...INITIAL_STATE, title: window._getText('shiftuserinput.title0') };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        //on open
        if (prevProps.isOpen === false && this.props.isOpen === true) {
            console.log('opem', this.props.activeIndex)
            this.setState({ isLoading: false, isNew: (this.props.sKey === null), error: false, activeIndex: this.props.activeIndex, subIndex: this.props.subIndex, title: this.getTitle(this.props.activeIndex) })
        }
    }

    onCloseModal = () => {
        this.props.onCloseModal(modalKey)
    }

    onClickDelete = () => {
        this.setState({ isLoading: true })
        // doShit.then(() => {
        //     this.setState({ isLoading: false })
        //     this.onCloseModal()
        // })

    }

    onChangeText = (e) => {
        this.setState({ valueName: e.target.value, error: (e.target.value.length < 1) });
    }
    onClickSave = () => {

        this.setState({ isLoading: true })
        // doShit.then(() => {
        //     this.setState({ isLoading: false })
        //     this.onCloseModal()
        // })
    }

    handleClick = (e) => {
        console.log('this.props', this.props)
        const index = Number(e.target.getAttribute('index'))
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index


        this.setState({ activeIndex: newIndex, title: this.getTitle(newIndex), subIndex: -1 })
    }
    getTitle = (index) => {
        let title = window._getText('shiftuserinput.title0')
        switch (index) {
            case -1:
                title = window._getText('shiftuserinput.title0')
                break;
            case 1:
                title = window._getText('shiftuserinput.title1')
                break;
            case 2:
                title = ''// window._getText('shiftuserinput.title2')
                break;
            case 3:
                title = window._getText('shiftuserinput.title3')//'Add acomment'
                break;
            case 4:
                title = window._getText('shiftuserinput.title4')//'Mark as sick!'
                break;
            default:
        }

        return title
    }

    defaultViwe = () => {

        return (
            <div style={{ height: 'calc(100% - 100px)', border: 'solid 0px red', display: 'flex', flexDirection: 'column' }}>
                <Button compact className='btnMobile' index={1} onClick={this.handleClick} icon='tag' content={window._getText('shiftuserinput.btn1')} style={{ width: '100%', marginBottom: '10px', flexGrow: '1' }}></Button>
                <Button compact className='btnMobile' index={2} onClick={this.handleClick} icon='comments' content={window._getText('shiftuserinput.btn2')} style={{ width: '100%', marginBottom: '10px', flexGrow: '1' }}></Button>
                <Button compact className='btnMobile' index={3} onClick={this.handleClick} icon='comment' content={window._getText('shiftuserinput.btn3')} style={{ width: '100%', marginBottom: '10px', flexGrow: '1' }}></Button>
                <Button compact className='btnMobile' index={4} onClick={this.handleClick} icon='bed' content={window._getText('shiftuserinput.btn4')} style={{ width: '100%', marginBottom: '10px', flexGrow: '1' }}></Button>
            </div>
        )
    }
    setSubIndex = (index) => {
        this.setState({ subIndex: index })
    }
    getCtrl = () => {

        switch (this.state.activeIndex) {
            case -1:
                return this.defaultViwe()
            case 1:
                return <CtrlTimeDeviation onClose={this.onClose} doSave={this.state.doSave} key={this.props.data} resetSave={this.resetSave} />
            case 2:
                return <CtrlAskForChange subIndex={this.state.subIndex} setSubIndex={this.setSubIndex} onClose={this.onClose} doSave={this.state.doSave} key={this.props.data} resetSave={this.resetSave} />
            case 3:
                return <CtrlAddComment onClose={this.onClose} doSave={this.state.doSave} key={this.props.data} resetSave={this.resetSave} />

            case 4:
                return <CtrlMarkAsSick onClose={this.onClose} doSave={this.state.doSave} key={this.props.data} resetSave={this.resetSave} />

            default:
                return this.defaultViwe()
        }


    }
    onClose = () => {
        this.setState({ activeIndex: -1, subIndex: -1 })
        this.onCloseModal()
    }
    onBack = () => {

        if (isElectronApp === true) {
            this.setState({ activeIndex: -1, subIndex: -1, title: window._getText('shiftuserinput.title0') })
            this.onCloseModal()
            return
        }

        switch (this.state.activeIndex) {
            case -1:
                this.onCloseModal()
                break;
            case 1:
                this.setState({ activeIndex: -1, subIndex: -1, title: window._getText('shiftuserinput.title0') })
                break;
            case 2:
                this.setState({ activeIndex: -1, subIndex: -1, title: window._getText('shiftuserinput.title0') })
                break;
            case 3:
                this.setState({ activeIndex: -1, subIndex: -1, title: window._getText('shiftuserinput.title0') })
                break;
            case 4:
                this.setState({ activeIndex: -1, subIndex: -1, title: window._getText('shiftuserinput.title0') })
                break;
            default:

        }


    }
    resetSave = () => {
        this.setState({ doSave: false })
    }
    onSave = () => {
        this.setState({ doSave: true })
    }
    render() {
        const { data } = this.props
        const shift = data.shift || {}

        //TODO FIX THIS SHIT! mobile and modalFrame
        return (


            <ModalFrame name={modalKey} className='modalAniSlideUp' dimmer='inverted' size='large' open={this.props.isOpen} onClickClose={this.onBack}  >

                {/* <ModalFrame.Header>
                    <MobileHeadModal title={this.state.title} onBack={this.onBack} showSave={this.state.activeIndex > -1} ></MobileHeadModal>
                    </ModalFrame.Header> */}
                {/* {!isMobile && <Button floated='right' icon='arrow left' labelPosition='left' content='back' className='closeButton' onClick={this.onBack}></Button>} */}

                <ModalFrame.Content className='modalBodyScroll'  >

                    <div style={{ height: 'calc(100% - 50px)' }}>
                        {!isMobile && <b>{this.state.title}</b>}
                        {/*                         
                        <div key={shift.k} style={{ marginLeft: '4px', marginRight: '4px', marginBottom: '20px' }} className={"cellMultiUserhome ui label large dragMe x" + shift.c}  >
                            <div>{shift.l}
                                {data.meta && data.meta['deviation'] && <span> <Icon name='tag' size='small' /></span>}
                                {data.meta && data.meta['comment'] && <span> <Icon name='comment' size='small' /></span>}
                                {data.meta && data.meta['change'] && <span> <Icon name='comments' size='small' /></span>}
                                {data.meta && data.meta['sick'] && <span> <Icon name='bed' size='small' /></span>}
                            </div>
                            <span>{shift.co && shift.co}</span>
                        </div> */}

                        <div style={{ border: 'solid 0px red', height: 'calc(100% - 30px)', display: 'flex', flexDirection: 'column' }}>
                            {this.getCtrl()}
                        </div>

                    </div>
                </ModalFrame.Content>
                <ModalFrame.Actions>
                    <div style={{ display: 'flex' }}>
                        {this.state.activeIndex > -1 &&
                            <Button icon='arrow left' content='back' onClick={() => { this.setState({ activeIndex: -1 }) }}></Button>
                        }
                        <div style={{ marginLeft: 'auto' }}>
                            {(this.state.activeIndex === 1 || this.state.activeIndex > 2) &&
                                <Button onClick={this.onSave} content={window._getText('save')} icon='check'></Button>
                            }
                            {(this.state.activeIndex === 2 && this.state.subIndex > -1) &&
                                <Button onClick={this.onSave} content={window._getText('save')} icon='check'></Button>
                            }
                        </div>
                    </div>
                </ModalFrame.Actions>
            </ModalFrame >)

    }
}


const mapActions = {
    onCloseModal: closeModal,
};

const mapStateToProps = state => {
    // console.log('state.modal.modals[modalKey].data', state.modal.modals[modalKey].data)
    const data = state.modal.modals[modalKey].data || {}
    return {
        isOpen: state.modal.modals[modalKey].open || false,
        data: data,
        activeIndex: data.activeIndex ? data.activeIndex : -1,
        subIndex: data.subIndex ? data.subIndex : -1

    };
};
export default connect(
    mapStateToProps,
    mapActions
)(ShiftUserInput);



