import React, { Component } from "react";
import { Form, Icon, Image, Button, Input } from "../../common/component/Button";
import { connect } from "react-redux";
import { openModal, closeModal } from "../../redux/action/modalAction";
import { updateUser, addUser, deleteUser, uploadProfileImage } from "../../redux/action/userAction";
import { Field, reduxForm } from "redux-form";
import InputToggle from '../../common/form/InputToggle'
import noUserImage from '../../../img/noimg.png'
import AvatarEditor from 'react-avatar-editor'
import shortid from 'shortid'
import { FireHelper } from '../../common/util/FireHelper'
import { isAndroid } from 'react-device-detect';
import ModalFrame from './ModalFrame'
import { getUserImage } from '../../common/util/helpers'
import object from 'lodash/object'



const validate = values => {

  const errors = { name: false }
  if (!values.name) {
    errors.name = true
  } else if (values.name.length <= 1) {
    errors.name = true
  }

  return errors
}

const INIT_STATE = {
  savingImage: false,
  sliderValue: 1.0,
  image: undefined,
  loading: false,
  rotate: 0,
  error: undefined,
  doDelete: false
}

class UserModal extends Component {


  constructor(props) {
    super(props);
    this.state = { ...INIT_STATE }
  }
  componentDidUpdate = (prevProps, prevState, snapshot) => {
    if (this.props.userObj.key !== prevProps.userObj.key) {
      this.setState({ ...INIT_STATE })
    }
  }


  saveData = async (values) => {
    //isActive
    var response = { succes: true, msg: '' }
    console.log('Check for new email!')

    var saveObj = { ...values }
    if (saveObj.isActive === undefined)
      saveObj.isActive = true
    if (saveObj.email)
      saveObj.email = values.email.toLowerCase()



    if (saveObj.email && saveObj.email.length > 0) {
      // console.log('userList', this.props.userList)
      // console.log('saveObj.email', saveObj.email)
      let dupUserKey = object.findKey(this.props.userList, (u) => {
        if (!u)
          return false

        return u.email === saveObj.email
      })
      // console.log('userWithSameEmail', dupUserKey)
      // console.log('saveObj.key', saveObj.key)
      if (dupUserKey) {
        var dupUser = this.props.userList[dupUserKey]
        if (dupUser.key !== saveObj.key || !saveObj.key) {
          response.msg = 'dup'
          response.succes = false

          return response
        }

      }
    }

    if (this.props.userObj.newUser)
      response = await this.props.addUser({ ...saveObj, newUser: false });
    else
      response = await this.props.updateUser(saveObj);

    console.log('response', response)


    this.props.reset()
    return response
  }
  onSubmit = async values => {
    var saveObj = { ...values }
    if (saveObj.name) {
      this.setState({ loading: true })
      var r = { success: true, msg: '' }

      if (this.state.image) {
        if (this.editor) {
          try {
            this.editor.getImageScaledToCanvas().toBlob(async (blob) => {
              if (!saveObj.key)
                saveObj.key = shortid.generate()

              await this.props.uploadProfileImage(blob, saveObj.key, this.props.project.key)
              saveObj.image = shortid.generate();
              r = await this.saveData(saveObj)
            }, 'image/jpg');
          } catch (error) {
            this.setState({ savingImage: false })
          }
        }
      }
      else {
        r = await this.saveData(saveObj)

      }

      if (r.success === true) {
        this.setState({ ...INIT_STATE })
        this.props.closeModal('user');
      }
      else {
        this.setState({ loading: false, error: r })
      }


    } else {
      // this.setState({ noName: true })
      //TODO validate input!
    }
  };

  onDeleteUser = () => {

    this.props.deleteUser(this.props.userObj)
    this.props.closeModal('user');
  }
  onCloseModal = () => {
    this.props.closeModal('user');
  }



  renderInput = (name) => {

    return (
      <Input

        style={{
          width: '100%',
          paddingTop: '9px',
        }}
        className='fieldInput'
        autoComplete="new-password" type="text" {...name.input} placeholder={window._getText('users.dname')} error={name.meta.touched && name.meta.error} />
    )
  }


  handleFileUpload = (e) => {
    // const file = files[0]

    if (e.target.files) {
      var file = e.target.files[0]
      this.setState({ image: file })
      //this.props.userObj.image = file;
    }
  }

  setEditorRef = (editor) => this.editor = editor
  handleChange = (event) => { this.setState({ sliderValue: Number(event.target.value) }) };

  onRotateImage = (e) => {
    e.preventDefault()
    this.setState({ rotate: this.state.rotate + 90 });
  }

  removeMappedUser = async (e) => {
    e.preventDefault()
    let { userObj, projectKey } = this.props;
    this.setState({ loading: true })
    await FireHelper.removeInAppUserMapping({ inSysUserKey: userObj.key, projectKey })
    this.setState({ loading: false })
  }
  // remappUser = async (e) => {
  //   e.preventDefault()
  //   let { userObj, projectKey } = this.props;
  //   this.setState({ loading: true })
  //   await FireHelper.updateInAppUser({ remappuser: true }, { inSysUserKey: userObj.key, projectKey })
  //   this.setState({ loading: false })
  // }
  getRole = (role) => {
    if (role === 200)
      return (<Icon name='chess king' ></Icon>)

    if (role === 150)
      return (<Icon name='chess rook' ></Icon>)

    if (role === 50)
      return (<Icon name='chess pawn' ></Icon>)

    return (<Icon name='chess pawn' ></Icon>)
  }
  confirmDelete = () => {
    this.setState({ doDelete: true })
  }
  onCancle = () => {
    this.setState({ image: null })
  }
  render() {

    if (this.props.isOpen === false)
      return ''

    let { handleSubmit, projectKey } = this.props;
    let { error, image, rotate, sliderValue, value, savingImage, doDelete } = this.state
    var userObj = this.props.userObj
    let isCordovaAndroid = isAndroid && window.isCordovaApp
    //if (!userObj.email) userObj.email = "";
    return (
      <React.Fragment>
        <ModalFrame
          name='userModal'
          className='modalAniSlideUp'
          open={this.props.isOpen}
          // onClose={this.handleClose}
          dimmer="inverted"
          size='tiny'
          onClickClose={this.onCloseModal}
        >
          <ModalFrame.Header >
            <div style={{ display: 'flex', flexDirection: 'row', }}>
              <div style={{ flexGrow: '1' }}>
                {!doDelete && <span>{this.getRole(userObj.role)} {userObj.name}</span>}
                {doDelete === true && <span>Delete user {userObj.name}</span>}
              </div>
              {!doDelete &&
                <div>
                  {userObj.role !== 200 &&
                    <div>
                      <b>{window._getText('active')}: </b>
                      <Field name="isActive" component={InputToggle} />
                    </div>
                  }

                </div>
              }
            </div>


          </ModalFrame.Header>
          <ModalFrame.Content >

            {!doDelete &&
              <Form autoComplete="new-password" >
                <input type="hidden" value="something" />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', border: 'solid 0px red', paddingLeft: '20px', paddingRight: '20px' }}>
                  <div style={{ border: 'solid 0px red' }}  >


                    {/* {!image &&
                      <Image style={{ maxWidth: '100px', maxHeight: '100px', marginLeft: 'auto', marginRight: 'auto' }}
                        src={userObj.image ?
                          getUserImage(projectKey, userObj.key, userObj.image)
                          : noUserImage}
                        circular
                        onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }}
                      />

                    } */}


                    {image &&

                      <React.Fragment>
                        <AvatarEditor
                          style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                          ref={this.setEditorRef}
                          image={URL.createObjectURL(image)}
                          width={80}
                          height={80}
                          borderRadius={50}
                          border={32}
                          scale={Number(sliderValue || 1)}
                          rotate={rotate}
                        />

                        <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '4px' }}>


                          <Button
                            style={{ marginRight: '5px', maxHeight: '30px' }}
                            compact
                            onClick={this.onCancle}
                            icon="x"
                            disabled={savingImage}
                          />
                          <input
                            id="typeinp"
                            type="range"
                            min="0.5" max="5"
                            defaultValue="1"
                            value={value}
                            onChange={this.handleChange}
                            step="0.01"
                            style={{ width: '100%', flexGrow: '1' }}
                          />
                          <Button
                            style={{ marginLeft: '5px', maxHeight: '30px' }}
                            compact
                            onClick={this.onRotateImage}
                            icon="redo"
                            disabled={savingImage}
                          />
                        </div>
                      </React.Fragment>

                    }


                    <div style={{}}>
                      {!savingImage && !image &&
                        < React.Fragment >
                          <label htmlFor='upload' style={{ width: '100%', display: isCordovaAndroid ? 'none' : '' }}>

                            <Image style={{ maxWidth: '100px', maxHeight: '100px', marginLeft: 'auto', marginRight: 'auto' }}
                              src={userObj.image ?
                                getUserImage(projectKey, userObj.key, userObj.image)
                                : noUserImage}
                              circular
                              onError={(e) => { e.target.onerror = null; e.target.src = noUserImage }}
                            />


                          </label>
                          <input className={isCordovaAndroid ? 'custom-file-input' : ''} type="file" ref={this.fileRef} id="upload" accept="image/*" onChange={(e) => { this.handleFileUpload(e) }} style={{ display: isCordovaAndroid ? 'inline-block' : 'none', border: 'none' }} />
                        </React.Fragment>

                      }



                    </div>

                  </div>
                  <div  >

                    <div style={{ border: 'solid 0px red', display: 'flex', borderBottom: 'solid 1px var(--color-border)', minHeight: '35px' }}>
                      <div style={{ width: '30%', paddingTop: '11px', textAlign: 'right', border: 'solid 0px red', fontWeight: 'bold' }}>{window._getText('users.dname')}:</div>
                      <div >
                        <Field name="name"

                          component={this.renderInput} required

                        />
                      </div>
                    </div>
                    <div style={{ border: 'solid 0px red', display: 'flex', borderBottom: 'solid 1px var(--color-border)', minHeight: '35px' }}>
                      <div style={{ width: '30%', paddingTop: '11px', textAlign: 'right', border: 'solid 0px red', fontWeight: 'bold', }}>
                        {/* {(userObj.sysUserKey && userObj.role !== 200) && <Button compact onClick={this.removeMappedUser} disabled={this.state.loading} loading={this.state.loading} content={window._getText('user.unlockEmail')}></Button>} */}
                        {(userObj.sysUserKey && userObj.role !== 200) && <span onClick={this.removeMappedUser} tooltip={window._getText('user.unlockEmail')}><Icon name='lock'  ></Icon></span>}{window._getText('email')}:</div>
                      <div >
                        {error && error.msg === 'dup' && <span style={{ color: 'red' }}>({window._getText('users.uniqueemail')})</span>}

                        <Field name="email"
                          style={{
                            width: '100%',
                            paddingTop: '9px',
                          }}
                          className='fieldInput'

                          error={error !== undefined} readOnly={userObj.sysUserKey ? true : false} autoComplete="new-password" component={Input} type="text" placeholder={window._getText('email')} />
                        {/* {!userObj.sysUserKey && userObj.email && <Button onClick={this.remappUser} disabled={this.state.loading} loading={this.state.loading}>Remapp</Button>} */}
                      </div>
                    </div>
                    <div style={{ border: 'solid 0px red', display: 'flex', borderBottom: 'solid 1px var(--color-border)', minHeight: '35px' }}>
                      <div style={{ width: '30%', paddingTop: '11px', textAlign: 'right', border: 'solid 0px red', fontWeight: 'bold' }}>{window._getText('phone')}:</div>
                      <div >
                        <Field name="phone"
                          style={{
                            width: '100%',
                            paddingTop: '9px',
                          }}
                          className='fieldInput'
                          autoComplete="new-password" component={Input} type="text" placeholder={window._getText('phone')} />
                      </div>
                    </div>
                    <div style={{ border: 'solid 0px red', display: 'flex', borderBottom: 'solid 1px var(--color-border)', minHeight: '35px' }}>
                      <div style={{ width: '30%', paddingTop: '11px', textAlign: 'right', border: 'solid 0px red', fontWeight: 'bold' }}>{window._getText('phone') + '2'}:</div>
                      <div >
                        <Field
                          style={{
                            width: '100%',
                            paddingTop: '9px',
                          }}
                          className='fieldInput'
                          name="phone2" autoComplete="new-password" component={Input} type="text" placeholder={window._getText('phone') + '2'} />
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            }
            {doDelete === true &&
              <span>
                <p>{window._getText('users.delete')}</p>
              </span>
            }
          </ModalFrame.Content>
          <ModalFrame.Actions>
            <div style={{ display: 'flex' }}>
              {(userObj.newUser !== true && userObj.role !== 200 && !doDelete) &&
                <Button disabled={this.state.loading} icon='trash alternate outline' onClick={this.confirmDelete} />
              }
              {(userObj.newUser !== true && userObj.role !== 200 && doDelete === true) &&
                <React.Fragment>
                  {/* <Button onClick={this.onCloseModal} content='cancel' compact style={{ marginRight: '10px' }}></Button> */}
                  <Button style={{ marginLeft: 'auto' }} content={window._getText('users.deleteBtn2')} onClick={this.onDeleteUser} />
                </React.Fragment>
              }

              {!doDelete &&
                <React.Fragment>
                  {/* <Button onClick={this.onCloseModal} content='cancel' compact style={{ marginRight: '10px' }}></Button> */}
                  <Button
                    style={{ marginLeft: 'auto' }}
                    compact
                    type="submit"
                    onClick={handleSubmit(this.onSubmit)}
                    positive icon='checkmark'
                    disabled={this.state.loading}
                    loading={this.state.loading}
                  />
                </React.Fragment>
              }
            </div>
          </ModalFrame.Actions>
        </ModalFrame>
      </React.Fragment>
    );
  }
}

const mapActions = {
  onOpenModal: openModal,
  closeModal: closeModal,
  updateUser: updateUser,
  uploadProfileImage,
  deleteUser,
  addUser
};

const mapStateToProps = state => {
  let userObj;

  if (state.modal.modals['user'].data && state.firestore.data.users) {
    userObj = state.firestore.data.users.userlist[state.modal.modals['user'].data];
    //this.setState({ name: userObj.name });
    //console.log(state.firestore.data.projects.users);
  }

  if (!userObj) {//TODO THIS NEED TO COME FROM A FACTORY
    userObj = {

      newUser: true,
      isActive: true,
      name: "",
      email: ""
    }


    if (state.modal.modals['user'].data)
      userObj.key = state.modal.modals['user'].data
  }

  //  console.log('userList', state.firestore.data.users.userlist)


  // if (userObj.isActive === undefined) {
  //   userObj.isActive = true;
  // }

  // if (userObj.isDeleted === undefined) {
  //   userObj.isDeleted = false;
  // }




  return {
    userList: state.firestore.data.users.userlist || {},
    currentUser: state.firebase.profile,
    projectKey: state.firebase.profile.projectKey,
    project: state.firestore.data.project,
    isOpen: state.modal.modals['user'].open,
    enableReinitialize: true,
    initialValues: userObj,
    userObj
  };
};
export default connect(
  mapStateToProps,
  mapActions
)(reduxForm({ form: "userForm", touchOnChange: true, validate })(UserModal));
