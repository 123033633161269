

const transaltions = {}
let selectedLang = 'en'

export const init = () => {
    transaltions.en = {}
    transaltions.sv = {}

    transaltions.en['on'] = 'On'
    transaltions.sv['on'] = 'På'

    transaltions.en['off'] = 'Off'
    transaltions.sv['off'] = 'Av'


    transaltions.en['now'] = 'Now'
    transaltions.sv['now'] = 'Nu'

    transaltions.en['delete'] = 'Delete'
    transaltions.sv['delete'] = 'Radera'

    transaltions.en['name'] = 'Name'
    transaltions.sv['name'] = 'Namn'


    transaltions.en['rotateimage'] = 'Rotate image'
    transaltions.sv['rotateimage'] = 'Rotera bild'

    transaltions.en['schedule'] = 'Schedule'
    transaltions.sv['schedule'] = 'Schema'

    transaltions.en['hours'] = 'Hours'
    transaltions.sv['hours'] = 'Timmar'

    transaltions.en['sick'] = 'Sick'
    transaltions.sv['sick'] = 'Sjuk'

    transaltions.en['break'] = 'Break'
    transaltions.sv['break'] = 'Rast'

    transaltions.en['time'] = 'Time'
    transaltions.sv['time'] = 'Tid'

    transaltions.en['signout'] = 'Sign Out'
    transaltions.sv['signout'] = 'Logga ut'

    transaltions.en['update'] = 'Update'
    transaltions.sv['update'] = 'Uppdatera'

    transaltions.en['yes'] = 'Yes'
    transaltions.sv['yes'] = 'Ja'

    transaltions.en['no'] = 'No'
    transaltions.sv['no'] = 'Nej'

    transaltions.en['January'] = 'January'
    transaltions.sv['January'] = 'Januari'

    transaltions.en['February'] = 'February'
    transaltions.sv['February'] = 'Februari'

    transaltions.en['March'] = 'March'
    transaltions.sv['March'] = 'Mars'

    transaltions.en['April'] = 'April'
    transaltions.sv['April'] = 'April'

    transaltions.en['May'] = 'May'
    transaltions.sv['May'] = 'Maj'

    transaltions.en['June'] = 'June'
    transaltions.sv['June'] = 'Juni'

    transaltions.en['July'] = 'July'
    transaltions.sv['July'] = 'Juli'

    transaltions.en['August'] = 'August'
    transaltions.sv['August'] = 'Augusti'

    transaltions.en['September'] = 'September'
    transaltions.sv['September'] = 'September'

    transaltions.en['October'] = 'October'
    transaltions.sv['October'] = 'Oktober'

    transaltions.en['November'] = 'November'
    transaltions.sv['November'] = 'November'

    transaltions.en['December'] = 'December'
    transaltions.sv['December'] = 'December'

    transaltions.en['Jan'] = 'Jan'
    transaltions.sv['Jan'] = 'Jan'

    transaltions.en['Feb'] = 'Feb'
    transaltions.sv['Feb'] = 'Feb'

    transaltions.en['Mar'] = 'Mar'
    transaltions.sv['Mar'] = 'Mar'

    transaltions.en['Apr'] = 'Apr'
    transaltions.sv['Apr'] = 'Apr'

    transaltions.en['May'] = 'May'
    transaltions.sv['May'] = 'Maj'

    transaltions.en['Jun'] = 'Jun'
    transaltions.sv['Jun'] = 'Jun'

    transaltions.en['Jul'] = 'Jul'
    transaltions.sv['Jul'] = 'Jul'

    transaltions.en['Aug'] = 'Aug'
    transaltions.sv['Aug'] = 'Aug'

    transaltions.en['Sep'] = 'Sep'
    transaltions.sv['Sep'] = 'Sep'

    transaltions.en['Oct'] = 'Oct'
    transaltions.sv['Oct'] = 'Okt'

    transaltions.en['Nov'] = 'Nov'
    transaltions.sv['Nov'] = 'Nov'

    transaltions.en['Dec'] = 'Dec'
    transaltions.sv['Dec'] = 'Dec'

    transaltions.en['credit'] = 'Credit'
    transaltions.sv['credit'] = 'Kredit'

    transaltions.en['type'] = 'Type'
    transaltions.sv['type'] = 'typ'


    transaltions.en['users'] = 'users'
    transaltions.sv['users'] = 'användare'

    transaltions.en['average'] = 'Average'
    transaltions.sv['average'] = 'Medel'

    transaltions.en['date'] = 'Date'
    transaltions.sv['date'] = 'Datum'

    transaltions.en['total'] = 'Total'
    transaltions.sv['total'] = 'Total'

    transaltions.en['week'] = 'Week'
    transaltions.sv['week'] = 'Vecka'

    transaltions.en['wShort'] = 'w.'
    transaltions.sv['wShort'] = 'v.'

    transaltions.en['monday'] = 'Monday'
    transaltions.sv['monday'] = 'Måndag'

    transaltions.en['sunday'] = 'Sunday'
    transaltions.sv['sunday'] = 'Söndag'

    transaltions.en['new'] = 'New'
    transaltions.sv['new'] = 'Ny'

    transaltions.en['active'] = 'Active'
    transaltions.sv['active'] = 'Aktiv'

    transaltions.en['inactive'] = 'Inactive'
    transaltions.sv['inactive'] = 'Inaktiv'

    transaltions.en['active'] = 'Active'
    transaltions.sv['active'] = 'Aktiv'

    transaltions.en['invites'] = 'Invite'
    transaltions.sv['invites'] = 'Bjud in'

    transaltions.en['import'] = 'Import'
    transaltions.sv['import'] = 'Importera'

    transaltions.en['search'] = 'Search...'
    transaltions.sv['search'] = 'Sök...'

    transaltions.en['password'] = "Password"
    transaltions.sv['password'] = 'Lösenord'

    transaltions.en['oops'] = "Oops!"
    transaltions.sv['oops'] = 'Hoppsan!'

    transaltions.en['uploading_image'] = "Uploading image..."
    transaltions.sv['uploading_image'] = 'Överför bild ...'

    transaltions.en['picture'] = "Picture"
    transaltions.sv['picture'] = 'Bild'

    transaltions.en['add'] = "Add"
    transaltions.sv['add'] = 'Lägg till'

    transaltions.en['save'] = "Save"
    transaltions.sv['save'] = 'Spara'

    transaltions.en['cancel'] = "Cancel"
    transaltions.sv['cancel'] = 'Avbryt'


    transaltions.en['upload'] = "Upload"
    transaltions.sv['upload'] = 'Ladda upp'

    transaltions.en['create'] = "Create"
    transaltions.sv['create'] = 'Skapa'

    transaltions.en['email'] = "Email"
    transaltions.sv['email'] = 'E-post'

    transaltions.en['phone'] = "Phone"
    transaltions.sv['phone'] = 'Telefon'

    transaltions.en['back'] = "back"
    transaltions.sv['back'] = 'tillbaka'

    transaltions.en['send'] = "Send"
    transaltions.sv['send'] = 'Skicka'

    transaltions.en['loading'] = "Loading..."
    transaltions.sv['loading'] = 'Laddar..'

    transaltions.en['logginin'] = "Logging in..."
    transaltions.sv['logginin'] = 'Loggar in...'

    transaltions.en['logginout'] = "Logging out..."
    transaltions.sv['logginout'] = 'Loggar ut...'

    transaltions.en['message.in.empty'] = "You don't have any incoming requests."
    transaltions.sv['message.in.empty'] = 'Du har inga inkommande förfrågningar.'

    transaltions.en['message.out.empty'] = "You don't have any pending requests."
    transaltions.sv['message.out.empty'] = 'Du har inga pågående förfrågningar.'

    transaltions.en['message.in'] = "In"
    transaltions.sv['message.in'] = 'In'

    transaltions.en['message.out'] = "Out"
    transaltions.sv['message.out'] = 'Ut'

    transaltions.en['message.chat'] = "Chat"
    transaltions.sv['message.chat'] = 'Chat'

    transaltions.en['login.titel'] = "Login"
    transaltions.sv['login.titel'] = 'Logga in'

    transaltions.en['login.lostpass'] = "Forgot your password?"
    transaltions.sv['login.lostpass'] = 'Glömt ditt lösenord?'

    transaltions.en['login.sendpass'] = "Send new password"
    transaltions.sv['login.sendpass'] = 'Skicka nytt lösenord'


    transaltions.en['login.titleNewAccount'] = "New account"
    transaltions.sv['login.titleNewAccount'] = 'Nytt konto'

    transaltions.en['login.forEmp'] = "For the employee"
    transaltions.sv['login.forEmp'] = 'För den anställde'


    transaltions.en['login.newUser'] = "New user"
    transaltions.sv['login.newUser'] = 'Ny användare'

    transaltions.en['login.newSchedule'] = "New schedule"
    transaltions.sv['login.newSchedule'] = 'Nytt schema'

    transaltions.en['login.forCom'] = "For the company"
    transaltions.sv['login.forCom'] = 'För företaget'

    transaltions.en['login.sentResetMail'] = 'We have sent a reset link to your email.'
    transaltions.sv['login.sentResetMail'] = 'Vi har skickat en återställningslänk till din e-post.'



    transaltions.en['login.err.1'] = "There is no user corresponding to this email address. The user may have been deleted."
    transaltions.sv['login.err.1'] = 'Det finns ingen användare som motsvarar den här e-postadressen. Användaren kan ha tagits bort.'

    transaltions.en['login.err.2'] = "The password is invalid."
    transaltions.sv['login.err.2'] = 'Lösenordet är ogiltigt.'

    transaltions.en['login.err.3'] = "The email address is badly formatted."
    transaltions.sv['login.err.3'] = 'E-postadressen är dåligt formaterad.'

    transaltions.en['login.err.4'] = "The account has been disabled by an administrator."
    transaltions.sv['login.err.4'] = 'Kontot har inaktiverats av en administratör.'

    transaltions.en['login.err.5'] = "The password must be 6 characters long or more."
    transaltions.sv['login.err.5'] = 'Lösenordet måste vara 6 tecken långt eller mer.'



    transaltions.en['createNewAccount.newaccount'] = "Create new account"
    transaltions.sv['createNewAccount.newaccount'] = 'Skapa nytt konto'

    transaltions.en['createNewAccount.newSchedule'] = "Create new schedule"
    transaltions.sv['createNewAccount.newSchedule'] = 'Skapa nytt schema'

    transaltions.en['createNewAccount.theKey'] = 'The key you got from your manager'
    transaltions.sv['createNewAccount.theKey'] = 'Nyckeln du fick från din chef'

    transaltions.en['createNewAccount.secretKey'] = 'Secret key'
    transaltions.sv['createNewAccount.secretKey'] = 'Hemlig nyckel'

    transaltions.en['createNewAccount.comName'] = 'Company name'
    transaltions.sv['createNewAccount.comName'] = 'Företagsnamn'


    transaltions.en['createNewAccount.weekstart'] = 'Week starts with'
    transaltions.sv['createNewAccount.weekstart'] = 'Veckan börjar med'

    transaltions.en['createNewAccount.comDisplayName'] = 'Company display name'
    transaltions.sv['createNewAccount.comDisplayName'] = 'Företagets visningsnamn'

    transaltions.en['createNewAccount.adminAccount'] = 'Administrator account'
    transaltions.sv['createNewAccount.adminAccount'] = 'Administratörskonto'

    transaltions.en['createNewAccount.emailAndPass'] = 'Email and password'
    transaltions.sv['createNewAccount.emailAndPass'] = 'E-post och lösenord'

    transaltions.en['createNewAccount.useSameEmail'] = 'Use the same email you got in the invite. So we can mapp you to a user in the schedule'
    transaltions.sv['createNewAccount.useSameEmail'] = 'Använd samma email som du fick i inbjudan. Så vi kan mappa dig till en användare i schemat'

    transaltions.en['createNewAccount.youneedkey'] = 'You need to have a company key'
    transaltions.sv['createNewAccount.youneedkey'] = 'Du måste ha en företagsnyckel'

    transaltions.en['createNewAccount.youneedCompanyName'] = 'You need to have a company name'
    transaltions.sv['createNewAccount.youneedCompanyName'] = 'Du måste ha ett företagsnamn'

    transaltions.en['createNewAccount.terms'] = "I agree to the Terms and Conditions"
    transaltions.sv['createNewAccount.terms'] = 'Jag godkänner de allmänna villkoren'

    transaltions.en['createNewAccount.termsApprove'] = 'You need to approve the terms and conditions'
    transaltions.sv['createNewAccount.termsApprove'] = "Du måste godkänna villkoren"


    transaltions.en['setup.notValidKey'] = 'This is not a valid company key'
    transaltions.sv['setup.notValidKey'] = "Detta är inte en giltig företagsnyckel"

    transaltions.en['setup.creatingCompany'] = 'Creating business account ...'
    transaltions.sv['setup.creatingCompany'] = "Skapar företagskontoföretag ..."

    transaltions.en['setup.mappingUser'] = 'Matching user to a schedule ...'
    transaltions.sv['setup.mappingUser'] = "Matchar användare till ett schema ..."


    transaltions.en['setup.createNewAccount'] = 'Create a new user account'
    transaltions.sv['setup.createNewAccount'] = 'Skapa ett nytt användarkonto'

    transaltions.en['setup.wrongKey'] = 'Company key is missing or wrong'
    transaltions.sv['setup.wrongKey'] = 'Företags nyckel saknas eller fel'

    transaltions.en['setup.comKey'] = 'Company key'
    transaltions.sv['setup.comKey'] = 'Företags nyckel'

    transaltions.en['setup.needSetup'] = 'We have create your account successfully but we need to connect it to a company'
    transaltions.sv['setup.needSetup'] = 'Vi har skapat ditt konto framgångsrikt men vi måste ansluta det till ett företag'

    transaltions.en['schedual.emptyTitle'] = 'Your schedule is empty !'
    transaltions.sv['schedual.emptyTitle'] = 'Ditt schema är tomt!'

    transaltions.en['schedual.dropdelete'] = 'Drop shift/user here to remove it!'
    transaltions.sv['schedual.dropdelete'] = 'Släpp pass/användare här för att ta bort det!'

    transaltions.en['schedual.emptyTxt1'] = 'Use '
    transaltions.sv['schedual.emptyTxt1'] = 'Använd '

    transaltions.en['schedual.emptyTxt2'] = 'to add users or a new schedule'
    transaltions.sv['schedual.emptyTxt2'] = 'för att lägga till användare eller ett nytt schema'

    transaltions.en['locked.title'] = 'Your account is locked!'
    transaltions.sv['locked.title'] = 'Ditt konto är låst!'

    transaltions.en['locked.txt1'] = 'Your account is temporarily locked due to lack of credits.'
    transaltions.sv['locked.txt1'] = 'Ditt konto är tillfälligt låst på grund av brist på krediter.'

    transaltions.en['locked.txt2'] = "Don't worry it's an easy fix add more credit and in minutes it will be enabled again"
    transaltions.sv['locked.txt2'] = 'Oroa dig inte, det är en enkel lösning, lägg till mer kredit och om några minuter aktiveras det igen'

    transaltions.en['locked.link'] = "Click here to get more credits"
    transaltions.sv['locked.link'] = 'Klicka här för att få mer kredit'


    transaltions.en['schedual.last'] = 'Last updated'
    transaltions.sv['schedual.last'] = 'Senast uppdaterad'

    transaltions.en['schedual.openshifts'] = "Open shifts"
    transaltions.sv['schedual.openshifts'] = "Öppna skift"

    transaltions.en['schedual.m.print'] = 'Print'
    transaltions.sv['schedual.m.print'] = 'Skriv ut'

    transaltions.en['schedual.m.addNewUser'] = 'Add new user'
    transaltions.sv['schedual.m.addNewUser'] = 'Lägg till ny användare'

    transaltions.en['schedual.m.addOpenShifts'] = "Add 'open shifts'"
    transaltions.sv['schedual.m.addOpenShifts'] = "Lägg till 'öppna skift'"

    transaltions.en['schedual.m.addDiv'] = 'Add user divider'
    transaltions.sv['schedual.m.addDiv'] = 'Lägg till användar delare'

    transaltions.en['schedual.m.addNewSchedule'] = 'Add new schedule'
    transaltions.sv['schedual.m.addNewSchedule'] = 'Lägg till nytt schema'

    transaltions.en['schedual.m.copyS'] = 'Copy schedule'
    transaltions.sv['schedual.m.copyS'] = 'Kopia schema'

    transaltions.en['schedual.m.pasteS'] = 'Paste schedule'
    transaltions.sv['schedual.m.pasteS'] = 'Klistra in schemat'

    transaltions.en['schedual.m.undo'] = 'Undo all changes'
    transaltions.sv['schedual.m.undo'] = 'Ångra alla ändringar'

    transaltions.en['schedual.m.clear'] = 'Clear all shifts'
    transaltions.sv['schedual.m.clear'] = 'Rensa alla skift'

    transaltions.en['schedual.m.tools'] = 'Tools'
    transaltions.sv['schedual.m.tools'] = 'Verktyg'

    transaltions.en['schedual.editS'] = 'Edit schedules'
    transaltions.sv['schedual.editS'] = 'Redigera scheman'

    transaltions.en['schedual.addS'] = 'Add new schedule'
    transaltions.sv['schedual.addS'] = 'Lägg till nytt schema'

    transaltions.en['schedual.editS'] = 'Edit schedule'
    transaltions.sv['schedual.editS'] = 'Ändra schema'


    transaltions.en['schedual.addSFirstTitle'] = 'Add new schedule'
    transaltions.sv['schedual.addSFirstTitle'] = 'Lägg till nytt schema'


    transaltions.en['schedual.addSName'] = 'Schedule name'
    transaltions.sv['schedual.addSName'] = 'Schema namn'

    transaltions.en['schedual.deleteBtn1'] = 'Delete schedule'
    transaltions.sv['schedual.deleteBtn1'] = 'Ta bort schema'

    transaltions.en['schedual.deleteWarning'] = 'If you delete the schedule , It will be permanently delete  and all historical data to it'
    transaltions.sv['schedual.deleteWarning'] = 'Om du raderar schemat, kommer det att raderas permanent och alla historiska data till den'

    transaltions.en['schedual.deleteBtn2'] = 'Confirm permanently delete'
    transaltions.sv['schedual.deleteBtn2'] = 'Bekräfta permanent radering'



    transaltions.en['users.newuser'] = 'New'
    transaltions.sv['users.newuser'] = 'Ny'

    transaltions.en['users.uniqueemail'] = 'Email must be unique'
    transaltions.sv['users.uniqueemail'] = 'E-post måste vara unik'


    transaltions.en['users.linked'] = 'Linked'
    transaltions.sv['users.linked'] = 'Kopplat'

    transaltions.en['users.dname'] = 'Display name'
    transaltions.sv['users.dname'] = 'Visningsnamn'

    transaltions.en['users.dname'] = 'Display name'
    transaltions.sv['users.dname'] = 'Visningsnamn'

    transaltions.en['users.delete'] = `If you delete the user, it will be permanently delete!
    And all historical data will be marked with 'Unknown user'
    Sometimes it might be better just to mark the user as inactive.`
    transaltions.sv['users.delete'] = `Om du tar bort användaren kommer den att raderas permanent!
    Och alla historiska data kommer att markeras med "Okänd användare"
    Ibland kan det vara bättre att bara markera användaren som inaktiv.`

    transaltions.en['users.deleteBtn1'] = 'Delete user'
    transaltions.sv['users.deleteBtn1'] = 'Ta bort användare'

    transaltions.en['users.deleteBtn2'] = 'Confirm permanently delete'
    transaltions.sv['users.deleteBtn2'] = 'Bekräfta permanent radering'

    transaltions.en['user.active'] = 'Active'
    transaltions.sv['user.active'] = 'Aktiva'

    transaltions.en['user.unlockEmail'] = 'Unlock email'
    transaltions.sv['user.unlockEmail'] = 'Lås upp e-post'

    transaltions.en['user.bulkimport'] = 'Bulk import'
    transaltions.sv['user.bulkimport'] = 'Bulk import'

    transaltions.en['user.bulkTitle'] = 'Insert a comma-separated list with users'
    transaltions.sv['user.bulkTitle'] = 'Infoga en kommaseparerad lista med användare'

    transaltions.en['user.bulkTxt'] = 'Insert one user per row: (Display name,  Email) '
    transaltions.sv['user.bulkTxt'] = 'Infoga en användare per rad: (Visningsnamn, Email)'

    transaltions.en['user.bulkTxt2'] = 'Display name, Email* , Telephone* , Telephone2*'
    transaltions.sv['user.bulkTxt2'] = 'Visningsnamn, E-post*, Telefon*, Telefon 2*'

    transaltions.en['user.inviteUnMaped'] = 'Invite unlinked users'
    transaltions.sv['user.inviteUnMaped'] = 'Bjud in icke länkade användare'

    transaltions.en['user.allLinked'] = 'All users is linked'
    transaltions.sv['user.allLinked'] = 'Alla användare är länkade'

    transaltions.en['set.billing'] = 'Billing'
    transaltions.sv['set.billing'] = 'Fakturering'

    transaltions.en['set.settings'] = 'Settings'
    transaltions.sv['set.settings'] = 'Inställningar'

    transaltions.en['set.settings.title'] = 'System settings'
    transaltions.sv['set.settings.title'] = 'Systeminställningar'

    transaltions.en['set.settings.time'] = 'Time'
    transaltions.sv['set.settings.time'] = 'Tid'

    transaltions.en['or'] = 'or'
    transaltions.sv['or'] = 'eller'

    transaltions.en['set.settings.timeWarning'] = 'When changed you need to re-save your schedule data to renew old cached time labels'
    transaltions.sv['set.settings.timeWarning'] = 'När du ändrat måste du spara om dina schemalagda data för att förnya gamla cachade tidetiketter'

    transaltions.en['set.settings.auto'] = 'Automatic approve user shift changes'
    transaltions.sv['set.settings.auto'] = 'Automatisk godkänna ändringar i användarskiftet'

    transaltions.en['set.settings.btn.dev'] = 'Deviations'
    transaltions.sv['set.settings.btn.dev'] = 'Avvikelser'

    transaltions.en['set.settings.btn.com'] = 'Comments'
    transaltions.sv['set.settings.btn.com'] = 'kommentarer'

    transaltions.en['set.settings.btn.sCan'] = 'Remove shifts'
    transaltions.sv['set.settings.btn.sCan'] = 'Ta bort skift'

    transaltions.en['set.settings.btn.sMov'] = 'Move shifts'
    transaltions.sv['set.settings.btn.sMov'] = 'Flytta skift'

    transaltions.en['set.settings.btn.sSwp'] = 'Swap shifts'
    transaltions.sv['set.settings.btn.sSwp'] = 'Byta skift'

    transaltions.en['set.settings.btn.sCha'] = 'Change time'
    transaltions.sv['set.settings.btn.sCha'] = 'Ändra tid'

    transaltions.en['set.settings.btn.sik'] = 'Sick leave'
    transaltions.sv['set.settings.btn.sik'] = 'Sjukskrivning'


    transaltions.en['billing.msgHead'] = "Your account don't have enough credits."
    transaltions.sv['billing.msgHead'] = 'Ditt konto har inte tillräckligt med krediter.'

    transaltions.en['billing.msgBody'] = 'Please add more credit before to continue using the system'
    transaltions.sv['billing.msgBody'] = 'Lägg till mer kredit innan du fortsätter använda systemet'

    transaltions.en['billing.msgHead1'] = 'Please add more credit before'
    transaltions.sv['billing.msgHead1'] = 'Lägg till mer kredit innan '

    transaltions.en['billing.msgBodyDays'] = 'days'
    transaltions.sv['billing.msgBodyDays'] = 'dagar'

    transaltions.en['billing.msgHead2'] = 'or your account will automatically be locked'
    transaltions.sv['billing.msgHead2'] = 'eller ditt konto kommer automatiskt att vara låst'

    transaltions.en['billing.nextInvoice'] = 'Next invoice'
    transaltions.sv['billing.nextInvoice'] = 'Nästa faktura'

    transaltions.en['billing.bHistory'] = 'Billing history'
    transaltions.sv['billing.bHistory'] = 'Faktureringshistorik'

    transaltions.en['billing.infoTxt1'] = 'Every 30 day we will check the used credits vs you existing credits , if you are low on credit you will have 7 days to add more credits'
    transaltions.sv['billing.infoTxt1'] = 'Varje 30 dag kommer vi att kontrollera de använda krediterna mot dina existerande krediter, om du har låg kredit kommer du att ha 7 dagar att lägga till fler krediter'

    transaltions.en['billing.infoTxt2'] = 'after that your access will be disabled until you add more credit'
    transaltions.sv['billing.infoTxt2'] = 'därefter inaktiveras din åtkomst tills du lägger till mer kredit'

    transaltions.en['billing.infoTxt3'] = "If you don't want to continue using your schedule you can just skip adding new credits, after 7 days your account will be locked and after 360 day it will be deleted"
    transaltions.sv['billing.infoTxt3'] = 'Om du inte vill fortsätta använda ditt schema kan du bara hoppa över att lägga till nya krediter, efter 7 dagar kommer ditt konto att vara låst och efter 360 dagar kommer det att raderas'


    transaltions.en['wallet.title1'] = 'Buy more credit'
    transaltions.sv['wallet.title1'] = 'Köp mer kredit'

    transaltions.en['wallet.txtWeb1'] = 'At the moment we only support buying new credit thru Google Play or iOS App Store'
    transaltions.sv['wallet.txtWeb1'] = 'För närvarande stöder vi bara att köpa ny kredit via Google Play eller iOS App Store'

    transaltions.en['wallet.txtWeb2'] = "even if you only using TimeTo.Work at the web , you need to do the payments thru one of the stores. Please download the app from one of the stores and you will see a buy more credits button inside the app here"
    transaltions.sv['wallet.txtWeb2'] = 'även om du bara använder TimeTo.Work på webben, måste du göra betalningarna genom en av butikerna. Var god ladda ner appen från en av butikerna och du kommer se en köp mer krediter knapp i appen här'


    transaltions.en['billing.payDetails'] = 'Payment details'
    transaltions.sv['billing.payDetails'] = 'Betalningsinformation'

    transaltions.en['report.downloadall'] = 'Download all'
    transaltions.sv['report.downloadall'] = 'Ladda ner alla'

    transaltions.en['report.downloadExcel'] = 'Download excel'
    transaltions.sv['report.downloadExcel'] = 'Ladda Ner excel'


    transaltions.en['report.h_date'] = 'Date'
    transaltions.sv['report.h_date'] = 'Datum'

    transaltions.en['report.h_time'] = 'Time'
    transaltions.sv['report.h_time'] = 'Tid'

    transaltions.en['report.h_break'] = 'Break'
    transaltions.sv['report.h_break'] = 'Rast'

    transaltions.en['report.h_hours'] = 'Hours'
    transaltions.sv['report.h_hours'] = 'Timmar'

    transaltions.en['report.h_sik'] = 'Sick'
    transaltions.sv['report.h_sik'] = 'Sjuk'


    transaltions.en['report.h_workedhours'] = 'Worked hours'
    transaltions.sv['report.h_workedhours'] = 'Arbetade timmar'

    transaltions.en['report.h_presence'] = 'Presence'
    transaltions.sv['report.h_presence'] = 'Närvaro'

    transaltions.en['report.h_schedal'] = 'Schedule'
    transaltions.sv['report.h_schedal'] = 'Schema'


    transaltions.en['appmenu.user'] = 'User'
    transaltions.sv['appmenu.user'] = 'Användare'

    transaltions.en['appmenu.pushmessages'] = 'Push notifications'
    transaltions.sv['appmenu.pushmessages'] = 'Pushnotifikationer'

    transaltions.en['appmenu.changepass'] = 'Change password'
    transaltions.sv['appmenu.changepass'] = 'Ändra lösenord'

    transaltions.en['usernotmapped_txt1'] = 'You are not mapped to a user in the schedule'
    transaltions.sv['usernotmapped_txt1'] = 'Du är inte mappad till en användare i schemat'

    transaltions.en['usernotmapped_txt2'] = 'Ask your schema administrator to add your email address'
    transaltions.sv['usernotmapped_txt2'] = 'Be din schemaläggare om att lägga till din e-postadress'

    transaltions.en['usernotmapped_title'] = 'Important!'
    transaltions.sv['usernotmapped_title'] = 'Viktig!'


    transaltions.en['selectuser.warning'] = 'Select at least one user to add to the schedule'
    transaltions.sv['selectuser.warning'] = 'Välj minst en användare att lägga till i schemat'

    transaltions.en['selectuser.title'] = 'User list'
    transaltions.sv['selectuser.title'] = 'Användarlista'

    transaltions.en['editdivider.title'] = 'Group divider'
    transaltions.sv['editdivider.title'] = 'Gruppdelare'

    transaltions.en['userhome.youarefree'] = 'You are free to do whatever you like this week :)'
    transaltions.sv['userhome.youarefree'] = 'Du är fri att göra vad du vill den här veckan :)'


    transaltions.en['shiftuserinput.title0'] = 'Update shift'
    transaltions.sv['shiftuserinput.title0'] = 'Uppdatera skift'

    transaltions.en['shiftuserinput.title1'] = 'Actual working hours'
    transaltions.sv['shiftuserinput.title1'] = 'Faktisk arbetstid'

    transaltions.en['shiftuserinput.title2'] = 'Ask for a change'
    transaltions.sv['shiftuserinput.title2'] = 'Be om en ändring'

    transaltions.en['shiftuserinput.title3'] = 'Add a comment'
    transaltions.sv['shiftuserinput.title3'] = 'Lägg till en kommentar'

    transaltions.en['shiftuserinput.title4'] = 'Mark as sick'
    transaltions.sv['shiftuserinput.title4'] = 'Markera som sjuk'

    transaltions.en['shiftuserinput.btn1'] = 'Report time deviation'
    transaltions.sv['shiftuserinput.btn1'] = 'Rapportera tidsavvikelse'

    transaltions.en['shiftuserinput.btn2'] = 'Ask for a change'
    transaltions.sv['shiftuserinput.btn2'] = 'Be om en ändring'

    transaltions.en['shiftuserinput.btn3'] = 'Add a comment'
    transaltions.sv['shiftuserinput.btn3'] = 'Lägg till en kommentar'

    transaltions.en['shiftuserinput.btn4'] = 'Mark as sick'
    transaltions.sv['shiftuserinput.btn4'] = 'Markera som sjuk'

    transaltions.en['shiftuserinput.btn5'] = 'Cancel shift'
    transaltions.sv['shiftuserinput.btn5'] = "Avbryt skift"

    transaltions.en['shiftuserinput.btn6'] = 'Adjust time'
    transaltions.sv['shiftuserinput.btn6'] = "Justera tiden"

    transaltions.en['shiftuserinput.btn7'] = 'Assign shift to another user'
    transaltions.sv['shiftuserinput.btn7'] = "Tilldel skift till en annan användare"

    transaltions.en['shiftuserinput.btn8'] = 'Swap shift with another user'
    transaltions.sv['shiftuserinput.btn8'] = "Byt skift med en annan användare"

    transaltions.en['shiftuserinput.comment'] = 'Comment'
    transaltions.sv['shiftuserinput.comment'] = 'Kommentar'

    transaltions.en['shiftuserinput.swapTitle1'] = 'Select user to swap with'
    transaltions.sv['shiftuserinput.swapTitle1'] = 'Välj användare att byta med'

    transaltions.en['shiftuserinput.swapTitle2'] = 'Select week'
    transaltions.sv['shiftuserinput.swapTitle2'] = 'Välj vecka'

    transaltions.en['shiftuserinput.swapTitle3'] = 'Select Shift'
    transaltions.sv['shiftuserinput.swapTitle3'] = 'Välj Skift'


    transaltions.en['shiftuserinput.addCommentToUser'] = 'Add comment to the user'
    transaltions.sv['shiftuserinput.addCommentToUser'] = 'Lägg till kommentar till användaren'

    transaltions.en['shiftuserinput.cancelation'] = 'Cause of cancellation'
    transaltions.sv['shiftuserinput.cancelation'] = 'Orsak till avbokning'



    transaltions.en['request.adminRequest'] = 'Request'
    transaltions.sv['request.adminRequest'] = 'Förfrågan'

    transaltions.en['request.titleCancle'] = 'Cancel shift'
    transaltions.sv['request.titleCancle'] = 'Avbryt skift'

    transaltions.en['request.titleChange'] = 'Change time'
    transaltions.sv['request.titleChange'] = 'Ändra tid'

    transaltions.en['request.titleMove'] = 'Move shift'
    transaltions.sv['request.titleMove'] = 'Flytta skiftet'

    transaltions.en['request.titleSwap'] = 'Swap shift'
    transaltions.sv['request.titleSwap'] = 'Byt skift'

    transaltions.en['request.titleTime'] = 'Time deviation'
    transaltions.sv['request.titleTime'] = 'Tidavvikelse'

    transaltions.en['request.titleSick'] = 'Sick'
    transaltions.sv['request.titleSick'] = 'Sjuk'

    transaltions.en['request.titleComment'] = 'Comment'
    transaltions.sv['request.titleComment'] = 'Kommentar'



    transaltions.en['wallet.waiting_for_validation'] = 'Waiting for validation.'
    transaltions.sv['wallet.waiting_for_validation'] = ''

    transaltions.en['wallet.pleasewait'] = 'please wait'
    transaltions.sv['wallet.pleasewait'] = ''

    transaltions.en['wallet.success'] = 'Success.'
    transaltions.sv['wallet.success'] = ''

    transaltions.en['wallet.success1'] = 'the credits will come in to you account shortly'
    transaltions.sv['wallet.success1'] = ''

    transaltions.en['wallet.faild'] = 'Failed to purchase.'
    transaltions.sv['wallet.faild'] = ''

    transaltions.en['wallet.faild'] = 'contact support if you have any questions'
    transaltions.sv['wallet.faild1'] = ''


    transaltions.en['wallet.purchasing'] = ''
    transaltions.sv['wallet.purchasing'] = ''

    transaltions.en['wallet.failed'] = 'Failed to purchase.'
    transaltions.sv['wallet.failed'] = ''

    transaltions.en['wallet.failed1'] = 'contact support if you have any questions'
    transaltions.sv['wallet.failed1'] = ''


    transaltions.en['wallet.restored'] = 'The purchase of has been restored.'
    transaltions.sv['wallet.restored'] = ''

    transaltions.en['wallet.deferred'] = 'The purchase of has been deferred.'
    transaltions.sv['wallet.deferred'] = ''


    transaltions.en['appdiv.pushmessages'] = 'Push messages'
    transaltions.sv['appdiv.pushmessages'] = 'Push-meddelanden'

    transaltions.en['appdiv.changepass'] = 'Change password'
    transaltions.sv['appdiv.changepass'] = 'Ändra lösenord'


    transaltions.en['schedual.adminrequest'] = 'Administrator'
    transaltions.sv['schedual.adminrequest'] = 'Administratör'



    // transaltions.en['schedual.'] = ''
    // transaltions.sv['schedual.'] = ''


    //{window._getText('shiftuserinput.cancelation')}


}

export const setLang = (lang) => {
    selectedLang = lang
}

export const text = (key) => {
    var tran = transaltions[selectedLang][key]
    if (!tran)
        tran = transaltions['en'][key] //Fallback

    if (!tran) {
        console.log('#ERR# LANG KEY MISSING #', key)
        console.log('selectedLang', selectedLang)
        console.log('transaltions', transaltions)
        tran = key
    }

    return tran
}
window._getText = text

