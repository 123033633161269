import React, { Component } from "react";
import { Label, Segment, Header } from "../../common/component/Button";
import MenuSchema from "../../common/menu/MenuSchema";

class Help extends Component {
  render() {
    return (
      <div>
        <div style={{ width: '60px', borderRight: 'solid 1px var(--color-border)', position: 'absolute', top: '0px', left: '0px', zIndex: '100', backgroundColor: '#f1f1f1', height: '100%' }}>
          <MenuSchema />
        </div>
        <div style={{ marginLeft: '66px', borderTop: 'solid 1px var(--color-border)' }}>
          <Segment attached style={{ border: 'none' }}>
            <Header as="h2" >
              Help
            </Header>
            This is the <Label color="blue">help x</Label> page
          </Segment>
        </div>
      </div>
    );
  }
}

export default Help;
