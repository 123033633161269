import React from "react";
import TextMessagesMessage from './TextMessagesMessage'
import { FireHelper } from '../../common/util/FireHelper'
//import { toTop } from '../../common/util/helpers'

//import { Icon, Button } from '../../common/component/Button'

import SelectUsers from '../modal/SelectUsers'

const shortid = require('shortid');


const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});
class TextMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedChanel: 'All', tbValue: '', isLoading: false, chatSearch: '' };
  }




  componentWillUnmount = () => {
    window.removeEventListener('newchat', this.openUserDialog);

  }

  componentDidMount = () => {
    window.addEventListener('newchat', this.openUserDialog);

  }

  componentDidUpdate = (prevProps, prevState, snapshot) => {

    if (prevProps.currentUser.alerts) {
      if (this.props.currentUser.alerts.m[this.state.selectedChanel] && this.props.currentUser.alerts.m[this.state.selectedChanel].m !== undefined && this.state.isLoading === true) {
        if (this.state.onTimer === false)
          this.setState({ isLoading: false })
      }
    }
  }

  setChanel = (cKey) => () => {
    console.log('Set cha>', cKey)
    this.setState({ selectedChanel: cKey, selectedUsers: null })
  }

  sendMsg = () => {
    const val = this.state.tbValue
    if (val.length > 0) {
      this.setState({ tbValue: '', isLoading: true, onTimer: true })
      FireHelper.setChatMesage(this.state.selectedChanel, val, this.props.currentUser)
      setTimeout(() => {
        clearTimeout(this.loadingTimer)
        this.setState({ isLoading: false, onTimer: false })
      }, 1005);
    }

  }

  openUserDialog = () => {

    let availableUsersList = [...this.props.userlist]
    availableUsersList.forEach(user => {
      if (user && user.selected)
        delete user.selected
    });
    this.setState({ selectedUsers: null, chanelDataKey: null })

    this.props.openModal('selectUsers')
  }

  createNewChat = (users) => {



    var selectedUser = users.map(u => {
      if (u && u.selected === true) {
        delete u.selected
        return u
      }
      return null
    })
    selectedUser = selectedUser.filter(u => u !== null);
    var chatName = selectedUser.map(u => u.text).toString()
    var chatIds = selectedUser.map(u => u.sysKey)

    if (chatIds.length > 0) {
      chatIds.push(this.props.currentUser.key)
      if (this.state.chanelDataKey) {
        FireHelper.modifyChat(chatName, this.state.chanelDataKey, chatIds, this.props.currentUser)
        this.setState({ selectedChanel: this.state.chanelDataKey, selectedUsers: null, chanelDataKey: null })
      }
      else {
        var newId = shortid.generate()
        this.props.currentUser.alerts.m[newId] = { n: window._getText('loading') }
        FireHelper.createChat(chatName, chatName, newId, chatIds, this.props.currentUser)
        this.setState({ isLoading: false, selectedChanel: newId, selectedUsers: null, chanelDataKey: null })
      }

    }

  }
  onSelectDefault = () => {
    this.setChanel('All')()
  }
  onDeleteChat = (chanelData) => {

    delete this.props.currentUser.alerts.m[chanelData.key]
    FireHelper.deleteChat(chanelData.key, this.props.currentUser)
    this.setChanel('All')()
  }
  onEditParticipants = (chanelData) => {
    let availableUsersList = [...this.props.userlist]
    availableUsersList.forEach(user => {
      if (user && user.selected)
        delete user.selected
    });
    this.setState({ selectedUsers: chanelData.p, chanelDataKey: chanelData.key })
    this.props.openModal('selectUsers')
  }

  onKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.sendMsg()
    }
  }

  render() {


    //toTop(document.body.scrollHeight)

    const selectedUser = this.state.selectedUsers
    const chatSearch = this.state.chatSearch
    let availableUsersList = [...this.props.userlist]
    availableUsersList = availableUsersList.filter(u => u !== null);
    let currentChats = []

    if (selectedUser) {
      selectedUser.forEach(userKey => {
        var index = availableUsersList.findIndex(u => u && u.sysKey === userKey)
        if (index > -1)
          availableUsersList[index].selected = true
      });
    }



    if (this.props.currentUser.alerts && this.props.currentUser.alerts.m) {
      var chatChanles = this.props.currentUser.alerts.m
      currentChats = Object.keys(chatChanles).map(o => {
        return ({ date: chatChanles[o].d || 0, key: o, message: chatChanles[o].m, name: chatChanles[o].n || 'chat' })
      })
      currentChats = currentChats.sort((a, b) => {

        if (!a.name || !b.name)
          return 0

        const aName = (a.name || '').toLowerCase()
        const bName = (b.name || '').toLowerCase()

        if (aName < bName) return -1
        if (aName > bName) return 1
        return 0
      })
    }


    if (chatSearch && chatSearch !== '') {
      currentChats = currentChats.filter(item => {
        if (!item)
          return null

        return (item.name || '').toLowerCase().indexOf(chatSearch) > -1
      })
    }


    // console.log('this.props.isKeyboardOpen', this.props.isKeyboardOpen)
    // console.log('this.props.isLandscape', this.props.isLandscape)
    // console.log('isMobile', isMobile)

    // let marginBottom = '0px'

    //    if (isMobile)
    //    marginBottom = (this.props.isKeyboardOpen === true) ? '72px' : '0px'

    // console.log('this.props.isKeyboardOpen', this.props.isKeyboardOpen)

    return (
      <React.Fragment>
        <div style={{ flexDirection: 'row', display: 'flex', height: '100%', width: '100%' }}>
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', border: 'solid 0px red', display: 'flex', flexGrow: '1', alignItems: 'stretch' }}>

            <div style={{ maxWidth: '250px', minWidth: '250px', background: 'rgba(204,204,204,0.1)', borderRight: 'solid 1px var(--color-border)' }}>
              {/* {(this.props.currentUser.role > 100) && <Button compact color='blue' icon='comment outline' content={window._getText('new')} onClick={this.openUserDialog}></Button>} */}
              {/* <div onClick={this.setChanel('All')} key='All' style={{ cursor: 'pointer', fontWeight: this.state.selectedChanel === 'All' ? 'bold' : 'normal' }}>All</div> */}



              <div style={{ paddingTop: '12px', paddingBottom: '12px', paddingLeft: '14px', paddingRight: '5px', borderBottom: 'solid 1px var(--color-border)', color: '#000', display: 'flex', fontSize: '12px' }}>

                <input
                  className="form-control"
                  icon="search"
                  placeholder={window._getText('search')}
                  onChange={(e) => this.setState({ chatSearch: e.target.value })}
                  style={{ height: '10px', marginRight: '8px' }}

                />



              </div>


              <div style={{ overflowY: 'auto', overflowX: 'hidden', height: 'calc(100% - 90px)', border: 'solid 0px green' }}>
                {
                  currentChats.map(chatKey => {
                    return (
                      <div onClick={this.setChanel(chatKey.key)} key={chatKey.key} style={{ borderBottom: 'dotted 1px var(--color-border)', cursor: 'pointer', fontWeight: chatKey.message === 1 ? 'bold' : 'normal' }}>
                        {chatKey.name === 'All' &&
                          <div style={{
                            padding: '16px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '12px',
                            borderLeft: this.state.selectedChanel === chatKey.key ? 'solid 3px rgb(251, 189, 8)' : 'solid 3px transparent',
                            backgroundColor: this.state.selectedChanel === chatKey.key ? 'rgba(204,204,204,0.3)' : ''
                          }}>
                            {chatKey.message === 1 && <i aria-hidden="true" className="building  icon" style={{ fontSize: '20px' }}></i>}
                            {chatKey.message !== 1 && <i aria-hidden="true" className="building outline icon" style={{ fontSize: '20px' }}></i>}
                            {chatKey.name}
                          </div>}
                        {chatKey.name === 'system' &&
                          <div style={{
                            padding: '16px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '12px',
                            borderLeft: this.state.selectedChanel === chatKey.key ? 'solid 3px rgb(251, 189, 8)' : 'solid 3px transparent',
                            backgroundColor: this.state.selectedChanel === chatKey.key ? 'rgba(204,204,204,0.3)' : ''
                          }}>
                            {chatKey.message === 1 && <i aria-hidden="true" className="folder  icon" style={{ fontSize: '20px' }}></i>}
                            {chatKey.message !== 1 && <i aria-hidden="true" className="folder outline icon" style={{ fontSize: '20px' }}></i>}
                            Log
                      </div>}
                        {chatKey.name !== 'system' && chatKey.name !== 'All' &&

                          <div style={{
                            position: 'relative', padding: '16px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '12px',
                            borderLeft: this.state.selectedChanel === chatKey.key ? 'solid 3px rgb(251, 189, 8)' : 'solid 3px transparent',
                            backgroundColor: this.state.selectedChanel === chatKey.key ? 'rgba(204,204,204,0.3)' : ''
                          }}>
                            {chatKey.message === 1 && <i aria-hidden="true" className="comments  icon" style={{ fontSize: '20px' }}></i>}
                            {chatKey.message !== 1 && <i aria-hidden="true" className="comments outline icon" style={{ fontSize: '20px' }}></i>}



                            {chatKey.name}<br></br>
                            {/* <span style={{ color: 'silver', fontSize: '11px' }}>{12} participants</span>

                            <span style={{ color: 'silver', position: 'absolute', right: '10px', top: '0px', fontSize: '9px' }}>23 feb 20:33</span> */}
                          </div>}
                      </div>
                    )
                  })
                }

              </div>
              <div style={{ flexGrow: '1' }}></div>
              <div style={{ border: 'solid 0px red', height: '40px', padding: '6px', borderTop: 'solid 1px var(--color-border)', background: 'rgba(204,204,204,0.1)', }}>
                {(this.props.currentUser.role > 100) &&
                  <button tooltip_t={'Add a new chat'} style={{ marginRight: '8px', marginLeft: '8px' }} className="btn btn-default" onClick={this.openUserDialog}>
                    <span className="ico ico-plus"></span>
                  </button>
                }

              </div>
            </div>


            <div style={{ width: 'calc(100% - 250px)', flexGrow: '1', display: 'flex', flexDirection: 'column-reverse' }}>


              <div style={{ padding: '3px' }} >
                <div style={{ display: 'flex', height: '40px', padding: '5px', alignItems: 'flex-end', flexDirection: 'row' }}>
                  <input
                    className="form-control"
                    style={{ resize: 'none', flexGrow: 1, marginRight: '8px', height: '10px' }}
                    value={this.state.tbValue} maxLength={500}
                    onChange={event => { this.setState(byPropKey('tbValue', event.target.value)) }}
                    onKeyPress={this.onKeyPress}

                  />

                  <button style={{ marginRight: '8px', marginLeft: '8px' }} onClick={this.sendMsg} className="btn btn-default" disabled={this.state.isLoading}>
                    <span className="ico ico-comment"></span>

                  </button>

                </div>
              </div>
              <div style={{ flexGrow: '1', flexDirection: 'column', display: 'flex', alignContent: 'flex-end', }}>
                <TextMessagesMessage onSelectDefault={this.onSelectDefault} chanelKey={this.state.selectedChanel} onEditParticipants={this.onEditParticipants} onDeleteChat={this.onDeleteChat} />
              </div>

            </div>
            <div style={{ clear: 'both' }}></div>
          </div>
        </div>
        <SelectUsers availableUsers={availableUsersList} onAddSelectedUsers={this.createNewChat}></SelectUsers>
      </React.Fragment>
    )
  }
}

export default TextMessages;

