import React, { Component } from "react";
import { withRouter } from "react-router-dom";
// import Dimmer from 'semantic-ui-react/dist/commonjs/modules/Dimmer'
// import Loader from 'semantic-ui-react/dist/commonjs/elements/Loader'

import { connect } from 'react-redux'
import { isMobile } from 'react-device-detect';
import { toastr } from 'react-redux-toastr'
import firebase from './app/config/firebase';
import "./css/App.css";
//import imgLogo from './img/48x48.png'
import { version } from '../package.json'
import PropTypes from 'prop-types'

import AppRouter from "./app/pages/router/AppRouter";
import LoginRouter from "./app/pages/router/LoginRouter";

//import { fetchUser } from './app/redux/action/authUserAction';
import { setOrientation, setKeyboardOpen } from './app/redux/action/viewstatAction';
import { FireHelper } from './app/common/util/FireHelper'

//import EditEventModal from './app/pages/modal/EditEventModal';

const shortid = require('shortid');


require('moment/min/locales.min') //we need this , locale else we always have EN locale


var isElectronApp = (process.env.REACT_APP_ELECTRON === '1')
let ipcRenderer
isElectronApp = !!window.require //TEMP
if (isElectronApp) {
  ipcRenderer = window.require('electron').ipcRenderer
}

class App extends Component {
  static contextTypes = { router: PropTypes.object }
  state = { loggedIn: false, haveUser: false, oldVersion: false, token: null }

  onNotificationOpen = (notification) => {
    //todo check if its in the forground or not!
    this.context.router.history.push('/messages')
  }
  onTokenRefresh = (e) => {
    // TODO ADD TO redux
    window._webToken = e.detail
    this.setState({ token: e.detail })
  }

  getToken = (e) => {
    // TODO ADD TO redux
    window._webToken = e.detail
    this.setState({ token: e.detail })
  }
  onMessagePush = (payload) => {
    console.log('onMessagePush>not>', payload)
    if (payload.detail)
      payload = payload.detail

    try {
      if (payload.notification)
        toastr.success(payload.notification.title || '', payload.notification.body || '');
      else if (payload.data)
        toastr.success(payload.data.title || '', payload.data.body || '');
    } catch (error) {
      console.log('#err msg in>', error)
    }
  }

  appCmd = (e, cmd) => {
    console.log('>appCmd', cmd)
    console.log('cmd.cmd', cmd.cmd)
    window.dispatchEvent(new CustomEvent(cmd.cmd, { detail: { ...cmd.arg } }));
  }
  componentDidMount = () => {

    if (ipcRenderer)
      ipcRenderer.on('appCmd', this.appCmd)

    // console.log('window.FirebasePlugin',window.FirebasePlugin)
    window.addEventListener("onMessagePush", this.onMessagePush)
    window.addEventListener("onNotificationOpen", this.onNotificationOpen)
    window.addEventListener("onTokenRefresh", this.onTokenRefresh)
    window.addEventListener("getToken", this.getToken)
    window.addEventListener('resize', this.resize)
    window.addEventListener('orientationchange', this.orientation)

    this.orientation()

    //console.log('versionxx', version)

    fetch("/version.js?" + shortid.generate())
      .then(x => x.text())
      .then(
        (result) => {
          if (result !== version) {
            this.setState({ oldVersion: true })
            console.log('version DONT match')
          }
        },
        (error) => console.log('#err 5jdu7 ', error)
      )


  }
  componentWillUnmount = () => {
    window.removeEventListener("orientationchange", this.orientation)
    window.removeEventListener('resize', this.resize)

    if (ipcRenderer)
      ipcRenderer.removeListener('appCmd', this.appCmd)
  }

  calcVH = () => {
    var vH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    document.documentElement.style.setProperty('--vh', vH + 'px');
  }

  isKeyboardOpen = false
  resize = () => {
    //  console.log('resize',window.screen.orientation)
    // this.calcVH()
    // console.log('>>1resize sisLandscape:', window.innerHeight > window.innerWidth)



    if (isMobile && window.screen.orientation) {
      const angel = window.screen.orientation.angle
      if (angel === 180 || angel === 0) {
        if (window.innerHeight < window.innerWidth) {
          // console.log('Keyboard')
          this.isKeyboardOpen = true
          document.body.classList.add('keyboardOpen');
          // document.documentElement.style.setProperty('--bottom-menu-height', '50px');
        }
        else {
          document.body.classList.remove('keyboardOpen');
          this.isKeyboardOpen = false
          // document.documentElement.style.setProperty('--bottom-menu-height', '0px');
        }

        this.props.setKeyboardOpen(this.isKeyboardOpen)
        // this.props.setOrientation(false)
      }
      //else
      //   this.props.setOrientation(true)
    }
    else {
      // console.log('>>isKeyboardOpen', this.isKeyboardOpen)
      if (window.innerHeight > window.innerWidth) {
        if (this.props.isLandscape !== false) {
          //  this.props.setOrientation(false)
        }
      } else {
        if (this.props.isLandscape !== true) {
          //  this.props.setOrientation(true)
        }
      }
    }


  }

  orientation = () => {
    // console.log('!!!!!!sorientationchange',window.orientation)
    //  this.calcVH()

    if (isMobile && window.orientation !== undefined) {
      const orientation = window.orientation

      this.props.setOrientation((orientation === -90 || orientation === 90))


    }
    else {
      // console.log('orientation old', window.innerHeight > window.innerWidth)
      // console.log('isKeyboardOpen', this.isKeyboardOpen)

      if (window.innerHeight > window.innerWidth) {
        if (this.props.isLandscape !== false) {
          this.props.setOrientation(false)
        }
      } else {
        if (this.props.isLandscape !== true) {
          this.props.setOrientation(true)
        }
      }
    }


    // if (window.innerHeight > window.innerWidth) {
    //   if (this.props.isLandscape !== false) {
    //     this.props.setOrientation(false)
    //   }
    // } else {
    //   if (this.props.isLandscape !== true) {
    //     this.props.setOrientation(true)
    //   }
    // }
  }


  componentDidUpdate = async (prevProps, prevState, snapshot) => {


    if (this.props.currentUser.isLoaded && this.props.authUser.isLoaded) {


      if (this.props.currentUser !== prevProps.currentUser && this.props.authUser && this.props.authUser.isLoaded === true && this.props.authUser.isEmpty === false) {
        // console.log('TRY TO GET PROFILE')
        // console.log('componentDidUpdate CHANGE', this.props.authUser)
        // console.log('componentDidUpdate CHANGE', this.props.currentUser)

        // FireHelper.reloadToken(true).then(x=>console.log(x.claims))

        try {
          // await firebase.reloadAuth();
          const idTokenResult = await FireHelper.reloadToken(false)

          //console.log('idTokenResult.claims', idTokenResult.claims)
          // console.log('idTokenResult.claims.role', idTokenResult.claims.role)
          // console.log('idTokenResult.claims.pKey', idTokenResult.claims.pKey)
          //if (idTokenResult.claims.role && idTokenResult.claims.pKey)
          // alert('ok redirect', idTokenResult.claims.pKey)
          if (idTokenResult.claims.role >= 50 && idTokenResult.claims.pKey) {
            //console.log('---================>componentDidMount REDIRECT3.1')
            if (this.state.haveUser === false)
              this.setState({ haveUser: true })
          }
          else {

            await firebase.reloadAuth().then(async () => {
              // alert('todo wee need to know when cloudfunctions is done!, use httpfunctions och vänta på response ?')
              const idTokenResult2 = await FireHelper.reloadToken(true)
              // console.log('22idTokenResult2.claims.role', idTokenResult2.claims.role)
              // console.log('22idTokenResult2.claims.pKey', idTokenResult2.claims.pKey)
              if (idTokenResult2.claims.role >= 50 && idTokenResult2.claims.pKey) {
                //  console.log('---================>componentDidMount REDIRECT3.2')
                if (this.state.haveUser === false)
                  this.setState({ haveUser: true })
              }
            })
          }

        } catch (error) {
          console.log(error)
        }


      }
      else {
        if (this.state.haveUser === true && this.props.authUser && this.props.authUser.isEmpty === true) {
          // console.log('KILL haveuser')
          // console.log('kill CHANGE', this.props.authUser)
          // console.log('kill CHANGE', this.props.currentUser)
          this.setState({ haveUser: false })
        }


      }
    }
    //console.log('app>componentDidUpdate')
    //   if (!this.state.loggedIn) {
    //     console.log('read token 2')
    //     FireHelper.reloadToken(false).then(token => {
    //       console.log('1token.claims.role', token.claims.role)
    //       console.log('token.claims.role', token.claims.role >= 50)
    //       console.log('token.claims.pKey', token.claims.pKey)
    //       console.log('token.claims.role >= 50 && token.claims.pKey2 ', ((token.claims.role >= 50) && token.claims.pKey))
    //       if (token.claims.role >= 50 && token.claims.pKey) {
    //         this.setState({ loggedIn: true })
    //       }
    //     })
    //   }
  }




  render() {
    const { currentUser } = this.props




    if (currentUser && currentUser.projectKey && window.location.pathname !== '/newschedule' && window.location.pathname !== '/newuser' && window.location.pathname !== '/login' && window.location.pathname !== '/setup') {

      return (
        <React.Fragment>

          {this.state.oldVersion &&
            <div style={{ textAlign: 'center', left: '0px', right: '0px', fontSize: '10px', position: 'absolute', top: '-2px', zIndex: '999999999999' }}>
              <span style={{ backgroundColor: '#eff7b2', borderRadius: '5px', paddingLeft: '10px', paddingRight: '10px', paddingTop: '10px' }}>
                There is a new version out . please close all instances of the app and reopen it
              </span>
            </div>
          }
          <AppRouter token={this.state.token} currentUser={currentUser} haveUser={this.state.haveUser} />
        </React.Fragment>
      );
    }

    if (this.props.currentUser.isLoaded === false && window.location.pathname !== '/newschedule' && window.location.pathname !== '/newuser' && window.location.pathname !== '/login' && window.location.pathname !== '/setup') {
      // alert('loader 0')


      return ('')
      // <div className='cc'>

      //   <img alt='TimeTo.Work' src='/128x128.png' />

      // </div>
      //)

      // return (
      //   <Dimmer active inverted >
      //     <div style={{ alignContent: 'center', alignItems: 'center', }}>
      //       <Loader  >
      //         <h2>
      //           xxxxx {window._getText('logginin')}

      //         </h2>
      //         <div>
      //           {/* If you stuck herer ..TODO logout button */}
      //         </div>
      //       </Loader>
      //     </div>
      //   </Dimmer>
      // )
    }

    //if we dont have a project go to login router
    return (
      <LoginRouter currentUser={currentUser} haveUser={this.state.haveUser} />
    )

  }
}


const mapStateToProps = (state) => {
  return {
    isLandscape: state.viewstate.isLandscape,
    authUser: state.firebase.auth, currentUser: state.firebase.profile
  };
}
const mapActions = { setOrientation, setKeyboardOpen }
//
export default withRouter(connect(mapStateToProps, mapActions)(App))


