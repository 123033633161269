import React, { Component } from 'react'
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon'
import { Button } from '../../common/component/Button'
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types'



export default class MobileHeadModal extends Component {
    static contextTypes = { router: PropTypes.object }
    onOpenAppMenuModal = () => {
        this.props.onOpenModal('appMenu')
        // this.context.router.history.push('/signout')
    }

    render() {
        if (!isMobile)
            return null;

        return (

            <div
                className='mobileModalHeader'
            >

                {this.props.onBack &&
                    <div onClick={this.props.onBack} style={{ fontSize: '16px', position: 'absolute', left: '8px', top: '10px' }}><Icon size='large' name='arrow left'></Icon></div>
                }

                {!this.props.onBack &&
                    <div onClick={this.onOpenAppMenuModal} style={{ fontSize: '16px', position: 'absolute', left: '8px', top: '10px' }}><Icon size='large' name='user circle'></Icon></div>
                }

                {(this.props.onSave && this.props.showSave) &&
                    <Button compact color='green' style={{ fontSize: '16px', position: 'absolute', right: '12px', top: '12px', padding: '10px' }} onClick={this.props.onSave} content='Save' icon='save'></Button>

                }

                {(this.props.onSignout) &&
                    <Button compact color='blue' style={{ fontSize: '12px', position: 'absolute', right: '12px', top: '8px', padding: '10px' }} onClick={this.props.onSignout} content='Signout' icon='log out'></Button>

                }


                <b style={{ display: 'inline', margin: 'auto', fontSize: '16px' }}>{this.props.title || ''}</b>



            </div>

        )
    }
}

