import React from 'react'
import ReactDOM from 'react-dom'
import ScheduleToday from '../schedule/ScheduleToday'
//var isElectronApp = (process.env.REACT_APP_ELECTRON === '1')
//let ipcRenderer
//isElectronApp = !!window.require
// if (isElectronApp) {
//   ipcRenderer = window.require('electron').ipcRenderer


//   ipcRenderer.on('window-manager', (event, result) => {
//     console.log('window-manager CLOSED')
//   })

// }
let modal = null

function copyStyles(sourceDoc, targetDoc) {

  Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
    if (styleSheet.cssRules) { // true for inline styles
      const newStyleEl = targetDoc.createElement('style');

      Array.from(styleSheet.cssRules).forEach(cssRule => {
        newStyleEl.appendChild(targetDoc.createTextNode(cssRule.cssText));
      });

      targetDoc.head.appendChild(newStyleEl);
    } else if (styleSheet.href) { // true for stylesheets loaded from a URL
      const newLinkEl = targetDoc.createElement('link');

      newLinkEl.rel = 'stylesheet';
      newLinkEl.href = styleSheet.href;
      targetDoc.head.appendChild(newLinkEl);
    }
  });
}

export default class ElectronTodayPortal extends React.PureComponent {
  modalOnunload = () => {
    console.log('>>>modalOnunload')
    if (this.props.modalOnunload)
      this.props.modalOnunload()
  }
  constructor(props) {
    super(props);


    var name = 'todayWindow'
    //var options = props.options || {width:300}
    // STEP 1: create a container <div>
    this.containerEl = document.createElement('div');
    //this.containerEl.style.marginTop ='20px'
    modal = null;
    modal = window.open('', name, 'transparent=true')
    console.log('OPEn tootl windows')
    //modal.document.write("<base href='http://" + document.location.host + "' />");


    modal.document.head.innerHTML = modal.document.head.innerHTML + "<base href='" + document.location.href + "' />";

    copyStyles(document, modal.document);
    modal.onunload = this.modalOnunload;
    // modal.onload = ()=>{console.log('hehhe')}
    var innerscript = document.createElement('script');
    innerscript.innerHTML = "window.require('electron').ipcRenderer.on('setColor', function (event,color) { document.documentElement.setAttribute('data-theme', color) })"
    modal.document.head.append(innerscript)

    modal.document.body.innerHTML = '';
    modal.document.body.appendChild(this.containerEl)
    modal.document.body.style.overflow = 'hidden'
    modal.document.body.style.backgroundColor = 'transparent'

  }

  render() {
    if (!this.containerEl) {
      console.log('null render')
      return null;
    }


    //return <ScheduleToday></ScheduleToday>
    return ReactDOM.createPortal(<ScheduleToday></ScheduleToday>, this.containerEl);
  }

  componentDidMount() {
    // STEP 3: open a new browser window and store a reference to it
    // this.externalWindow = window.open('', '', 'width=400,height=300,left=200,top=200,status=no,titlebar=no,toolbar=no');

    // const BrowserWindow = window.require('electron').remote.BrowserWindow;
    // let parent = BrowserWindow.getFocusedWindow();
    // var win = new BrowserWindow({ parent: parent, modal: true, frame: true, width: 500, height: 500 });
    // win.setMenu(null);
    // win.loadURL('http://localhost:3000/jens');

    // var meta = document.createElement('meta');
    // meta.httpEquiv = "Content-Security-Policy";
    // meta.content = "default-src * gap://ready https://ssl.gstatic.com data:; img-src *  'self' data: blob:;font-src * 'unsafe-inline' 'self' data: blob:; style-src * 'unsafe-inline' data:; script-src * 'self' 'unsafe-inline' 'unsafe-eval';";
    // modal.document.getElementsByTagName('head')[0].appendChild(meta);

    if (this.props.doRerender)
      this.props.doRerender()


    //ipcRenderer.send('open-window', 'ping')

    // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
    //this.externalWindow.document.body.appendChild(this.containerEl);
  }

  componentWillUnmount() {
    // STEP 5: This will fire when this.state.showWindowPortal in the parent component becomes false
    // So we tidy up by closing the window
    if (modal)
      modal.close();
  }
}